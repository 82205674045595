<template>
	<div>
		<portal-target name="register-header" />

		<v-card elevation="2">
			<v-tabs>
				<v-tab v-for="tab in tabs" :key="tab.path" :to="tab.path">
					{{ tab.label }}
				</v-tab>
			</v-tabs>
			<div class="pa-6">
				<router-view />
			</div>
		</v-card>
	</div>
</template>

<script>

export default {
	computed: {
		tabs() {
			return [{
				path: '/profile/accounting-template',
				label: 'Konteringsmallar'
			}, {
				path: '/profile/user-replacement',
				label: 'Ersättarhantering'
			}].filter(Boolean);
			// , {
			// 	path: '/profile/settings',
			// 	label: 'Inställningar'
			// }, {
			// 	path: '/profile/references',
			// 	label: 'Referenskoder'
			// }

		}
	}
};
</script>
