import i18n from '@/plugins/i18n';
import utils from '@/utils';
import component from './invoices.vue';
import Invoices from './children/invoices/invoices.vue';
import FaultyInvoices from './children/faulty-invoices/faulty-invoices.vue';
import RejectedInvoices from './children/rejected-invoices/rejected-invoices.vue';

export default [{
	path: '/invoices',
	name: 'Invoices',
	meta: {
		title: i18n.t('routes.titles.invoices')
	},
	component,

	children: [{
		path: 'all',
		name:'Invoices_All',
		component: Invoices,
		meta: {
			title: i18n.t('routes.titles.all_invoices')
		}
	}, {
		path: 'faulty-invoices',
		name:'Invoices_Faulty',
		component: FaultyInvoices,
		meta: {
			title: i18n.t('routes.titles.faulty_invoices')
		},
		beforeEnter: (to, from, next) => {
			return utils.user.isAllowedTo('InvoiceHandleErrors') ? next() : next({ name: 'Invoices_All' });
		}
	}, {
		path: 'rejected-invoices',
		name:'Invoices_Rejected',
		component: RejectedInvoices,
		meta: {
			title: i18n.t('routes.titles.rejected_invoices')
		},
		beforeEnter: (to, from, next) => {
			return utils.user.isAllowedTo('InvoiceHandleRejections') ? next() : next({ name: 'Invoices_All' });
		}
	}]
}];
