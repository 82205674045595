<template>
	<v-container>
		<div class="mx-auto">
			<div class="LoginLogo primary mb-10">
				<v-icon class="white--text" size="84px">
					mdi-note-multiple-outline
				</v-icon>
			</div>
			<v-card class="pa-8 mx-auto pt-16" rounded="xl" elevation="2" max-width="420px">
				<v-form ref="form" class="LoginForm" :action="samlTokenUrl" method="POST" @submit="doLogin">
					<input v-if="method === 'saml'" type="hidden" name="redirectUrl" :value="getSamlRedirectUrl">
					<v-text-field v-if="method !== 'saml'"
						v-model="username"
						outlined
						:label="$t('login.username')"
						:rules="$validator('token/username')"
					/>
					<v-text-field v-if="method !== 'saml'"
						v-model="password"
						outlined
						:label="$t('base.password')"
						:rules="$validator('token/password')"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:type="showPassword ? 'text' : 'password'"
						@click:append="showPassword = !showPassword"
					/>
					<v-row>
						<v-col cols="6">
							<v-select v-model="method" name="method" width="100%" label="Metod" :items="['ldap', 'wp', 'saml']" />
						</v-col>
						<v-col cols="6" class="d-flex justify-end align-center">
							<v-btn type="submit" color="primary" :loading="isLoadingLogin">
								{{ $t('login.login') }}
							</v-btn>
						</v-col>
					</v-row>
					<Loader :service="[$service.token.login]" />
				</v-form>
			</v-card>
			<div class="text-center mt-4">
				<router-link to="/password-recover" class="subtitle-2">
					{{ $t('login.forgot_password') }}
				</router-link>
			</div>
		</div>
	</v-container>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';
import router from '@/plugins/router';

export default {
	name: 'LoginView',

	data: vm => ({
		username: '',
		password: '',
		remember: false,

		showPassword: false,

		isLoadingLogin: false,
		loginError: '',

		method: 'saml',

		samlTokenUrl: `${vm.$config.apiBaseUrl}wp-json/kp/v1/token/saml`
	}),

	computed: {
		getSamlRedirectUrl() {
			return `${location.origin}/login`;
		}
	},

	async created() {
		await this.loginSaml();
	},

	methods: {
		...mapActions('userStore', ['login', 'loginSaml', 'setUser']),

		doLogin(e) {
			if (['wp', 'ldap'].includes(this.method)) {
				e.preventDefault();
				this.loginWordpress();
			}
		},

		async loginSaml() {
			try {
				const userData = await this.$service.token.loginSaml({
					method: 'saml_token',
					redirectUrl: this.getSamlRedirectUrl
				}, {
					withCredentials: true,
					throwException: true,
					loading: val => {
						this.isLoadingLogin = val;
					}
				});

				this.setUser(userData);

				Vue.nextTick(() => {
					router.push({name: 'Dashboard'});
				});
			} catch (error) {
				this.loginError = error.message;
			}
		},

		async loginWordpress() {
			if (!this.$refs.form.validate()) {
				return;
			}

			try {
				const userData = await this.$service.token.login({
					username: this.username,
					password: this.password,
					method: this.method
				}, {
					throwException: true,
					loading: val => {
						this.isLoadingLogin = val;
					}
				});

				this.setUser(userData);

				Vue.nextTick(() => {
					router.push({name: 'Dashboard'});
				});
			} catch (error) {
				this.loginError = error.message;
			}
		}
	}
};
</script>

<style type="scss">
	.LoginLogo {
		width: 200px;
		height: 200px;
		margin: auto;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.LabeledDivider {
		display: flex;
		align-items: center;
		text-align: center;
	}

	.LabeledDivider::before,
	.LabeledDivider::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid #9E9E9E;
	}

	.LabeledDivider:not(:empty)::before {
		margin-right: 10px;
	}

	.LabeledDivider:not(:empty)::after {
		margin-left: 10px;
	}
</style>
