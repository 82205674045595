<template>
	<ui-autocomplete v-model="refId"
		:label="label"
		item-text="number"
		item-value="number"
		:clearable="clearable"
		:disabled="disabled"
		:rules="rules"
		:loading="isSearchPending"
		:items="giros"
		@change="change"
	/>
</template>

<script>
// import { json } from 'body-parser';

export default {
	props: {
		value: {
			type: String,
			default: ''
		},
		giros: {
			type: Array,
			required: true,
			default: () => ({})
		},

		label: {
			type: String,
			default: 'Konto/Gironr'
		},

		clearable: {
			type: Boolean,
			default: true
		},

		disabled: {
			type: Boolean,
			default: false
		},

		rules: {
			type: Array,
			default: () => ([])
		}
	},

	data: () => ({
		items: [],
		refId: null,
		isSearchPending: false
	}),

	watch: {
		refId(to) {
			this.$emit('input', to);
		},

		value(to) {
			this.refId = to;
		}
	},

	created() {
		this.refId = this.value;
	},

	methods: {
		change(refId) {
			let position = this.giros.indexOf(refId);
			
			this.$emit('on-change', position);
		}

		// async getAccountsAndGiros() {
		// 	this.items = await this.$service.invoices.searchAccounts('644648b80be9cd1e8c536d42', 'JWKOD01', { throwException: true });
		// 	// this.items = await this.$service.invoices.getAccounts({
		// 	// 	sort: {
		// 	// 		reference: 1
		// 	// 	},
		// 	// 	pagination: {
		// 	// 		pageSize: 9999,
		// 	// 		page: 1
		// 	// 	}
		// 	// }, {
		// 	// 	loading: val => {
		// 	// 		this.isSearchPending = val;
		// 	// 	}
		// 	// });
		// }
	}
};
</script>
