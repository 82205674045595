import * as rules from '../rules';

export default {
	name: () => ([
		rules.required(),
		rules.maxLenght(30)
	]),

	vernoFrom: () => ([
		rules.required()
	]),

	vernoTo: () => ([
		rules.required()
	]),

	vernoPrefix: () => ([
		rules.required()
	]),

	vernoMin: () => ([
		rules.required(),
		rules.isPositiveInteger()
	]),

	vernoMax: () => ([
		rules.required(),
		rules.isPositiveInteger()
	])
};
