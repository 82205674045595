<template>
	<div>
		<div class="d-flex justify-end">
			<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
				@click="exportExcel"
			>
				<v-icon>mdi-microsoft-excel</v-icon>
			</v-btn>
		</div>
		<Loader :error="errorMessage" :loading="isPending" />

		<RegisterList
			:items="accontingRecords"
			:headers="getHeaders"
			sidebar-width="60%"
			:push-content="false"
			item-key="_id"
			:mass-actions="massActions"
			:pagination="pagination"
			:show-sidebar="false"
			@mass-action="onMassAction"
			@active-item-changed="setActiveItem"
			@pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<!--InvoiceSidebar :invoice-id="activeInvoiceId" /--> <!--ska faktura med här?-->
		</RegisterList>

		<Loader :error="errorMessage" :loading="isPending" />
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
// import InvoiceSidebar from '../../../invoices/children/invoices/components/sidebar';
import {mapGetters} from 'vuex';



export default {
	name: 'InvoicesChildView',

	components: {
		RegisterList
		// InvoiceSidebar
	},

	data: () => ({
		accontingRecords: [],
		isPending: false,
		activeInvoice: null,
		activeInvoiceId: null,
		errorMessage: '',

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: [],
			sortDesc: [true]
		},

		massActions: [{
			text: 'Skicka in på nytt',
			value: 'resend'
		}],

		filter: {}
	}),

	computed: {
		...mapGetters('accounting', ['getCodeNames', 'getIsActiveCode', 'getCodelistIntKeys', 'getCodeLengths']),

		getHeaders() {
			return	[{
				text: 'Datum',
				value: 'date',
				align: 'start',
				filter: [{
					type: 'date',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				},
				{
					type: 'date',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				}]
			},{
				text: 'Betalningsmottagare',
				value: 'meta.supplier_name',
				filter: {
					type: 'text',
					placeholder: ''
				}
			}, {
				text: 'Fakturanr',
				value: 'meta.invoice_name',
				filter: {
					type: 'text'
				}
			}, {
				text: 'Verifikationsnr',
				value: 'meta.verification_no',
				filter: {
					type: 'text'
				}
			}, {
				text: 'Bokföringsstatus',
				value: 'meta.type',
				filter: {
					type: 'select',
					items: [{
						text: 'Ankomstbokföring',
						value: 'Ankomstbokföring'
					}]
				}
			}, this.getIsActiveCode(0) && {
				text: `${this.getCodeNames.A}`,
				value: 'codes.0',
				filter: {
					type: 'text'
				}
			}, this.getIsActiveCode(1) && {
				text: `${this.getCodeNames.B}`,
				value: 'codes.1',
				filter: {
					type: 'text'
				}
			}, this.getIsActiveCode(2) && {
				text: `${this.getCodeNames.C}`,
				value: 'codes.2',
				filter: {
					type: 'text'
				}
			}, this.getIsActiveCode(3) && {
				text: `${this.getCodeNames.D}`,
				value: 'codes.3',
				filter: {
					type: 'text'
				}
			}, this.getIsActiveCode(4) && {
				text: `${this.getCodeNames.E}`,
				value: 'codes.4',
				filter: {
					type: 'text'
				}
			}, this.getIsActiveCode(5) && {
				text: `${this.getCodeNames.F}`,
				value: 'codes.5',
				filter: {
					type: 'text'
				}
			}, this.getIsActiveCode(6) && {
				text: `${this.getCodeNames.G}`,
				value: 'codes.6',
				filter: {
					type: 'text'
				}
			}, this.getIsActiveCode(7) && {
				text: `${this.getCodeNames.H}`,
				value: 'codes.7',
				filter: {
					type: 'text'
				}
			}, this.getIsActiveCode(8) && {
				text: `${this.getCodeNames.I}`,
				value: 'codes.8',
				filter: {
					type: 'text'
				}
			}, this.getIsActiveCode(9) && {
				text: `${this.getCodeNames.J}`,
				value: 'codes.9',
				filter: {
					type: 'text'
				}
			}, {
				text: 'Belopp',
				value: 'amount',
				align: 'right',
				filter: [{
					type: 'text',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(val) : null
				},
				{
					type: 'text',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(val) : null
				}]
			}].filter(Boolean);
		}
	},

	methods: {
		setActiveItem(item) {
			this.activeInvoice = item;
			this.activeInvoiceId = {
				_id: item.meta.invoice
			};
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fechAccountingRecords();
		},

		onFilterUpdate(filter) {
			this.filter = filter;
			this.fechAccountingRecords();
		},

		onMassAction(data) {
			const invoiceIds = data.items.map(invoice => invoice._id);

			this.resendBookings(invoiceIds);
		},

		async resendBookings(ids) {
			this.errorMessage = '';

			try {
				const response = await this.$service.bookings.resend({ ids }, {
					loading: val => {
						this.isPending = val;
					}
				});

				await this.$utils.file.saveFromResponse(response, 'bokföringar.txt');

			} catch(error) {
				this.errorMessage = error.message;
			}
			this.fechAccountingRecords();
		},

		async exportExcel() {
			this.isPending = true;
			const charCode = ['A', 'B','C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
			const data = await this.$service.bookings.search({
				pageSize: 0,
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			});
			const excell = data.map(d => {
				let activeCodes = [];

				for (let i = 0; i < 10; i++) {
					if (this.getIsActiveCode(i)) {
						activeCodes.push({ key: this.getCodeNames[charCode[i]], value: d.codes[i] || '' });
					}
				}

				activeCodes = activeCodes.reduce((acc, code) => {
					acc[code.key] = code.value;

					return acc;
				}, {});

				return {
					'Datum': this.$utils.date.intToPretty(d.date),
					'Betalningsmottagare': d.meta ? d.meta.supplier_name : '',
					'Fakturanr': d.meta ? d.meta.invoice_name: '',
					'Verifikationsnr': d.meta ? d.meta.verification_no : '',
					'Fakturastatus': d.meta ? d.meta.type : '',
					...activeCodes,
					'Belopp': d.amount
				};
			});

			await this.$utils.excel.download(excell, 'Bokföringar');
			this.isPending = false;
		},

		async fechAccountingRecords() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const data = await this.$service.bookings.search({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			}, {
				loading: val => {
					this.isPending = val;
				}
			});

			this.pagination.total = data._paginationTotal;

			this.accontingRecords = data.map(d => ({
				...d,
				'codes.0': d.codes[0],
				'codes.1': d.codes[1],
				'codes.2': d.codes[2],
				'codes.3': d.codes[3],
				'codes.4': d.codes[4],
				'codes.5': d.codes[5],
				'codes.6': d.codes[6],
				'codes.7': d.codes[7],
				'codes.8': d.codes[8],
				'codes.9': d.codes[9],
				date:  d.date ? this.$utils.date.getFullDate(this.$utils.date.intToDate(d.date)): '',
				amount: this.$utils.format.price(d.amount)
			}));
		}
	}
};
</script>
