<template>
	<div class="Layout-Plain">
		<v-main>
			<v-container class="pa-14" fluid>
				<Message />
				<v-slide-x-transition mode="out-in">
					<router-view />
				</v-slide-x-transition>
			</v-container>
		</v-main>
	</div>
</template>

<script>
import Message from '@/components/message';

export default {
	name: 'PlainLayout',

	components: {
		Message
	}
};
</script>
