import actions from './actions';
import caps from './caps';
import groups from './groups';
import invoices from './invoices';
import logs from './logs';
import roles from './roles';
import sites from './sites';
import token from './token';
import users from './users';
import register from './register';
import mail from './mail';
import suppliers from './suppliers';
import references from './references';
import todo from './todo';
import accounting from './accounting';
import settings from './settings';
import calendar from './calendar';
import attachment from './attachment';
import bookings from './bookings';
import subscriptions from './subscriptions';
import codestrings from './codestrings';
import attest from './attest';
import codelists from './codelists';
import payment from './payment';
import ledger from './ledger';

export default {
	actions,
	caps,
	groups,
	invoices,
	logs,
	roles,
	sites,
	token,
	users,
	register,
	mail,
	suppliers,
	references,
	payment,
	todo,
	accounting,
	settings,
	calendar,
	attachment,
	bookings,
	subscriptions,
	codestrings,
	attest,
	codelists,
	ledger
};
