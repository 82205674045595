var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"title":"Reskontra","width":"2100px"},on:{"reject":_vm.close}},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('ui-datepicker',{staticClass:"pr-2",attrs:{"label":"Från","scrollable":""},model:{value:(_vm.ledgerData.from),callback:function ($$v) {_vm.$set(_vm.ledgerData, "from", $$v)},expression:"ledgerData.from"}}),_c('ui-datepicker',{staticClass:"pr-2",attrs:{"label":"Till","scrollable":""},model:{value:(_vm.ledgerData.to),callback:function ($$v) {_vm.$set(_vm.ledgerData, "to", $$v)},expression:"ledgerData.to"}})],1),_c('div',{staticClass:"text-subtitle-2 font-weight-bold"},[_vm._v("Belopp:")]),_c('v-data-table',{attrs:{"headers":_vm.header,"loading":_vm.isLedgerPending,"items":_vm.ledger,"hide-default-footer":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.sum_debit",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.sum_debit.toFixed(2))+" ")])]}},{key:"item.sum_credit",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.sum_credit.toFixed(2))+" ")])]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.sum.toFixed(2))+" ")])]}}])}),_c('v-data-table',{attrs:{"headers":_vm.getHeaders,"loading":_vm.isLedgerPending,"items":_vm.bookings,"hide-default-footer":true,"items-per-page":-1,"multi-sort":""},scopedSlots:_vm._u([{key:"item.attestants",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getAttestant(item))+" ")])]}},{key:"item.approvals",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getApproval(item))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.amount.toFixed(2))+" ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }