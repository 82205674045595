<template>
	<ui-autocomplete v-model="refId"
		:label="label"
		item-text="reference"
		item-value="_id"
		:no-label="noLabel"
		:clearable="clearable"
		:items="items"
		:disabled="disabled"
		:rules="rules"
		:loading="isSearchPending"
		@change="change"
	/>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: ''
		},

		label: {
			type: String,
			default: 'Referenskod'
		},

		clearable: {
			type: Boolean,
			default: true
		},

		noLabel: {
			type: Boolean,
			default: true
		},

		disabled: {
			type: Boolean,
			default: false
		},

		rules: {
			type: Array,
			default: () => ([])
		}
	},

	data: () => ({
		items: [],
		refId: null,
		isSearchPending: false
	}),

	watch: {
		refId(to) {
			this.$emit('input', to);
		},

		value(to) {
			this.refId = to;
		}
	},

	async created() {
		this.refId = this.value;
		await this.fetchRefrences();
	},

	methods: {
		change(id) {
			const item = this.items.find(x => x._id === id);

			this.$emit('on-change', item);
		},

		async fetchRefrences() {
			this.items = await this.$service.references.search({
				sort: {
					reference: 1
				},
				pagination: {
					pageSize: 9999,
					page: 1
				}
			}, {
				loading: val => {
					this.isSearchPending = val;
				}
			});
		}
	}
};
</script>
