<template>
	<div>
		<v-row>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(0)"
					v-model="accountingFormData.A"
					class="pr-2"
					:items="getCodelist.A"
					item-value="intval"
					:label="`${getCodeNames.A}`"
					:item-text="itemText"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(1)"
					v-model="accountingFormData.B"
					class="pr-2"
					:items="getCodelist.B"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.B}`"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(2)"
					v-model="accountingFormData.C"
					class="pr-2"
					:items="getCodelist.C"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.C}`"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(3)"
					v-model="accountingFormData.D"
					class="pr-2"
					:items="getCodelist.D"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.D}`"
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(4)"
					v-model="accountingFormData.E"
					class="pr-2"
					:items="getCodelist.E"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.E}`"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(5)"
					v-model="accountingFormData.F"
					class="pr-2"
					:items="getCodelist.F"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.F}`"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(6)"
					v-model="accountingFormData.G"
					class="pr-2"
					:items="getCodelist.G"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.G}`"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(7)"
					v-model="accountingFormData.H"
					class="pr-2"
					:items="getCodelist.H"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.H}`"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(8)"
					v-model="accountingFormData.I"
					class="pr-2"
					:items="getCodelist.I"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.I}`"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(9)"
					v-model="accountingFormData.J"
					class="pr-2"
					:items="getCodelist.J"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.J}`"
				/>
			</v-col>
		</v-row>

		<v-divider class="py-2" />

		<div class="d-flex">
			<ui-text-field v-model="accountingFormData.percent" dense type="number"
				value=" "
				label="Procent"
				class="pr-2"
			>
				<template v-slot:append>
					<v-btn icon small @click="accountingFormData.percent = amountLeft"><v-icon small>mdi-auto-fix</v-icon></v-btn>
				</template>
			</ui-text-field>
		</div>

		<div class="d-flex">
			<v-alert dense color="primary" text class="d-flex justify-space-between mr-1 align-center">
				<span class="text-overline">
					{{ getLenghtOfCode(0, `${ accountingFormData.A}`.length, 'A') }} {{ getLenghtOfCode(1, `${ accountingFormData.B}`.length, 'B') }}
					{{ getLenghtOfCode(2, `${ accountingFormData.C}`.length, 'C') }} {{ getLenghtOfCode(3, `${ accountingFormData.D}`.length, 'D') }}
					{{ getLenghtOfCode(4, `${ accountingFormData.E}`.length, 'E') }} {{ getLenghtOfCode(5, `${ accountingFormData.F}`.length, 'F') }}
					{{ getLenghtOfCode(6, `${ accountingFormData.G}`.length, 'G') }} {{ getLenghtOfCode(7, `${ accountingFormData.H}`.length, 'H') }}
					{{ getLenghtOfCode(8, `${ accountingFormData.I}`.length, 'I') }} {{ getLenghtOfCode(9, `${ accountingFormData.J}`.length, 'J') }}
				</span>
			</v-alert>
			<v-alert dense color="primary" text class="d-flex justify-space-between">
				<span class="text-overline">
					{{ accountingFormData.percent }}%
				</span>
			</v-alert>

			<div v-if="isVat" class="ml-4 mb-4">
				<v-checkbox v-model="accountingFormData.is_cost" label="Kostnad" />
			</div>
		</div>

		<div class="d-flex align-end justify-end mt-6">
			<span v-if="error" class="error--text text-caption mr-4" style="height: 28px;">{{ error }}</span>

			<v-btn text @click="noteOpen = true">
				<v-icon v-if="accountingFormData.note" left small>mdi-note-outline</v-icon>
				Anteckning
			</v-btn>

			<v-btn v-if="!accountingFormData._id && !readOnly" color="accent" text :disabled="parseInt(accountingFormData.percent) === 0 || isNaN(parseInt(accountingFormData.percent))" @click="add">Lägg till</v-btn>
			<v-btn v-if="accountingFormData._id && !readOnly" color="warning" text @click="reset">Avbryt</v-btn>
			<v-btn v-if="accountingFormData._id && !readOnly" color="error" text @click="deleteAccounting">Ta bort</v-btn>
			<v-btn v-if="accountingFormData._id && !readOnly" color="accent" text :disabled="parseInt(accountingFormData.percent) === 0 || isNaN(parseInt(accountingFormData.percent))" @click="save">Spara</v-btn>
		</div>

		<Dialog v-if="noteOpen" title="Anteckning" @confirm="noteOpen = false" @reject="noteOpen = false">
			<ui-textarea v-model="accountingFormData.note" label="Anteckning" />
		</Dialog>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

const defaultData = () => ({
	_id: null,
	codes: [null, null, null, null, null, null, null, null, null, null],
	percent: null,
	note: '',
	is_cost: false
});

export default {
	props: {
		showCodeTexts: {
			type: Boolean,
			default: true
		},

		value: {
			type: Object,
			default: defaultData()
		},

		error: {
			type: String,
			default: ''
		},

		date: {
			type: Number,
			default: 0
		},

		readOnly: {
			type: Boolean,
			default: false
		},

		amountLeft: {
			type: Number,
			default: 0
		},

		isVat: {
			type: Boolean,
			default: false
		}

	},

	data: () => ({
		localData: {},

		noteOpen: false,

		dateMenu: false,

		bookingDateMenu: false,

		accountingFormData: {
			A: '',
			B: '',
			C: '',
			D: '',
			E: '',
			F: '',
			G: '',
			H: '',
			period: ['', ''],
			percent: '',
			note: '',
			date: '',
			isCost: false
		}
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getIsActiveCode', 'getCodeLengths'])
	},

	watch: {
		'value._id'() {
			this.setData();
		},

		accountingFormData: {
			deep: true,

			handler() {
				this.$emit('input', {
					_id: this.value ? this.value._id : null,
					codes: [
						this.accountingFormData.A,
						this.accountingFormData.B,
						this.accountingFormData.C,
						this.accountingFormData.D,
						this.accountingFormData.E,
						this.accountingFormData.F,
						this.accountingFormData.G,
						this.accountingFormData.H,
						this.accountingFormData.I,
						this.accountingFormData.J
					],
					percent: Number(this.accountingFormData.percent),
					note: this.accountingFormData.note,
					is_cost: this.accountingFormData.is_cost
				});
			}
		}
	},

	created() {
		this.setData();
	},

	methods: {
		setData() {
			const value = this.value ? this.value : defaultData();

			this.accountingFormData = {
				_id: value._id,
				A: Number(value.codes[0]),
				B: Number(value.codes[1]),
				C: Number(value.codes[2]),
				D: Number(value.codes[3]),
				E: Number(value.codes[4]),
				F: Number(value.codes[5]),
				G: Number(value.codes[6]),
				H: Number(value.codes[7]),
				I: Number(value.codes[8]),
				J: Number(value.codes[9]),
				percent: value.percent,
				note: value.note,
				is_cost: value.is_cost
			};
		},


		getLenghtOfCode(codeIndex, codeLength, code) {
			const length = this.getCodeLengths[codeIndex] - codeLength;

			if(!this.accountingFormData[code]) {
				return this.getCodeLengths[codeIndex] === 0 ? '' : '-';
			}

			return `${'0'.repeat(length)}${ this.accountingFormData[code] } -`;
		},

		itemText(a) {
			return this.showCodeTexts ? `${a.kod} - ${a.kodtext}` : a.kod;
		},

		add() {
			this.$emit('add');
		},

		reset() {
			this.$emit('reset');
		},

		deleteAccounting() {
			this.$emit('delete');
		},

		save() {
			this.$emit('save');
		}
	}
};
</script>
