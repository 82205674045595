<template>
	<v-list-item inactive style="cursor: pointer;" @click="handleInput">
		<v-list-item-icon>
			<v-icon :color="color">
				{{ icon }}
			</v-icon>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title v-text="label" />
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	name: 'Checkbox',

	props: {
		label: {
			type: String,
			default: ''
		},

		value: {
			type: Boolean,
			default: undefined
		}
	},

	data: vm => ({
		localValue: vm.value
	}),

	computed: {
		icon() {
			const icons = {
				granted: 'mdi-checkbox-marked',
				denied: 'mdi-minus-box',
				undefined: 'mdi-checkbox-blank-outline'
			};

			if (typeof this.localValue === 'undefined' || this.localValue === null) {
				return icons['undefined'];
			}

			if (this.localValue) {
				return icons['granted'];
			}

			return icons['denied'];
		},

		color() {
			const colors = {
				granted: 'success',
				denied: 'error',
				undefined: 'grey'
			};

			if (typeof this.localValue === 'undefined' || this.localValue === null) {
				return colors['undefined'];
			}

			if (this.localValue) {
				return colors['granted'];
			}

			return colors['denied'];
		}
	},

	watch: {
		value(to) {
			this.localValue = to;
		}
	},

	methods: {
		handleInput() {
			if (typeof this.localValue === 'undefined' || this.localValue === null) {
				this.localValue = true;
			} else if (this.localValue) {
				this.localValue = false;
			} else {
				this.localValue = undefined;
			}

			this.$emit('input', this.localValue);
		}
	}
};
</script>

<style lang="scss">
	.Checkbox {
		.v-list-item__icon {
			margin-right: 10px !important;
		}
	}
</style>
