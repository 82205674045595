import store from '@/plugins/vuex';

const getCurrentTimeInSeconds = () => Math.round(new Date().getTime() / 1000);
const secondsToMinutes = val => Math.round(val / 60);

export const validateExpireTime = value => {
	if (getCurrentTimeInSeconds() > value) {
		return false;
	}

	return true;
};

export const shouldRefresh = (expireTime, diff) => {
	const now = secondsToMinutes(getCurrentTimeInSeconds());
	const expire = secondsToMinutes(expireTime);

	if ((expire - now) <= diff) {
		return true;
	}

	return false;
};

export const get = () => store.getters['userStore/getUser'].token;
