<template>
	<div>
		<Dialog v-for="dialog in dialogs" :key="dialog.id" v-model="dialog.show" max-width="460"
			:title="dialog.headline"
			:loading="dialog.promisePending"
			:ok-btn-text="dialog.okBtnText"
			:cancel-btn-text="dialog.cancelBtnText"
			:show-cancel-btn="dialog.showCancelBtn"
			:disable-ok-btn="dialog.disableOkBtn"
			:alert="dialog.alert"
			@reject="reject(dialog)"
			@confirm="confirm(dialog)"
		>
			<!-- eslint-disable-next-line vue/no-v-html -->
			<div v-html="dialog.text" />
			<ui-textarea v-if="dialog.showMessage" v-model="dialog.message" class="mt-4" label="Meddelande" />
			<Loader class-name="pt-4" :error="dialog.error" />
		</Dialog>
	</div>
</template>

<script>
import {uid} from 'uid';
import Loader from '@/components/loader';
import Dialog from './dialog.vue';

const initialState = vm => ({
	promisePending: false,
	error: '',
	headline: '',
	text: '',
	isAlert: false,
	okBtnText: vm.$t('base.ok'),
	cancelBtnText: vm.$t('base.cancel'),
	confirmed: () => {},
	rejected: () => {},
	message: '',
	showMessage: false,
	showCancelBtn: true,
	disableOkBtn: false,
	alert: false
});

export default {
	name: 'ConfirmDialog',

	components: {
		Loader,
		Dialog
	},

	data: () => ({
		dialogs: []
	}),

	created() {
		this.$bus.on('confirm:open', this.add);
	},

	beforeDestroy() {
		this.$bus.off('confirm:open', this.add);
	},

	methods: {
		add(data) {
			const newDialog = {
				id: uid(),
				show: true,
				...initialState(this)
			};

			Object.entries(data).forEach(([key, value]) => {
				if (key in newDialog) {
					newDialog[key] = value;
				}
			});

			this.dialogs.push(newDialog);
		},

		remove(dialog) {
			this.dialogs = this.dialogs.filter(d => d.id !== dialog.id);
		},

		confirm(dialog) {
			dialog.promisePending = true;

			Promise.resolve(dialog.confirmed({
				message: dialog.message
			}))
				.then(() => {
					this.remove(dialog);
				})
				.catch(error => {
					dialog.error = (typeof error === 'object' && 'message' in error) ? error.message : error.toString();
				}).finally(() => {
					dialog.promisePending = false;
				});
		},

		reject(dialog) {
			dialog.rejected();
			this.remove(dialog);
		}
	}
};
</script>
