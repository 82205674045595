import { shallowEqual } from 'fast-equals';
import base64 from 'base-64';

export const parseQuery = query => {
	if (!query) {
		return;
	}

	const arr = base64.decode(query).split(',').map(x => {
		return x === '' ? undefined : JSON.parse(x);
	});

	return arr;
};

export const saveStateInQuery = (vm, arr) => {
	const prev = vm.$route.query;
	const clean = arr.map(x => {
		return (x === null || typeof x === 'undefined') ? '' : x;
	});
	const f = JSON.stringify(clean).replaceAll('""', '').substring(1).slice(0, -1);
	const query = {
		f: base64.encode(f)
	};

	if (shallowEqual(prev, query)) {
		return;
	}

	vm.$router.push({ query });
};

export const getStateFromQuery = (vm, callback) => {
	if (!('queryParams' in vm) || vm.queryParams.length === 0) {
		return;
	}

	const qp = vm.queryParams;

	callback(qp);
};
