<template>
	<div>
		<portal-target name="register-header" />

		<v-card elevation="2">
			<v-tabs>
				<v-tab v-for="tab in tabs" :key="tab.path" ref="tab" :to="tab.path">
					{{ tab.label }}
				</v-tab>
			</v-tabs>
			<div class="pa-6">
				<router-view />
			</div>
		</v-card>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	computed: {
		...mapGetters('userStore', []),

		tabs() {
			return [{
				path: '/inbox/todo',
				label: 'Att göra',
				ref: 'tab'
			}, {
				path: '/inbox/conversations',
				label: 'Konversationer'
			}].filter(Boolean);
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.$refs['tab'][0].$el.focus();
		});
	}
};
</script>
