<template>
	<div>
		<div class="d-flex align-center mx-auto mb-6" style="width: 800px;">
			<div class="d-flex align-center">
				<v-btn icon @click="prev"><v-icon>mdi-chevron-left</v-icon></v-btn>
				<div class="mx-4 d-flex justify-center" style="width: 200px;">
					<span class="text-h6 text-uppercase">{{ title }}</span>
				</div>
				<v-btn icon @click="next"><v-icon>mdi-chevron-right</v-icon></v-btn>
			</div>
			<v-spacer />
			<v-btn color="primary" :loading="isPending" :disabled="!canCreateBankdays || isPending" @click="createBankDays">Skapa bankdagar för {{ currentYear }}</v-btn>
		</div>
		<div class="d-flex justify-center">
			<v-row>
				<v-col class="mt-3">
					<v-row>
						<v-sheet color="background" width="20" height="20" rounded />
						<span class="text-caption ml-2">Bankdag</span>
					</v-row>
					<v-row class="mt-4">
						<v-sheet color="primary" width="20" height="20" rounded />
						<span class="text-caption ml-2">Bankfri dag</span>
					</v-row>
				</v-col>
				<v-col>
					<v-sheet height="600" width="800">
						<v-calendar
							ref="calendar"
							v-model="value"
							locale="sv"
							color="info"
							:type="type"
							:weekdays="[1,2,3,4,5,6,0]"
							@change="calChanged"
						>
							<template v-slot:day="ctx">
								<div v-if="events[currentYear] && events[currentYear].length !== 0">
									<v-row v-if="isBankDay(ctx.date)" class="fill-height">
										<v-sheet
											color="background"
											width="100%"
											height="100"
											tile
										>
											<v-btn v-tooltip content="Ta bort som bankdag" color="transparent"
												class="ml-3" width="100%" height="100%" @click="removeDate(ctx.date)"
											/>
										</v-sheet>
									</v-row>
									<v-row v-else>
										<v-sheet
											color="primary"
											width="100%"
											height="100"
											tile
										>
											<v-btn v-tooltip content="Lägg till som bankdag" color="transparent" :disabled="checkIfWeekday(ctx.date)"
												class="ml-3" width="100%" height="100%" @click="addDate(ctx.date)"
											/>
										</v-sheet>
									</v-row>
								</div>
							</template>
						</v-calendar>
					</v-sheet>
				</v-col>
				<v-col />
			</v-row>
		</div>
	</div>
</template>

<script>
export default {

	data: () => ({
		value: '',
		type: 'month',

		weekend: false,
		isPending: true,

		events: {

		},

		currentYear: null,

		title: ''
	}),

	computed: {
		canCreateBankdays() {
			if (!(this.currentYear in this.events)) {
				return true;
			}

			if (this.events[this.currentYear].length === 0) {
				return true;
			}

			return false;
		}
	},

	watch: {
		currentYear() {
			this.fetchYear();
		}
	},

	created() {
		this.checkIfWeekday();
	},

	methods: {
		checkIfWeekday(ctx) {
			const d = parseInt(ctx.replaceAll('-', ''));
			const intDate = this.$utils.date.intToDate(d);

			if (intDate.getDay() == 6 || intDate.getDay() == 0) {
				return true;
			}

			return false;
		},

		isBankDay(date) {
			const d = parseInt(date.replaceAll('-', ''));

			if (!(this.currentYear in this.events)) {
				return false;
			}

			return this.events[this.currentYear].some(x => x.intdate === d);
		},

		calChanged() {
			this.title = this.$refs.calendar ? this.$refs.calendar.title : '';

			this.currentYear = Number(this.title.substr(this.title.length - 4));
		},

		next() {
			if (this.$refs.calendar) {
				this.$refs.calendar.next();
			}
		},

		prev() {
			if (this.$refs.calendar) {
				this.$refs.calendar.prev();
			}
		},

		addYearData(data) {
			const newData = data.filter(x => x.bankday).map(x => ({
				name: 'Bankdag',
				start: this.$utils.date.intToDate(x.date),
				end: this.$utils.date.intToDate(x.date),
				intdate: x.date,
				timed: false,
				color: 'red'
			}));

			this.$set(this.events, this.currentYear, newData);
		},


		async removeDate(date) {
			await this.$service.calendar.update.call({
				date: parseInt(date.replaceAll('-', '')),
				value: 0
			});

			await this.fetchYear();
		},

		async addDate(date) {
			await this.$service.calendar.update.call({
				date: parseInt(date.replaceAll('-', '')),
				value: 1
			});

			await this.fetchYear();
		},

		async createBankDays() {
			this.isPending = true;
			const data = await this.$service.calendar.addYear.call({year: this.currentYear});

			this.addYearData(data);
			this.isPending = false;
		},

		async fetchYear() {
			this.isPending = true;
			const data = await this.$service.calendar.get.call({year: this.currentYear});

			this.addYearData(data);
			this.isPending = false;
		}
	}
};
</script>
