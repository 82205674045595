<template>
	<v-card class="pa-6">
		<v-card-title class="px-0">{{ label }}</v-card-title>
		<ui-select v-model="groupId" :items="groups" label="Grupp" item-text="name" item-value="_id"
			@input="fetchSettings"
		/>
		<v-divider class="my-4" />

		<div v-for="setting in groupSettings" :key="setting.key">
			<label>{{ setting.label }}</label>
			<v-icon v-if="getGroupById(setting.group)" v-tooltip color="warning" small class="ml-2"
				:content="`Inställning från gruppen ${getGroupById(setting.group).name}`"
			>
				mdi-account-group
			</v-icon>
			<div v-if="getCodelist" class="d-flex">
				<ui-autocomplete
					v-model="setting.value[0]"
					class="pr-2"
					:items="getCodelist.A"
					item-value="intval"
					:label="`${getCodeNames.A}`"
					:item-text="itemText"
					clearable
				/>
				<ui-autocomplete
					v-model="setting.value[1]"
					class="pr-2"
					:items="getCodelist.B"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.B}`"
					clearable
				/>

				<ui-autocomplete
					v-if="!setting.no"
					v-model="setting.value[2]"
					class="pr-2"
					:items="getCodelist.C"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.C}`"
					clearable
				/>
			</div>
		</div>

		<Loader :error="updateError" :success="updateFeedbackMessage" />

		<v-card-actions class="d-flex justify-end">
			<v-btn text color="primary" :loading="isPending" :disabled="isPending" @click="updateSetting(groupSettings)">Spara</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {


	props: {
		label: {
			type: String,
			default: ''
		},

		settings: {
			type: Array,
			required: true
		},

		groups: {
			type: Array,
			required: true
		}
	},


	data: () => ({
		groupId: 0,
		isPending: false,
		groupSettings: null,
		updateError: '',
		updateFeedbackMessage: null
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames'])
	},

	created() {
		this.groupSettings = {...this.settings};

		this.fetchSettings();
	},

	methods: {
		getGroupById(id) {
			if(id) {
				return this.groups.find(x => x._id === id);
			}

			return null;
		},

		itemText(a) {
			return  `${a.kod} - ${a.kodtext}`;
		},

		async fetchSettings() {
			const keys = this.settings.map(x => x.key);
			const _settings  = await this.$service.settings.list(this.groupId, {
				keys
			}, {
				loading: val => {
					this.isPending = val;
				}
			});

			this.groupSettings = this.settings.map(obj => {
				return {
					...obj,
					value:  _settings[obj.key] ? _settings[obj.key].value.map(value => value) : [],
					group: _settings[obj.key].group
				};
			});
		},

		async updateSetting(settings) {
			const getVal = (setting, index) => setting.value[index] ? setting.value[index] : 0;
			const groupSettings = Object.values(settings).reduce((acc, curr) => ({
				...acc,
				[curr.key]: curr.no ? [getVal(curr, 0), getVal(curr, 1)] : [getVal(curr, 0), getVal(curr, 1), getVal(curr, 2)]
			}), {});

			this.updateFeedbackMessage = null;
			this.updateError = '';

			try {
				await this.$service.settings.update(this.groupId, {
					...groupSettings
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});

				this.updateFeedbackMessage = ['Inställingar uppdaterades'];

				await this.fetchSettings();
			} catch (error) {
				this.updateError = error.message;
			}
		}
	}
};
</script>
