<template>
	<div>
		<div class="text-caption mb-6">
			Nuvarande värden:
			<div class="d-flex">
				<div v-for="(paymentMean, index ) in localInvoice.PaymentMeans" :key="index">
					<strong>{{ paymentMean.PaymentReference }}{{ localInvoice.PaymentMeans.length > index + 1 ? ',&nbsp; ' : '' }}</strong>
				</div>
			</div>
		</div>

		<div class="d-flex align-center justify-space-between">
			<ui-text-field v-model="newRef" label="Betalningsreferens" />
		</div>

		<div v-if="newRef">
			<p class="text-caption">Vill du uppdatera referensnr till  <strong class="primary--text">{{ newRef }}</strong>?</p>
			<v-btn color="success" text small @click="updateInvoice">Ja, uppdatera</v-btn>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		newRef: '',
		localInvoice: null
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
					this.newRef = '';
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);
	},

	methods: {
		updateInvoice() {
			this.localInvoice.PaymentMeans.map(paymentMean => paymentMean.PaymentReference = this.newRef);
			this.newRef = '';

			this.$emit('on-invoice-update', this.localInvoice);
		}
	}
};
</script>
