import i18n from '@/plugins/i18n';
import { kpApiV1 } from '@/plugins/axios';
import { validate } from '@/utils/validation';
import { compose, getFields } from '@/utils/service';
import apiClient from '@/plugins/axios';


const fields = {
	title: {
		rules: input => validate(input).required().maxLenght(30).test(),
		label: i18n.t('service.sites.title'),
		counter: 30
	}
};

export default {
	get: compose(kpApiV1.get, 'sites'),

	add: compose(kpApiV1.post, 'sites', {
		fields: getFields(fields, ['title'])
	}),

	getAll: (body = {}, opts = {}) => apiClient.get('/wp-json/kp/v1/sites', body, opts),

	update: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/sites/name', body, opts),

	activate: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/sites/activate', body, opts),

	deactivate: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/sites/deactivate', body, opts)
};
