<template>
	<Dialog title="Välj attestanter"
		:loading="submitIsPending"
		@confirm="onSubmit"
		@reject="$emit('on-close')"
	>
		<Loader :error="fetchAttestantsError" />

		<v-form v-if="!fetchAttestantsError" ref="form">
			<div v-for="(row, index) in rows" :key="index">
				<div class="text-subtitle-2 font-weight-bold">
					Rad {{ index + 1 }}
				</div>

				<div class="d-flex">
					<v-alert dense color="primary" text class="d-flex justify-space-between mr-1">
						<span class="text-overline">
							{{ getLenghtOfCode(0, `${row.codes[0]}`.length, index) }} {{ getLenghtOfCode(1, `${row.codes[1]}`.length, index) }}
							{{ getLenghtOfCode(2, `${row.codes[2]}`.length, index) }} {{ getLenghtOfCode(3, `${row.codes[3]}`.length, index) }}
							{{ getLenghtOfCode(4, `${row.codes[4]}`.length, index) }} {{ getLenghtOfCode(5, `${row.codes[5]}`.length, index) }}
							{{ getLenghtOfCode(6, `${row.codes[6]}`.length, index) }} {{ getLenghtOfCode(7, `${row.codes[7]}`.length, index) }}
							{{ getLenghtOfCode(8, `${row.codes[8]}`.length, index) }} {{ getLenghtOfCode(9, `${row.codes[9]}`.length, index) }}
						</span>
					</v-alert>
					<v-alert dense color="primary" text class="d-flex justify-space-between">
						<span class="text-overline">
							{{ row.percent }}%
						</span>
					</v-alert>
				</div>
				<v-row>
					<v-col cols="6">
						<div class="text-subtitle-2 font-weight-bold">Godkännare</div>
						<ui-select v-model="selectedApprovals[index]" multiple
							:rules="[required(attestants.approvals[index] && attestants.approvals[index].required)]"
							:items="attestants.approvals[index] ? attestants.approvals[index].attestants : []" :item-text="item => `${item.name} (${item.login})`"
							item-value="id"
						/>
					</v-col>
					<v-col cols="6">
						<div class="text-subtitle-2 font-weight-bold">Beslutare</div>
						<ui-select v-model="selectedAuthorizations[index]" multiple
							:rules="[required(attestants.authorizations[index] && attestants.authorizations[index].required)]"
							:items="attestants.authorizations[index] ? attestants.authorizations[index].attestants : []" :item-text="item => `${item.name} (${item.login})`"
							item-value="id"
						/>
					</v-col>
				</v-row>
			</div>
		</v-form>

		<Loader :error="submitErrorMessage" />
	</Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		subscriptionId: {
			type: String,
			required: true
		},

		rows: {
			type: Array,
			required: true
		},

		subAttestants: {
			type: Array,
			required: true
		},

		isVat: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		attestants:  {
			approvals: [],
			authorizations: []
		},

		selectedApprovals: [],
		selectedAuthorizations: [],

		submitIsPending: false,
		submitErrorMessage: '',

		fetchAttestantsError: ''
	}),

	computed: {
		...mapGetters('accounting', ['getCodeLengths'])
	},

	async created() {
		await this.fetchAttestants();
		this.setDefaults();
		this.setSelectedAttestants();
	},

	methods: {
		getLenghtOfCode(codeIndex, codeLength, index) {
			const length = this.getCodeLengths[codeIndex] - codeLength;

			if(!this.rows[index].codes[codeIndex]) {
				return this.getCodeLengths[codeIndex] === 0 ? '' : '-';
			}

			return `${'0'.repeat(length)}${ this.rows[index].codes[codeIndex] } -`;
		},

		required(count) {
			return (values) => {
				if (!values || values.length > count || values.length < count) {
					return `Antal attestanter måste vara ${count}`;
				}

				return true;
			};
		},

		setDefaults() {
			this.attestants.approvals.forEach((value, index) => {
				this.$set(this.selectedApprovals, index, value.attestants.map(x => x.id).slice(0, value.required));
			});

			this.attestants.authorizations.forEach((value, index) => {
				this.$set(this.selectedAuthorizations, index, value.attestants.map(x => x.id).slice(0, value.required));
			});
		},

		setSelectedAttestants() {
			this.subAttestants.forEach((value, index) => {
				this.$set(this.selectedApprovals, index,
					value.approvals
						.map(x => x.id)
						.filter(userId => this.isAttestantValid(userId, index, 'approvals'))
				);

				this.$set(this.selectedAuthorizations, index,
					value.authorizations
						.map(x => x.id)
						.filter(userId => this.isAttestantValid(userId, index, 'authorizations'))
				);
			});
		},

		isAttestantValid(userId, rowIndex, type) {
			if (type === 'approvals') {
				return rowIndex in this.attestants.approvals ?
					this.attestants.approvals[rowIndex].attestants.some(user => user.id === userId) :
					false;
			}

			if (type === 'authorizations') {
				return rowIndex in this.attestants.authorizations ?
					this.attestants.authorizations[rowIndex].attestants.some(user => user.id === userId) :
					false;
			}

			return false;
		},

		async fetchAttestants() {
			this.fetchAttestantsError = '';

			try {
				this.attestants = this.isVat ?
					await this.$service.subscriptions.getVatAttestants(this.subscriptionId, { throwException: true }) :
					await this.$service.subscriptions.getAttestants(this.subscriptionId, { throwException: true });
			} catch (error) {
				this.fetchAttestantsError = error.message;
			}
		},

		async onSubmit() {
			if (!this.$refs.form.validate()) {
				return;
			}

			const data = {
				rows: this.rows.map((_, index) => {
					return {
						approvals: this.selectedApprovals[index],
						authorizations: this.selectedAuthorizations[index]
					};
				})
			};

			try {
				if (this.isVat) {
					await this.$service.subscriptions.setVatAttestants(this.subscriptionId, data, {
						throwException: true,
						loading: val => {
							this.submitIsPending = val;
						}
					});
				} else {
					await this.$service.subscriptions.setAttestants(this.subscriptionId, data, {
						throwException: true,
						loading: val => {
							this.submitIsPending = val;
						}
					});
				}

				this.$emit('on-submit');
			} catch (error) {
				this.submitErrorMessage = error.message;
			}
		}
	}
};
</script>
