<template>
	<ui-autocomplete v-model="id" :label="label" item-text="name" item-value="_id" clearable
		:items="items"
		:loading="isSearchPending" :clean="clean" :no-label="noLabel" @change="change"
	/>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: ''
		},

		label: {
			type: String,
			default: 'Abonnemangstyp'
		},

		noLabel: {
			type: Boolean,
			default: false
		},

		clean: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		items: [],
		id: null,
		isSearchPending: false
	}),

	watch: {
		id(to) {
			this.$emit('input', to);
		},

		value(to) {
			this.id = to;
		}
	},

	async created() {
		this.id = this.value;
		await this.fetchSuppliers();
	},

	methods: {
		change(id) {
			const item = this.items.find(x => x._id === id);

			this.$emit('on-change', item);
		},

		async fetchSuppliers() {
			let data = await this.$service.subscriptions.searchTypes({
				sort: {
					reference: 1
				},
				pagination: {
					pageSize: 9999,
					page: 1
				}
			}, {
				loading: val => {
					this.isSearchPending = val;
				}
			});

			this.items = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
		}
	}
};
</script>

