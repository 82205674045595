<template>
	<Dialog v-model="open" :title="`${ type._id ? type.name : 'Ny Abonnemangstyp'}`" max-width="640" width="640" :retain-focus="false"
		@confirm="confirm"
		@reject="reject"
	>
		<v-container>
			<v-card-text>
				<v-form ref="form" v-model="formIsValid" :disabled="isPending">
					<ui-text-field v-model="formData.name" :label="$service.roles.add.fields.name.label"
						:rules="[$service.roles.add.fields.name.rules]" @keydown.enter="confirm"
					/>
				</v-form>

				<Loader :error="error" />
			</v-card-text>
		</v-container>
	</Dialog>
</template>

<script>
export default {
	props: {
		type: {
			type: Object,
			default: () => {}
		}
	},

	data: () => ({
		open: true,
		isPending: false,
		error: '',

		formIsValid: false,
		formData: {
			name: ''
		}
	}),

	created() {
		if(this.type._id) {
			this.formData.name = this.type.name;
		}
	},

	methods: {
		reject() {
			this.$emit('close');
		},

		async confirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			if(this.type._id) {
				try {
					await this.$service.subscriptions.updateType(this.type._id, {
						...this.formData
					},
					{
						loading: val => {
							this.IsPending = val;
						},
						throwException: true
					});

					this.$emit('on-update');

					return;
				} catch (error) {
					this.error = error.message;
				}
			}

			try {
				await this.$service.subscriptions.createType(this.formData, {
					loading: val => {
						this.IsPending = val;
					},
					throwException: true
				});

				this.$emit('on-create');
			} catch (error) {
				this.error = error.message;
			}
		}
	}
};
</script>
