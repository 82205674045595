<template>
	<div>
		<div class="text-caption mb-6">
			Nuvarande värde: <strong>{{ $utils.date.intToPretty( $utils.dotProp(localInvoice, 'meta.date.payment_due', '')) }}</strong>
		</div>
		<ui-datepicker v-model="newDate" :close-on-content-click="true" label="Nytt datum" clearable />

		<div v-if="newDate">
			<p class="text-caption">Vill du uppdatera datumet till <strong class="primary--text">{{ newDate }}</strong>?</p>
			<v-btn color="success" text small @click="updateInvoice">Ja, uppdatera</v-btn>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		newDate: null,
		localInvoice: null
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
					this.newDate = null;
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);
	},

	methods: {
		updateInvoice() {
			this.localInvoice.meta.date.payment_due = parseInt(this.newDate.replaceAll('-',''));
			this.newDate = null;

			this.$emit('on-invoice-update', this.localInvoice);
		}
	}
};
</script>
