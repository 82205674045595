<template>
	<div>
		<v-row no-gutters>
			<v-col cols="12">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn v-tooltip :is-pending="isPending" content="Exportera till excel" color="primary" class="mx-4"
						@click="exportExcel"
					>
						<v-icon>mdi-microsoft-excel</v-icon>
					</v-btn>
					<v-btn v-if="isUserAllowedToHandleReferenceCodes" color="accent mr-2" @click="newDialogOpen = true">Ny referenskod </v-btn>
				</div>
			</v-col>
		</v-row>
		<RegisterList ref="registerList" :pagination="pagination" :items="items" :headers="headers" sidebar-width="50%"
			item-key="_id"
			:push-content="false"
			:no-click="!isUserAllowedToHandleReferenceCodes"
			@pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
			@active-item-changed="setActiveItem"
		>
			<EditSidebar :data="active" @updated="fetchReference" @deleted="onDeleted" />
		</RegisterList>

		<NewDialog v-if="newDialogOpen" @close="newDialogOpen = false" @created="onCreated" />
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import NewDialog from './components/new';
import EditSidebar from './components/edit';
import { mapGetters } from 'vuex';

export default {
	components: {
		RegisterList,
		NewDialog,
		EditSidebar
	},

	data: () => ({
		newDialogOpen: false,
		isPending: false,

		items: [],

		groups: [],

		active: null,

		headers: [{
			text: 'Referenskod',
			value: 'reference',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Beskrivning',
			value: 'description',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Grupp',
			value: 'groupId',
			filter: {
				type: 'group'
			}
		}, {
			text: 'Aktiv',
			value: '_active',
			filter: {
				type: 'select',
				items: [{
					text: 'Aktiv',
					value: true
				}, {
					text: 'Inaktiv',
					value: false
				}]
			}
		}, {
			text: 'Uppdaterad',
			value: 'updated_date',
			filter:[{
				type: 'date',
				placeholder: 'fr.o.m.',
				transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
			},
			{
				type: 'date',
				placeholder: 't.o.m.',
				transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
			}]
		}, {
			text: 'Användare',
			value: '_userIds',
			filter: {
				type: 'users',
				transform: val => val !== null ? [val] : null
			}
		}],

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: ['reference'],
			sortDesc: [true]
		},

		filter: {}
	}),

	computed: {
		...mapGetters('users', ['getUsers']),
		...mapGetters('userStore', ['isUserAllowedToHandleReferenceCodes'])
	},

	async created() {
		await this.fetchGroups();
		await this.fetchReference();
		this.allowClick();
	},

	methods: {
		async onFilterUpdate(filter) {
			const newObj = {};

			for (let prop in filter) {
				// eslint-disable-next-line no-prototype-builtins
				if (filter.hasOwnProperty(prop)) {
					const newKey = prop.startsWith('_') ? prop.substring(1) : prop;

					newObj[newKey] = filter[prop];

					if(newKey === 'userIds') {
						newObj[newKey] = filter[prop][0];
					}
				}
			}
			this.filter = newObj;

			await this.fetchReference();
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchReference();
		},

		async fetchGroups() {
			this.groups = await this.$service.groups.list({});
		},

		setActiveItem(item) {
			if(item) {
				this.active = {
					...item,
					groupId: item._groupId
				};
			}
		},

		onCreated(item) {
			this.active = item;

			this.fetchReference();
		},

		allowClick() {
			if(this.isUserAllowedToHandleReferenceCodes) {
				return this.isAllowTo = false;
			}

			if(this.isUserAllowedToHandleAttestRegister) {
				return this.isAllowTo = false;
			}

			return this.isAllowTo = true;
		},

		onDeleted() {
			this.active = null;

			this.$refs.registerList.close();

			this.fetchReference();
		},

		async exportExcel() {
			this.isPending = true;
			const active = this.filter.active;
			const data = await this.$service.references.search({
				pageSize: 0,
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					'active': active
				}
			});
			const excell = [];

			data.map(d => {
				const group = this.groups.find(g => g._id === d.groupId);

				if(d.userIds && d.userIds.length) {
					for (let i = 0; i < d.userIds.length; i++) {
						excell.push({
							'Referenskod': d.reference,
							'Grupp': group ? group.name : '',
							'Export': d.export ? 'Ja': 'Nej',
							'Beskrivning': d.description,
							'Aktiv':  d.active ? 'Ja' : 'Nej',
							'Uppdateringsdatum': this.$utils.date.intToPretty(d.updated_date),
							'Användarnamn': this.getUsers.find(u => u.id === d.userIds[i]).name,
							'Användare': this.getUsers.find(u => u.id === d.userIds[i]).login,
							'SystemID': d._id
						});
					}
				}
			});

			await this.$utils.excel.download(excell, 'Referenskoder');
			this.isPending = false;
		},

		async fetchReference() {
			const active = this.filter.active;
			const data = await this.$service.references.search({
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					'active': active
				},
				page: this.pagination.page,
				pageSize: this.pagination.pageSize

			});

			this.pagination.total = data._paginationTotal;

			this.items = data.map(x => {
				const group = this.groups.find(y => y._id === x.groupId);

				return {
					...x,
					id: x._id,
					groupId: group ? group.name : '',
					_groupId: x.groupId,
					updated_date:  x.updated_date  ? this.$utils.date.intToPretty(x.updated_date) : '',
					_userIds: x.userIds ? (x.userIds.length > 1 ? this.getUsers.find(u => u.id === x.userIds[0])._userName + ` + ${x.userIds.length - 1} till` : this.getUsers.find(u => u.id === x.userIds[0])._userName) : '',
					_active: x.active ? 'Ja' : `Nej ${x.inactivation_date ? `(${this.$utils.date.intToPretty(x.inactivation_date)})`: ''}`
				};
			});
		}
	}
};
</script>
