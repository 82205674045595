import { kpApiV1 } from '@/plugins/axios';
import { compose } from '@/utils/service';
import apiClient from '@/plugins/axios';


export default {
	search: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/todos/search', body, opts),

	count: (body, opts = {}) => apiClient.get('/wp-json/kp/v1/todos/count', body, opts),

	get: compose(kpApiV1.get, 'todos'),

	getCount: compose(kpApiV1.get, 'todos/count'),

	read: compose(kpApiV1.post, 'todos/read'),

	unread: compose(kpApiV1.post, 'todos/unread'),

	forward: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/todos/forward', body, opts)
};
