<template>
	<div>
		<portal-target name="register-header" />

		<v-card elevation="2">
			<v-tabs>
				<v-tab v-for="tab in tabs" :key="tab.path" ref="tab" :to="tab.path">
					{{ tab.label }}
				</v-tab>
			</v-tabs>
			<div class="pa-6">
				<router-view />
			</div>
		</v-card>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	computed: {
		...mapGetters('userStore', ['isUserAllowedToSupplierEdit', 'isUserAllowedToSubscriptionEdit']),

		tabs() {
			return [this.isUserAllowedToSupplierEdit && {
				path: '/register/suppliers',
				label: 'Leverantörer'
			}, {
				path: '/register/accounting-records',
				label: 'Bokföringar'
			}, this.isUserAllowedToSubscriptionEdit && {
				path: '/register/subscriptions',
				label: 'Abonnemang'
			}, {
				path: '/register/references',
				label: 'Referenskoder'
			}, {
				path: '/register/attest',
				label: 'Attest'
			}, {
				path: '/register/codelist',
				label: 'Kodlista'
			}, {
				path: '/register/ledger',
				label: 'Reskontra'
			}].filter(Boolean);
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.$refs['tab'][0].$el.focus();
		});
	}
};
</script>
