<template>
	<div>
		<portal to="sidebar-right-title">
			{{ formData.reference }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-tooltip fab text small
					content="Ta bort referenskod"
					@click="openDeleteConfirmDialog"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</div>
		</portal>

		<v-expansion-panels v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Information
					<span v-if="formData.creation_date" class="d-flex justify-end text-none text-caption">
						{{ `Skapad ${$utils.date.intToPretty(formData.creation_date)}` }}
					</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-form ref="form" @submit.prevent="submit">
						<v-card elevation="0">
							<v-card-text class="px-0">
								<ui-text-field
									v-model="formData.reference"
									label="Referenskod"
									:rules="$validator('reference/reference')"
								/>

								<ui-autocomplete
									v-model="formData.groupId"
									:items="groups"
									label="Grupp"
									item-text="name"
									item-value="_id"
									:rules="$validator('reference/groupId')"
								/>

								<ui-autocomplete
									v-model="selectedItem"
									:items="getUsers"
									label="Användare"
									item-text="_userName"
									return-object
									clearable
									placeholder="Lägg till ny"
									@change="addUser"
								/>

								<v-list v-if="users" dense nav class="pa-0 mb-5">
									<v-list-item-group color="primary">
										<v-list-item v-for="user in users" :key="user.id" inactive>
											<v-list-item-content>
												<v-list-item-title v-text="getUsers.find(u => u.id === user.id)._userName" />
											</v-list-item-content>
											<v-list-item-icon @click="deleteUser(user.id)">
												<v-icon>mdi-delete</v-icon>
											</v-list-item-icon>
										</v-list-item>
									</v-list-item-group>
								</v-list>

								<ui-textarea v-model="formData.description" label="Beskrivning" :rules="$validator('reference/description')" />

								<ui-checkbox v-model="formData.export" class="text-subtitle-2 font-weight-medium" label="Exportera referens" />
								<ui-checkbox v-model="formData.active" class="text-subtitle-2 font-weight-medium" label="Aktiv referens" />
							</v-card-text>

							<Loader :error="error" />

							<v-card-actions class="px-0">
								<v-spacer />
								<v-btn color="primary" text type="submit" :loading="isUpdatePending">
									{{ $t('base.save') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		data: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		panel: [0],

		groups: [],
		users: [],

		selectedItem: null,

		formData: {
			reference: '',
			groupId: undefined,
			userIds: [],
			description: '',
			export: true,
			active: true
		},

		isUpdatePending: false,
		isRemovePending: false,
		error: ''
	}),

	computed: {
		...mapGetters('users', ['getUsers'])
	},

	watch: {
		'data._id'() {
			this.formData = {...this.data};
		}
	},

	async created() {
		this.formData = {...this.data};
		this.users = this.formData.userIds.map(x => this.getUsers.find(u => u.id === x));
		await this.fetchGroups();
	},

	methods: {
		openDeleteConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: this.$t('register.groups.delete_confirm_title'),
				text: this.$t('register.groups.delete_confirm_text', {name: this.formData.reference}),
				confirmed: this.delete
			});
		},

		addUser(user) {
			if(user) {
				this.users.push(user);
			}

			this.submit();
			this.$nextTick(() => {
				this.selectedItem = null;
			});
		},

		deleteUser(id) {
			const index = this.users.findIndex((x) => x === id);

			this.users.splice(index, 1);
			this.submit();
		},

		async fetchGroups() {
			this.groups = await this.$service.groups.list();
		},

		async submit() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.formData.userIds = this.users.map(item => item.id);
			const { _id, ...body } = this.formData;

			try {
				await this.$service.references.update(_id, body, {
					loading: val => {
						this.isUpdatePending = val;
					},
					throwException: true
				});

				this.$emit('updated');
			} catch (error) {
				this.error = error.message;
			}
		},

		async delete() {
			await this.$service.references.remove(this.formData._id, {
				loading: val => {
					this.isRemovePending = val;
				},
				throwException: true
			});

			this.$emit('deleted');

		}
	}
};
</script>
