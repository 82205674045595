import isValidEmail from 'is-valid-email';
import i18n from '@/plugins/i18n';

export const required = () => input => {
	if (!input) {
		return i18n.t('validation.required');
	}

	return true;
};

export const email = () => input => {
	if (!isValidEmail(input)) {
		return i18n.t('validation.invalid_email');
	}

	return true;
};

export const minLenght = n => input => {
	if (typeof input !== 'string' || input.length < n) {
		return i18n.t('validation.min_lenght', { n });
	}

	return true;
};

export const maxLenght = n => input => {
	if (typeof input !== 'string' || input.length > n) {
		return i18n.t('validation.max_lenght', { n });
	}

	return true;
};

export const maxNumber = n => input => {
	if (!Number.isInteger(Number(input)) || Number(input) > n) {
		return i18n.t('validation.max_number', { n });
	}

	return true;
};

export const minNumber = n => input => {
	if (!Number.isInteger(Number(input)) || Number(input) < n) {
		return i18n.t('validation.min_number', { n });
	}

	return true;
};

export const isBoolean = () => input => {
	if (typeof input !== 'boolean') {
		return i18n.t('validation.is_boolean');
	}

	return true;
};

export const isInteger = () => input => {
	if (!Number.isInteger(Number(input))) {
		return i18n.t('validation.is_integer');
	}

	return true;
};


export const isDecimal = () => input => {
	const regex = /^(\d{1,3}( \d{3})*([.,]\d{1,2})?|\d+([.,]\d{1,2})?)$/;

	if (!regex.test(input)) {
		return i18n.t('validation.is_decimal');
	}

	return true;
};

export const isPositiveInteger = () => input => {
	if (!Number.isInteger(Number(input)) && Number(input) <= 0) {
		return i18n.t('validation.is_positive_integer');
	}

	return true;
};

export const match = (value, name) => input => {
	if (input !== value) {
		return i18n.t('validation.match', { name });
	}

	return true;
};

export const regex = (pattern, message) => input => {
	if (!pattern.test(input)) {
		return message;
	}

	return true;
};

