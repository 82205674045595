<template>
	<v-container>
		<v-card v-for="item in getHistory" :key="`${item.timestamp}-${item.description}`" class="pa-2 mb-2">
			<div class="d-flex text-caption">
				<strong>{{ item.date }}</strong>
				<v-spacer />
				<div v-if="item.uid !== 0">
					<em v-if="item.uid" class="ml-2">{{ getUserName(item.uid) }}</em>
					<em v-if="item.eid && item.uid !== item.eid" class="ml-2">som {{
						getUserName(item.eid)
					}}</em>
				</div>
			</div>
			<div class="text-caption">
				<strong>{{ item.trigger }}</strong>
				<p class="ma-0">{{ item.description }}</p>
			</div>
		</v-card>
	</v-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		history: {
			type: Array,
			required: true
		}
	},

	computed: {
		...mapGetters('users', ['getUsers']),

		getHistory() {
			return this.history ? this.history.map(x => {
				const d = this.$utils.date.toLocal(x.timestamp);
				const fd = this.$utils.date.getFullDate(d);
				const h = this.$utils.date.getHours(d);
				const m = this.$utils.date.getMinutes(d);

				return {
					...x,
					date: `${fd} ${h}:${m}`
				};
			}).reverse() : [];
		}
	},

	methods: {
		getUserName(id) {
			const u = this.getUsers.find(x => x.id === id);

			return u ? `${u._userName}` : '';
		}
	}
};
</script>
