<template>
	<div>
		<v-row no-gutters>
			<v-col cols="8">
				<!--Search :filter-data="filter" :is-open="filterIsOpen" @on-search="onFilter" @on-reset="onFilter" /-->
			</v-col>
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<!--v-btn color="accent" @click="newDialogOpen = true">
							Ny Leverantör
						</v-btn-->
				</div>
			</v-col>
		</v-row>

		<Loader :service="[$service.invoices.searchErrors]" hide-loading />

		<RegisterList ref="registerList" :items="items" :headers="headers" :pagination="pagination"
			sidebar-width="80%"
			@active-item-changed="setActiveItem" @pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<FaultyInvoiceEdit v-if="activeId" :id="activeId" @change="onChange" @on-archive="onChange" />
		</RegisterList>
	</div>
</template>

<script>
import FaultyInvoiceEdit from './components/rejected-invoice-edit';
import RegisterList from '@/components/register-list';

export default {
	components: {
		RegisterList,
		FaultyInvoiceEdit
	},

	data: () => ({
		activeId: null,

		items: [],

		headers: [{
			text: 'Id',
			value: 'id',
			width: 300
		}, {
			text: 'Felmeddelande',
			value: 'error',
			filter: {
				type: 'text'
			}
		}],

		page: 1,
		pageSize: 25,
		pagination: {
			total: null,
			sortBy: ['error'],
			sortDesc: [false]
		},

		filter: {}
	}),

	// async created() {
	// 	await this.fetchInvoices();
	// },


	methods: {
		onChange() {
			this.activeId = null;
			this.$refs.registerList.close();
			this.fetchInvoices();
		},

		setActiveItem(item) {
			if (!item) {
				this.activeId = undefined;

				return;
			}

			this.activeId = item.id;
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchInvoices();
		},


		async fetchInvoices() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const response = await this.$service.invoices.searchRejected({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: this.$utils.filter.getFilterQuery(this.filter),
				'projection' : {
					'ID': 1,
					'Dates.DueDate' : 1,
					'Amounts.TaxInclusiveTotal' : 1,
					'Parties.Seller' : 1,
					'meta' : 1
				}
			});

			this.pagination.total = response._paginationTotal;
			this.items = response.map(x => ({...x, id: x._id}));
		},

		async onFilterUpdate(filter) {
			this.filter = filter;
			await this.fetchInvoices();
		}
	}
};
</script>
