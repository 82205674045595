import i18n from '@/plugins/i18n';
import utils from '@/utils';
import component from './administration';
import usersComponent from './children/users/users';
import sitesComponent from './children/sites/sites';
import rolesComponent from './children/roles/roles';
import groupsComponent from './children/groups/groups';
import subscriptionstypesComponent from './children/subscriptiontypes/subscriptiontypes';
import AccountingPeriods from './children/accounting-periods/accounting-periods';
import InactiveUsers from './children/inactive-users/inactive-users';

export default [{
	path: '/administration',
	name: 'Administration',
	meta: {
		title: i18n.t('routes.titles.register')
	},
	component,
	children: [{
		path: 'users',
		name: 'Administration_Users',
		meta: {
			title: i18n.t('routes.titles.register_users')
		},
		props: route => ({
			queryParams: route.query
		}),
		component: usersComponent
	}, {
		path: 'roles',
		name: 'Administration_Roles',
		meta: {
			title: i18n.t('routes.titles.register_roles')
		},
		props: route => ({
			idFromQueryParam: route.query.id
		}),
		component: rolesComponent,
		beforeEnter: (to, from, next) => {
			return utils.user.isAllowedTo('ViewRoles') ? next() : next({ name: 'Administration_Groups' });
		}
	}, {
		path: 'groups',
		name: 'Administration_Groups',
		meta: {
			title: i18n.t('routes.titles.register_groups')
		},
		props: route => ({
			idFromQueryParam: Number(route.query.id)
		}),
		component: groupsComponent,
		beforeEnter: (to, from, next) => {
			return utils.user.isAllowedTo('GroupView') ? next() : next({ name: 'Administration_Sites' });
		}
	}, {
		path: 'sites',
		name: 'Administration_Sites',
		meta: {
			title: i18n.t('routes.titles.register_sites')
		},
		component: sitesComponent
	},
	{
		path: 'subscriptiontypes',
		name: 'Administration_Subscriptionstypes',
		meta: {
			title: i18n.t('routes.titles.register_subscriptiontypes')
		},
		component: subscriptionstypesComponent
	},
	{
		path: 'accounting-periods',
		name: 'AccountiongPeriods_Settings',
		component: AccountingPeriods,
		meta: {
			title: i18n.t('routes.titles.accounting_periods')
		}
	},
	{
		path: 'inactive-users',
		name: 'Administration_Inactiveusers',
		component: InactiveUsers,
		meta: {
			title: i18n.t('routes.titles.administration_inactiveusers')
		}
	}]
}];
