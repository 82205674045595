import {run} from '@/utils/service';

export const setRoleByQueryParam = async vm => {
	if (!vm.idFromQueryParam) {
		return;
	}

	run(await vm.$service.roles.get.call({ roleId: vm.idFromQueryParam }), data => {
		vm.activeRole = data;
		vm.$refs.registerList.open(data);
	});
};

export const updateQueryParams = (vm, role) => {
	if (!role) {
		// eslint-disable-next-line no-unused-vars
		const { id, ...query } = vm.$route.query;

		vm.$router.push({ query });

		return;
	}

	if (vm.idFromQueryParam !== role.name) {
		vm.$router.push({ query: { ...vm.$route.query, id: role.name } });
	}
};

export default {
	setRoleByQueryParam,
	updateQueryParams
};
