<template>
	<div class="d-flex align-center">
		<v-btn class="ml-2" color="warning" small
			@click.stop="openConfirmDialog"
		>
			Godkänn med fel
		</v-btn>
	</div>
</template>

<script>

export default {
	components: {
	},

	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		formIsValid: false,
		localInvoice: null
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);
	},

	methods: {
		async confirmFaultyIvoice(params) {
			if (!params.message) {
				throw 'Du måste skriva en kommentar.';
			}

			this.localInvoice.meta.actions.skip = [...this.localInvoice.meta.actions.skip, 'L03_SupplierBlockedCheck'];
			this.localInvoice.meta.actions.skip_comment = [...this.localInvoice.meta.actions.skip_comment, `${params.message}`];
			await this.$emit('on-invoice-update', this.localInvoice);
		},

		openConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Godkänn felaktig faktura',
				text: 'Är du säker på att du vill godkänna fakturan med fel?',
				showMessage: true,
				confirmed: this.confirmFaultyIvoice
			});
		}
	}
};
</script>
