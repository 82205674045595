<template>
	<div>
		<v-alert v-for="message in messages" :key="message.id" text dismissible rounded="xl"
			elevation="2" :type="message.type" :icon="getIcon(message.type)" @input="remove(message)"
		>
			{{ message.text }}
		</v-alert>
	</div>
</template>

<script>
import {uid} from 'uid';

const initialState = () => ({
	text: '',
	type: 'info',
	duration: undefined,
	timeoutFn: undefined
});

export default {
	name: 'Message',

	data: () => ({
		messages: []
	}),

	created() {
		this.$bus.on('message:add', this.add);
	},

	beforeDestroy() {
		this.$bus.off('message:add', this.add);
	},

	methods: {
		add(data) {
			const newMessage = {
				id: uid(),
				...initialState()
			};

			Object.entries(data).forEach(([key, value]) => {
				if (key in newMessage) {
					newMessage[key] = value;
				}
			});

			if (newMessage.duration) {
				newMessage.timeoutFn = setTimeout(() => {
					this.remove(newMessage);
					clearTimeout(newMessage.timeoutFn);
				}, newMessage.duration);
			}

			this.messages.unshift(newMessage);
		},

		remove(message) {
			this.messages = this.messages.filter(m => m.id !== message.id);
		},

		getIcon(type) {
			const icons = {
				warning: 'mdi-alert',
				error: 'mdi-alert-octagon',
				info: 'mdi-information-outline',
				success: 'mdi-check-circle'
			};

			return icons[type];
		}
	}
};
</script>
