export const createTree = (dataset, parentKey = 'parent', addNotFoundToRoot = true) => {
	const hashTable = Object.create(null);

	dataset.forEach(aData => hashTable[aData.id] = { ...aData, children: [] });
	const dataTree = [];

	dataset.forEach(aData => {
		if (typeof aData[parentKey] === 'string') {
			if (aData[parentKey] in hashTable) {
				hashTable[aData[parentKey]].children.push(hashTable[aData.id]);
			} else {
				if (addNotFoundToRoot) {
					dataTree.push(hashTable[aData.id]);
				}
			}
		} else {
			dataTree.push(hashTable[aData.id]);
		}
	});

	return dataTree;
};
