<template>
	<div class="Layout-Default">
		<Sidebar />
		<Header :title="meta.title" />
		<v-main v-if="codeListFetched">
			<v-container class="pa-6" fluid>
				<Message />
				<v-slide-x-transition mode="out-in">
					<router-view />
				</v-slide-x-transition>
			</v-container>
		</v-main>
	</div>
</template>

<script>
import Header from '@/components/header';
import Sidebar from '@/components/sidebar';
import Message from '@/components/message';
import { mapActions } from 'vuex';

export default {
	name: 'DefaultLayout',

	components: {
		Header,
		Sidebar,
		Message
	},

	props: {
		meta: {
			type: Object,
			default: () => ({
				title: ''
			})
		}
	},

	data: () => ({
		codeListFetched: false
	}),

	async created() {
		await this.fetchAccountingCodelist();
		await this.setCodeRules();
		this.codeListFetched = true;
	},


	methods: {
		...mapActions('accounting', ['fetchAccountingCodelist', 'setCodeRules'])
	}
};
</script>
