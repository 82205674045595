<template>
	<div style="height: 100%;">
		<portal to="sidebar-right-title">
			{{ id }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-tooltip class="ml-2" fab text small
					content="Arkivera faktura" @click.stop="openArhiveConfirmDialog"
				>
					<v-icon>mdi-package-down</v-icon>
				</v-btn>

				<v-btn v-tooltip class="ml-2" fab text small
					color="primary"
					content="Skicka på ny kontroll" @click.stop="onRetryClick"
				>
					<v-icon>mdi-send</v-icon>
				</v-btn>
			</div>
		</portal>

		<Loader :loading="isFetchPending" />

		<p class="text-subtitle-2">{{ error }}</p>

		<codemirror v-model="invoice" :options="cmOptions" />
	</div>
</template>

<script>
import {decode as b64decode} from 'base-64';
import {codemirror} from 'vue-codemirror';
import pako from 'pako';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/mode/xml/xml.js';

export default {
	components: {
		codemirror
	},

	props: {
		id: {
			type: String,
			required: true
		}
	},

	data: () => ({
		invoice: '',
		error: '',

		isPending: false,
		isFetchPending: false,
		isArchivePending: false,

		cmOptions: {
			tabSize: 4,
			mode: 'application/xml',
			theme: 'monokai',
			lineNumbers: true,
			line: true
		}
	}),

	watch: {
		async id() {
			await this.fetchInvoice();
		}
	},

	async created() {
		await this.fetchInvoice();
	},

	methods: {
		openArhiveConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Arkivera felaktig faktura',
				text: `Är du säker på att du vill arkivera fakturan med id ${this.id}?`,
				showMessage: true,
				confirmed: this.archiveIvoice
			});
		},

		async archiveIvoice(params) {
			if (!params.message) {
				throw 'Du måste fylla i meddelande.';
			}

			await this.$service.invoices.archiveRejected(this.id, {
				note: params.message
			}, {
				loading: val => {
					this.isArchivePending = val;
				}
			});

			this.$emit('on-archive');
		},

		async onRetryClick() {
			try {
				await this.$service.invoices.retryRejected(this.id, this.invoice, {
					headers: {
						'Content-Type': 'application/xml'
					},
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});
			} catch (error) {
				this.error = error.message;
			}
		},

		async fetchInvoice() {
			try {
				const d = await this.$service.invoices.getRejected(this.id, {
					throwException: true,
					loading: val => {
						this.isFetchPending = val;
					}
				});
				const strData = b64decode(d.xml);
				const charData = strData.split('').map(x => x.charCodeAt(0));
				const binData = new Uint8Array(charData);
				const data = pako.inflate(binData, { to: 'string' });
				let final = data.substring(1, data.length - 1);

				final = final
					.replaceAll('\\u00ef\\u00bb\\u00bf', '')
					.replaceAll('\\n', '\n')
					.replaceAll('\\t', '\t')
					.replaceAll('\\"', '"')
					.replaceAll('\\/', '/');

				this.invoice = final;
				this.error = d.error;

			} catch (error) {
				this.error = error;
			}
		}
	}
};
</script>

<style>
	.vue-codemirror,
	.CodeMirror {
		height: 100%;
	}
</style>
