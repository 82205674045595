<template>
	<div>
		<portal to="sidebar-right-title">
			{{ site.title }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-if="site.is_archived" v-tooltip class="ml-auto" fab text
					small
					content="Aktivera organisation"
					@click="openActivateDialog"
				>
					<v-icon>mdi-package-up</v-icon>
				</v-btn>
				<v-btn v-if="!site.is_archived" v-tooltip class="ml-auto" fab text
					small
					content="Inaktivera organisation"
					@click="openDeleteConfirmDialog"
				>
					<v-icon>mdi-package-down</v-icon>
				</v-btn>
			</div>
		</portal>
		<v-card elevation="0" />
		<v-card class="pa-16">
			<ui-text-field v-model="localName" label="Namn" />

			<v-card-actions class="mt-12">
				<v-btn class="ml-auto" color="primary" text @click.stop="updateSite">
					Spara
				</v-btn>
			</v-card-actions>
		</v-card>

		<Loader :error="errorMessage" />
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {

	props: {
		site: {
			type: Object,
			default: () => ({})
		}
	},

	data: () => ({
		localName: '',
		errorMessage: ''
	}),

	computed: {
		...mapGetters('userStore', ['isUserAllowedToDeleteRole'])
	},

	watch: {
		'site.id'() {
			this.localName = this.site.title;
		}
	},

	created() {
		this.localName = this.site.title;
	},

	methods: {
		openActivateDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Aktivera organisation',
				text: 'Är du säker på att du vill aktivera organisation ' + this.site.title,
				confirmed: this.activateSite
			});
		},

		async activateSite() {
			await this.$service.sites.activate({id: this.site.id}, {
				throwException: true
			});

			this.$emit('site-update');
		},

		openDeleteConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Inaktivera organisation',
				text: 'Är du säker på att du vill inaktivera organisation ' + this.site.title,
				confirmed: this.deactivateSite
			});
		},

		async deactivateSite() {
			await this.$service.sites.deactivate({id: this.site.id}, {
				throwException: true
			});

			this.$emit('site-update');
		},

		async updateSite() {
			try {
				let data = {
					id: this.site.id,
					name: this.localName
				};

				await this.$service.sites.update(data, {
					throwException: true
				});

				this.$emit('site-update');
			} catch (error) {
				this.errorMessage = error.message;
			}
		}
	}
};
</script>

