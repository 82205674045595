var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-button d-flex align-center",staticStyle:{"line-height":"100%"}},[_c('span',[_vm._v("Checklista")]),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-end pr-8"},[(_vm.hasReds)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-box")]):_vm._e(),(_vm.hasYellows)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-alert-box")]):_vm._e()],1)],1),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('p',[_vm._v(" Här är en checklista över det som en faktura ska innehålla för att uppfylla kraven som ställs för attestering/kontering. ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.getChecklist),function(check){return _c('v-col',{key:check.key,attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"icon":"","disable":_vm.isPending},on:{"click":function($event){return _vm.updateCheck(check.key, check.value)}}},[(check.value === 0)?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")]):_vm._e(),(check.value === 1)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-box")]):_vm._e(),(check.value === 2)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-help-box")]):_vm._e(),(check.value === 3)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-marked")]):_vm._e()],1),(check.key === 'line_info')?_c('v-tooltip',{attrs:{"bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-1",attrs:{"small":""}},on),[_vm._v("mdi-information")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t(("invoice.checklist." + (check.key)))))])]}}],null,true)},[_c('span',[_vm._v("Vid beställning av "),_c('strong',[_vm._v("varor")]),_vm._v(" ska typ av vara + eventuell varubeteckning, antal och á-pris finnas med."),_c('br'),_vm._v("Vid beställning av "),_c('strong',[_vm._v("tjänster")]),_vm._v(" ska typ av tjänst vara angivet samt antal timmar och timpris eller fast pris + "),_c('br'),_vm._v("eventuella utlägg. För kontroll; bifoga en tidsredovisning till fakturan.")])]):(check.key === 'reference')?_c('v-tooltip',{attrs:{"bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-1",attrs:{"small":""}},on),[_vm._v("mdi-information")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t(("invoice.checklist." + (check.key)))))])]}}],null,true)},[_c('span',[_vm._v("Om referenskoden är felaktig eller felaktig placerad - kontakta leverantör för rättelse.")])]):(check.key === 'vat_subtaxes')?_c('v-tooltip',{attrs:{"bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-1",attrs:{"small":""}},on),[_vm._v("mdi-information")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t(("invoice.checklist." + (check.key)))))])]}}],null,true)},[_c('span',[_vm._v("Beskattningsunderlaget är det belopp som momsen beräknas på.")])]):(check.key === 'vat_total')?_c('v-tooltip',{attrs:{"bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-1",attrs:{"small":""}},on),[_vm._v("mdi-information")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t(("invoice.checklist." + (check.key)))))])]}}],null,true)},[_c('span',[_vm._v("Momsbelopp är korrekt uträknat utifrån tillämpad momssats och beskattningsunderlag")])]):_vm._e(),(!['line_info', 'reference', 'vat_subtaxes', 'vat_total'].includes(check.key))?[_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t(("invoice.checklist." + (check.key)))))])]:_vm._e()],2)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"d-flex justify-end text-caption"},[_vm._v(" *de som går att verifiera med korrekta uppgifter markeras med (*) ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }