<template>
	<ui-autocomplete v-model="supplierId"
		:label="label"
		item-text="_name"
		item-value="_id"
		clearable
		:items="items"
		:loading="isSearchPending"
		:clean="clean"
		:no-label="noLabel"
		@change="change"
	/>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: ''
		},

		label: {
			type: String,
			default: 'Förälder'
		},

		noLabel: {
			type: Boolean,
			default: false
		},

		clean: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		items: [],
		supplierId: null,
		isSearchPending: false
	}),

	watch: {
		supplierId(to) {
			this.$emit('input', to);
		},

		value(to) {
			this.supplierId = to;
		}
	},

	async created() {
		this.supplierId = this.value;
		await this.fetchSuppliers();
	},

	methods: {
		change(id) {
			const item = this.items.find(x => x._id === id);

			this.$emit('on-change', item);
		},

		async fetchSuppliers() {
			const data = await this.$service.suppliers.search({
				sort: {
					reference: 1
				},
				pagination: {
					pageSize: 9999,
					page: 1
				}
			}, {
				loading: val => {
					this.isSearchPending = val;
				}
			});

			data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

			this.items = data.map(supplier => {
				return {
					...supplier,
					_name: `${supplier.name} (${supplier.orgNumber})`
				};
			});
		}
	}
};
</script>

