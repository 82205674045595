<template>
	<div>
		<v-row no-gutters>
			<v-col cols="8">
				<v-checkbox label="Inkludera arkiverade abonnemang" @change="filterArchived" />
			</v-col>
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
						@click="exportExcel"
					>
						<v-icon>mdi-microsoft-excel</v-icon>
					</v-btn>
					<v-btn color="accent" @click="showNewSubDialog = true"> Nytt Abonnemang</v-btn>
				</div>
			</v-col>
		</v-row>

		<RegisterList :items="subscriptions" :headers="headers" sidebar-width="60%" :push-content="false" item-key="_id"
			:pagination="pagination" @active-item-changed="setActiveItem" @pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<subscription-detailed v-if="activeSubscription" :subscription-id="activeSubscription._id" @on-archive="fetchSubscriptions" />
		</RegisterList>

		<subscription-new v-if="showNewSubDialog"
			@close="showNewSubDialog = false"
			@on-updated="fetchSubscriptions"
			@on-created="fetchSubscriptions"
		/>
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import SubscriptionNew from './components/subscription-new';
import SubscriptionDetailed from './components/subscription-detailed';

export default {
	components: {
		RegisterList,
		SubscriptionNew,
		SubscriptionDetailed
	},

	data: () => ({
		subscriptions: [],
		types: [],
		references: [],
		activeSubscription: null,
		isPending: false,
		showNewSubDialog: false,
		includeArchived: false,

		headers: [{
			align: 'start',
			text: 'Kategori',
			value: 'type',
			filter: {
				type: 'select',
				items: []
			}
		}, {
			text: 'Abonnemangsnr',
			value: 'ID',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Namn',
			value: 'name',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Kommentar',
			value: 'note',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Skapad',
			value: 'creation_date',
			filter: [{
				type: 'date',
				placeholder: 'fr.o.m.',
				transform: val => val !== null ? Number(val.replaceAll('-', '')) : null
			},
			{
				type: 'date',
				placeholder: 't.o.m.',
				transform: val => val !== null ? Number(val.replaceAll('-', '')) : null
			}]
		}],

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: ['name'],
			sortDesc: [false]
		},

		filter: {}
	}),

	methods: {
		handleEnterKey() {
			this.$refs.checkbox.$el.click();
		},

		async filterArchived(value) {
			this.includeArchived = value;
			await this.fetchSubscriptions();
		},

		setActiveItem(item) {
			this.activeSubscription = item;
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchSubscriptions();
		},

		async exportExcel() {
			this.isPending = true;
			const data = await this.$service.subscriptions.search({
				pageSize: 0,
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					...(!this.includeArchived ? { 'archived': false }  : {})
				}
			});
			const excell = data.map(d => {
				const type = this.types.find(x => x._id === d.type);
				const reference = this.references.find(x => x._id === d.reference).reference;

				return {
					'Kategori': d.type && type ? type.name : d.type,
					'Abonnemangsnr': d.ID,
					'Namn': d.name,
					'Kommentar': d.note,
					'Skapad': this.$utils.date.intToPretty(d.creation_date),
					'Referenskod': reference
				};
			});

			await this.$utils.excel.download(excell, 'Abonemang');
			this.isPending = false;
		},

		async fetchTypes() {
			const data = await this.$service.subscriptions.searchTypes({
				pageSize: 9999
			});

			this.types = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

			this.types.map(t => (this.headers[0].filter.items.push({
				text: t.name,
				value: t._id
			})));
		},


		async fetchReference() {
			this.references = await this.$service.references.search({
				sort: {
					reference: 1
				},
				pagination: {
					pageSize: 9999,
					page: 1
				}
			});
		},

		async fetchSubscriptions() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const data = await this.$service.subscriptions.search({
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					...(!this.includeArchived ? { 'archived': false }  : {})
				},
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {})
			});

			this.pagination.total = data._paginationTotal;
			this.subscriptions = data.map(d => {
				const type = this.types.find(x => x._id === d.type);

				return {
					...d,
					type: d.type && type ? type.name : d.type,
					creation_date: d.creation_date ? this.$utils.date.intToPretty(d.creation_date): ''
				};
			});
		},

		async onFilterUpdate(filter) {
			this.filter = filter;
			await this.fetchSubscriptions();
		}
	}
};
</script>
