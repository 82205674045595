import Vue from 'vue';
import services from '@/services';
import {run} from '@/utils/service';
import { SET_CAPS } from '../mutation-types';

export default {
	async fetchCaps({ commit }) {
		run(await services.caps.get.call(), data => commit(SET_CAPS, data), error => Vue.bus.emit('message:add', {
			type: 'error',
			text: error
		}));
	}
};
