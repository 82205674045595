<template>
	<div>
		<v-row>
			<v-col cols="4">
				<bank-card :groups="groups" label="Bank" />
			</v-col>
			<v-col cols="4">
				<payaccount-card :groups="groups" label="Betalkonton" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import BankCard from './components/bank-card.vue';
import PayaccountCard from './components/payaccount-card.vue';

export default {
	components: {
		BankCard,
		PayaccountCard
	},

	data: () => ({
		groups: []
	}),

	async created() {
		await this.fetchGroups();
	},

	methods: {
		async fetchGroups() {
			const data = await this.$service.groups.list();

			this.groups = [{ _id: 0, name: '' }, ...data];
		}
	}
};
</script>
