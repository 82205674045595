var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"title":"Signera Attestering","width":"1200","ok-btn-text":"Signera","loading":_vm.signIsPending,"disable-ok-btn":_vm.getAttests.length === 0},on:{"reject":function($event){return _vm.$emit('reject')},"confirm":_vm.sign}},[_c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Attestera")]),_c('v-tab',[_vm._v("Bokföring")])],1),(_vm.tab === 0)?_c('div',[_c('v-data-table',{staticClass:"GlobalAttestTable",attrs:{"items":_vm.getAttests,"headers":_vm.headers,"disable-pagination":"","expanded":_vm.expandedRows,"items-per-page":-1,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.date.getFullDate(_vm.$utils.date.intToDate(item.date)))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.format.price(item.amount))+" ")]}},{key:"item.codes",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(item.codes[0])+" - "+_vm._s(item.codes[1])+" - "+_vm._s(item.codes[2])+" - "+_vm._s(item.codes[3])+" - "+_vm._s(item.codes[4])+" - "+_vm._s(item.codes[5])+" - "+_vm._s(item.codes[6])+" - "+_vm._s(item.codes[7])+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.isApproval)?_c('span',[_vm._v("Godkännare")]):(item.isAuthorize)?_c('span',[_vm._v("Beslutare")]):_c('span',[_vm._v("-")])]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('Loader',{attrs:{"error":_vm.getErrorMessage(item)}})],1)]}}],null,false,96355081)})],1):_vm._e(),(_vm.tab === 1)?_c('div',[_vm._v(" n/a ")]):_vm._e(),_c('v-form',{ref:"form"},[_c('div',{staticClass:"d-flex justify-end mt-6"},[_c('ui-text-field',{attrs:{"type":"password","label":"Lösenord","rules":_vm.$validator('base/required'),"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]),_c('Loader',{attrs:{"error":_vm.responseError,"slim":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }