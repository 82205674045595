<template>
	<div class="InvoiceRegister">
		<div class="d-flex justify-end">
			<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
				@click="exportExcel"
			>
				<v-icon>mdi-microsoft-excel</v-icon>
			</v-btn>
		</div>

		<RegisterList v-model="selectedItems"
			:items="invoices"
			:headers="getHeaders"
			sidebar-width="60%"
			:push-content="false"
			item-key="_id"
			:pagination="pagination"
			:mass-actions="massActions"
			show-select
			:show-sidebar="activeInvoice !== null"
			@mass-action="doMassAction"
			@active-item-changed="setActiveItem"
			@pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<InvoiceSidebar :invoice="activeInvoice" @on-refetch="fetchInvoices" @close-fetch="closeFetch" />
		</RegisterList>


		<Dialog v-if="passwordDialog" title="Signera" ok-btn-text="Sign" @reject="passwordDialog = false" @confirm="doMassAction(true)">
			<v-text-field v-model="password" type="password" label="Lösenord" />
			<Loader :service="[$service.invoices.pay]" hide-loading slim />
		</Dialog>
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import InvoiceSidebar from './components/sidebar';
import {run} from '@/utils/service';

export default {
	name: 'InvoicesChildView',

	components: {
		RegisterList,
		InvoiceSidebar
	},

	data: () => ({
		invoices: [],
		password: '',
		passwordDialog: false,

		isPending: false,
		activeInvoice: null,
		activeInvoiceId: null,
		selectedItems: [],

		massAction: null,
		massActions: [{
			value: 'pay',
			text: 'Godkänn betalning'
		},{
			value: 'block',
			text: 'Blockera betalning av faktura'
		}, {
			value: 'unblock',
			text: 'Avblockera betalning av faktura'
		},{
			value: 'test',
			text: 'Testa betalfil'
		}],

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: [],
			sortDesc: [false]
		},

		filter: {
			meta: {
				payment_status: { '$in' : ['unpaid', 'blocked'] }
			}
		}
	}),

	computed: {
		getHeaders() {
			return [{
				text: 'Dokument-ID',
				value: 'ID',
				align: 'start',
				filter: {
					type: 'text'
				}
			}, {
				text: 'Betalningsmottagare',
				value: 'Parties.Seller.Name',
				filter: {
					type: 'text',
					placeholder: ''
				}
			}, {
				text: 'Utbetalningsenhet',
				value: 'meta.payment.debtor.name',
				sortable: false
			}, {
				text: 'Belopp inkl. moms',
				value: 'Amounts.TaxInclusiveTotal.Amount',
				align: 'left',
				filter: [{
					type: 'text',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(val) : null
				},
				{
					type: 'text',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(val) : null
				}]
			}, {
				text: 'Betaldatum',
				value: 'meta.payment.paydate',
				align: 'left',
				filter: [{
					type: 'date',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				},
				{
					type: 'date',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				}]
			}, {
				text: 'Konto/Gironr',
				value: 'meta.payment.debtor.number',
				filter: {
					type: 'text',
					placeholder: ''
				}
			}, {
				text: 'Förfallodatum',
				value: 'Dates.DueDate',
				filter: {
					type: 'date',
					placeholder: 'fr.o.m.'
				}
			}, {
				text: 'Fakturatyp',
				value: 'meta.document_sub_type',
				filter: {
					type: 'select',
					items: [{
						text: 'Debet',
						value: 'debit'
					}, {
						text: 'Kredit',
						value: 'credit'
					}]
				}
			}, {
				text: 'Betalstatus',
				sortable: false,
				align: 'left',
				value: 'meta.payment_status'
			}, {
				text: 'Källa',
				value: 'meta.source',
				filter: {
					type: 'select',
					items: [{
						text: 'Scancloud',
						value: 'scancloud'
					}, {
						text: 'Manuell',
						value: 'manual'
					},{
						text: 'Smartbook',
						value: 'smartbook'
					}]
				}
			}];

		}
	},

	methods: {
		closeFetch() {
			this.activeInvoice = null;
			this.fetchInvoices();
		},

		doMassAction(action) {

			if (typeof action === 'boolean' && action === true) {
				this.markAsPayed(this.selectedItems.map(x => x._id));

				return;
			}

			if (action.type === 'block') {

				this.markAsBlocked(this.selectedItems.map(x => x._id));

				return;
			}

			if (action.type === 'unblock') {
				this.markAsUnblocked(this.selectedItems.map(x => x._id));

				return;
			}

			if (action.type === 'pay') {
				this.passwordDialog = true;

				return;
			}

			if (action.type === 'test') {
				this.sendPaymentTest(this.selectedItems.map(x => x._id));

				return;
			}
		},

		async sendPaymentTest(items) {
			await this.$service.payment.test({
				ids: items
			});

			this.fetchInvoices();
			this.selectedItems = [];
		},

		async markAsPayed(items) {
			await this.$service.invoices.pay({
				invoiceIds: items,
				password: this.password
			}, {
				throwException: true,
				loading: val => {
					this.isPending = val;
				}
			});

			this.fetchInvoices();
			this.password = '';
			this.selectedItems = [];
		},

		async markAsBlocked(items) {
			run(await this.$service.invoices.markAsBlocked.call({
				body: {
					invoiceIds: items
				}
			}), () => {
				this.fetchInvoices();
				this.selectedItems = [];
			});
		},

		async markAsUnblocked(items) {
			run(await this.$service.invoices.markAsUnblocked.call({
				body: {
					invoiceIds: items
				}
			}), () => {
				this.fetchInvoices();
				this.selectedItems = [];
			});
		},

		setActiveItem(item) {
			this.activeInvoice = item;
			this.activeInvoiceId = item && item._id;
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchInvoices();
		},

		async exportExcel() {
			this.isPending = true;
			const data = await this.$service.invoices.search({
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					'meta.payment_status': { '$in' : ['unpaid', 'blocked']}
				}
			});
			const excell = data.map(d => ({
				'Dokument-ID': d.ID,
				Betalningsmottagare: d.Parties.Seller.Name,
				Utbetalningsenhet: d.meta.payment.debtor.name,
				'Belopp inkl. moms': d.Amounts.TaxInclusiveTotal.Amount,
				Betaldatum: d.meta.payment.duedate,
				'Konto & Giro': d.meta.payment.creditor.number,
				Förfallodatum: d.Dates.DueDate,
				Fakturatyp: d.meta.document_sub_type,
				Status: d.meta.status,
				Källa: d.meta.source,
				// Verifikationsnr: ????
				'System ID': d._id
			}));

			await this.$utils.excel.download(excell, 'Betalningsförslag');
			this.isPending = false;
		},

		async fetchInvoices() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const response = await this.$service.invoices.search({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),

				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					'meta.payment_status': { '$in' : ['unpaid', 'blocked']},
					'meta.deleted': false
				},
				'projection' : {
					'ID': 1,
					'Dates.DueDate' : 1,
					'Amounts.TaxInclusiveTotal' : 1,
					'Parties.Seller' : 1,
					'meta' : 1
				}
			});

			this.pagination.total = response._paginationTotal;
			this.invoices = response.map(d => ({
				...d,
				Amounts: {
					...d.Amounts,
					TaxInclusiveTotal: {
						...(d.Amounts ? {...d.Amounts.TaxInclusiveTotal} : {}),
						Amount: d?.Amounts?.TaxInclusiveTotal?.Amount ? this.$utils.format.price(d.Amounts.TaxInclusiveTotal.Amount) : null
					}
				},
				meta: {
					...d.meta,
					document_sub_type: d.meta.document_sub_type === 'debit' ? 'Debet' : 'Kredit',
					payment: {
						...d.meta.payment,
						paydate: this.$utils.date.intToPretty(d.meta.payment.paydate),
						debtor: {
							number: d.meta.payment.debtor.number ? this.$utils.format.giro('BG', `${d.meta.payment.debtor.number}`) : '',
							name: d.meta.payment.debtor.name
						}
					},
					source: d.source === 'smartbook' ? 'Smartbook' : d.source === 'manual' ? 'Manuell' : 'Scancloud'
				},
				_skipInStepper: d.meta.sensitivity && d.meta.sensitivity > 1
			}));
		},

		async onFilterUpdate(filter) {
			this.setFilter(filter);
			await this.fetchInvoices();
		},

		setFilter(filter) {
			this.filter = filter;
			this.filter = {...filter, 'meta.payment_status': { '$in' : ['unpaid', 'blocked']} };
		}
	}
};
</script>
