<template>
	<div>
		<RegisterList ref="registerList"
			:pagination="pagination"
			:items="users"
			:headers="headers"
			sidebar-width="65%"
			item-key="_id"
			@pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
			@active-item-changed="setActiveItem"
		>
			<EditInactiveUser :user-to-edit="active" />
		</RegisterList>
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import EditInactiveUser from './components/edit-inactive-user';
export default {
	components: {
		RegisterList,
		EditInactiveUser
	},

	data: () => ({
		active: null,

		isPending: false,

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortDesc: []
		},
		filter: {},

		users: [],
		headers: [{
			text: 'Namn',
			value: 'name',
			align: 'start',
			filter: {
				type: 'text'
			}
		},{
			text: 'Login',
			value: 'login',
			filter: {
				type: 'text'
			}
		}]
	}),

	methods: {
		onFilterUpdate(filter) {
			this.filter = filter;
			this.fetchUsers();
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;
			this.fetchUsers();
		},

		setActiveItem(item) {
			this.active = item;
		},

		async fetchUsers() {
			const data = await this.$service.users.searchUserInfo({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					'active': false,
					'closed': false
				}
			}, {
				loading: val => {
					this.isPending = val;
				}
			});

			this.pagination.total = data._paginationTotal;

			this.users = data;
		}
	}
};
</script>
