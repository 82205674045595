<template>
	<v-card class="pa-6">
		<v-card-title class="px-0">{{ label }}</v-card-title>
		<ui-select v-if="!noGroup" v-model="group" :items="groups" label="Grupp" item-text="name"
			item-value="_id"
			@input="fetchSettings"
		/>
		<v-divider v-if="!noGroup" class="my-4" />

		<div v-for="setting in settings" :key="setting.key">
			<div v-if="setting.type.value === 'checkbox'">
				<ui-checkbox v-model="settingValues[setting.key].value">
					<template #ui-label>
						{{ setting.label }}
						<v-icon v-if="getGroupById(settingValues[setting.key].group)" v-tooltip color="warning" small class="ml-2"
							:content="`Inställning från gruppen ${getGroupById(settingValues[setting.key].group).name}`"
						>
							mdi-account-group
						</v-icon>
					</template>
				</ui-checkbox>
			</div>

			<ui-text-field v-if="setting.type.value === 'number'" v-model.number="settingValues[setting.key].value" :rules="$validator(`settings/minMaxNumber`, {from: setting.type.from, to: setting.type.to})">
				<template #ui-label>
					{{ setting.label }}
					<v-icon v-if="getGroupById(settingValues[setting.key].group)" v-tooltip color="warning" small class="ml-2"
						:content="`Inställning från gruppen ${getGroupById(settingValues[setting.key].group).name}`"
					>
						mdi-account-group
					</v-icon>
				</template>
			</ui-text-field>

			<div v-if="setting.type.value === 'slider'" class="mb-6">
				<label class="text-subtitle-2 font-weight-bold">{{ setting.label }}
					<v-icon v-if="getGroupById(settingValues[setting.key].group)" v-tooltip color="warning" small class="ml-2"
						:content="`Inställning från gruppen ${getGroupById(settingValues[setting.key].group).name}`"
					>
						mdi-account-group
					</v-icon>
				</label>
				<v-slider
					v-model="settingValues[setting.key].value"
					:min="setting.type.from"
					:max="setting.type.to"
					:step="setting.type.stepSize"
					:hint="`Värde: ${settingValues[setting.key].value}`"
					persistent-hint
				>
					<template v-slot:prepend>
						<v-text-field
							v-if="setting.type.showSliderInput"
							v-model="settingValues[setting.key].value"
							class="mt-0 pt-0 mr-6"
							hide-details
							single-line
							type="number"
							style="width: 110px"
						/>
					</template>
					<template v-slot:thumb-label="props">
						{{ props.value }}
					</template>
				</v-slider>
			</div>

			<div v-if="setting.type.value === 'list'">
				<label class="text-subtitle-2 font-weight-bold">{{ setting.label }}
					<v-icon v-if="getGroupById(settingValues[setting.key].group)" v-tooltip color="warning" small class="ml-2"
						:content="`Inställning från gruppen ${getGroupById(settingValues[setting.key].group).name}`"
					>
						mdi-account-group
					</v-icon>
				</label>
				<div class="d-flex">
					<ui-text-field
						v-model="settingValues[setting.key].items[0]"
						class="pr-2"
						label="Källa"
						clearable
					/>
					<ui-text-field
						v-model="settingValues[setting.key].items[1]"
						class="pr-2"
						label="Ursprungstyp"
						clearable
					/>

					<ui-text-field
						v-model="settingValues[setting.key].items[2]"
						class="pr-1"
						label="Rutinkod"
						clearable
					/>
				</div>
				<v-data-table
					:items="list"
					:headers="headers"
					:hide-default-footer="true"
				>
					<template v-slot:item.delete="{ item }">
						<v-icon @click.stop="deleteRow(item, setting.key)">
							mdi-delete
						</v-icon>
					</template>
				</v-data-table>
			</div>

			<ui-text-field v-if="setting.type === ''" v-model="settingValues[setting.key].value">
				<template #ui-label>
					{{ setting.label }}
					<v-icon v-if="getGroupById(settingValues[setting.key].group)" v-tooltip color="warning" small class="ml-2"
						:content="`Inställning från gruppen ${getGroupById(settingValues[setting.key].group).name}`"
					>
						mdi-account-group
					</v-icon>
				</template>
				<template v-slot:append-outer>
					<span v-if="setting.size">
						{{ setting.size }}
					</span>
				</template>
			</ui-text-field>
		</div>

		<Loader :error="updateError" :success="updateFeedbackMessage" />

		<v-card-actions class="d-flex justify-end">
			<v-btn text color="primary" :loading="isPending" :disabled="isPending" @click="updateSetting">Spara</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>

export default {
	props: {
		label: {
			type: String,
			default: ''
		},

		settings: {
			type: Array,
			required: true
		},

		groups: {
			type: Array,
			required: true
		},

		noGroup: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		group: 0,
		isPending: false,
		updateFeedbackMessage: null,
		updateError: '',

		settingValues: {},
		list: [],

		headers: [
			{
				text: 'Källa',
				sortable: false,
				value: '0'
			}, {
				text: 'Ursprungstyp',
				sortable: false,
				value: '1'
			}, {
				text: 'Rutinkod',
				sortable: false,
				value: '2'
			},
			{
				sortable: false,
				value: 'delete',
				align: 'right'
			}
		]
	}),

	async created() {
		this.setDefaultValues();
		await this.fetchSettings();
	},

	methods: {
		deleteRow(item, key) {
			this.settingValues[key].value.splice(item._index, 1);

			this.updateSetting();
		},

		setDefaultValues() {
			this.settings.forEach(setting => {
				this.$set(this.settingValues, setting.key, {
					value: setting.value,
					group: setting.group,
					items: setting.items
				});
			});
		},

		setValues(settings) {
			Object.entries(settings).forEach(([key, value]) => {
				if(key === 'kiUTPImport') {
					this.list = value.value.length ? value.value.map((x, i) => x.reduce((obj, item, index) => {
						return {
							...obj,
							[index]: item,
							_index: i
						};
					}, {})) : [];
					this.settingValues[key].value = value.value;
					this.settingValues[key].group = value.group;
					this.settingValues[key].items = [null, null, null];

					return;
				}

				this.settingValues[key].value = key === 'kiMaxAttachmentSize' ? value.value / 1000 : value.value;
				this.settingValues[key].group = value.group;
			});
		},

		getGroupById(id) {
			return this.groups.find(x => x._id === id);
		},

		async fetchSettings() {
			const settings =  await this.$service.settings.list(this.group, {
				keys: this.settings.map(x => x.key)
			}, {
				loading: val => {
					this.isPending = val;
				}
			});

			this.setValues(settings);
		},

		async updateSetting() {
			const settings = Object.entries(this.settingValues).reduce((acc, [key, value]) => {
				if(key === 'kiUTPImport') {
					value.items.some(x => x != null) ? value.value.push(value.items) : null;
				}

				return {
					...acc,
					[key]: value.value
				};
			}, {});

			if ('kiMaxAttachmentSize' in settings) {
				settings['kiMaxAttachmentSize'] = settings['kiMaxAttachmentSize'] * 1000;
			}

			try {
				await this.$service.settings.update(this.group, settings, {
					loading: val => {
						this.isPending = val;
					}
				});

				this.updateFeedbackMessage = ['Inställingar uppdaterades'];
				await this.fetchSettings();
			} catch (error) {
				this.updateError = error.message;
			}
		}
	}
};
</script>
