<template>
	<div>
		<v-dialog v-if="isDialog" v-model="open" :max-width="width" :width="width" :retain-focus="false"
			:hide-overlay="hideOverlay"
			scrollable
			@click:outside="reject"
		>
			<transition :duration="150">
				<v-card>
					<v-card-title class="primary white--text">
						<h3 class="headline">{{ title }}</h3>
						<v-spacer />
						<v-btn icon color="white" @click="reject">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text :class="paddingText">
						<!-- eslint-disable-next-line vue/no-v-html -->
						<div v-html="text" />
						<slot />
					</v-card-text>

					<v-divider />

					<v-card-actions>
						<v-spacer />
						<v-btn v-if="showCancelBtn" color="warning" text :disabled="loading" @click.stop="reject">
							{{ rejectBtn }}
						</v-btn>
						<v-btn v-if="!alert" color="primary" :loading="loading" text :disabled="disableOkBtn"
							@click.stop="confirm" @keyup.enter="confirm"
						>
							{{ confirmBtn }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</transition>
		</v-dialog>
		<div v-else>
			<transition :duration="150">
				<v-card flat>
					<v-card-title v-if="title">
						<h3 class="headline">{{ title }}</h3>
					</v-card-title>
					<v-card-text :class="{ 'py-12': title }">
						<!-- eslint-disable-next-line vue/no-v-html -->
						<div v-html="text" />
						<slot />
					</v-card-text>

					<v-divider />

					<v-card-actions>
						<v-spacer />
						<v-btn v-if="showCancelBtn" color="warning" text :disabled="loading" @click.stop="reject">
							{{ rejectBtn }}
						</v-btn>
						<v-btn v-if="!alert" color="primary" :loading="loading" text :disabled="disableOkBtn"
							@click.stop="confirm" @keyup.enter="confirm"
						>
							{{ confirmBtn }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Dialog',

	props: {
		isDialog: {
			type: Boolean,
			default: true
		},

		title: {
			type: String,
			default: ''
		},

		hideOverlay: {
			type: Boolean,
			default: false
		},

		alert: {
			type: Boolean,
			default: false
		},

		width: {
			type: String,
			default: '640'
		},

		paddingText: {
			type: String,
			default: 'py-12'
		},

		text: {
			type: String,
			default: ''
		},

		cancelBtnText: {
			type: String,
			default: ''
		},

		showCancelBtn: {
			type: Boolean,
			default: true
		},

		okBtnText: {
			type: String,
			default: ''
		},

		disableOkBtn: {
			type: Boolean,
			default: false
		},

		loading: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		open: true,
		confirmBtn: '',
		rejectBtn: ''
	}),

	created() {
		this.confirmBtn = this.okBtnText || this.$t('base.save');
		this.rejectBtn = this.alert ? 'Ok' : (this.cancelBtnText || this.$t('base.cancel'));
	},

	methods: {
		confirm() {
			if (this.disableOkBtn) {
				return;
			}

			this.$emit('confirm');
		},

		reject() {
			this.$emit('reject');
		}
	}
};
</script>
