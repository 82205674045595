import i18n from '@/plugins/i18n';
import Component from './payments.vue';
import Payments from './children/payments/payments.vue';
import AwaitingPayments from './children/awaiting-payments/awaiting-payments.vue';
import ManualPayments from './children/manual-payments/manual-payments.vue';

export default [{
	path: '/payments',
	name: 'awaiting_payments',
	component: Component,
	meta: {
		title: i18n.t('routes.titles.payments')
	},

	children: [{
		path: 'awaiting-payments',
		component: AwaitingPayments,
		meta: {
			title: i18n.t('routes.titles.payments')
		}
	}, {
		path: 'payments',
		component: Payments,
		meta: {
			title: i18n.t('routes.titles.all_payments')
		}
	}, {
		path: 'manual-payments',
		component: ManualPayments,
		meta: {
			title: i18n.t('routes.titles.manual_payments')
		}
	}]
}];
