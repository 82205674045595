import Vue from 'vue';
import Bus from 'vue-bus';
import Portal from 'portal-vue';
import App from '@/app';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import store from '@/plugins/vuex';
import router from '@/plugins/router';
import loaderComponent from '@/components/loader';
import dialogComponent from '@/components/dialog';
import uiTextField from '@/components/ui/text-field';
import uiFileInput from '@/components/ui/file-input';
import uiSelect from '@/components/ui/select';
import uiAutocomplete from '@/components/ui/autocomplete';
import uiTextarea from '@/components/ui/textarea';
import uiMovableDialog from '@/components/ui/movable-dialog';
import uiCheckbox from '@/components/ui/checkbox';
import uiDatepicker from '@/components/ui/datepicker';
import searchReference from '@/components/search/reference';
import supplierSearch from '@/components/search/supplier';
import subtypeSearch from '@/components/search/subtype';
import groupsSearch from '@/components/search/groups';



import config from '@/config';
import services from '@/services';
import utils from '@/utils';
import conf from '@/assets/config.json';
import validator from '@/plugins/validator';
import '@/plugins/tippy';

Vue.config.productionTip = false;

Vue.use(Portal);
Vue.use(Bus);

/* Add protoypes to Vue instance */
Vue.prototype.$color = config.colors;
Vue.prototype.$service = services;
Vue.prototype.$utils = utils;
Vue.prototype.$config = conf;
Vue.prototype.$validator = validator;

/* Globally register components */
Vue.component('Loader', loaderComponent);
Vue.component('Dialog', dialogComponent);
Vue.component('ui-text-field', uiTextField);
Vue.component('ui-file-input', uiFileInput);
Vue.component('ui-select', uiSelect);
Vue.component('ui-autocomplete', uiAutocomplete);
Vue.component('ui-textarea', uiTextarea);
Vue.component('ui-checkbox', uiCheckbox);
Vue.component('ui-movable-dialog', uiMovableDialog);
Vue.component('ui-datepicker', uiDatepicker);
Vue.component('search-reference', searchReference);
Vue.component('search-supplier', supplierSearch);
Vue.component('search-subtype', subtypeSearch);
Vue.component('search-groups', groupsSearch);




new Vue({
	vuetify,
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app');
