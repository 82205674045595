<template>
	<v-row no-gutters>
		<v-col
			cols="12"
		>
			<v-card v-if="isUserAllowedToLogView" class="rounded-xl pa-8">
				<RegisterList
					ref="registerList"
					:items="getLogs"
					:headers="headers"
					:pagination="pagination"
					item-key="_id"
					@active-item-changed="setActiveItem"
					@pagination-update="updatePagination"
					@on-filter-update="onFilterUpdate"
				/>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import RegisterList from '@/components/register-list';
import { mapGetters } from 'vuex';
export default {
	name: 'DashboardView',

	components: {
		RegisterList
	},

	data: () => ({
		isPending: false,
		activeLog: null,
		logs: [],

		filter: {},
		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: [],
			sortDesc: []
		},

		headers: [{
			text: 'Nivå',
			value: 'level',
			filter: {
				type: 'select',
				items: [{
					text: 'Debug',
					value: 1
				}, {
					text: 'Fel',
					value: 4
				}, {
					text: 'Info',
					value: 2
				}, {
					text: 'Trace',
					value: 0
				}, {
					text: 'Varning',
					value: 3
				}]
			}
		},{
			text: 'Område',
			value: 'area',
			filter: {
				type: 'select',
				items: [{
					text: 'Auth',
					value: 'auth'
				}, {
					text: 'Användare',
					value: 'user'
				},  {
					text: 'Fakturor',
					value: 'invoice'
				}, {
					text: 'Grupper',
					value: 'groups'
				},{
					text: 'Roller',
					value: 'roles'
				}]
			}
		},{
			text: 'Tagg',
			value: 'tag',
			filter: {
				type: 'text'
			}
		},{
			text: 'User',
			value: '_user',
			filter: {
				type: 'users',
				transform: val => val !== null ? [val] : null
			}
		},{
			text: 'Meddelande',
			value: 'message',
			filter: {
				type: 'text'
			}
		}],

		levelTypes: {
			0: 'Trace',
			1: 'Debug',
			2: 'Info',
			3: 'Varning',
			4: 'Fel'
		}
	}),

	computed: {
		...mapGetters('users', ['getUsers']),
		...mapGetters('userStore', ['isUserAllowedToLogView']),

		getLogs() {
			return this.logs.map(x => {
				const eUser = this.getUsers.find(u => u.id === x.eid);
				const uUser = this.getUsers.find(u => u.id === x.uid);
				let _user = '';

				if (eUser && x.uid === x.eid) {
					_user = eUser._userName;
				}

				if (eUser && uUser && x.uid !== x.eid) {
					_user = `${eUser._userName} - via ${uUser._userName}`;
				}

				return {
					...x,
					level: this.levelTypes[x.level],
					_user
				};
			});
		}
	},

	methods: {
		setActiveItem(item) {
			this.activeLog = item;
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchLogs();
		},

		onFilterUpdate(filter) {
			this.filter = filter;

			if(this.filter._user) {
				this.filter.eid = this.filter._user[0];
				this.filter.uid = this.filter._user[0];

				delete this.filter._user;
			}

			this.fetchLogs();
		},

		async fetchLogs() {
			const data = await this.$service.logs.get({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			}, {
				loading: val => {
					this.isPending = val;
				}
			});

			this.pagination.total = data._paginationTotal;

			this.logs = data;
		}
	}
};
</script>
