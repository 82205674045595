import userStore from './user';
import sitesStore from './sites';
import capabilitiesStore from './capabilities';
import accounting from './accounting';
import users from './users';
import globalAttest from './global-attest';

export default {
	accounting,
	userStore,
	sitesStore,
	capabilitiesStore,
	users,
	globalAttest
};
