import i18n from '@/plugins/i18n';
import apiClient, { kpApiV1 } from '@/plugins/axios';
import { compose, getFields } from '@/utils/service';
import { validate } from '@/utils/validation';

const fields = {
	name: {
		rules: input => validate(input).required().maxLenght(30).test(),
		label: i18n.t('base.name'),
		counter: 30
	},

	from: {
		rules: input => validate(input).required().test(),
		label: 'Startdatum'
	},

	to: {
		rules: input => validate(input).required().test(),
		label: 'Slutdatum'
	},

	prefix: {
		rules: input => validate(input).required().test(),
		label: 'Prefix'
	},

	start: {
		rules: input => validate(input).required().test(),
		label: 'Startnummer'
	},

	length: {
		rules: input => validate(input).required().test(),
		label: 'Längd'
	}
};

export default {
	get: compose(kpApiV1.get, ['groups', 'groups/<id>']),

	list: (opts = {}) => apiClient.get('/wp-json/kp/v1/groups', opts),

	add: compose(kpApiV1.post, 'groups', {
		fields: getFields(fields, ['name'])
	}),

	delete: (groupId, opts = {}) => apiClient.delete(`/wp-json/kp/v1/groups/${groupId}`, opts),

	addUser: (groupId, userId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/groups/${groupId}/users/${userId}`, body, opts),

	deleteUser: (groupId, userId, opts = {}) => apiClient.delete(`/wp-json/kp/v1/groups/${groupId}/users/${userId}`, opts),

	addAction: (groupId, eventId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/groups/${groupId}/actions/${eventId}`, body, opts),

	updateAction: (groupId, eventId, actionId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/groups/${groupId}/actions/${eventId}/${actionId}`, body, opts),

	reorderActions: (groupId, eventId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/groups/${groupId}/actions/${eventId}/reorder`, body, opts),

	removeAction: (groupId, eventId, actionId, opts = {}) => apiClient.delete(`/wp-json/kp/v1/groups/${groupId}/actions/${eventId}/${actionId}`, opts),

	addParent: (groupId, parentId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/groups/${groupId}/parent/${parentId}`, body, opts),

	deleteParent: (groupId, body, opts = {}) => apiClient.delete(`/wp-json/kp/v1/groups/${groupId}/parent`, body, opts),


	getVerificationCodes: (groupId, opts = {}) => apiClient.get(`/wp-json/kp/v1/groups/${groupId}/verifications`, opts),

	deleteVerificationCode: (groupId, verificationId, opts = {}) => apiClient.delete(`/wp-json/kp/v1/groups/${groupId}/verifications/${verificationId}`, opts),

	addVerification: (id, body, opts) => apiClient.post(`/wp-json/kp/v1/groups/${id}/verifications`, body, opts),

	search: (body, opts) => apiClient.post('/wp-json/kp/v1/groups/search', body, opts),

	getCodes: (groupId, opts = {}) => apiClient.get(`/wp-json/kp/v1/groups/${groupId}/codes`, opts),

	updateCodes: (groupId, body, opts) => apiClient.put(`/wp-json/kp/v1/groups/${groupId}/codes`, body, opts)

};
