<template>
	<div>
		<div class="text-caption mb-6">
			Nuvarande värde: <strong>{{ $utils.dotProp(localInvoice, 'Amounts.TotalTax.Amount', '') }}</strong>
		</div>
		<div class="d-flex align-center justify-space-between">
			<ui-text-field v-model.number="newAmount" label="Momstotal" />
		</div>
		<div v-if="newAmount">
			<p class="text-caption">Vill du uppdatera beloppet till  <strong class="primary--text">{{ newAmount }}</strong>?</p>
			<v-btn color="success" text small @click="updateInvoice">Ja, uppdatera</v-btn>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		newAmount: null,
		localInvoice: null
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
					this.newAmount = null;
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);
	},

	methods: {
		updateInvoice() {
			this.localInvoice.Amounts.TotalTax.Amount = parseFloat(this.newAmount).toFixed(2);
			this.newAmount = null;

			this.$emit('on-invoice-update', this.localInvoice);
		}
	}
};
</script>
