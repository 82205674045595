import * as rules from '../rules';

export default {
	required: () => ([
		rules.required()
	]),

	amount: () => ([
		rules.isDecimal()
		// rules.required()
	])
};
