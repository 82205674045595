<template>
	<div>
		<v-row>
			<v-col cols="4">
				<accounting-card :groups="groups" :settings="arrival_accounting" label="Ankomstbokföring" />
				<accounting-card :groups="groups" :settings="payments" label="Återredovisning" class="mt-5" />
			</v-col>
			<v-col cols="4">
				<accounting-card :groups="groups" :settings="definitive_accounting" label="Definitiv Bokföring" />
			</v-col>
			<v-col cols="4">
				<settings-card :groups="groups" :settings="types" label="Ursprungstyper" no-group />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import accountingCard from './components/accounting-card';
import settingsCard from '../system/components/settings-card';

export default {
	components: {
		accountingCard,
		settingsCard
	},


	data: () => ({
		groups: [],


		arrival_accounting: [{
			key: 'kiPayableArrival',
			label: 'Leverantörsskuld',
			value: [],
			group: 0
		}, {
			key: 'kiVATArrival',
			label: 'Moms',
			value: [],
			group: 0
		}, {
			key: 'kiExpenseArrival',
			label: 'Preliminär kostnad',
			value: [],
			group: 0
		}],

		definitive_accounting: [{
			key: 'kiPayableFinal',
			label: 'Leverantörsskuld',
			value: [],
			group: 0
		}, {
			key: 'kiVATFinal',
			label: 'Moms',
			value: [],
			group: 0
		}, {
			key: 'kiInterimClaimFinal',
			label: 'Interimfordran',
			no: true,
			value: [],
			group: 0
		}, {
			key: 'kiInterimDebtFinal',
			label: 'Interimskuld',
			no: true,
			value: [],
			group: 0
		}],

		payments: [{
			key: 'kiOutboundPayment',
			label: 'Levarantörsskuld',
			no: true,
			value: [],
			group: 0
		}, {
			key: 'kiInboundPayment',
			label: 'Motbokning',
			no: true,
			value: [],
			group: 0
		}],

		types: [{
			key: 'kiUTP',
			label: 'Leverantörsfakturor',
			value: 0,
			group: 0,
			type: {
				value: 'number',
				from: 0,
				to: 999
			}
		}, {
			key: 'kiUTPManual',
			label: 'Manuella fakturor',
			value: 0,
			group: 0,
			type: {
				value: 'number',
				from: 0,
				to: 999
			}
		}, {
			key: 'kiUTPManualPayment',
			label: 'Manuella utbetalningar',
			value: 0,
			group: 0,
			type: {
				value: 'number',
				from: 0,
				to: 999
			}
		}, {
			key: 'kiBTP0',
			label: 'Bearbetningstyp ankomstbokföring',
			value: 0,
			group: 0,
			type: {
				value: 'number',
				from: 0,
				to: 999
			}
		}, {
			key: 'kiBTP1',
			label: 'Bearbetningstyp definitiv bokföring',
			value: 0,
			group: 0,
			type: {
				value: 'number',
				from: 0,
				to: 999
			}
		}, {
			key: 'kiUTPImport',
			label: 'Importerande system',
			value: [],
			items: [],
			group: 0,
			type: {
				value: 'list'
			}
		}]
	}),

	async created() {
		await this.fetchGroups();

	},

	methods: {
		async fetchGroups() {
			const data = await this.$service.groups.list();

			this.groups = [{_id: 0, name: ''}, ...data];
		}

	}
};
</script>
