<template>
	<div>
		<portal to="sidebar-right-title">
			{{ localInvoice.ID }}
			<Loader :error="errorMessage" :loading="isPending" slim />
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-tooltip fab text
					small
					content="Konversation"
					@click.stop="showConvoDialog = true"
				>
					<v-icon>mdi-chat-outline</v-icon>
				</v-btn>

				<v-btn v-tooltip fab text
					small
					content="Bilagor"
					@click.stop="openPreview(localInvoice.meta.attachments[0].type || '' )"
				>
					<v-icon>mdi-paperclip</v-icon>
				</v-btn>

				<v-btn v-tooltip class="ml-2" fab text
					small
					content="Historik"
					@click.stop="openPreview('history')"
				>
					<v-icon>mdi-history</v-icon>
				</v-btn>

				<div v-if="localInvoice.meta">
					<v-btn v-tooltip class="ml-2" fab text
						small
						:content="`Fakturans sekretessnivå: ${sensitivityLevels[localInvoice.meta.sensitivity].text}`"
						:color="localInvoice.meta.sensitivity > 0 ? 'error' : 'background'"
						@click="showChangeSensitivityDialog = true"
					>
						<v-icon v-if="localInvoice.meta.sensitivity > 0">mdi-lock-outline</v-icon>
						<v-icon v-else>mdi-lock-open-outline</v-icon>
					</v-btn>
				</div>

				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="ml-2" fab text small v-bind="attrs"
							v-on="on"
						>
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item v-if="localInvoice.meta && localInvoice.meta.supplier_id" @click="$router.push({path: '/register/suppliers', query: {
							supplierId: localInvoice.meta.supplier_id,
							invoiceId: localInvoice._id
						}})"
						>
							<v-list-item-title>
								<v-icon left>mdi-truck-delivery</v-icon> Gå till Leverantör
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-else @click="$router.push({ path: '/register/suppliers', query: {
							supplierId: 'new',
							invoiceId: localInvoice._id
						}})"
						>
							<v-list-item-title>
								<v-icon left>mdi-truck-delivery</v-icon> Skapa Leverantör
							</v-list-item-title>
						</v-list-item>

						<v-list-item v-if="localInvoice.meta" @click="showReviewDialog = true">
							<v-list-item-title>
								<v-icon :color="localInvoice.meta.review ? 'error' : ''" left>mdi-file-find-outline</v-icon> {{ localInvoice.meta.review ? 'Utredd' : 'Sätt som utredning' }}
							</v-list-item-title>
						</v-list-item>
						<v-list-item :disabled="localInvoice.meta && localInvoice.meta.deleted" @click="showCancelInvoiceDialog = true">
							<v-list-item-title>
								<v-icon left>mdi-cancel</v-icon> Makulera Faktura
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</portal>

		<v-expansion-panels v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Fakturauppgifter
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<InvoiceInfo v-if="localInvoice.ID" :invoice="localInvoice" />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Betalning
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<payment v-if="localInvoice.ID" :invoice="localInvoice" @on-update="paymentUpdate" />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<invoice-checklist v-if="localInvoice.ID" :invoice="localInvoice" @checklist-updated="fetchInvoice" />
		</v-expansion-panels>

		<invoice-accounting v-if="localInvoice.ID" class="mt-4" :disabled="localInvoice.meta.deleted"
			:amount-left="amountLeft"
			:supplier="supplier"
			:show-kontering="false"
			:invoice="localInvoice"
			@accounting:updated="fetchInvoice"
		/>

		<invoice-preview v-if="previewOpen" :key="activeType" width="40%" :default-view="activeType" :invoice="localInvoice"
			@on-close="closePreview"
		/>

		<convoDialog v-if="showConvoDialog"
			width="35%"
			:mail="mail"
			:invoice="localInvoice"
			@close="showConvoDialog = false"
			@updated="setUpdatedConvo"
			@leave-chat="showConvoDialog = false"
		/>

		<Dialog v-if="showChangeSensitivityDialog" title="Ändra Sekretessnivå"
			:loading="isPending"
			@reject="showChangeSensitivityDialog = false"
			@confirm="onStatusUpdate"
		>
			<ui-select v-model="localInvoice.meta.sensitivity" label="Nivå" :items="sensitivityLevels" />
			<Loader :error="errorMessage" slim />
		</Dialog>

		<Dialog v-if="showReviewDialog" :title="`Sätt som ${localInvoice.meta.review ? 'utredd': 'under utredning'}`"
			@reject="showReviewDialog = false"
			@confirm="setReviewStatus"
		>
			<div class="py-6 mb-6">
				{{ `Vill du sätta ${localInvoice.meta.bookings.arrival[0].meta.invoice_name} som ${localInvoice.meta.review ? 'utredd': 'under utredning'}?` }}
			</div>
			<Loader :error="errorMessage" :loading="isPendingReview" slim />
		</Dialog>

		<Dialog v-if="showCancelInvoiceDialog" title="Makulera Faktura"
			:loading="isPending"
			width="400px"
			ok-btn-text="Makulera"
			@reject="showCancelInvoiceDialog = false"
			@confirm="confirmCancel"
		>
			<div class="d-flex flex-column align-center">
				<ui-text-field class="pb-4" :value="localInvoice.meta.date.deleted || new Date().toISOString().substr(0, 10)" readonly label="Makuleringsdatum" />
				<v-date-picker v-model="cancleDate" type="date" no-title scrollable />
			</div>
			<Loader v-if="errorMessage" :error="[errorMessage]" slim />
		</Dialog>
	</div>
</template>

<script>
import InvoiceInfo from '@/views/invoices/children/invoices/components/info.vue';
import InvoiceChecklist from '@/views/invoices/children/invoices/components/checklist';
import {run} from '@/utils/service';
import invoicePreview from '@/components/invoice-preview';
import convoDialog from '@/views/inbox/children/conversations/components/chat';
import InvoiceAccounting from '@/views/invoices/children/invoices/components/accounting';
import Payment from './payment';

export default {
	name: 'InvoiceSidebar',

	components: {
		InvoiceInfo,
		InvoiceChecklist,
		invoicePreview,
		convoDialog,
		InvoiceAccounting,
		Payment
	},

	props: {
		invoice: {
			type: Object,
			default: () => ({})
		},

		invoiceId: {
			type: String,
			default: null
		}
	},

	data: () => ({
		panel: [0, 1],
		isPending: false,
		attachmentFile: null,

		cancleDate: null,

		mail: {},
		supplier: {},
		localInvoice: {
			ID: '',
			Amounts: {
				TaxExclusiveTotal: {
					Currency: ''
				}
			}
		},

		showChangeSensitivityDialog: false,

		sensitivityLevels: [
			{ text: 'Ingen', value: 0 },
			{ text: 'Utskrift', value: 1},
			{ text: 'Visning', value: 2}
		],

		showCancelInvoiceDialog: false,
		showReviewDialog: false,
		showConvoDialog: false,
		previewOpen: false,
		activeType: '',

		errorMessage: '',
		isPendingReview: false
	}),

	computed: {
		amountLeft() {
			if (!this.localInvoice.ID) {
				return 0;
			}

			const accounted = this.localInvoice.meta.accounting.reduce((acc, curr) => {
				acc = acc + (Number(curr.amount) * 100);

				return acc;
			}, 0);
			const value = ((this.localInvoice.meta.amount.posting_total * 100) - accounted) / 100;

			return Number(value).toFixed(2);
		}
	},

	watch: {
		async 'invoice._id'() {
			await this.fetchInvoice();
			await this.fetchSupplier(this.localInvoice.meta.supplier_id);
		},

		async invoiceId() {
			await this.fetchInvoice();
			await this.fetchSupplier(this.localInvoice.meta.supplier_id);
		}
	},

	async created() {
		await this.fetchInvoice();
		await this.fetchSupplier(this.localInvoice.meta.supplier_id);
	},

	methods: {
		paymentUpdate() {
			this.fetchInvoice();
			this.$emit('close-fetch');
		},

		setUpdatedConvo(item) {
			this.mail = {_id: item._id};
		},

		openPreview(type) {
			this.activeType = type;
			this.previewOpen = true;
		},

		closePreview() {
			this.previewOpen = false;
		},

		async completeAccounting() {
			run(await this.$service.invoices.accountingComplete.call({
				id: this.localInvoice._id
			}));
		},

		async confirmCancel() {
			this.errorMessage = '';
			const date = this.cancelDate.replaceAll('-', '');

			try {
				await this.$service.invoices.delete(this.invoice._id, date, {
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.fetchInvoice();
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async onStatusUpdate() {
			this.errorMessage = '';
			try {
				await this.$service.invoices.setSensitivity(this.invoice._id, this.formData.level, {
					throwException: true,
					loading: val => {
						this.updateIsPending = val;
					}
				});

				this.fetchInvoice();
				this.$emit('on-refetch');
			} catch (error) {
				this.errorMessage = error;
			}
		},

		async fetchInvoice() {
			this.errorMessage = '';

			try {
				const data = await this.$service.invoices.get(this.invoiceId || this.invoice._id, {
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.localInvoice = {
					...data,
					_underPayment: ['unpaid', 'blocked'].includes(data.meta.payment_status)
				};

				if(this.localInvoice.meta.mail.length > 0) {
					this.mail = {_id: this.localInvoice.meta.mail[0]};
				}

			} catch(error) {
				this.errorMessage = error.message;
			}

		},

		async setReviewStatus() {
			this.errorMessage= '';
			this.isPendingReview = true;
			try {
				this.localInvoice.meta.review ?
					await this.$service.invoices.unreview({
						invoiceIds: [
							this.localInvoice._id
						]
					}) :
					await this.$service.invoices.review({
						invoiceIds: [
							this.localInvoice._id
						]
					});
				this.showReviewDialog = false;
			} catch(error) {
				this.errorMessage = error.message;
			}

			await this.fetchInvoice();
			this.isPendingReview = false;
		},

		async fetchSupplier(id) {
			if (id !== null) {
				this.supplier = await this.$service.suppliers.get(id);

				return;
			}

			this.supplier = {};
		}
	}
};
</script>
