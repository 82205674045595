import * as rules from '../rules';

export default {
	orgNumber: () => ([
		rules.required()
	]),

	name: () => ([
		rules.required()
	]),

	giroNumber: () => ([
		rules.required()
	]),

	BGNumber: () => ([
		rules.required(),
		rules.minLenght(7),
		rules.maxLenght(9)
	]),

	bicNumber: () => ([
		rules.required()
	]),

	dueDate: () => ([
		rules.required(),
		rules.isInteger()
	]),

	isNumber: () => ([
		rules.isInteger()
	])
};
