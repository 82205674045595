<template>
	<Dialog v-model="open" title="Ny Grupp" max-width="640" width="640" :retain-focus="false"
		@confirm="confirm"
		@reject="reject"
	>
		<v-container>
			<v-card-text>
				<p v-if="parent" class="text-caption">
					Förälder: <strong>{{ parent.name }}</strong>
				</p>

				<v-form ref="form" v-model="formIsValid" :disabled="$service.groups.add.isPending">
					<ui-text-field ref="input" v-model="formData.name" :label="$service.groups.add.fields.name.label" :rules="[$service.groups.add.fields.name.rules]" />
				</v-form>

				<Loader :service="[$service.groups.add]" />
			</v-card-text>
		</v-container>
	</Dialog>
</template>

<script>
import {run} from '@/utils/service';

export default {
	props: {
		parent: {
			type: [Object, null],
			default: null
		}
	},

	data: () => ({
		open: true,

		formIsValid: false,
		formData: {
			name: ''
		}
	}),

	created() {
		this.$nextTick(() => {
			this.$refs.input.$el.querySelector('input').focus();
		});
	},

	methods: {
		reject() {
			this.$emit('close');
		},

		async confirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			run(await this.$service.groups.add.call({
				body: {
					...this.formData,
					...(this.parent ? {parent: this.parent.id} : {})
				}
			}), data => {
				this.$emit('close');
				this.$emit('group-created', data);
			});
		}
	}
};
</script>
