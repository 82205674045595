<template>
	<v-form ref="form" class="pa-0">
		<v-row>
			<v-col cols="12">
				<ui-autocomplete v-model="formData.bic" label="Välj betalmetod"
					:items="bics"
				/>
			</v-col>
			<v-col cols="6">
				<ui-text-field v-model="formData.bic" label="BIC-Kod / Clearingnr" />
			</v-col>
			<v-col cols="6">
				<ui-text-field v-model="formData.number" label="Nummer" :rules="$validator('supplier/giroNumber')" />
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col>
				<v-checkbox v-model="formData.active" label="Aktivt" />
			</v-col>
			<Loader :error="error" />
		</v-row>
	</v-form>
</template>

<script>
export default {
	props: {
		value: {
			type: Object,
			default: null
		},

		error: {
			type: String,
			default: null
		},

		localNumber: {
			type: String,
			default: ''
		}
	},

	data: () => ({
		bics: [
			{text: 'Bankgiro', value: 'BGABSESS'},
			{text: 'Plusgiro', value: 'PGSISESS'},
			{text: 'Bankkonto', value: ''}
		],

		formData: {
			id: null,
			number: '',
			bic: 'BGABSESS',
			active: true
		}
	}),

	watch: {
		formData: {
			deep: true,
			handler(to) {
				this.$emit('input', { ...to });
			}
		}
	},

	created() {
		this.formData = {
			...this.formData,
			...this.value
		};

		this.formData.number = this.localNumber;

		this.$emit('input', { ...this.formData });
	},

	methods: {
		validate() {
			return this.$refs.form.validate();
		}
	}
};
</script>
