<template>
	<v-expansion-panel>
		<v-expansion-panel-header class="text-button d-flex align-center" style="line-height: 100%;">
			<span>Checklista</span>
			<v-spacer />
			<div class="d-flex justify-end pr-8">
				<v-icon v-if="hasReds" color="red">mdi-alert-box</v-icon>
				<v-icon v-if="hasYellows" color="orange">mdi-alert-box</v-icon>
			</div>
		</v-expansion-panel-header>

		<v-expansion-panel-content>
			<v-row>
				<v-col cols="6">
					<p>
						Här är en checklista över det som en faktura ska
						innehålla för att uppfylla kraven som ställs för attestering/kontering.
					</p>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col v-for="check in getChecklist" :key="check.key" cols="6">
					<v-btn icon :disable="isPending" @click="updateCheck(check.key, check.value)">
						<v-icon v-if="check.value === 0" color="grey">mdi-checkbox-blank-outline</v-icon>
						<v-icon v-if="check.value === 1" color="red">mdi-close-box</v-icon>
						<v-icon v-if="check.value === 2" color="orange">mdi-help-box</v-icon>
						<v-icon v-if="check.value === 3" color="green">mdi-checkbox-marked</v-icon>
					</v-btn>

					<v-tooltip v-if="check.key === 'line_info'" bottom z-index="99">
						<template v-slot:activator="{ on }">
							<v-icon class="pl-1" small v-on="on">mdi-information</v-icon>
							<span class="text-body-2">{{ $t(`invoice.checklist.${check.key}`) }}</span>
						</template>
						<span>Vid beställning av <strong>varor</strong> ska typ av vara + eventuell varubeteckning, antal och á-pris finnas med.<br>Vid beställning av <strong>tjänster</strong> ska typ av tjänst vara angivet samt antal timmar och timpris eller fast pris + <br>eventuella utlägg. För kontroll; bifoga en tidsredovisning till fakturan.</span>
					</v-tooltip>

					<v-tooltip v-else-if="check.key === 'reference'" bottom z-index="99">
						<template v-slot:activator="{ on }">
							<v-icon class="pl-1" small v-on="on">mdi-information</v-icon>
							<span class="text-body-2">{{ $t(`invoice.checklist.${check.key}`) }}</span>
						</template>
						<span>Om referenskoden är felaktig eller felaktig placerad - kontakta leverantör för rättelse.</span>
					</v-tooltip>

					<v-tooltip v-else-if="check.key === 'vat_subtaxes'" bottom z-index="99">
						<template v-slot:activator="{ on }">
							<v-icon class="pl-1" small v-on="on">mdi-information</v-icon>
							<span class="text-body-2">{{ $t(`invoice.checklist.${check.key}`) }}</span>
						</template>
						<span>Beskattningsunderlaget är det belopp som momsen beräknas på.</span>
					</v-tooltip>

					<v-tooltip v-else-if="check.key === 'vat_total'" bottom z-index="99">
						<template v-slot:activator="{ on }">
							<v-icon class="pl-1" small v-on="on">mdi-information</v-icon>
							<span class="text-body-2">{{ $t(`invoice.checklist.${check.key}`) }}</span>
						</template>
						<span>Momsbelopp är korrekt uträknat utifrån tillämpad momssats och beskattningsunderlag</span>
					</v-tooltip>

					<template v-if="!['line_info', 'reference', 'vat_subtaxes', 'vat_total'].includes(check.key)">
						<span class="text-body-2">{{ $t(`invoice.checklist.${check.key}`) }}</span>
					</template>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<p class="d-flex justify-end text-caption">
						*de som går att verifiera med korrekta uppgifter markeras med (*)
					</p>
				</v-col>
			</v-row>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import {run} from '@/utils/service';
/*
0: ej kontrollerad (Tom checkruta) mdi-checkbox-blank-outline
1: kontrollerad felaktig (Röd bock) mdi-close-box
2: kontrollerad okänt om OK eller ej (Gult frågetecken) mdi-help-box
3: kontrollerad OK (Grön bock) mdi-checkbox-marked
*/

export default {
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		isPending: false
	}),

	computed: {
		getChecklist() {
			return Object.entries(this.invoice.meta.checklist).map(([key, value]) => {
				return {key, value};
			});
		},

		hasReds() {
			return Object.values(this.invoice.meta.checklist).some(value => value === 1);
		},

		hasYellows() {
			return Object.values(this.invoice.meta.checklist).some(value => value === 2);
		}
	},

	methods: {
		async updateCheck(key, value) {
			this.isPending = true;
			let newValue;

			switch(value) {
			case 0: newValue = 1; break;
			case 1: newValue = 2; break;
			case 2: newValue = 3; break;
			case 3: newValue = 0; break;
			}

			run(await this.$service.invoices.check.call({
				id: this.invoice._id,
				key: key,
				value: newValue
			}), () => {
				this.isPending = false;

				this.$emit('checklist-updated');
			});
		}
	}
};
</script>
