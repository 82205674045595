<template>
	<div>
		<portal to="sidebar-right-title">
			<v-avatar v-if="localUser.avatar" size="48">
				<img :src="localUser.avatar">
			</v-avatar>
			<v-icon v-else size="48">
				mdi-account-circle
			</v-icon>
			<span class="ml-4" style="position: relative; top: 2px;">{{ localUser.name }} ({{ localUser.login }})</span>
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn color="accent" class="d-flex align-center" :disabled="checkDisabled || isLoading" @click="openFinishConfirmDialog">
					Avsluta Användare
				</v-btn>
			</div>
		</portal>

		<Loader :error="errorMessage" />

		<v-container class="d-flex justify-end" />

		<v-expansion-panels v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Referenser
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-data-table
						:loading="isReferencesPending"
						:headers="referenceHeaders"
						:items="references"
						:items-per-page="-1"
						hide-default-footer
						disable-sort
						@click:row="referenceClick"
					/>

					<ReferenceNewUser v-if="activeReference" :id="localUser.id" :reference="activeReference" @close="activeReference = null" @created="fetchReferences" />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Abonnemang
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-data-table
						:loading="isSubscriptionsPending"
						:headers="subscriptionHeaders"
						:items="subscriptions"
						:items-per-page="-1"
						hide-default-footer
						disable-sort
						@click:row="subscriptionClick"
					/>
					<SubscriptionNewUser v-if="activeSubscription"
						:subscription-id="activeSubscription._id"
						:rows="activeSubscription.accounting"
						:sub-attestants="activeSubscription.attestants"
						:user-id="localUser.id"
						@on-close="activeSubscription = null"
						@on-submit="
							activeSubscription = null;
							fetchSubscriptions();
						"
					/>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Todos
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<RegisterListTodo ref="registerList"
						:items="todos"
						:loading="isTodosPending"
						:headers="todoHeaders"
						sort-by="value"
						item-key="_id"
						:show-sidebar="false"
						:items-per-page="-1"
						:hide-footer="true"
						:only-forward="true"
						@mass-action="doMassAction"
					/>
					<Dialog v-if="forwardDialog" title="Välj användare" ok-btn-text="Välj" :disable-ok-btn="!selectedForwardUser"
						:loading="isForwardPending" @reject="forwardDialog = false" @confirm="forwardTodos"
					>
						<ui-autocomplete v-model="selectedForwardUser" :items="getUsers" label="Användare" item-text="_userName" item-value="id" />
						<Loader :error="forwardError" />
					</Dialog>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import RegisterListTodo from '@/components/register-list-todo';
import { mapGetters } from 'vuex';
import ReferenceNewUser from './reference-new-user';
import SubscriptionNewUser from './subscription-new-user';
export default {
	components: {
		ReferenceNewUser,
		SubscriptionNewUser,
		RegisterListTodo
	},

	props: {
		userToEdit: {
			type: Object,
			default: undefined,
			required: true
		}
	},

	data: () => ({
		errorMessage: '',
		forwardError: '',

		panel: [0],

		localUser: null,
		activeReference: null,
		activeSubscription: null,
		selectedForwardUser: null,

		references: [],
		subscriptions: [],
		types: [],
		todos: [],
		groups: [],
		referenceHeaders: [{
			text: 'Referenskod',
			value: 'reference',
			align: 'start'
		}, {
			text: 'Grupp',
			value: '_groupId'
		}, {
			text: 'Användare',
			value: '_userIds'
		}, {
			text: 'Aktiv',
			value: '_active'
		}],

		subscriptionHeaders: [{
			align: 'start',
			text: 'Typ',
			value: 'type'
		},
		{
			text: 'Id',
			value: 'ID'
		}, {
			text: 'Namn',
			value: 'name'
		}, {
			text: 'Kommentar',
			value: 'note'
		}, {
			text: 'Skapad',
			value: 'creation_date'
		}],
		todoHeaders: [{
			text: 'Typ',
			value: 'todo_type'
		}, {
			text: 'Dokumentnr',
			value: 'meta.document_no'
		}, {
			text: 'Leverantör',
			value: 'meta.supplier'
		}, {
			text: 'Belopp inkl. moms',
			value: 'meta.amount',
			align: 'right'
		}, {
			text: 'Avsändare',
			value: 'from'
		}, {
			text: 'Hantera senast',
			value: 'meta.date'
		}, {
			text: 'Status',
			value: 'todo'
		}, {
			text: 'Utredning',
			value: 'meta.investigation'
		}, {
			text: '',
			value: 'misc'
		}],

		filter: {},

		isReferencesPending: false,
		isSubscriptionsPending: false,
		isTodosPending: false,
		isForwardPending: false,
		showChooseAttestant: false,
		forwardDialog: false,
		isLoading: true
	}),

	computed: {
		...mapGetters('users', ['getUsers']),

		checkDisabled() {
			if(this.references.length === 0 && this.subscriptions.length === 0 && this.todos.length === 0) {
				return false;
			}

			return true;
		}
	},

	watch: {

	},

	async created() {
		this.localUser = this.userToEdit;
		await this.fetchGroups();
		await this.fetchReferences();
		await this.fetchTypes();
		await this.fetchSubscriptions();
		await this.fetchTodos();
		this.isLoading = false;
	},

	methods: {

		referenceClick(item) {
			this.activeReference = item;
		},

		subscriptionClick(item) {
			this.activeSubscription = item;
		},

		doMassAction(data) {
			if (data.type === 'forward') {
				this.forwardDialog = true;
				this.forwardData = {...data};
			}
		},

		openFinishConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Avsluta användare',
				text: `Är du säker på att du vill avsluta användare ${this.localUser.name} (${this.localUser.login})?`,
				confirmed: this.finishUser
			});
		},

		async finishUser() {
			this.errorMessage = '';
			try {
				await this.$service.users.update(this.localUser.id, {
					closed: true
				});

				this.$emit('end-user');
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async forwardTodos() {
			this.forwardError = '';
			const id = this.selectedForwardUser;
			const rawBody = this.forwardData.items.map(x => x._id);

			try {
				await this.$service.todo.forward({
					uid: id,
					todos: [...rawBody]
				},{
					throwException: true,
					loading: val => {
						this.isForwardPending = val;
					}
				});
				this.fetchTodos();
				this.selectedForwardUser = null;
				this.forwardData = null;
				this.forwardDialog = false;
			} catch (error) {
				this.forwardError = error.message;
			}
		},

		async fetchGroups() {
			this.groups = await this.$service.groups.list({});
		},

		async fetchReferences() {
			const data = await this.$service.references.search({
				pageSize: 9999,
				filter: {
					userIds: this.localUser.id
				}
			}, {
				loading: val => {
					this.isReferencesPending = val;
				}
			});

			this.references = data.map(x => {
				const group = this.groups.find(y => y._id === x.groupId);

				return {
					...x,
					id: x._id,
					_groupId: group ? group.name : '',
					_userIds: x.userIds ? (x.userIds.length > 1 ? this.getUsers.find(u => u.id === this.localUser.id)._userName + ` + ${x.userIds.length - 1} till` : this.getUsers.find(u => u.id === x.userIds[0])._userName) : '',
					_active: x.active ? 'Ja' : `Nej ${x.inactivation_date ? `(${this.$utils.date.intToPretty(x.inactivation_date)})`: ''}`
				};
			});
		},

		async fetchTypes() {
			this.types = await this.$service.subscriptions.searchTypes({
				pageSize: 9999
			});
		},

		async fetchSubscriptions() {
			const data = await this.$service.subscriptions.search({
				pageSize: 9999,
				filter: {
					'$or' : [
						{'attestants.approvals':          { '$elemMatch': { 'id': this.localUser.id }}},
						{'attestants.authorizations':     { '$elemMatch': { 'id': this.localUser.id }}},
						{'vat_attestants.approvals':      { '$elemMatch': { 'id': this.localUser.id }}},
						{'vat_attestants.authorizations': { '$elemMatch': { 'id': this.localUser.id }}}
					]
				}
			},{
				loading: val => {
					this.isSubscriptionsPending = val;
				}
			});

			this.subscriptions = data.map(d => {
				const type = this.types.find(x => x._id === d.type);

				return {
					...d,
					type: d.type && type ? type.name : d.type,
					creation_date: d.creation_date ? this.$utils.date.intToPretty(d.creation_date): ''
				};
			});
		},

		async fetchTodos() {
			const data = await this.$service.todo.search({
				pageSize: 9999,
				filter: {
					user: this.localUser.id
				}
			}, {
				loading: val => {
					this.isPending = val;
				},
				throwException: true
			});

			this.todos = data.map(d => ({
				...d,
				meta: {
					...d.meta,
					amount: this.$utils.format.price(d.meta.amount),
					not_ok: d.meta.formal ? d.meta.formal.not_ok : null,
					unknown: d.meta.formal ? d.meta.formal.unknown : null
				}
			}
			));
		},

		close() {
			this.$emit('close');
		}
	}

};
</script>

<style scoped>

</style>
