import i18n from '@/plugins/i18n';

export default [{
	path: '*',
	name: 'Not-found',
	meta: {
		title: i18n.t('routes.titles.not_found')
	},
	component: () => import(/* webpackChunkName: "not-found" */ './not-found.vue')
}];
