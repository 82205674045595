<template>
	<v-list dense nav>
		<div v-for="(categoryCaps, categoryName) in getSortedRoles" :key="`header-${categoryName}`">
			<v-list-item :disabled="!isUserAllowedToCapsList">
				<v-list-item-content>
					<v-list-item-title class="text-subtitle-1 pt-1">{{ categoryName }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item-group color="primary">
				<v-list-item v-for="cap in categoryCaps" :key="`item-${cap.key}`" :content="cap.tooltip" @click="setCapability(cap.key)">
					<v-list-item-icon>
						<v-icon :color="cap.color">{{ cap.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-text="cap.value" />
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</div>
	</v-list>
</template>



<script>
import {get as dotProp} from 'dot-prop';
import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'CapabilityList',

	props: {
		source: {
			type: Object,
			required: true
		},

		add: {
			type: Function,
			required: true
		},

		remove: {
			type: Function,
			required: true
		}
	},


	data() {
		return {
			isActive: true

		};
	},

	computed: {
		...mapGetters('userStore', ['isUserAllowedToCapsList']),
		...mapGetters('capabilitiesStore', ['getCaps']),

		modifiedCaps() {
			return this.getCaps.map(cap => {
				const status = this.getCapabilityStatus(cap.key);

				return {
					...cap,
					color: this.getIconColor(status),
					icon: this.getIcon(status),
					tooltip: this.getTooltip(status)
				};
			});
		},

		getSortedRoles() {

			const caps = this.modifiedCaps;
			const categories = {
				'Användare': [],
				'Fakturahantering': [],
				'Maskering': [],
				'Roller': [],
				'Grupper': [],
				'Leverantör': [],
				'Betalningar': [],
				'Abonnemang': [],
				'Administration': [],
				'Övrigt': []
			};

			for (let cap of caps) {
				if(cap.key.startsWith('kportal_user')) {
					categories['Användare'].push(cap);
				}

				if(cap.key.startsWith('kportal_invoice')) {
					categories['Fakturahantering'].push(cap);
				}

				if(cap.key.startsWith('kportal_masquerade')) {
					categories['Maskering'].push(cap);
				}

				if (cap.key.startsWith('kportal_role')) {
					categories['Roller'].push(cap);
				}

				if (cap.key.startsWith('kportal_group')) {
					categories['Grupper'].push(cap);
				}

				if (cap.key.startsWith('kportal_supplier') || cap.key.startsWith('kportal_sites')) {
					categories['Leverantör'].push(cap);
				}

				if (cap.key.startsWith('kportal_payment') || cap.key.startsWith('kportal_edit')) {
					categories['Betalningar'].push(cap);
				}

				if (cap.key.startsWith('kportal_subscription')) {
					categories['Abonnemang'].push(cap);
				}

				if (cap.key.startsWith('kportal_code') ||
						cap.key.startsWith('kportal_attachment') ||
						cap.key.startsWith('kportal_attestation') ||
						cap.key.startsWith('kportal_cap') ||
						cap.key.startsWith('kportal_log')) {
					categories['Administration'].push(cap);
				}

				if(cap.key.startsWith('kportal_settings') ||
						cap.key.startsWith('kportal_autodeactivate') ||
						cap.key.startsWith('kportal_reference')) {
					categories['Övrigt'].push(cap);
				}
			}

			return categories;
		}
	},

	created() {
		if (this.getCaps.length === 0) {
			this.fetchCaps();
		}

	},

	methods: {
		...mapActions('capabilitiesStore', ['fetchCaps']),

		setCapability(capId) {
			const currentValue = dotProp(this.source, `${capId}`, undefined);

			if (currentValue === undefined) {
				this.add(capId, true);
			} else if (currentValue === true) {
				this.add(capId, false);
			} else {
				this.remove(capId);
			}
		},

		getCapabilityStatus(capId) {
			const value = dotProp(this.source, `${capId}`, 'undefined');

			return value === 'undefined' ? value : (value === true ? 'granted' : 'denied');
		},

		getTooltip(status) {
			return this.$t(`register.users.roles_form.role_${status}_tooltip`);
		},

		getIconColor(status) {
			const colors = {
				granted: 'success',
				denied: 'error',
				undefined: ''
			};

			return colors[status];
		},

		getIcon(status) {
			const icons = {
				granted: 'mdi-checkbox-marked',
				denied: 'mdi-minus-box',
				undefined: 'mdi-checkbox-blank-outline'
			};

			return icons[status];
		}
	}
};
</script>

<style>
.disabled {
	pointer-events: none;
	color: grey;
}
</style>
