<template>
	<div>
		<Loader :error="errorMessage" :loading="isPending" slim />
		<v-row>
			<v-col cols="3" class="pb-0">
				<div class="text-caption">Fakturanr</div>
				<div class="text-subtitle-2">{{ invoice.ID }}</div>
			</v-col>

			<v-col cols="3" class="pb-0">
				<div class="text-caption">Fakturastatus</div>
				<div class="text-subtitle-2">{{ invoice.meta.status ? invoice.meta.status : 'Okänd' }}</div>
			</v-col>

			<v-col cols="3" class="pb-0">
				<div class="text-caption">Fakturatyp</div>
				<div class="text-subtitle-2">{{ invoice.meta.document_sub_type === 'credit' ? 'Kredit' : 'Debet' }}</div>
			</v-col>

			<v-col cols="3" class="pb-0">
				<div class="text-caption">Belopp exkl. moms</div>
				<div class="text-subtitle-2">{{ $utils.format.price(invoice.meta.amount.posting_total) }}</div>
			</v-col>

			<v-col cols="3" class="pb-0">
				<div class="text-caption">Moms</div>
				<div class="text-subtitle-2">{{ invoice.meta.vat && invoice.meta.vat.total ? $utils.format.price(invoice.meta.vat.total) : $utils.format.price(invoice.Amounts.TotalTax.Amount) }}</div>
			</v-col>

			<v-col cols="3" class="pb-0">
				<div class="text-caption">Belopp inkl. moms</div>
				<div class="text-subtitle-2">{{ $utils.format.price(invoice.Amounts.TaxInclusiveTotal.Amount) }}</div>
			</v-col>

			<v-col cols="3" class="pb-0">
				<div class="text-caption">Fakturadatum</div>
				<div class="text-subtitle-2">{{ invoice.Dates.IssueDate }}</div>
			</v-col>

			<v-col cols="3" class="pb-0">
				<div class="text-caption">Förfallodatum</div>
				<div class="text-subtitle-2">{{ invoice.Dates.DueDate }}</div>
			</v-col>

			<v-col v-if="invoice.meta.verification_no && invoice.meta.verification_no.arrival" cols="3" class="pb-0">
				<div class="text-caption">Verifikationsnr</div>
				<div class="text-subtitle-2">{{ invoice.meta.verification_no.arrival }}</div>
			</v-col>

			<v-col cols="3">
				<div class="text-caption">Leverantör</div>
				<div class="text-subtitle-2">{{ invoice.Parties.Seller.Name }}</div>
			</v-col>

			<v-col cols="3">
				<div class="text-caption">Organisationsnr</div>
				<div class="text-subtitle-2">{{ invoice.Entities.Seller.Legal.ID }}</div>
			</v-col>

			<v-col cols="3">
				<div class="text-caption">Konto/Gironr</div>
				<div class="d-flex align-center">
					<div class="text-subtitle-2">
						{{ $utils.format.account(invoice.meta.account) }}
					</div>
					<div v-if="isUserAllowedToAlterCreditorAccount" class="pl-4">
						<v-btn icon x-small @click="editAccountGiro = true"><v-icon>mdi-pencil</v-icon></v-btn>
					</div>
				</div>
			</v-col>

			<v-col cols="3">
				<div class="text-caption">Sekretessnivå</div>
				<div class="text-subtitle-2">{{ invoice.meta.sensitivity ? $t(`privacy_level.${privacyLevel[invoice.meta.sensitivity]}`) : 'Okänd' }}</div>
			</v-col>

			<v-col cols="3" :class="invoice.meta.review && 'error--text'">
				<div class="text-caption ">Under utredning</div>
				<div class="text-subtitle-2">{{ invoice.meta.review ? 'Ja' : 'Nej' }}</div>
			</v-col>

			<v-col cols="3">
				<div class="text-caption ">Referenskod</div>
				<div class="d-flex align-center">
					<div class="text-subtitle-2 ">{{ invoice.meta.reference ? invoice.meta.reference.reference : 'Okänd' }}</div>
					<div v-if="isUserAllowedToInvoiceAlterInvoiceReference" class="pl-4">
						<v-btn icon x-small @click="editReferenceDialog = true"><v-icon>mdi-pencil</v-icon></v-btn>
					</div>
				</div>
			</v-col>

			<v-col cols="3">
				<div class="text-caption">Hanteras av</div>
				<div v-for="(todo, index) in todos" :key="index" class="d-flex align-center">
					<div class="text-subtitle-2">{{ todo.user.name }} ({{ todo.user.login }})</div>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn class="pl-4" v-bind="attrs" icon x-small v-on="on">
								<v-icon>mdi-information</v-icon>
							</v-btn>
						</template>
						<div>Att göra: {{ getTodoText(todo) }}</div>
						<div>Läst: <strong>{{ getReadValue(todo.read) }}</strong></div>
						<div>Skapad: {{ formatCreationDate(todo.creation_date) }}</div>
					</v-tooltip>
				</div>
			</v-col>

			<v-col v-if="invoice.meta.deleted" class="error--text" cols="3">
				<div class="text-caption">Makulerad</div>
				<div class="text-subtitle-2">{{ $utils.date.getFullDate($utils.date.intToDate(invoice.meta.date.deleted)) }}</div>
			</v-col>
		</v-row>

		<Dialog v-if="editReferenceDialog" :loading="isPending" title="Byt referenskod" @confirm="onConfirm" @reject="editReferenceDialog = false">
			<v-form ref="form">
				<search-reference v-model="reference" :rules="$validator('base/required')" />
			</v-form>
		</Dialog>
		<Dialog v-if="editAccountGiro" :loading="isPending" title="Byt Konto/Gironr" @confirm="OnGiroConfirm" @reject="editAccountGiro = false">
			<v-form ref="form">
				<select-giro v-model="selectedGiro" :giros="formattedGiros" :rules="$validator('base/required')" @on-change="OnGiroChange" />
			</v-form>
		</Dialog>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchReference from '@/components/search/reference';
import selectGiro from '@/components/invoice/selectGiro';
export default {
	components: {
		SearchReference,
		selectGiro
	},

	props: {
		invoice: {
			type: Object,
			required: true,
			default: () => ({})
		},
		giros: {
			type: Array,
			default: () => ([])
		},
		todos: {
			type: Array,
			default: () => ([])
		}
	},

	data: () => ({
		privacyLevel: ['none', 'printing', 'show'],
		selectedGiro: null,
		reference: null,
		girosFormatted: [],
		editReferenceDialog: false,
		editAccountGiro: false,
		isPending: false,
		errorMessage: '',
		selectedGiroIndex: null,

		filter: {
			items: [{
				text: 'Kontera',
				value: 'ac'
			}, {
				text: 'Godkänn kontering',
				value: 'acap'
			}, {
				text: 'Beslutsattestera',
				value: 'acau'
			}, {
				text: 'Godkänn betalning',
				value: 'pay'
			}, {
				text: 'Hantera abonnemangsfel',
				value: 'serr'
			}, {
				text: 'Godkänn kostnadskontering',
				value: 'cap'
			}, {
				text: 'Beslutsattestera kostnadskontering',
				value: 'cau'
			}, {
				text: 'Godkänn momskontering',
				value: 'vap'
			}, {
				text: 'Beslutsattestera momskontering',
				value: 'vau'
			}, {
				text: 'Hantera avslutad användare',
				value: 'hi'
			}]
		}

	}),
	computed: {
		...mapGetters('userStore', ['getUserData', 'isUserAllowedToInvoiceAlterInvoiceReference', 'isUserAllowedToAlterCreditorAccount']),
		formattedGiros() {
			if (this.giros.length > 0) {
				return this.giros.map(x => this.$utils.format.account({ AccountNumber: x.number, Scheme: x.bic }));
			} else {
				return [];
			}
		}
	},

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.invoice = val;
				}
			}
		}
	},

	async created() {
		this.selectedGiro = await this.$utils.format.account({AccountNumber: this.invoice.meta.account.AccountNumber, Scheme: this.invoice.meta.account.Scheme });
	},

	methods: {
		async onConfirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.errorMessage = '';
			try {
				await this.$service.invoices.updateReference(this.invoice._id, this.reference);
				this.$emit('on-update');
				this.editReferenceDialog = false;
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		getReadValue(value) {
			if(value === false) {
				return 'Nej';
			}

			return 'Ja';
		},

		getTodoText(todo) {
			const item = this.filter.items.find(item => item.value === todo.todo);

			return item ? item.text : 'Okänd';
		},

		formatCreationDate(dateNumber) {
			const dateString = dateNumber.toString();

			if (dateString.length === 8) {
				const year = dateString.substring(0, 4);
				const month = dateString.substring(4, 6);
				const day = dateString.substring(6, 8);

				return `${year}-${month}-${day}`;
			}

			return dateString;
		},

		OnGiroChange(item) {
			this.selectedGiroIndex = item;
		},

		async OnGiroConfirm() {
			this.errorMessage = '';
			try {
				await this.$service.invoices.changeGiro(this.invoice._id, this.selectedGiroIndex);
				this.$emit('on-update');
				this.editAccountGiro = false;
			} catch (error) {
				this.errorMessage = error.message;
			}
		}
	}
};
</script>
