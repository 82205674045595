import * as rules from '../rules';

export default {
	reference: () => ([
		rules.required()
	]),

	groupId: () => ([
		rules.required()
	]),

	userId: () => ([
		rules.required()
	]),

	description: () => ([
		rules.required()
	])
};
