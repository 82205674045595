<template>
	<v-form ref="form">
		<v-row>
			<v-col cols="4">
				<ui-text-field ref="orgNumber" v-model="formData.orgNumber" label="Organisationsnr"
					:rules="$validator('base/required')" required :error-messages="inyettError"
				/>
			</v-col>
			<v-col cols="4">
				<v-btn v-if="isNew" style="position: relative; top: 26%;" color="primary" elevation="0" :loading="isFetchInyettPending"
					@click="fetchInyett"
				>
					Hämta från Inyett
				</v-btn>
			</v-col>
		</v-row>


		<v-row>
			<v-col cols="4">
				<ui-text-field v-model="formData.name" label="Leverantörsnamn" :rules="$validator('base/required')" required />
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.address" label="Adress" />
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.zipCode" label="Postnr" />
			</v-col>

			<v-col cols="4">
				<ui-text-field v-model="formData.town" label="Stad" />
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.phonenumber" label="Telefonnr" />
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.email" label="E-Post" />
			</v-col>


			<v-col cols="4">
				<v-checkbox v-model="formData.vatRegistered" label="Momsregistrerad" />
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.vatNo" label="Momsregistrering"
					:rules="formData.vatRegistered ? $validator('base/required'): []" :required="formData.vatRegistered"
				/>
			</v-col>
			<v-col cols="4">
				<ui-select v-model="formData.vat_type"
					:items="[{
						text: $t('vat.swedish'),
						value: 'swedish'
					}, {
						text: $t('vat.eu'),
						value: 'eu'
					}, {
						text: $t('vat.none_eu'),
						value: 'non-eu'
					}]"
					label="Momstyp"
					:rules="$validator('base/required')"
					required
				/>
			</v-col>


			<v-col cols="4">
				<v-checkbox v-model="formData.fvatRegistered" label="F-Skatt" />
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model.number="formData.duedays" label="Antal betaldagar" required :rules="$validator('supplier/dueDate')" />
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.max_duedays" label="Betalningsfrist"
					:rules="$validator('supplier/dueDate')" required
				/>
			</v-col>

			<v-col cols="4">
				<ui-text-field v-model="formData.match_length" :rules="$validator('supplier/isNumber')" label="Matchlängd referenskod" />
			</v-col>
			<v-col cols="4">
				<search-reference v-model="formData.default_reference" :no-label="false" />
			</v-col>
			<v-col cols="4">
				<ui-autocomplete
					v-model="formData.counterpart"
					class="pr-2"
					clearable
					:items="getCodelist.H"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.H"
				/>
			</v-col>

			<v-col cols="4">
				<ui-text-field v-model="formData.acceptable_amount_diff" label="Tillåten totalbeloppsavvikelse"
					:rules="$validator('supplier/isNumber')"
					required
				/>
			</v-col>
			<v-col cols="4">
				<ui-select v-model="formData.sensitivity"
					:items="[{
						text: $t('privacy_level.none'),
						value: 0
					}, {
						text: $t('privacy_level.printing'),
						value: 1
					}, {
						text: $t('privacy_level.show'),
						value: 2
					}]"
					label="Sekretessnivå"
					required
				/>
			</v-col>
			<v-col cols="4">
				<v-checkbox v-if="isUserAllowedToSupplierEdit" v-model="formData.active" label="Aktiv" class="pt-10" />
				<ui-textarea v-if="!formData.active" v-model="formData.inactive_reason" label="Anledning till spärr"
					:rules="$validator('base/required')" required
				/>
			</v-col>

			<v-col cols="4">
				<v-checkbox v-model="formData.fraud" label="Blufföretag" />
			</v-col>
			<v-col cols="4">
				<ui-select v-model="formData.fraudLevel"
					:items="[{
						text: $t('color.green'),
						value: 'green'
					}, {
						text: $t('color.yellow'),
						value: 'yellow'
					}, {
						text: $t('color.red'),
						value: 'red'
					}]"
					label="Bluffnivå"
					:rules="$validator('base/required')"
					required
				/>
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.registrationDate" label="Registreringsdatum" />
			</v-col>

			<v-col cols="4">
				<ui-text-field v-model="formData.municipality" label="Kommun" />
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.county" label="Län" />
			</v-col>
			<v-col cols="4">
				<ui-text-field v-model="formData.webaddress" label="Hemsida" />
			</v-col>

			<v-col cols="4">
				<ui-text-field v-model="formData.faxnumber" label="Faxnr" />
			</v-col>

			<v-col cols="4">
				<ui-text-field v-model="formData.sni" label="SNI" />
			</v-col>
			<v-col cols="4">
				<search-supplier v-model="formData.parent" label="Koncernförälder" />
			</v-col>
		</v-row>

		<v-row />
	</v-form>
</template>

<script>

import {mapGetters} from 'vuex';


export default {
	props: {
		value: {
			type: Object,
			default: null
		},

		isNew: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		formData: {
			name: '',
			orgNumber: '',
			address: '',
			zipCode: '',
			phonenumber: '',
			faxnumber: '',
			email: '',
			webaddress: '',
			municipality: '',
			county: '',
			registrationDate: '',
			town: '',
			sni: '',
			vatRegistered: true,
			fvatRegistered: true,
			fraudLevel: 'green',
			giro: [],
			active: true,
			inactive_reason: '',
			foreign: false,
			fraud: false,
			default_reference: '',
			created_date: 20221031,
			updated_date: 20221031,
			vat_type: 'swedish',
			vatNo: '',
			parent: '',
			duedays: 30,
			max_duedays: 60,
			match_length: null,
			sensitivity: 0,
			counterpart: 0,
			acceptable_amount_diff: 0
		},

		isFetchInyettPending: false,
		inyettError: ''
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames']),
		...mapGetters('userStore', ['isUserAllowedToSupplierEdit'])
	},

	watch: {
		formData: {
			deep: true,
			handler(to) {
				to.counterpart = !isNaN(parseInt(to.counterpart)) ? parseInt(to.counterpart) : 0;
				to.match_length = !isNaN(parseInt(to.match_length)) ? parseInt(to.match_length) : 0;
				to.acceptable_amount_diff = !isNaN(parseInt(to.acceptable_amount_diff)) ? parseInt(to.acceptable_amount_diff) : 0;
				to.default_reference = to.default_reference || '';
				to.parent = to.parent || '';
				to.sni = to.sni || '';

				this.$emit('input', {...to });
			}
		}
	},

	created() {
		this.formData = {
			...this.formData,
			...this.value
		};

		this.formData.registrationDate = this.$utils.date.getISODate(this.formData.registrationDate),

		this.$emit('input', { ...this.formData });
	},

	methods: {
		validate() {
			return this.$refs.form.validate();
		},

		itemText(a) {
			return `${a.kod} - ${a.kodtext}`;
		},

		async fetchInyett() {
			this.inyettError = '';

			try {
				const data  = await this.$service.suppliers.getFromInyett({
					orgno: this.formData.orgNumber
				}, {
					throwException: true,
					loading: val => {
						this.isFetchInyettPending = val;
					}
				});

				this.formData = {
					...this.formData,
					...data,
					registrationDate:  this.$utils.date.getISODate(data.registrationDate),
					vat_type:  data.vat_type || this.formData.vat_type
				};
			} catch (error) {
				this.inyettError = 'Misslyckades att hämta data från Inyett med angivet Organisationsnr.';
			}
		}
	}
};
</script>
