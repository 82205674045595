<template>
	<Dialog
		title="Ändra Sekretessnivå"
		:loading="updateIsPending"
		@reject="reject"
		@confirm="confirm"
	>
		<ui-select v-model="formData.level" label="Nivå" :items="items" />
		<Loader :error="errorMessage" slim />
	</Dialog>
</template>

<script>
export default {
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		updateIsPending: false,
		errorMessage: '',

		items: [{
			text: 'Ingen',
			value: 0
		}, {
			text: 'Utskrift',
			value: 1
		}, {
			text: 'Visning',
			value: 2
		}],

		formData: {
			level: null
		}
	}),

	created() {
		this.formData.level = this.invoice.meta.sensitivity;
	},

	methods: {
		reject() {
			this.$emit('reject');
		},

		async confirm() {
			this.errorMessage = '';


			try {
				await this.$service.invoices.setSensitivity(this.invoice._id, this.formData.level, {
					throwException: true,
					loading: val => {
						this.updateIsPending = val;
					}
				});

				this.$emit('on-update');
				this.$emit('reject');
			} catch (error) {
				this.errorMessage = error;
			}
		}
	}
};
</script>
