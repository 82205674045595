<template>
	<Dialog
		max-width="1024"
		width="1024"
		title="Ny konteringmall"
		:loading="isAddPending"
		@reject="reject"
		@confirm="confirm"
	>
		<v-form ref="form">
			<v-row>
				<v-col cols="12">
					<accounting-template v-model="formData" />
				</v-col>
			</v-row>
		</v-form>
		<Loader :error="error" />
	</Dialog>
</template>

<script>
import AccountingTemplate from '@/components/accounting-template';

export default {
	components: {
		AccountingTemplate
	},

	data: () => ({
		formData: null,
		isAddPending: false,

		error: ''
	}),

	methods: {
		reject() {
			this.$emit('close');
		},

		async confirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.formData.codes = this.formData.codes.map(code => parseInt(code));

			try {
				const data = await this.$service.codestrings.create({
					...this.formData
				}, {
					loading: val => {
						this.isAddPending = val;
					},
					throwException: true
				});

				this.$emit('close');
				this.$emit('created', data);
			} catch (error) {
				this.error = error.message;
			}
		}
	}
};
</script>
