import store from '@/plugins/vuex';

export const addFilter = (name, method, value, prefix = '', suffix = '') => {
	if ((typeof value !== 'boolean' && !value) || value === undefined) {
		return;
	}

	return {
		[name]: {
			method,
			value: `${prefix}${value}${suffix}`
		}
	};
};

export const hasActiveFilters = filters => Object.values(filters).some(value => {
	if ((typeof value !== 'boolean' && !value) || value === undefined) {
		return false;
	}

	return true;
});


export const getFilterQuery = filters => {
	return Object.entries(filters).reduce((acc, [key, value]) => {
		if(!value) {
			return {
				...acc
			};
		}

		if (Array.isArray(value)) {
			const objValue = {};

			if(isNaN(value[0])) {
				return acc;
			}

			if (value[0] !== null) {
				objValue['$gte'] = value[0];
			}

			if (value[0].toString().length === 4) {
				const str = (value[0] * 10000);

				objValue['$gte'] = parseInt(str);
			}

			if (value[1] !== null) {
				objValue['$lte'] = value[1];
			}

			if (Object.keys(objValue).length === 0) {
				return acc;
			}

			if(key === 'to' && value.length === 1) {
				objValue['$lte'] = 20999999;
			}

			return {
				...acc,
				[key]: objValue
			};
		}

		if(['userId' ,'id', 'userIds', 'level', 'eid', 'uid', 'type'].includes(key)) {
			return {
				...acc,
				[key]: value
			};
		}

		if(['giro'].includes(key)) {
			return {
				...acc,
				['giro.number']: {
					'$regex': `${value.replaceAll('-', '')}`,
					'$options': 'i'
				}
			};
		}

		if (key === 'text') {
			return {
				...acc,
				[key]: {
					'$regex': `${value}`,
					'$options': 'i'
				}
			};
		}

		if(key === 'name') {

			return {
				...acc,
				'$or': [
					{
						name: {
							'$regex': `${value}`,
							'$options': 'i'
						}
					}, {
						login: {
							'$regex': `${value}`,
							'$options': 'i'
						}
					}
				]
			};
		}

		if(key.includes('codes')) {
			const objValue = {};
			const newKey = key.replace('[', '.').replace(']', '');
			const number = newKey.slice(-1);

			objValue['$gte'] = Number(value.padEnd(store.getters['accounting/getCodeLengths'][number] , '0'));
			objValue['$lte'] = Number(value.padEnd(store.getters['accounting/getCodeLengths'][number] , '9'));

			if (Object.keys(objValue).length === 0) {
				return acc;
			}

			return {
				...acc,
				[newKey]: objValue
			};
		}

		if (['ID', 'reference', 'code'].includes(key)) {
			return {
				...acc,
				[key]: {
					'$regex': `^${value}`,
					'$options': 'i'
				}
			};
		}

		return {
			...acc,
			[key]: {
				'$regex': `${value}`,
				'$options': 'i'
			}
		};
	}, {});
};
