<template>
	<div>
		<v-tabs v-model="tab">
			<v-tab href="#tab-1">Ankomstbokföring</v-tab>
			<v-tab href="#tab-2">Definitiv bokföring</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item value="tab-1">
				<v-data-table
					:headers="getHeaders"
					:items="getArrival"
					hide-default-footer
					:items-per-page="9999"
				>
					<template v-slot:item.date="{ item }">
						<span :tabIndex="-1">{{ $utils.date.intToPretty(item.date) }}</span>
					</template>
					<template v-slot:item.amount="{item}">
						<strong class="primary--text d-flex justify-end" :tabIndex="-1">{{ $utils.format.price(item.amount) }}</strong>
					</template>

					<template v-for="(head, i) in headers" v-slot:[`item.${head}`]="{ item }">
						<div :key="head" v-tooltip :content="item.codetexts[i]" :tabIndex="-1">
							{{ getCodeValue(head, item.codes[i]) }}
						</div>
					</template>
				</v-data-table>
			</v-tab-item>

			<v-tab-item value="tab-2">
				<v-data-table
					:headers="getHeaders"
					:items="getDefinitive"
					hide-default-footer
					:items-per-page="9999"
				>
					<template v-slot:item.date="{ item }">
						<span :tabIndex="-1">{{ $utils.date.intToPretty(item.date) }}</span>
					</template>
					<template v-slot:item.amount="{item}">
						<strong class="primary--text d-flex justify-end" :tabIndex="-1">{{ $utils.format.price(item.amount) }}</strong>
					</template>
					<template v-for="(head, i) in headers" v-slot:[`item.${head}`]="{ item }">
						<div :key="head" v-tooltip :content="item.codetexts[i]" :tabIndex="-1">
							{{ getCodeValue(head, item.codes[i]) }}
						</div>
					</template>
				</v-data-table>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		bookings: {
			required: true,
			type: Object,
			default: () => ({})
		}
	},

	data: () => ({
		tab: null,
		isPending: false,
		headers: ['A', 'B', 'C', 'D', 'E', 'F','G', 'H', 'I', 'J']
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getCodeValue', 'getIsActiveCode']),

		getArrival() {
			return [
				...this.$utils.dotProp(this.bookings, 'arrival', []).map(x => ({...x, _type: ''})),
				...this.$utils.dotProp(this.bookings, 'arrival_cancellation', []).map(x => ({ ...x, _type: 'Avbokning' })),
				...this.$utils.dotProp(this.bookings, 'final', []).filter(booking => booking.meta.code === 1).map(x => ({ ...x, _type: '' })),
				...this.$utils.dotProp(this.bookings, 'arrival_deletion', []).map(x => ({ ...x, _type: 'Makulering' }))
			].sort((a, b) => a.date - b.date);
		},

		getDefinitive() {
			return [
				...this.$utils.dotProp(this.bookings, 'final', []).filter(booking => booking.meta.code !== 1).map(x => ({ ...x, _type: '' })),
				...this.$utils.dotProp(this.bookings, 'final_deletion', []).map(x => ({ ...x, _type: 'Makulering' }))
			].sort((a, b) => a.date - b.date);
		},

		getHeaders() {
			return [{
				text: 'Datum',
				value: 'date',
				align: 'start'
			}, {
				text: 'Typ',
				value: 'meta.type'
			}, this.getIsActiveCode(0) && {
				text: `${this.getCodeNames.A}`,
				value: 'A'
			}, this.getIsActiveCode(1) && {
				text: `${this.getCodeNames.B}`,
				value: 'B'
			}, this.getIsActiveCode(2) && {
				text: `${this.getCodeNames.C}`,
				value: 'C'
			}, this.getIsActiveCode(3) && {
				text: `${this.getCodeNames.D}`,
				value: 'D'
			}, this.getIsActiveCode(4) && {
				text: `${this.getCodeNames.E}`,
				value: 'E'
			}, this.getIsActiveCode(5) && {
				text: `${this.getCodeNames.F}`,
				value: 'F'
			}, this.getIsActiveCode(6) && {
				text: `${this.getCodeNames.G}`,
				value: 'G'
			}, this.getIsActiveCode(7) && {
				text: `${this.getCodeNames.H}`,
				value: 'H'
			}, this.getIsActiveCode(8) && {
				text: `${this.getCodeNames.I}`,
				value: 'I'
			}, this.getIsActiveCode(9) && {
				text: `${this.getCodeNames.J}`,
				value: 'J'
			}, {
				text: 'Belopp',
				value: 'amount'
			}].filter(Boolean);
		}
	},

	methods: {

	}
};
</script>
