<template>
	<v-expansion-panel>
		<v-expansion-panel-header class="text-button" style="line-height: 100%;">
			{{ action.name }}
		</v-expansion-panel-header>

		<v-expansion-panel-content>
			<label>Lägg till regel</label>
			<div class="d-flex align-center">
				<ui-autocomplete v-model="actionToAdd" style="width: 50% !important" :items="getSelectableActions" class="pr-8"
					item-text="name" item-value="key" return-object
				/>
				<v-btn fab x-small color="primary" elevation="0"
					@click="addActionWithParams"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</div>

			<v-list dense class="pa-0">
				<v-list-item-group color="primary">
					<v-list-item v-for="(a, index) in actions" :key="a.id" inactive>
						<v-list-item-content>
							<v-list-item-title v-text="a.name" />
						</v-list-item-content>

						<v-list-item-icon>
							<v-icon color="primary" class="mr-2" :disabled="index === actions.length - 1"
								@click="moveRule(index, index + 1)"
							>
								mdi-arrow-down
							</v-icon>

							<v-icon color="primary" class="mr-2" :disabled="index === 0" @click="moveRule(index, index - 1)">
								mdi-arrow-up
							</v-icon>

							<v-icon color="primary" class="mr-2" @click="editParams(a)">mdi-cog</v-icon>

							<v-icon color="primary" @click="deleteRule(a.id)">mdi-delete</v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list-item-group>
			</v-list>

			<Dialog v-if="showParamDialog"
				title="Parametrar"
				@reject="showParamDialog = false"
				@confirm="isUpdate ? updateAction(params) : addAction(params)"
			>
				<div v-for="paramName in actionToAdd.paramNames" :key="paramName">
					<ui-text-field
						v-model="params[paramName]"
						:label="paramName"
					/>
				</div>
				<v-checkbox v-model="isError" label="Hantera som fel" />
			</Dialog>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>


<script>
import { arrayMoveImmutable as arrayMove } from 'array-move';

export default {
	props: {
		groupId: {
			type: String,
			required: true
		},

		action: {
			type: Object,
			required: true
		},

		actions: {
			type: Array,
			required: true
		}
	},

	data: () => ({
		actionToAdd: null,
		isUpdate: false,
		showParamDialog: false,
		params: {},

		isError: true
	}),

	computed: {
		getSelectableActions() {
			return this.action.actions.filter(action => !this.actions.some(a => a.id === action.key));
		}
	},

	methods: {
		addActionWithParams() {
			if (this.actionToAdd.paramNames.length) {
				this.actionToAdd.paramNames.forEach((paramName, index) => {
					this.params[paramName] = this.actionToAdd.paramDefaults[index];
				});

				this.showParamDialog = true;

				return;
			}

			this.addAction();
		},

		editParams(action) {
			this.isUpdate = true;

			action.paramNames.forEach((paramName, index) => {
				this.params[paramName] = action.paramValues[index];
			});


			this.actionToAdd = {...action};
			this.isError = action.is_error;
			this.showParamDialog = true;
		},

		async addAction(params = {}) {
			await this.$service.groups.addAction(this.groupId, this.action.group, {
				action: this.actionToAdd.key,
				params: Object.values(params),
				is_error: true
			});

			this.actionToAdd = null;
			this.showParamDialog = false;
			this.$emit('on-action-update');
		},

		async deleteRule(id) {
			await this.$service.groups.removeAction(this.groupId, this.action.group, id);

			this.$emit('on-action-update');
		},

		async moveRule(fromIndex, toIndex) {
			let clone = this.$utils.cloneDeep(this.actions);

			clone = arrayMove(clone, fromIndex, toIndex);

			await this.$service.groups.reorderActions(this.groupId, this.action.group, {
				order: clone.map(x => x.id)
			});

			this.$emit('on-action-update');
		},

		async updateAction(params) {
			await this.$service.groups.updateAction(this.groupId, this.action.group, this.actionToAdd.id, {
				params: Object.values(params),
				is_error: this.isError
			});

			this.isUpdate = false;
			this.actionToAdd = null;
			this.showParamDialog = false;
			this.$emit('on-action-update');
		}
	}
};
</script>
