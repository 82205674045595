import i18n from '@/plugins/i18n';
import { kpApiV1 } from '@/plugins/axios';
import { compose, getFields } from '@/utils/service';
import { validate } from '@/utils/validation';

const fields = {
	name: {
		rules: input => validate(input).required().maxLenght(25).test(),
		label: i18n.t('base.name'),
		counter: 25
	},

	grant: {
		rules: input => validate(input).isBoolean().test()
	}
};

export default {
	get: compose(kpApiV1.get, ['roles', 'roles/<roleId>']),

	add: compose(kpApiV1.post, 'roles', {
		fields: getFields(fields, ['name']),
		successMessage: 'service.roles.add_success'
	}),

	delete: compose(kpApiV1.delete, 'roles/<roleId>'),

	addCapability: compose(kpApiV1.post, 'roles/<roleId>/cap/<capId>', {
		fields: getFields(fields, ['grant'])
	}),

	deleteCapability: compose(kpApiV1.delete, 'roles/<roleId>/cap/<capId>')
};
