<template>
	<div>
		<portal-target name="register-header" />

		<v-card elevation="2">
			<v-tabs>
				<v-tab v-for="tab in tabs" :key="tab.path" ref="tab" :to="tab.path">
					{{ tab.label }}
				</v-tab>
			</v-tabs>
			<div class="pa-6">
				<router-view />
			</div>
		</v-card>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	computed: {
		...mapGetters('userStore', ['isUserAllowedToInvoiceHandleErrors', 'isUserAllowedToInvoiceHandleRejections']),

		tabs() {
			return [{
				path: '/invoices/all',
				label: 'Alla Fakturor'
			}, this.isUserAllowedToInvoiceHandleRejections && {
				path: '/invoices/faulty-invoices',
				label: 'Felaktiga fakturor'
			}, this.isUserAllowedToInvoiceHandleRejections && {
				path: '/invoices/rejected-invoices',
				label: 'Avvisade fakturor'
			}].filter(Boolean);
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.$refs['tab'][0].$el.focus();
		});
	}
};
</script>
