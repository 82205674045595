<template>
	<div>
		<div class="d-flex mb-6 align-center">
			<div class="text-caption">
				Nuvarande värde: <strong>{{ $utils.dotProp(localInvoice, 'meta.account.AccountNumber', '') }} ({{ $utils.dotProp(localInvoice, 'meta.account.Scheme', '') }})</strong>
			</div>
			<v-spacer />
			<v-btn color="primary" small @click="newDialogOpen = true"><v-icon left>mdi-plus</v-icon>Nytt Giro</v-btn>
		</div>
		<ui-select v-if="supplier" v-model="newGiro" :items="supplier.giro" item-value="number" return-object
			label="Välj Giro"
		>
			<template #selection="{ item }">
				<span class="text-caption">{{ item.number }} ({{ item.bic }})</span>
			</template>
			<template #item="{item}">
				<span class="text-caption">{{ item.number }} ({{ item.bic }})</span>
			</template>
		</ui-select>
		<div v-if="newGiro">
			<p class="text-caption">
				Vill du uppdatera till giro <strong class="primary--text">{{ newGiro.number
				}} ({{ newGiro.bic }})</strong>?
			</p>
			<v-btn color="success" text small @click="updateInvoice">Ja, uppdatera</v-btn>
		</div>

		<ui-movable-dialog v-if="newDialogOpen" title="Nytt Giro" @reject="newDialogOpen = false" @confirm="addGiro">
			<GiroForm v-model="giroToAdd" :error="errorMessage" :local-number="localInvoice.meta.account.AccountNumber" />
		</ui-movable-dialog>
	</div>
</template>

<script>
import GiroForm from '@/views/register/children/suppliers/components/giro-form';

export default {
	components: {
		GiroForm
	},

	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		newGiro: null,
		newDialogOpen: false,
		localInvoice: null,
		giroToAdd: null,

		supplier: null,

		errorMessage: ''
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
					this.newGiro = null;
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);

		this.fetchSupplier();
	},

	methods: {
		updateInvoice() {
			this.localInvoice.meta.account.AccountNumber = this.newGiro.number;
			this.localInvoice.meta.account.Scheme = this.newGiro.bic;


			this.$emit('on-invoice-update', this.localInvoice);
		},

		async addGiro() {
			try {
				await this.$service.suppliers.updateGiro(this.localInvoice.meta.supplier_id, {
					giro: [
						...this.supplier.giro,
						{
							number: this.giroToAdd.number,
							bic: this.giroToAdd.bic,
							active: this.giroToAdd.active
						}
					]
				}, {
					throwException: true,
					loading: val => {
						this.isGiroAddPending = val;
					}
				});

				this.newGiro = {...this.giroToAdd};
				this.newDialogOpen = false;

				this.fetchSupplier();
			} catch (error) {
				this.errorMessage = error.message;
			}
		},


		async fetchSupplier() {
			this.supplier = await this.$service.suppliers.get(this.localInvoice.meta.supplier_id);
		}
	}
};
</script>
