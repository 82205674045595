import i18n from '@/plugins/i18n';
import LogsView from './logs.vue';

export default [{
	path: '/logs',
	name: 'Logs',
	component: LogsView,
	meta: {
		title: i18n.t('routes.titles.logs')
	}
}];
