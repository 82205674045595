<template>
	<Dialog
		title="Makulera Faktura"
		:loading="deleteIsPending"
		width="400px"
		ok-btn-text="Makulera"
		@reject="reject"
		@confirm="confirm"
	>
		<div class="d-flex flex-column align-center">
			<ui-text-field class="pb-4" :value="formData.date" readonly label="Makuleringsdatum" />
			<v-date-picker v-model="formData.date" type="date" no-title scrollable />
		</div>
		<Loader v-if="errorMessage" :error="[errorMessage]" slim />
	</Dialog>
</template>

<script>

export default {
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		deleteIsPending: false,
		errorMessage: '',

		formData: {
			date: null
		}
	}),

	created() {
		this.formData.date = this.invoice.meta.date.deleted || new Date().toISOString().substr(0, 10);
	},

	methods: {
		reject() {
			this.$emit('reject');
		},

		async confirm() {
			this.errorMessage = '';
			const cancelDate = this.formData.date.replaceAll('-', '');

			try {
				await this.$service.invoices.delete(this.invoice._id, cancelDate, {
					throwException: true,
					loading: val => {
						this.deleteIsPending = val;
					}
				});

				this.$emit('on-update');
			} catch (error) {
				this.errorMessage = error.message;
			}
		}
	}
};
</script>
