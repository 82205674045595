import axios from 'axios';
import utils from '@/utils';
import config from '@/assets/config.json';
import ApiResponseError from '@/errors/ApiResponseError';
import i18n from '@/plugins/i18n';

const apiClient = axios.create({
	baseURL: config.apiBaseUrl,
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
});

apiClient.interceptors.request.use(req => {
	req.headers.Authorization = `Bearer ${utils.token.get()}`;

	return req;
});

apiClient.interceptors.response.use(res => {
	if (Array.isArray(res.data) && res.headers['x-kp-pagination-total']) {
		res.data._paginationTotal = Number(res.headers['x-kp-pagination-total']);
	}

	return res.data;
},
error => {
	if (!error.response) {
		throw new ApiResponseError(undefined, i18n.t('error.no_server_connection'), undefined);
	}

	const status = error.response.status;
	const {code, message} = error.response.data;

	if (status === 401) {
		utils.user.logout();
	}

	throw new ApiResponseError(code, message || error.response.data, status);
});

const callAsync = (promise, opts) => {
	const noop = () => { };
	const options = {
		throwException: typeof opts.throwException === 'boolean' ? opts.throwException : false,
		loading: typeof opts.loading === 'function' ? opts.loading : noop
	};

	options.loading(true);

	return new Promise((resolve, reject) => {
		promise.then(data => {
			return resolve(data);
		}).catch(error => {
			if (options.throwException) {
				return reject(error);
			}

			console.log(error);
			//store.dispatch('error/setError', { message: error.message, id: +Date.now() }, { root: true });
		}).finally(() => {
			options.loading(false);
		});
	});
};
const getOptions = (opts = {}) => {
	const options = typeof opts === 'object' ? opts : {};
	const { loading, throwException, ...axiosOpts } = options;
	const params = axiosOpts.params;

	return {
		axios: { ...axiosOpts, params },
		custom: { loading, throwException }
	};
};
const simplifiedApiWrapper = basePath => ['get', 'post', 'put', 'patch', 'delete'].reduce((acc, type) => {
	return {
		...acc,
		[type]: (path, param1, param2) => apiClient[type](`${basePath}${path}`, param1, param2)
	};
}, {});

export const kpApiV1 = simplifiedApiWrapper('/wp-json/kp/v1/');

export default {
	get: (url, opts) => {
		const options = getOptions(opts);

		return callAsync(apiClient.get(url, options.axios), options.custom);
	},

	post: (url, body, opts) => {
		const options = getOptions(opts);

		return callAsync(apiClient.post(url, body, options.axios), options.custom);
	},

	put: (url, body, opts) => {
		const options = getOptions(opts);

		return callAsync(apiClient.put(url, body, options.axios), options.custom);
	},

	patch: (url, body, opts) => {
		const options = getOptions(opts);

		return callAsync(apiClient.patch(url, body, options.axios), options.custom);
	},

	delete: (url, opts) => {
		const options = getOptions(opts);

		return callAsync(apiClient.delete(url, options.axios), options.custom);
	}
};
