<template>
	<div class="SitesRegister">
		<v-row no-gutters>
			<v-col cols="8" />
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
						@click="exportExcel"
					>
						<v-icon>mdi-microsoft-excel</v-icon>
					</v-btn>
					<v-btn color="accent" @click="setNewItem">
						Ny Abonnemangstyp
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<Loader :service="[$service.subscriptions.searchTypes]" hide-loading />

		<RegisterList ref="registerList" item-key="_id" :items="types" :headers="getHeaders" sidebar-width="33%"
			@active-item-changed="setActiveItem"
		>
			<SubtypeEdit :type="activeType" @sub-deleted="subUpdated" @fetch="subUpdated" />
		</RegisterList>

		<SubtypeNew v-if="newDialogOpen" :type="activeType" @close="newDialogOpen = false" @on-create="createdType" />
	</div>
</template>

<script>
import {run} from '@/utils/service';
import RegisterList from '@/components/register-list';
import {mapActions} from 'vuex';
import SubtypeEdit from './components/subtype-edit';
import SubtypeNew from './components/subtype-new';

export default {
	components: {
		RegisterList,
		SubtypeNew,
		SubtypeEdit
	},

	data: () => ({
		activeType: {
			name: ''
		},

		newDialogOpen: false,
		isPending: false,
		types: []
	}),

	computed: {
		getHeaders() {
			return [{
				text: 'Abonnemangstyp',
				value: 'name',
				align: 'start'
			}];
		}
	},

	created() {
		this.fetchTypes();
	},

	methods: {
		...mapActions('sitesStore', ['fetchSites']),

		setActiveItem(item) {
			this.activeType = item;
		},

		setNewItem() {
			this.activeType = {
				name: ''
			};
			this.newDialogOpen = true;
		},

		createdType() {
			this.newDialogOpen = false;
			this.fetchTypes();
		},

		async subUpdated(type) {
			await this.fetchTypes();

			if (type) {
				this.$refs.registerList.open(type);
			} else {
				this.$refs.registerList.close();
			}
		},

		async exportExcel() {
			this.isPending = true;
			const data = this.$utils.service.run(await this.$service.subscriptions.searchTypes.call());

			await this.$utils.excel.download(data, 'Abonnemangstyper');
			this.isPending = false;
		},

		async fetchTypes() {
			run(await this.$service.subscriptions.searchTypes.call(), data => {
				this.types = data;
			});
		}
	}
};
</script>
