<template>
	<v-expansion-panel>
		<v-expansion-panel-header class="text-button" style="line-height: 100%;">
			Verifikationskoder
		</v-expansion-panel-header>

		<v-expansion-panel-content>
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">
								Från
							</th>
							<th class="text-left">
								Till
							</th>
							<th class="text-left">
								Prefix
							</th>
							<th class="text-left">
								Min
							</th>
							<th class="text-left">
								Max
							</th>
							<th class="text-left">
								Typ
							</th>
							<th class="text-right">
								Ta bort
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="code in codes" :key="code._id">
							<td>{{ $utils.date.intToPretty(code.from) }}</td>
							<td>{{ $utils.date.intToPretty(code.to) }}</td>
							<td>{{ code.prefix }}</td>
							<td>{{ code.min }}</td>
							<td>{{ code.max }}</td>
							<td>{{ $t(`verCode.${code.type}`) }}</td>
							<td class="d-flex justify-end"><v-icon @click="removeCode(code)">mdi-delete</v-icon></td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>

			<Loader :error="errorMessage" />

			<div class="d-flex justify-end mt-6">
				<v-btn text color="primary" @click="newCodeDialog = true">Ny kod</v-btn>
			</div>

			<VerificationCodeDialog v-if="newCodeDialog"
				:group-id="groupId"
				@on-close="newCodeDialog = false"
				@on-code-add="onCodeAdd"
			/>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import VerificationCodeDialog from './verification-code-dialog';

export default {
	components: {
		VerificationCodeDialog
	},

	props: {
		groupId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		newCodeDialog: false,
		codes: [],
		errorMessage: ''
	}),

	watch: {
		groupId() {
			this.fetchCodes();
		}
	},

	created() {
		this.fetchCodes();
	},

	methods: {
		async fetchCodes() {
			this.codes = await this.$service.groups.getVerificationCodes(this.groupId);
		},

		async removeCode(code) {
			this.errorMessage = '';
			try {
				await this.$service.groups.deleteVerificationCode(this.groupId, code._id);

				this.fetchCodes();
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		onCodeAdd() {
			this.$emit('on-code-add');
			this.newCodeDialog = false;
			this.fetchCodes();
		}
	}
};
</script>
