<template>
	<v-navigation-drawer ref="drawer" class="Sidebar elevation-2" app :expand-on-hover="!drawerIsLocked" permanent
		mini-variant-width="68"
		width="180" :mini-variant.sync="drawerIsMinimized"
	>
		<v-container class="Sidebar-header pr-4 pl-4 mb-5 pt-4 text-center">
			<router-link class="text-decoration-none" to="/profile/accounting-template">
				<v-avatar size="auto">
					<img class="ProfileImage" :src="getUserData.avatar">
				</v-avatar>
				<div v-if="!drawerIsMinimized" class="text-center mt-2">
					<span class="ProfileLink text-subtitle-2">
						{{ `${getUserData.name} (${getUserData.login})` }}
					</span>
				</div>
			</router-link>
		</v-container>
		<v-container class="Sidebar-body pa-0 d-flex align-start">
			<v-list class="pa-0" dense>
				<v-list-item-group
					color="accent"
				>
					<v-list-item v-for="item in items" :key="`${item.path}-${currentPath}`" :class="getListClass(item)" :to="item.path">
						<v-list-item-icon class="mr-0">
							<v-icon>
								{{ item.icon }}
							</v-icon>
							<v-badge v-if="item.icon === 'mdi-email' && unreadMailCount > 0" dot overlap color="red" />
						</v-list-item-icon>

						<v-list-item-content class="pa-0">
							<v-list-item-title class="ml-3 text-subtitle-2" style="position: relative; top: 1px;">
								{{ item.title }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-container>

		<v-container class="Sidebar-footer pa-0 d-flex align-end">
			<v-list style="flex: 0 0 100%;" flat>
				<v-list-item-group>
					<v-list-item v-for="item in bottomItems" :key="item.title" class="justify-start pl-5 mb-3" @click="item.action">
						<v-list-item-icon class="mr-0">
							<v-icon>
								{{ item.icon }}
							</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title class="ml-3 text-subtitle-2" style="position: relative; top: 1px;">
								{{ item.title }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-container>

		<v-divider v-if="showMetaAction" />

		<v-container v-if="showMetaAction" class="Sidebar-metaActions pa-0 py-2">
			<v-row no-gutters>
				<v-col cols="6" class="d-flex align-center justify-center">
					<v-btn v-tooltip color="accent" small
						plain
						:content="$vuetify.theme.dark ? 'Aktivera ljust tema' : 'Aktivera mörkt tema'"
						@click="darkMode"
					>
						<v-icon>{{ !$vuetify.theme.dark ? 'mdi-moon-waxing-crescent' : 'mdi-white-balance-sunny' }}</v-icon>
					</v-btn>
				</v-col>


				<v-col cols="6" class="d-flex align-center justify-center">
					<v-btn v-tooltip color="accent" small
						plain
						:content="drawerIsLocked ? 'Minimera sidomeny': 'Sätt meny expanderad'"
						@click="lockSidebar"
					>
						<v-icon>{{ drawerIsLocked ? 'mdi-arrow-expand-left': 'mdi-arrow-expand-right' }}</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-navigation-drawer>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';

// const UNREAD_MAIL_REFETCH_TIME = 60000;
const getSidebarLockFromLS = () => {
	const value = localStorage.getItem('kfp-sidebarlock');

	if (!value) {
		return false;
	}

	return JSON.parse(value);
};

export default {
	name: 'Sidebar',

	data: () => ({
		drawerIsMinimized: !getSidebarLockFromLS(),

		drawerIsLocked: getSidebarLockFromLS(),

		unreadMailInterval: undefined,
		unreadMailCount: 0,

		currentPath: ''
	}),

	computed: {
		...mapGetters('userStore', ['getUserData', 'isUserMasqed', 'isUserAllowedToMasquerade', 'isUserAllowedToInvoiceHandle', 'isUserAllowedToLogView', 'isUserAllowedToSettingsEdit']),

		showMetaAction() {
			return !this.drawerIsMinimized || this.drawerIsLocked;
		},

		items() {

			return [ this.isUserAllowedToLogView && {
				title: this.$t('navigation.dashboard'),
				path: '/',
				icon: 'mdi-view-dashboard',
				count: 0,
				isActive: this.currentPath === '/'
			}, {


				title: this.$t('navigation.inbox'),
				path: '/inbox/todo',
				icon: 'mdi-email',
				count: this.unreadMailCount,
				isActive: this.currentPath.includes('/inbox')
			}, {
				title: this.$t('navigation.invoices'),
				path: '/invoices/all',
				icon: 'mdi-book-variant',
				count: 0,
				isActive: this.currentPath.includes('/invoices')
			}, {
				title: 'Betalningar',
				path: '/payments/awaiting-payments',
				icon: 'mdi-cash-100',
				count: 0,
				isActive: this.currentPath.includes('/payments')
			}, {
				title: 'Administration',
				path: '/administration/users',
				icon: 'mdi-police-badge',
				count: 0,
				isActive: this.currentPath.includes('/administration')
			}, {
				title: 'Loggar',
				path: '/logs',
				icon: 'mdi-text-box-multiple',
				count: 0,
				isActive: this.currentPath.includes('/logs')
			}, {
				title: 'Register',
				path: '/register/suppliers',
				icon: 'mdi-book-open',
				count: 0,
				isActive: this.currentPath.includes('/register')
			}, this.isUserAllowedToSettingsEdit && {
				title: this.$t('navigation.settings'),
				path: '/settings/system',
				icon: 'mdi-cog',
				count: 0,
				isActive: this.currentPath.includes('/settings')
			}].filter(Boolean);

		},

		bottomItems() {
			return [this.isUserMasqed && {
				action: this.onLogoutClick,
				title: this.$t('navigation.unmasq'),
				icon: 'mdi-incognito-off'
			}, {
				action: this.onLogoutClick,
				title: this.$t('navigation.logout'),
				icon: 'mdi-logout'
			}].filter(Boolean);
		}
	},

	watch: {
		$route(to) {
			this.currentPath = to.path;
		}
	},

	created() {
		this.currentPath = this.$route.path;
		this.fetchTodos();
	},

	beforeDestroy() {
		clearInterval(this.unreadMailInterval);
	},

	methods: {
		getListClass(item) {
			return {
				'justify-start': true,
				'pl-5': true,
				'mb-3': true,
				'v-item--active': item.isActive,
				'v-list-item--active': item.isActive
			};
		},

		async fetchTodos() {
			let todos = await this.$service.todo.count({
			});

			this.unreadMailCount = todos.unread;
		},

		onLogoutClick() {
			this.$router.push({name: 'Logout'});
		},

		darkMode() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			localStorage.setItem('kfp-dark', this.$vuetify.theme.dark);
		},

		lockSidebar() {
			this.drawerIsLocked = !this.drawerIsLocked;
			localStorage.setItem('kfp-sidebarlock', this.drawerIsLocked);
			Vue.nextTick(() => {
				this.$refs.drawer.$el.click();
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.Sidebar-header {
	height: 155px;
}

.Sidebar-body {
	height: calc(100vh - 155px - 160px - 61px);
	overflow-y: auto;
}

.Sidebar-footer {
	height: 160px;
}

.Sidebar-metaActions {
	height: 40px;
}

.ProfileImage {
	max-width: 100%;
}

.ProfileLink {
	text-decoration: none;
}
</style>
