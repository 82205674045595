import {emptyUser} from './';
import {SET_USER, SET_MASQUERADE, REMOVE_MASQUERADE} from '../mutation-types';
import services from '@/services';
import {run} from '@/utils/service';

export default {
	setUser({commit}, payload) {
		commit('SET_USER', payload);
	},

	async loginSaml({ commit }, body) {
		return run(await services.token.loginSaml.call({ body }, {
			withCredentials: true
		}), data => commit(SET_USER, data));
	},

	logout({commit}) {
		commit(SET_USER, emptyUser());
		commit(REMOVE_MASQUERADE);
	},

	async logoutMasq({commit}) {
		run(await services.token.masquerade.call({ as: 'cancel' }), data => {
			commit(SET_USER, data);
			commit(REMOVE_MASQUERADE);
			setTimeout(() => {
				window.location.reload();
			}, 100);
		});
	},

	async setMasquerade({commit}, as) {
		return run(await services.token.masquerade.call({ as }), data => {
			commit(SET_MASQUERADE, data);
			window.location.reload();
		});
	},

	async revokeToken({commit}) {
		return run(await services.token.revoke.call(), () => commit(SET_USER, emptyUser()));
	},

	async refreshToken({commit}) {
		return run(await services.token.refresh.call(), data => commit(SET_USER, data));
	}
};
