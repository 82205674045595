<template>
	<Dialog title="Signera Attestering"
		width="1200"
		ok-btn-text="Signera"
		:loading="signIsPending"
		:disable-ok-btn="getAttests.length === 0"
		@reject="$emit('reject')"
		@confirm="sign"
	>
		<div>
			<v-tabs v-model="tab">
				<v-tab>Attestera</v-tab>
				<v-tab>Bokföring</v-tab>
			</v-tabs>


			<div v-if="tab === 0">
				<v-data-table
					class="GlobalAttestTable"
					:items="getAttests"
					:headers="headers"
					disable-pagination
					:expanded="expandedRows"
					:items-per-page="-1"
					hide-default-footer
					disable-sort
				>
					<template #item.date="{item}">
						{{ $utils.date.getFullDate($utils.date.intToDate(item.date)) }}
					</template>
					<template #item.amount="{item}">
						{{ $utils.format.price(item.amount) }}
					</template>
					<template #item.codes="{item}">
						<span class="text-overline">
							{{ item.codes[0] }} - {{ item.codes[1] }} - {{ item.codes[2] }} - {{
								item.codes[3]
							}} - {{ item.codes[4] }} - {{ item.codes[5] }} - {{ item.codes[6] }} - {{
								item.codes[7] }}
						</span>
					</template>
					<template #item.type="{item}">
						<span v-if="item.isApproval">Godkännare</span>
						<span v-else-if="item.isAuthorize">Beslutare</span>
						<span v-else>-</span>
					</template>
					<template #item.remove="{item}">
						<v-btn icon @click="removeItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
					</template>

					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length">
							<Loader :error="getErrorMessage(item)" />
						</td>
					</template>
				</v-data-table>
			</div>


			<div v-if="tab === 1">
				n/a
			</div>
			<v-form ref="form">
				<div class="d-flex justify-end mt-6">
					<ui-text-field v-model="password" type="password" label="Lösenord" :rules="$validator('base/required')" required />
				</div>
			</v-form>
			<Loader :error="responseError" slim />
		</div>
	</Dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	props: {

	},

	data: () => ({
		tab: 0,
		password: '',
		responseError: '',
		signIsPending: false,
		rowErrors: [],

		headers: [{
			text: 'Dokumentnr',
			value: 'invoiceNumber'
		}, {
			text: 'Leverantör',
			value: 'sellerName'
		}, {
			text: 'Koddelar',
			value: 'codes'
		}, {
			text: 'Belopp',
			align: 'right',
			value: 'amount'
		}, {
			text: 'Datum',
			value: 'date'
		}, {
			text: 'Typ',
			value: 'type'
		}, {
			text: '',
			value: 'remove',
			sortable: false
		}]
	}),

	computed: {
		...mapGetters('globalAttest', ['getAttests']),

		expandedRows() {
			return this.getAttests.reduce((acc, curr, index) => {
				const error = this.rowErrors.find(r => r.id === curr.attestId);

				return error ? [...acc, index] : acc;
			}, []);
		}
	},

	methods: {
		...mapActions('globalAttest', ['removeRows']),

		getErrorMessage(item) {
			const error = this.rowErrors.find(r => r.id === item.attestId);

			return error ? error.message : null;
		},

		async sign() {
			this.responseError = '';
			this.rowErrors = [];

			try {
				if (!this.$refs.form.validate()) {
					return;
				}

				const data = await this.$service.accounting.signRows({
					password: this.password,
					ids: this.getAttests.map(x => x.attestId)
				}, {
					throwException: true,
					loading: val => {
						this.signIsPending = val;
					}
				});

				this.password = '';

				this.removeRows(data.success);
				this.rowErrors = data.error;
			} catch (error) {
				this.responseError = error.message;
			}
		},

		removeItem(item) {
			this.removeRows([item.attestId]);
		}
	}
};
</script>

<style lang="scss">
	.GlobalAttestTable {
		.v-data-table__expanded__content {
			box-shadow: none !important;
		}
	}
</style>
