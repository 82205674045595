<template>
	<div>
		<v-dialog
			v-if="isDialog"
			v-model="isOpen"
			:max-width="width"
			:width="width"
			:retain-focus="false"
			:hide-overlay="hideOverlay"
			scrollable
			@click:outside="reject"
		>
			<div
				ref="dialogWrapper"
				class="draggable-dialog"
				:style="dialogStyle"
			>
				<transition :duration="150">
					<v-card class="dialog-card">
						<v-card-title class="dialog-header primary white--text" @mousedown="startDrag"
							@touchstart="startDrag"
						>
							<h3 class="headline">{{ title }}</h3>
							<v-spacer />
							<v-btn icon color="white" @click="reject">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text :class="paddingText">
							<!-- eslint-disable-next-line vue/no-v-html -->
							<div class="dialog-content" v-html="text" />
							<slot />
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn
								v-if="showCancelBtn"
								color="warning"
								text
								:disabled="loading"
								@click.stop="reject"
							>
								{{ rejectBtn }}
							</v-btn>
							<v-btn
								v-if="!alert"
								color="primary"
								:loading="loading"
								text
								:disabled="disableOkBtn"
								@click.stop="confirm"
							>
								{{ confirmBtn }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</transition>
			</div>
		</v-dialog>
		<div v-else>
			<transition :duration="150">
				<v-card flat>
					<v-card-title v-if="title">
						<h3 class="headline">{{ title }}</h3>
					</v-card-title>
					<v-card-text :class="{ 'py-12': title }">
						<!-- eslint-disable-next-line vue/no-v-html -->
						<div v-html="text" />
						<slot />
					</v-card-text>

					<v-divider />

					<v-card-actions>
						<v-spacer />
						<v-btn v-if="showCancelBtn" color="warning" text :disabled="loading" @click.stop="reject">
							{{ rejectBtn }}
						</v-btn>
						<v-btn v-if="!alert" color="primary" :loading="loading" text :disabled="disableOkBtn"
							@click.stop="confirm"
						>
							{{ confirmBtn }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</transition>
		</div>
	</div>
</template>

<script>
export default {

	props: {
		isDialog: {
			type: Boolean,
			default: true
		},

		title: {
			type: String,
			default: ''
		},

		hideOverlay: {
			type: Boolean,
			default: false
		},

		alert: {
			type: Boolean,
			default: false
		},

		width: {
			type: String,
			default: '640'
		},

		paddingText: {
			type: String,
			default: 'py-12'
		},

		text: {
			type: String,
			default: ''
		},

		cancelBtnText: {
			type: String,
			default: ''
		},

		showCancelBtn: {
			type: Boolean,
			default: true
		},

		okBtnText: {
			type: String,
			default: ''
		},

		disableOkBtn: {
			type: Boolean,
			default: false
		},

		loading: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		isOpen: true,
		isDragging: false,
		isResizing: false,
		dragStartX: 0,
		dragStartY: 0,
		resizeStartX: 0,
		resizeStartY: 0,
		dialogPosition: [0, 0],
		dialogSize: {
			width: 400
		}
	}),

	computed: {
		dialogStyle() {
			return {
				top: this.dialogPosition[1] + 'px',
				left: this.dialogPosition[0] + 'px',
				width: this.dialogSize.width + 'px'
			};
		}
	},

	mounted() {
		if(this.isDialog) {
			this.centerDialog();
		}
	},

	created() {
		this.confirmBtn = this.okBtnText || this.$t('base.save');
		this.rejectBtn = this.alert ? 'Ok' : (this.cancelBtnText || this.$t('base.cancel'));
		this.dialogSize = {
			width: this.width
		};
	},

	methods: {
		centerDialog() {
			const screenWidth = window.innerWidth;
			const screenHeight = window.innerHeight;
			const dialogWidth = this.$refs.dialogWrapper.offsetWidth;
			const dialogHeight = this.$refs.dialogWrapper.offsetHeight;
			const centerX = (screenWidth - dialogWidth) / 2;
			const centerY = (screenHeight - dialogHeight) / 2;

			this.dialogPosition = [centerX, centerY];
		},

		startDrag(event) {
			if (event.type === 'touchstart') {
				event.preventDefault();
				const touch = event.touches[0];

				this.dragStartX = touch.clientX;
				this.dragStartY = touch.clientY;
			} else {
				this.dragStartX = event.clientX;
				this.dragStartY = event.clientY;
			}

			this.isDragging = true;

			window.addEventListener('mousemove', this.handleDrag);
			window.addEventListener('touchmove', this.handleDrag);
			window.addEventListener('mouseup', this.stopDrag);
			window.addEventListener('touchend', this.stopDrag);
		},

		handleDrag(event) {
			if (!this.isDragging) return;

			const currentX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
			const currentY = event.type === 'touchmove' ? event.touches[0].clientY : event.clientY;
			const offsetX = currentX - this.dragStartX;
			const offsetY = currentY - this.dragStartY;

			this.dialogPosition = [
				this.dialogPosition[0] + offsetX,
				this.dialogPosition[1] + offsetY
			];
			this.dragStartX = currentX;
			this.dragStartY = currentY;
		},

		stopDrag() {
			this.isDragging = false;

			window.removeEventListener('mousemove', this.handleDrag);
			window.removeEventListener('touchmove', this.handleDrag);
			window.removeEventListener('mouseup', this.stopDrag);
			window.removeEventListener('touchend', this.stopDrag);
		},

		confirm() {
			if (this.disableOkBtn) {
				return;
			}

			this.$emit('confirm');
		},

		reject() {
			this.$emit('reject');
		}
	}
};
</script>

<style>
.draggable-dialog {
  position: fixed;
  z-index: 1000;
  resize: both;
}

.dialog-header {
	cursor: move;
}

.dialog-card {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.dialog-content {
  margin-bottom: 16px;
}
</style>
