<template>
	<div>
		<div class="d-flex mb-6 align-center">
			<div class="text-caption">
				Nuvarande värde: <strong>{{ $utils.dotProp(localInvoice, 'meta.account.AccountNumber', '') }} ({{ $utils.dotProp(localInvoice, 'meta.account.Scheme', '') }})</strong>
			</div>
			<v-spacer />
			<v-btn color="primary" small @click="newDialogOpen = true"><v-icon left>mdi-plus</v-icon>Nytt Giro</v-btn>
		</div>
		<ui-select v-if="supplier" v-model="newGiro" :items="supplier.giro" item-value="number" return-object
			label="Välj Giro"
		>
			<template #selection="{ item }">
				<span class="text-caption">{{ item.number }} ({{ item.bic }})</span>
			</template>
			<template #item="{item}">
				<span class="text-caption">{{ item.number }} ({{ item.bic }})</span>
			</template>
		</ui-select>
		<div v-if="newGiro">
			<p class="text-caption">
				Vill du uppdatera till giro <strong class="primary--text">{{ newGiro.number
				}} ({{ newGiro.bic }})</strong>?
			</p>
			<v-btn color="success" text small @click="updateInvoice">Ja, uppdatera</v-btn>
		</div>

		<ui-movable-dialog v-if="newDialogOpen" title="Nytt Giro" @reject="newDialogOpen = false" @confirm="addGiro">
			<GiroForm v-model="giroToAdd" />
		</ui-movable-dialog>


		<div class="d-flex align-center justify-end">
			<v-btn class="ml-2" color="warning" small
				@click.stop="openConfirmDialog"
			>
				Godkänn med fel
			</v-btn>
		</div>
	</div>
</template>

<script>
import GiroForm from '@/views/register/children/suppliers/components/giro-form';

export default {
	components: {
		GiroForm
	},

	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		newGiro: null,
		newDialogOpen: false,
		localInvoice: null,
		giroToAdd: null,

		supplier: null
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
					this.newGiro = null;
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);

		this.fetchSupplier();
	},

	methods: {
		updateInvoice() {
			this.localInvoice.meta.account.AccountNumber = this.newGiro.number;
			this.localInvoice.meta.account.Scheme = this.newGiro.bic;

			this.$emit('on-invoice-update', this.localInvoice);
		},

		async addGiro() {
			await this.$service.suppliers.updateGiro(this.localInvoice.meta.supplier_id, {
				giro: [
					...this.supplier.giro,
					{
						number: this.giroToAdd.number,
						bic: this.giroToAdd.bic,
						active: this.giroToAdd.active
					}
				]
			}, {
				throwException: true,
				loading: val => {
					this.isGiroAddPending = val;
				}
			});

			this.newGiro = {...this.giroToAdd};
			this.newDialogOpen = false;

			this.fetchSupplier();
		},

		async fetchSupplier() {
			this.supplier = await this.$service.suppliers.get(this.localInvoice.meta.supplier_id);
		},

		async confirmFaultyIvoice(params) {
			if (!params.message) {
				throw 'Du måste skriva en kommentar.';
			}

			this.localInvoice.meta.actions.skip = [...this.localInvoice.meta.actions.skip, 'L04_SupplierGiroBlockedCheck'];
			this.localInvoice.meta.actions.skip_comment = [...this.localInvoice.meta.actions.skip_comment, `${params.message}`];
			await this.$emit('on-invoice-update', this.localInvoice);
		},

		openConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Godgänn felaktig faktura',
				text: 'Är du säker på att du vill godkänna fakturan med fel?',
				showMessage: true,
				confirmed: this.confirmFaultyIvoice
			});
		}
	}
};
</script>
