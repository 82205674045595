<template>
	<v-card elevation="0">
		<portal to="sidebar-right-title">
			{{ role.value }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-if="isUserAllowedToDeleteRole" v-tooltip class="ml-auto" fab text
					small
					content="Ta bort roll"
					@click="openDeleteConfirmDialog"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</div>
		</portal>

		<Loader :service="[
			$service.roles.deleteCapability,
			$service.roles.addCapability,
			$service.roles.get,
			$service.caps.get
		]"
		/>

		<v-expansion-panels v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Rättigheter
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<CapabilityList v-if="localRole.capabilities" :key="`${new Date().getTime()}`" class="RolesRegisterEdit-list" :source="localRole.capabilities" :add="addCapability"
						:remove="removeCapability"
					/>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel class="mt-5">
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Användare
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-list dense nav>
						<v-list-item-group color="primary">
							<v-list-item v-for="user in localRole.users" :key="user.id">
								<v-list-item-content>
									<v-list-item-title>{{ `${user.name} (${user.login})` }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-card>
</template>

<script>
import {run} from '@/utils/service';
import {mapGetters} from 'vuex';
import CapabilityList from '@/components/capability-list';

export default {
	components: {
		CapabilityList
	},

	props: {
		role: {
			type: Object,
			default: () => ({})
		}
	},

	data: () => ({
		panel: [0],
		caps: [],
		localRole: {}
	}),

	computed: {
		...mapGetters('userStore', ['isUserAllowedToDeleteRole'])
	},

	watch: {
		'role.name'(name) {
			this.fetchRole(name);
		}
	},

	created() {
		this.fetchRole(this.role.name);
	},

	methods: {
		openDeleteConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: this.$t('register.roles.delete_confirm_title'),
				text: this.$t('register.roles.delete_confirm_text', {name: this.role.value}),
				confirmed: this.deleteRole
			});
		},

		async deleteRole() {
			run(await this.$service.roles.delete.call({
				roleId: this.localRole.name
			}), () => {
				this.$emit('role-deleted');
			});
		},

		async removeCapability(capId) {
			run(await this.$service.roles.deleteCapability.call({
				roleId: this.localRole.name,
				capId
			}), () => {
				this.fetchRole(this.localRole.name);
			});
		},

		async addCapability(capId, grant) {
			run(await this.$service.roles.addCapability.call({
				roleId: this.localRole.name,
				capId,
				body: {
					grant
				}
			}), () => {
				this.fetchRole(this.localRole.name);
			});
		},

		async fetchRole(id) {
			run(await this.$service.roles.get.call({roleId: id}), data => {
				this.localRole = data;
			});
		}
	}
};
</script>

