<template>
	<div class="UiTextField" :class="{ 'UiSelect--clean': clean }">
		<div v-if="!noLabel" :class="getHeaderClasses">
			<label :for="localId" class="font-weight-medium text-subtitle-2" style="cursor: pointer;"><slot name="ui-label" />{{ label }}</label>
			<v-spacer />
			<span v-if="required" style="position: relative; top: 3px;" class="error--text text-subtitle-1 font-weight-medium">*</span>
			<span v-else class="text-subtitle-1 font-weight-medium" style="color: transparent">&nbsp;</span>
			<span v-if="subLabel" style="position: relative; top: 3px;" class="text-subtitle-2 font-weight-medium">{{ subLabel }}</span>
		</div>
		<v-file-input :id="localId" v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners">
			<template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
				<slot :name="scopedSlotName" v-bind="slotData" />
			</template>
			<template v-for="(_, slotName) in $slots" #[slotName]>
				<slot :name="slotName" />
			</template>
		</v-file-input>
	</div>
</template>

<script>
import {uid} from 'uid';

export default {
	inheritAttrs: false,

	props: {
		label: {
			type: String,
			default: ''
		},

		subLabel: {
			type: String,
			default: ''
		},

		value: {
			type: String,
			default: ''
		},

		required: {
			type: Boolean,
			default: false
		},

		noLabel: {
			type: Boolean,
			default: false
		},

		clean: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		localId: null
	}),

	computed: {
		getHeaderClasses() {
			return {
				'pr-8': 'append-outer-icon' in this.$attrs,
				'pl-8': 'prepend-icon' in this.$attrs
			};
		},

		commonAttrs() {
			return {
				label: '',
				accept: '',
				persistentHint: true,
				outlined: true,
				dense: true,
				hideDetails: false,
				required: this.$props.required,
				class: {
					'ui-text-field': true
				}
			};
		}
	},

	mounted() {
		this.localId = uid();
	}
};
</script>

