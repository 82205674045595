<template>
	<v-btn elevation="0" icon
		@click="$emit('click')"
	>
		<v-icon>mdi-file-document-outline</v-icon>
		<v-badge v-if="getAttests.length !== 0" color="red" :content="getAttests.length" />
	</v-btn>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {

	},

	computed: {
		...mapGetters('globalAttest', ['getAttests'])
	},

	created() {

	},

	methods: {}
};
</script>
