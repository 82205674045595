import * as XLSX from 'xlsx';

export function download(data, type, newIdName) {
	const worksheet = XLSX.utils.json_to_sheet(data);

	if (type !== 'Attest') {
		adjustColumnWidths(worksheet, newIdName);
	}

	const workbook = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

	const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
	const excelFile = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	const url = window.URL.createObjectURL(excelFile);
	const link = document.createElement('a');

	link.href = url;
	link.setAttribute('download', `${type}.xlsx`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	window.URL.revokeObjectURL(url);
}

function adjustColumnWidths(worksheet, newIdName) {
	const columns = Object.keys(worksheet);
	const columnWidths = [];

	for (let i = 0; i < columns.length; i++) {
		const column = columns[i];
		let maxWidth = 10;

		for (const row in worksheet) {
			if (row.startsWith(column)) {

				const cellValue = worksheet[row].v;

				if (cellValue && cellValue.toString().length > 0) {
					worksheet[row].v = cellValue.toString() === 'ID' && newIdName ? `${newIdName}` : cellValue.toString();
					maxWidth = cellValue.toString().length ;
				}
			}
		}

		const columnWidth = maxWidth > 20 ? 30 : maxWidth + 5;

		columnWidths.push({ wch: columnWidth });
	}

	worksheet['!cols'] = columnWidths;

}



