import validators from '@/validators';

export default (validatorName, opts = {}) => {

	if (typeof validatorName !== 'string') {
		console.error('Invalid input, only accept string as input');

		return [];
	}

	const input = validatorName.split('/');

	if (input.length !== 2) {
		console.error('wrong format, input needs to have validator and field.');

		return [];
	}

	const validator = input[0];
	const field = input[1];

	if (!(validator in validators) || !(field in validators[validator])) {
		console.error('No validator or feild fond from specified input.');

		return [];
	}

	return validators[validator][field](opts);
};
