import apiClient from '@/plugins/axios';

export default {

	search: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/codestrings/search', body, opts),

	create: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/codestrings', body, opts),

	update: (codestringId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/codestrings/${codestringId}`, body, opts)

};
