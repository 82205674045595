<template>
	<div class="d-flex" style="height: 100%">
		<iframe :srcdoc="attachment" style="width: 100%; height: 100%" />
	</div>
</template>

<script>
export default {
	props: {
		attachmentId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		attachment: null
	}),

	created() {
		this.fetchAttachment();
	},

	methods: {
		async fetchAttachment() {
			this.attachment = await this.$service.attachment.get(this.attachmentId);

		}
	}
};
</script>
