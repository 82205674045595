const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

export const orgNo = str => {
	if (typeof str !== 'string' || str.length === 0) {
		return str;
	}

	const end = str.substr(-4);
	const start = str.substring(0, str.length - 4);

	return `${start}-${end}`;
};

export const capitalize = str => {
	if (typeof str !== 'string' || str.length === 0) {
		return str;
	}

	const start = str.charAt(0);
	const end = str.substring(1, str.length);

	return `${start.toUpperCase()}${end.toLowerCase()}`;
};

export const zipcode = str => {
	if (typeof str !== 'string' || str.length === 0) {
		return str;
	}

	const end = str.substr(-2);
	const start = str.substring(0, str.length - 2);

	return `${start} ${end}`;
};

export const bankgiro = str => {
	if (typeof str !== 'string' || str.length === 0) {
		return str;
	}

	const start = str.length > 7 ? str.substring(0, 4) : str.substring(0, 3) ;

	return `${start}-${str.substring(str.length - 4)}`;
};

export const plusgiro = str => {
	if (typeof str !== 'string' || str.length === 0) {
		return str;
	}

	const start = str.substring(0, str.length - 1);

	return `${start}-${str.substring(str.length - 1)}`;
};

export const giro =(bic , str) => {
	if (typeof str !== 'string' || str.length === 0) {
		return str;
	}

	if(/^\d/.test(bic)) {
		return str;
	}

	if(bic.startsWith('BG')) {
		return bankgiro(str);
	}

	if(bic.startsWith('PG')) {
		return plusgiro(str);
	}
};

export const account = accObj => {
	if (!accObj || !accObj.Scheme) {
		return '';
	}

	const val = accObj.AccountNumber;

	if (accObj.Scheme === 'BGABSESS') {
		const index = val.length - 4;

		return `${val.substr(0, index)}-${val.substr(index)} (BG)`;
	}

	if (accObj.Scheme === 'PGSISESS') {
		const index = val.length - 1;

		return `${val.substr(0, index)}-${val.substr(index)} (PG)`;
	}

	return val;
};

export const price = str => {
	const addNegative = str => isNegative ? `-${str}` : str;
	let price = String(Number(str).toFixed(2));
	let isNegative = false;

	if (price.includes('-')) {
		isNegative = true;
		price = price.replace('-', '');
	}

	switch (price.length) {
	case 7: return addNegative(insertAt(price, ' ', 1));
	case 8: return addNegative(insertAt(price, ' ', 2));
	case 9: return addNegative(insertAt(price, ' ', 3));
	case 10: return addNegative(insertAt(insertAt(price, ' ', 1), ' ', 5));
	default: return addNegative(price);
	}
};

export const seperateString = (str, seperator) => {
	let newStr = '';

	newStr = str.replace((/(?:\r\n|\r|\n)/g), seperator);

	return newStr;
};


export const onlyNumbers = (str) => {
	return  str.replace(/[^0-9]/g, '');
};
