import apiClient from '@/plugins/axios';

export default {
	search: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/codelists/search', body, opts),

	searchList: (body = {}, opts = {}) => apiClient.post('/wp-json/kp/v1/codelist/search', body, opts),

	searchId: (codeId, body = {}, opts = {}) => apiClient.post(`/wp-json/kp/v1/codelist/${codeId}/search`, body, opts),

	create: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/codelists', body, opts),

	updateCode: (codeId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/codelist/requirement/${codeId}`, body, opts)
};
