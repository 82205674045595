import apiClient from '@/plugins/axios';

export default {
	get: (attachmentId, opts = {}) => apiClient.get(`/wp-json/kp/v1/attachments/${attachmentId}`, opts),

	getTypes: (opts = {}) => apiClient.get('/wp-json/kp/v1/attachments/types', opts),

	listTypes: (opts = {}) => apiClient.get('/wp-json/kp/v1/attachments/types', opts),

	createType: (opts = {}) => apiClient.post('/wp-json/kp/v1/attachments/types', opts),

	updateType: (attachmentId, opts = {}) => apiClient.put(`/wp-json/kp/v1/attachments/types/${attachmentId}`, opts),

	deleteType: (attachmentId, opts = {}) => apiClient.delete(`/wp-json/kp/v1/attachments/types/${attachmentId}`, opts)
};
