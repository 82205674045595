<template>
	<div>
		<v-row>
			<v-col cols="4">
				<Loader :error="errorMessage" :loading="isPending" />

				<v-card class="px-8 py-4">
					<div class="d-flex align-center justify-space-between pb-6">
						<h3>Organisation</h3>
					</div>

					<div class="d-flex align-center justify-space-between">
						<div>Organisationskod</div>
						<ui-text-field v-model="orgNr.value" />
					</div>

					<div class="text-right mt-6">
						<v-btn text color="primary" :loading="isPending" @click="onSave"> Spara </v-btn>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	data: () => ({
		group: 0,
		isPending: false,
		errorMessage: '',
		orgNr: ''
	}),

	computed: {
		...mapGetters('accounting', [ 'getCodeNames', 'getIsActiveCode']),
		...mapGetters('userStore', [ 'getUser', 'getUserGroups']),
		...mapGetters('sitesStore', ['getSites'])
	},

	async created() {
		await this.fetchSettings();
	},

	methods: {
		async fetchSettings() {
			try {
				const settings =  await this.$service.settings.get('kSiteCode', 0, {
					loading: val => {
						this.isPending = val;
					}
				});

				this.orgNr = settings;
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async onSave() {
			try {
				await this.$service.settings.updateSingle('kSiteCode',{
					...this.orgNr
				}, {
					loading: val => {
						this.isPending = val;
					}
				});

			} catch (error) {
				this.errorMessage = error.message;
			}
			await this.fetchSettings();
		}
	}
};
</script>
