import apiClient from '@/plugins/axios';

export default {
	listPeriods: (opts = {}) => apiClient.get('/wp-json/kp/v1/bookings/periods', opts),

	createPeriod: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/bookings/periods', body, opts),

	updatePeriod: (periodID, opts = {}) => apiClient.put(`/wp-json/kp/v1/bookings/periods/${periodID}`, opts),

	deletePeriod: (periodID, opts = {}) => apiClient.delete(`/wp-json/kp/v1/bookings/periods/${periodID}`, opts),

	search: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/bookings/search', body, opts),

	searchPeriod: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/bookings/periods/search', body, opts),

	distinct: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/bookings/distinct_invoices', body, opts),

	resend: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/bookings/resend', body, opts)

};
