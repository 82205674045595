<template>
	<Dialog
		max-width="640"
		width="640"
		title="Ny Användare"
		padding-text="py-5"
		:loading="isAddPending"
		@reject="reject"
		@confirm="confirm"
	>
		<div>
			<v-tabs v-model="tab" class="mb-2">
				<v-tab>Intern</v-tab>
				<v-tab>Extern</v-tab>
			</v-tabs>

			<v-form v-if="tab === 0" ref="form">
				<ui-text-field v-model="internUsername" :label="$t('base.username')" :rules="$validator('user/username')" />
			</v-form>

			<v-form v-if="tab === 1" ref="form">
				<v-row>
					<v-col cols="6">
						<ui-text-field v-model="formData.username" :label="$t('base.username')" :rules="$validator('user/username')" />
					</v-col>
					<v-col cols="6">
						<ui-text-field v-model="formData.email" :label="$t('base.email')" :rules="$validator('user/email')" />
					</v-col>
					<v-col cols="6">
						<ui-text-field v-model="formData.name" :label="$t('base.name')" :rules="$validator('user/name')" />
					</v-col>
					<v-col cols="6" />

					<v-col cols="6">
						<ui-text-field v-model="formData.password" :label="$t('base.password')" :rules="$validator('user/password')"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
							@click:append="showPassword = !showPassword"
						/>
					</v-col>
					<v-col cols="6">
						<ui-text-field v-model="passwordRepeat" label="Upprepa lösenord"
							:rules="$validator('user/passwordRepeat', {password: formData.password, label: $t('base.password')})"
							:type="showPassword ? 'text' : 'password'"
						/>
					</v-col>
				</v-row>
			</v-form>
		</div>

		<Loader :error="error" />
	</Dialog>
</template>

<script>
import utils from '@/utils';

export default {
	data: () => ({
		tab: 0,
		showPassword: false,

		formData: {
			username: '',
			password: '',
			name: '',
			email: ''
		},

		internUsername: '',

		passwordRepeat: '',

		error: '',
		isAddPending: false
	}),

	methods: {
		passwordRepeatRule(input) {
			return utils.validation.validate(input).required().match(this.formData.password, this.$service.users.add.fields.password.label).test();
		},

		reject() {
			this.$emit('close');
		},

		async confirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			if(this.tab === 0) {
				try {
					const data = await this.$service.users.addIntern(this.internUsername, {},{
						loading: val => {
							this.isAddPending = val;
						},
						throwException: true
					});

					this.$emit('close');
					this.$emit('user-created', data);
				} catch (error) {
					this.error = error.message;
				}

				return;
			}

			try {
				const data = await this.$service.users.add(this.formData, {
					loading: val => {
						this.isAddPending = val;
					},
					throwException: true
				});

				this.$emit('close');
				this.$emit('user-created', data);
			} catch (error) {
				this.error = error.message;
			}
		}
	}
};
</script>
