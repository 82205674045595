<template>
	<div>
		<portal to="sidebar-right-title">
			{{ isNewSupplier ? 'Ny Leverantör' : supplier.name }}
			<span class="text-caption pl-2">{{ $utils.format.orgNo(supplier.orgNumber) }}</span>
		</portal>

		<portal to="sidebar-right-actions">
			<div v-if="isUserAllowedToSupplierEdit" class="d-flex align-center" style="height: 100%;">
				<v-btn v-if="invoiceId" v-tooltip fab text small
					content="Gå tillbaka till faktura"
					@click="$router.push({ path: '/invoices/all', query: {
						supplierInvoice: invoiceId
					}})"
				>
					<v-icon>mdi-arrow-u-left-top-bold</v-icon>
				</v-btn>
				<v-btn v-tooltip fab text small content="Reskontra"
					:disabled="isNewSupplier"
					@click="showLedgerPreview = true"
				>
					<v-icon>mdi-file-search</v-icon>
				</v-btn>
				<v-btn v-tooltip fab text small
					content="Lägg till ny betalningsmetod"
					:disabled="isNewSupplier"
					@click="showGiroAdd = true"
				>
					<v-icon>mdi-bank</v-icon>
				</v-btn>
				<v-btn v-tooltip fab text small
					content="Ändra"
					:disabled="isNewSupplier"
					@click="editSupplier"
				>
					<v-icon>mdi-pen</v-icon>
				</v-btn>

				<v-btn v-if="!supplier.deleted" v-tooltip fab text small
					content="Arkivera"
					:disabled="isNewSupplier"
					@click="openSupplierDeleteConfirmDialog"
				>
					<v-icon>mdi-package-down</v-icon>
				</v-btn>

				<v-btn v-else v-tooltip fab text small
					content="Avarkivera"
					:disabled="isNewSupplier"
					@click="openSupplierUndeleteDialog"
				>
					<v-icon>mdi-package-down</v-icon>
				</v-btn>
			</div>
		</portal>

		<v-alert v-if="'active' in supplier && !supplier.active" type="warning" text outlined dense><strong>Spärrad:</strong> {{ supplier.inactive_reason }}</v-alert>
		<v-alert v-if="supplier.fraud" type="error" text outlined dense>
			Leverantören är flaggad som bluffbolag
		</v-alert>

		<v-expansion-panels v-if="!isNewSupplier" v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Information
					<span v-if="supplier.creation_date" class="d-flex justify-end text-none text-caption">
						{{ `Skapad ${$utils.date.intToPretty(supplier.creation_date)}` }}
					</span>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-row>
						<v-col cols="4">
							<div class="text-caption">Blufföretag</div>
							<div :class="`text-subtitle-2 fraud-level-${supplier.fraud}`">{{ supplier.fraud ? 'Ja' : 'Nej' }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">Bluffnivå</div>
							<div :class="`text-subtitle-2 fraud-level-${supplier.fraudLevel}`">{{ supplier.fraudLevel ? $utils.format.capitalize($t(`color.${supplier.fraudLevel}`)) : '-' }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">Registreringsdatum</div>
							<div class="text-subtitle-2">{{ supplier.registrationDate ? $utils.date.getISODate(supplier.registrationDate) : '-' }}</div>
						</v-col>

						<v-col cols="4">
							<div class="text-caption">Momsregistrerad</div>
							<div class="text-subtitle-2">{{ supplier.vatRegistered ? 'Ja' : 'Nej' }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">F-Skatt</div>
							<div class="text-subtitle-2">{{ supplier.fvatRegistered ? 'Ja' : 'Nej' }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">Momstyp</div>
							<div class="text-subtitle-2">{{ supplier.vat_type ? $t(`vat.${supplier.vat_type}`) : '-' }}</div>
						</v-col>

						<v-col cols="4">
							<div class="text-caption">Momsregistrering</div>
							<div class="text-subtitle-2">{{ supplier.vatNo ? `${supplier.vatNo}` : '-' }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">Organisationsnr</div>
							<div class="text-subtitle-2">{{ $utils.format.orgNo(supplier.orgNumber) }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">Aktiv</div>
							<div class="text-subtitle-2">{{ supplier.active ? 'Ja' : 'Nej' }}</div>
						</v-col>

						<v-col cols="4">
							<div class="text-caption">Antal betaldagar</div>
							<div class="text-subtitle-2">{{ supplier.duedays }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">Betalningsfrist</div>
							<div class="text-subtitle-2">{{ supplier.max_duedays ? `${supplier.max_duedays}` : '-' }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">Sekretessnivå</div>
							<div class="text-subtitle-2">{{ supplier.sensitivity ? $t(`privacy_level.${privacyLevel[supplier.sensitivity]}`) : '-' }}</div>
						</v-col>

						<v-col cols="4">
							<div class="text-caption">Matchlängd referenskod</div>
							<div class="text-subtitle-2">{{ supplier.match_length ? supplier.match_length : '-' }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">Referenskod</div>
							<div class="text-subtitle-2">{{ refNumber ? refNumber : '-' }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">{{ getCodeNames.H }}</div>
							<div class="text-subtitle-2">{{ supplier.counterpart ? getCounterpart('H', supplier.counterpart) : '-' }}</div>
						</v-col>
						<v-col cols="4">
							<div class="text-caption">Tillåten totalbeloppsavvikelse</div>
							<div class="text-subtitle-2">{{ supplier.acceptable_amount_diff ? supplier.acceptable_amount_diff : '-' }}</div>
						</v-col>
					</v-row>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Adressinformation
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-row>
						<v-col cols="4" class="pb-0">
							<div class="text-caption">Adress</div>
							<div class="text-subtitle-2">{{ supplier.address || '-' }}</div>
						</v-col>
						<v-col cols="4" class="pb-0">
							<div class="text-caption">Postnr</div>
							<div class="text-subtitle-2">{{ $utils.format.zipcode(supplier.zipCode) }}</div>
						</v-col>
						<v-col cols="4" class="pb-0">
							<div class="text-caption">Stad</div>
							<div class="text-subtitle-2">{{ $utils.format.capitalize(supplier.town) }}</div>
						</v-col>

						<v-col cols="4" class="">
							<div class="text-caption">Kommun</div>
							<div class="text-subtitle-2">{{ $utils.format.capitalize(supplier.municipality) }}</div>
						</v-col>
						<v-col cols="4" class="">
							<div class="text-caption">Län</div>
							<div class="text-subtitle-2">{{ $utils.format.capitalize(supplier.county) }}</div>
						</v-col>
						<v-col cols="4" class="">
							<div class="text-caption">Telefonnr</div>
							<div class="text-subtitle-2">{{ $utils.format.capitalize(supplier.phonenumber) }}</div>
						</v-col>

						<v-col cols="4" class="">
							<div class="text-caption">E-Post</div>
							<div class="text-subtitle-2">{{ supplier.email ? $utils.format.capitalize(supplier.email) : '-' }}</div>
						</v-col>
						<v-col cols="6" class="">
							<div class="text-caption">Hemsida</div>
							<div class="text-subtitle-2"><a :href="supplier.webaddress" target="_blank" rel="nofollow">{{ supplier.webaddress }}</a></div>
						</v-col>
						<v-col cols="4" class="">
							<div class="text-caption">Faxnr</div>
							<div class="text-subtitle-2">{{ supplier.faxnumber ? $utils.format.capitalize(supplier.faxnumber) : '-' }}</div>
						</v-col>

						<v-col cols="4" class="">
							<div class="text-caption">SNI</div>
							<div class="text-subtitle-2">{{ supplier.sni }}</div>
						</v-col>
					</v-row>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Betalningsmetoder
					<div class="d-flex justify-end align-center mr-5">
						<v-btn v-if="isUserAllowedToSupplierEdit" color="accent" small :disabled="isNewSupplier"
							@click="showGiroAdd = true"
						>
							<v-icon left>mdi-plus</v-icon>
							Ny betalmetod
						</v-btn>
					</div>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-list dense>
						<v-list-item>
							<v-list-item-content>
								<v-data-table :headers="headers"
									:items="supplier.giro"
									:hide-default-footer="true"
									@click:row="setGiroToAdd"
								>
									<template #item.number="{item}">
										<div>{{ $utils.format.giro(item.bic, item.number) }}</div>
									</template>

									<template #item.active="{item}">
										<div>{{ item.active ? 'Ja' : 'Nej' }}</div>
									</template>

									<template #item.actions="{item}">
										<div class="d-flex flex-row-reverse">
											<v-btn v-if="isUserAllowedToSupplierEdit" v-tooltip content="Ta bort" icon
												@click.stop="openGiroDeleteConfirmDialog(item)"
											>
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</div>
									</template>
								</v-data-table>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<Loader :error="updateError" :success="updateFeedbackMessage" />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Krediter
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-list dense>
						<v-list-item>
							<v-list-item-content>
								<v-data-table :headers="creditHeaders"
									:items="credits"
									:hide-default-footer="true"
								/>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<supplier-new v-if="isNewSupplier" :is-dialog="false" />

		<ledger-dialog
			v-if="showLedgerPreview"
			:supplier-id="supplier._id"
			@close="showLedgerPreview = false"
		/>

		<Dialog v-if="showEditDialog"
			title="Uppdatera Leverantör"
			width="1024px"
			:loading="isUpdateSupplierPending"
			@reject="rejectEditDialog"
			@confirm="updateSupplier"
		>
			<loader v-if="updateSupplierError" :error="updateSupplierError" />
			<supplier-form ref="form" v-model="supplierToEdit" :is-new="false" />
		</Dialog>

		<Dialog v-if="showGiroAdd" title="Betalningsmetod" width="640px" :loading="isGiroAddPending"
			class="pa-0" @reject="showGiroAdd = false; giroToAdd = null" @confirm="addGiro"
		>
			<giro-form ref="form" v-model="giroToAdd" />
			<loader v-if="addGiroError" :error="addGiroError" />
		</Dialog>

		<invoice-preview v-if="invoicePreviewOpen" width="50%" default-view="html" :invoice-id="invoiceId" @on-close="invoicePreviewOpen = false" />
	</div>
</template>

<script>
import { uid } from 'uid';
import supplierForm from './supplier-form';
import giroForm from './giro-form';
import supplierNew from './supplier-new';
import invoicePreview from '@/components/invoice-preview';
import ledgerDialog from './ledger-dialog.vue';
import {mapGetters} from 'vuex';

export default {
	components: {
		supplierForm,
		giroForm,
		invoicePreview,
		supplierNew,
		ledgerDialog
	},

	props: {
		supplierId: {
			type: String,
			required: true
		},

		invoiceId: {
			type: String,
			required: false,
			default: null
		}
	},

	data: () => ({
		panel: [0, 2],
		selectedGiro: null,
		supplier: {},
		credits: [],
		supplierToEdit: null,
		deleteSupplierError: null,
		updateFeedbackMessage: null,
		updateError: '',

		showEditDialog: false,
		isUpdateSupplierPending: false,
		updateSupplierError: '',
		undeleteSupplierError: '',

		showGiroAdd: false,
		isGiroAddPending: false,
		addGiroError: '',
		giroToAdd: null,

		showLedgerPreview: false,
		invoicePreviewOpen: false,

		isNewSupplier: false,

		refNumber: '',

		privacyLevel: ['none', 'printing', 'show'],

		headers: [
			{ text: 'BIC-Kod / Clearing #', value: 'bic', align: 'start'},
			{ text: 'Konto/Giro', value: 'number'},
			{ text: 'Aktiv', value: 'active'},
			{ text: '', value: 'actions', sortable: false}
		],

		creditHeaders: [
			{ text: 'Ursprungligt belopp', value: 'original_amount', align: 'right'},
			{ text: 'Reserverat belopp', value: 'reserved_amount', align: 'right'},
			{ text: 'Nuvarande belopp', value: 'available_amount', align: 'right'},
			{ text: 'Hänvisar till', value: 'refers_to'},
			{ text: '', value: 'actions', sortable: false}
		]
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames']),
		...mapGetters('userStore', ['isUserAllowedToSupplierEdit'])
	},

	watch: {
		async supplierId(to) {
			if (to !== 'new') {
				this.isNewSupplier = false;
			}

			await this.fetchSupplier();
			await this.fetchCredits();
		}
	},

	async created() {
		if (this.supplierId === 'new') {
			this.isNewSupplier = true;
		} else {
			await this.fetchSupplier();
			await this.fetchCredits();
		}

		if (this.invoiceId) {
			this.invoicePreviewOpen = true;
		}
	},

	methods: {
		async fetchRef() {
			this.refNumber = '';

			if (this.supplier.default_reference) {
				const data = await this.$service.references.get(this.supplier.default_reference);

				this.refNumber = data.reference;
			}
		},

		getCounterpart(type, value) {
			const code = this.getCodelist[type].find(x => x.intval === value);

			if (!code) {
				return '-';
			}

			return `${code.kod} - ${code.kodtext}`;
		},

		setGiroToAdd(item) {
			if (this.isUserAllowedToSupplierEdit === false) {
				return this.showGiroAdd = false;
			}

			this.giroToAdd = item;

			this.showGiroAdd = true;
		},

		editSupplier() {
			this.showEditDialog = true;
			this.supplierToEdit = {...this.supplier};
		},

		openSupplierUndeleteDialog() {
			this.$bus.emit('confirm:open', {
				headline: `Avarkivera ${this.supplier.name}`,
				text: 'Vill du aktivera denna leverantören igen?',
				error: this.undeleteSupplierError,
				confirmed: () => this.undeleteSupplier(this.supplier._id)
			});
		},

		rejectEditDialog() {
			this.updateSupplierError = '';
			this.showEditDialog = false;
		},

		openSupplierDeleteConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: `Arkivera ${this.supplier.name}`,
				text: `Säker på att du vill arkivera ${this.supplier.name}?`,
				error: this.deleteSupplierError,
				confirmed: () => this.deleteSupplier(this.supplier._id)
			});
		},

		openGiroDeleteConfirmDialog(giro) {
			this.$bus.emit('confirm:open', {
				headline: `Ta bort giro ${this.$utils.format.giro(giro.bic ,giro.number)}`,
				text: 'Säker på att du vill ta bort valt giro?',
				confirmed: () => this.deleteGiro(giro.id)
			});
		},

		async undeleteSupplier(id) {
			this.undeleteSupplierError = '';

			try {
				await this.$service.suppliers.undelete(id);

				this.$emit('supplier-updated');
			} catch (error) {
				this.undeleteSupplierError = error.message;
			}
		},

		async deleteSupplier(id) {
			this.deleteSupplierError = '';

			try {
				await this.$service.suppliers.delete(id);

				this.$emit('supplier-updated');
			} catch (error) {
				this.deleteSupplierError = error.message;
			}
		},

		async deleteGiro(id) {
			const giro = this.supplier.giro.filter(g => g.id !== id);

			try {
				await this.$service.suppliers.updateGiro(this.supplierId, {
					giro
				});
				this.updateFeedbackMessage = ['Giro borttagen'];
				this.fetchSupplier();
			} catch (error) {
				this.addGiroError = error.message;
			}
		},

		async addGiro() {
			let giro;

			this.addGiroError = '';
			this.updateFeedbackMessage = ['Giro tillagd'];

			if (!this.giroToAdd.id) {
				giro = [
					...this.supplier.giro,
					{
						id: uid(),
						...this.giroToAdd
					}
				];
			} else {
				giro = this.supplier.giro.map(giro => {
					if (giro.id === this.giroToAdd.id) {
						return this.giroToAdd;
					}

					return giro;
				});
			}

			try {
				await this.$service.suppliers.updateGiro(this.supplierId, {
					giro
				}, {
					throwException: true,
					loading: val => {
						this.isGiroAddPending = val;
					}
				});

				this.fetchSupplier();
				this.giroToAdd = null;
				this.showGiroAdd = false;
			} catch (error) {
				this.addGiroError = error.message;
			}
		},

		async updateSupplier() {
			this.updateSupplierError = '';

			if (!this.$refs.form.validate()) {
				return;
			}

			try {
				await this.$service.suppliers.update(this.supplierId, this.supplierToEdit, {
					throwException: true,
					loading: val => {
						this.isUpdateSupplierPending = val;
					}
				});

				this.fetchSupplier();

				this.$emit('supplier-updated');
				this.showEditDialog = false;
			} catch (error) {
				this.updateSupplierError = error.message;
			}
		},

		async fetchSupplier() {
			const data = await this.$service.suppliers.get(this.supplierId, {});

			this.supplier = {
				...data,
				giro: data.giro.map(g => ({
					id: uid(),
					...g
				}))
			};

			this.fetchRef();
		},

		async fetchCredits() {
			const data = await this.$service.suppliers.getCredits(this.supplierId, {});

			this.credits = data.map(c => ({
				...c,
				original_amount: this.$utils.format.price(c.original_amount),
				reserved_amount: this.$utils.format.price(c.reserved_amount),
				available_amount: this.$utils.format.price(c.available_amount)
			}));
		}
	}
};
</script>

<style lang="scss">
.fraud-level-green {
	color: var(--v-success-base);
}

.fraud-level-yellow {
	color: var(--v-warning-base);
}

.fraud-level-red {
	color: var(--v-error-base);
}

.v-application .py-12 {
    padding-bottom: 10px !important;
}
</style>
