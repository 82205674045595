<template>
	<Dialog title="Ny verifikationskod" @confirm="addCode" @reject="reject">
		<v-form ref="form" class="pt-6" :disabled="addRequestLoading">
			<v-row>
				<v-col cols="6">
					<v-menu v-model="formCodeFromMenu" :close-on-content-click="false" :nudge-right="40"
						transition="scale-transition" offset-y min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<ui-text-field v-model="formData.from"
								label="Från"
								:rules="$validator('group/vernoFrom')" v-bind="attrs" readonly
								required
								v-on="on"
							/>
						</template>
						<v-date-picker v-model="formData.from" @input="formCodeFromMenu = false" />
					</v-menu>
				</v-col>
				<v-col cols="6">
					<v-menu v-model="formCodeToMenu" :close-on-content-click="false" :nudge-right="40"
						transition="scale-transition" offset-y min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<ui-text-field v-model="formData.to" label="Till"
								:rules="$validator('group/vernoTo')"
								v-bind="attrs" readonly required
								v-on="on"
							/>
						</template>
						<v-date-picker v-model="formData.to" @input="formCodeToMenu = false" />
					</v-menu>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="6">
					<ui-select v-model="formData.type"
						:items="[{
							text: 'Standard',
							value: 'standard'
						}, {
							text: 'Manuell',
							value: 'manual'
						}, {
							text: 'Manuell betalning',
							value: 'manual_payment'
						}]"
						label="Typ"
					/>
				</v-col>
				<v-col cols="6">
					<ui-text-field v-model="formData.prefix" label="Prefix"
						:rules="$validator('group/vernoPrefix')"
						required
					/>
				</v-col>
				<v-col cols="6">
					<ui-text-field v-model="formData.min" label="Min"
						type="number" :rules="$validator('group/vernoMin')"
						required
					/>
				</v-col>
				<v-col cols="6">
					<ui-text-field v-model="formData.max" label="Max"
						type="number" :rules="$validator('group/vernoMax')"
						required
					/>
				</v-col>
			</v-row>

			<Loader :error="errorMessage" />
		</v-form>
	</Dialog>
</template>

<script>
export default {
	name: 'MyComponent',

	props: {
		groupId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		formData: {
			from: '',
			to: '',
			type: 'standard',
			prefix: '',
			min: 1,
			max: ''
		},

		formCodeFromMenu: false,
		formCodeToMenu: false,
		formCodeIsValid: true,

		addRequestLoading: false,

		errorMessage: ''
	}),

	methods: {
		reject() {
			this.$emit('on-close');
		},

		async addCode() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.errorMessage = '';

			try {
				await this.$service.groups.addVerification(this.groupId, {
					...this.formData,
					from: this.$utils.date.dateToInt(this.formData.from),
					to: this.$utils.date.dateToInt(this.formData.to)
				}, {
					throwException: true,
					loading: val => {
						this.addRequestLoading = val;
					}
				});

				this.$emit('on-code-add');
			} catch (error) {
				this.errorMessage = error.message;
			}
		}
	}
};
</script>
