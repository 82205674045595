import i18n from '@/plugins/i18n';
import Inbox from './inbox.vue';
import Conversations from './children/conversations/conversations';
import Todo from './children/todo/todo';

export default [{
	path: '/inbox',
	name: 'Inbox',
	component: Inbox,
	meta: {
		title: i18n.t('routes.titles.inbox')
	},

	children: [{
		path: 'conversations',
		component: Conversations,
		meta: {
			title: i18n.t('routes.titles.conversations')
		}
	}, {
		path: 'todo',
		component: Todo,
		meta: {
			title: i18n.t('routes.titles.todo')
		}
	}]
}];
