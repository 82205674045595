<template>
	<div>
		<ui-text-field
			v-model="accountingFormData.name"
			class="pb-6"
			label="Namn"
		/>

		<v-row>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(0)"
					v-model="accountingFormData.A"
					class="pr-2"
					:items="getCodelist.A"
					item-value="intval"
					:label="`${getCodeNames.A}`"
					:item-text="itemText"
				/>
			</v-col>

			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(1)"
					v-model="accountingFormData.B"
					class="pr-2"
					:items="getCodelist.B"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.B}`"
				/>
			</v-col>

			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(2)"
					v-model="accountingFormData.C"
					class="pr-2"
					:items="getCodelist.C"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.C}`"
				/>
			</v-col>

			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(3)"
					v-model="accountingFormData.D"
					class="pr-2"
					:items="getCodelist.D"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.D}`"
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(4)"
					v-model="accountingFormData.E"
					class="pr-2"
					:items="getCodelist.E"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.E}`"
				/>
			</v-col>

			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(5)"
					v-model="accountingFormData.F"
					class="pr-2"
					:items="getCodelist.F"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.F}`"
				/>
			</v-col>

			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(6)"
					v-model="accountingFormData.G"
					class="pr-2"
					:items="getCodelist.G"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.G}`"
				/>
			</v-col>

			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(7)"
					v-model="accountingFormData.H"
					class="pr-2"
					:items="getCodelist.H"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.H}`"
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(8)"
					v-model="accountingFormData.I"
					class="pr-2"
					:items="getCodelist.I"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.I}`"
				/>
			</v-col>

			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(9)"
					v-model="accountingFormData.J"
					class="pr-2"
					:items="getCodelist.J"
					item-value="intval"
					:item-text="itemText"
					:label="`${getCodeNames.J}`"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

const defaultData = () => ({
	uid: 0,
	name: '',
	codes: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
});

export default {
	props: {
		showCodeTexts: {
			type: Boolean,
			default: true
		},

		value: {
			type: Object,
			default: defaultData()
		},

		error: {
			type: String,
			default: ''
		},

		readOnly: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		accountingFormData: {
			uid: 0,
			name:'',
			A: '',
			B: '',
			C: '',
			D: '',
			E: '',
			F: '',
			G: '',
			H: '',
			I: '',
			J: ''

		}
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getIsActiveCode'])
	},

	watch: {
		'value._id'() {
			this.setData();
		},

		accountingFormData: {
			deep: true,
			handler() {
				this.$emit('input', {
					uid: this.value ? this.value.uid : 0,
					name: this.accountingFormData.name,
					codes: [
						this.accountingFormData.A,
						this.accountingFormData.B,
						this.accountingFormData.C,
						this.accountingFormData.D,
						this.accountingFormData.E,
						this.accountingFormData.F,
						this.accountingFormData.G,
						this.accountingFormData.H,
						this.accountingFormData.I,
						this.accountingFormData.J
					]
				});
			}
		}
	},

	created() {
		this.setData();
	},

	methods: {
		setData() {
			const value = this.value ? this.value : defaultData();

			this.accountingFormData = {
				uid: value.uid,
				name: value.name,
				A: value.codes[0],
				B: value.codes[1],
				C: value.codes[2],
				D: value.codes[3],
				E: value.codes[4],
				F: value.codes[5],
				G: value.codes[6],
				H: value.codes[7],
				I: value.codes[8],
				J: value.codes[9]
			};
		},

		itemText(a) {
			return this.showCodeTexts ? `${a.kod} - ${a.kodtext}` : a.kod;
		}
	}
};
</script>
