<template>
	<v-row no-gutters>
		<v-col
			cols="6"
			class="pt-14 pr-7"
		>
			<v-card class="rounded-xl pa-8" />
		</v-col>

		<v-col
			cols="6"
			class="pt-14 pl-7"
		>
			<v-card class="rounded-xl pa-8" />
		</v-col>

		<v-col
			cols="6"
			class="pt-14 pr-7"
		>
			<v-card class="rounded-xl pa-8" />
		</v-col>

		<v-col
			cols="6"
			class="pt-14 pl-7"
		>
			<v-card class="rounded-xl pa-8" />
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'DashboardView',

	components: {
	},

	data: () => ({

	}),

	computed: {

	},

	methods: {

	}
};
</script>
