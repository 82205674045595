import i18n from '@/plugins/i18n';
import apiClient, { kpApiV1 } from '@/plugins/axios';
import {validate} from '@/utils/validation';
import {compose, getFields} from '@/utils/service';

const fields = {
	username: {
		rules: input => validate(input).required().test(),
		label: i18n.t('login.username')
	},

	password: {
		rules: input => validate(input).required().test(),
		label: i18n.t('base.password')
	},

	method: {
		rules: input => validate(input).required().test(),
		label: i18n.t('base.method')
	},

	redirectUrl: {
		rules: input => validate(input).required().test(),
		label: i18n.t('base.method')
	},

	revoke: {
		rules: input => validate(input).required().test(),
		label: i18n.t('base.method')
	}
};

export default {
	login: (body, opts) => apiClient.post('/wp-json/kp/v1/token', body, opts),

	loginSaml: (body, opts) => apiClient.post('/wp-json/kp/v1/token/saml', body, opts),

	getGroup: (groupId, opts = {}) => apiClient.get(`/wp-json/kp/v1/token/group/${groupId}`, opts),

	setGroup: (groupId, body = {}, opts = {}) => apiClient.post(`/wp-json/kp/v1/token/group/${groupId}`, body, opts),

	setSite: (siteId, body = {}, opts = {}) => apiClient.post(`/wp-json/kp/v1/token/site/${siteId}`, body, opts),

	login_old: compose(kpApiV1.post, 'token', {
		fields: getFields(fields, ['username', 'password', 'method'])
	}),

	revoke: compose(kpApiV1.get, 'token/revoke'),

	refresh: compose(kpApiV1.get, 'token/refresh'),

	masquerade: compose(kpApiV1.get, 'token/masquerade/<as>'),

	site: compose(kpApiV1.post, 'token/site/<siteId>')
};
