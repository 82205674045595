<template>
	<v-navigation-drawer :class="getContainerClasses()" fixed
		permanent
		right
		:width="width"
	>
		<v-toolbar>
			<v-btn fab small color="elevation-0" @click="close">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-toolbar-title class="ml-4">
				<portal-target name="sidebar-right-title" />
			</v-toolbar-title>
			<v-spacer />
			<v-toolbar-items>
				<portal-target name="sidebar-right-actions" />
			</v-toolbar-items>
		</v-toolbar>

		<v-container class="SidebarRight-body pa-6">
			<slot />
		</v-container>

		<v-container v-if="!sidebarNoFooter" class="SidebarRight-footer">
			<slot name="footer" />
		</v-container>
	</v-navigation-drawer>
</template>

<script>
export default {
	name: 'SidebarRight',

	props: {
		width: {
			type: String,
			default: '460px'
		},
		pushContent: {
			type: Boolean,
			default: false
		},
		sidebarNoFooter: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ''
		}
	},

	created() {
		document.addEventListener('keyup', this.esc);
	},

	beforeDestroy() {
		document.removeEventListener('keyup', this.esc);
	},

	methods: {
		esc(e) {
			if (e.keyCode === 27) {
				this.close();
			}
		},

		getContainerClasses() {
			return {
				'SidbarRight': true,
				'elevation-2': true,
				'pa-4': false,
				'SidebarRight--noFooter': this.sidebarNoFooter
			};
		},

		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss">
.SidebarRight .v-navigation-drawer__content {
	overflow-y: hidden !important;
}

.SidebarRight-body {
	height: calc(100vh - 64px - 100px);
	overflow-y: auto;
}

.SidebarRight-footer {
	height: 84px;
}

.SidebarRight--noFooter {
	.SidebarRight-body {
		height: calc(100vh - 64px - 32px);
	}
}

</style>
