<template>
	<div class="Layout">
		<Default v-if="getLayout === 'default'" :meta="meta">
			<slot />
		</Default>
		<Plain v-if="getLayout === 'plain'">
			<slot />
		</Plain>
	</div>
</template>

<script>
import {get as dotProp} from 'dot-prop';
import Default from './default';
import Plain from './plain';

export default {
	name: 'Layout',

	components: {
		Default,
		Plain
	},

	data: vm => ({
		meta: dotProp(vm.$router, 'history.current.meta', {
			title: '',
			layout: 'default'
		})
	}),

	computed: {
		getLayout() {
			return this.meta.layout || 'default';
		}
	},

	watch: {
		'$route'() {
			this.meta = dotProp(this.$router, 'history.current.meta', {
				title: '',
				layout: 'default'
			});
		}
	}
};
</script>
