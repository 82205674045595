<template>
	<div>
		<portal to="sidebar-right-title">
			<v-avatar size="48">
				<img :src="user.avatar">
			</v-avatar>
			<span class="ml-4" style="position: relative; top: 2px;">{{ user.name }} ({{ user.login }})</span>
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-if="canMasq" v-tooltip fab text small
					content="Maskera dig som.."
					@click="openMasqConfirmDialog"
				>
					<v-icon>mdi-incognito</v-icon>
				</v-btn>

				<!--v-btn v-if="allowedToDelete" v-tooltip class="ml-1" fab text
					small
					content="Ta bort användare"
					@click="openDeleteConfirmDialog"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn-->
			</div>
		</portal>

		<Loader :error="errorMessage" :service="[$service.users.get]" />

		<v-container class="d-flex justify-end" />

		<v-expansion-panels v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Profil
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-form ref="form" @submit.prevent="submitUser">
						<v-card elevation="0">
							<v-card-text class="px-0">
								<ui-text-field v-model="user.name"
									:label="$t('base.name')"
									:rules="$validator('user/name')"
									autocomplete="new-password"
								/>
								<ui-text-field v-model="user.email" type="email"
									:label="$t('base.email')"
									:rules="$validator('user/email')"
									autocomplete="new-password"
								/>
							</v-card-text>

							<div v-if="user.replacement" class="d-flex text-caption mt-2 align-center">
								<div v-if="user.r_to">
									Ersätts av {{ user.replacement.name }} ({{ user.replacement.login }})
									mellan {{ $utils.date.intToPretty(user.r_from) }} och {{ $utils.date.intToPretty(user.r_to) }}
								</div>
								<div v-if="user.r_to">
									<v-btn v-tooltip content="Ta bort ersättare" icon small :loading="isReplacementPending"
										@click="removeReplacement"
									>
										<v-icon small color="error">mdi-close</v-icon>
									</v-btn>
								</div>
								<div v-else>
									<v-checkbox :input-value="!user.active" disabled label="Inaktiv" />
									Permanent ersatt av {{ user.replacement.name }} ({{ user.replacement.login }})
									fr.o.m. {{ $utils.date.intToPretty(user.r_from) }}
								</div>
							</div>

							<Loader :error="userUpdateError" />

							<v-card-actions class="px-0">
								<v-spacer />
								<v-btn color="primary" text type="submit" :loading="isUserUpdatePending">
									{{ $t('base.save') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Roller
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<Loader :service="[$service.roles.get, $service.users.deleteRole, $service.users.addRole]" />

					<v-list dense nav :disabled="!isUserAllowedToEditUserRoles" class="pa-0">
						<v-list-item-group color="primary">
							<v-list-item v-for="role in roles" :key="role.key" v-tooltip content="some tooltip" @click="setUserRole(role.key)">
								<v-list-item-icon>
									<v-icon :color="userHasRole(role.key) ? 'success' : ''">
										{{ userHasRole(role.key) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title v-text="role.value" />
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Rättigheter
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Loader :service="[$service.users.addCap, $service.users.deleteCap]" />
					<CapabilityList v-if="isUserAllowedToCapsList === true" :key="`${new Date().getTime()}`" :source="user.caps" :add="addCapability" :remove="removeCapability" />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Grupper
				</v-expansion-panel-header>

				<v-expansion-panel-content v-for="group in user.groups" :key="group._id">
					<div class="text-body">
						{{ group.name }}
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Ersättare
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-card elevation="0">
						<div class="d-flex" style="">
							<ui-datepicker v-model="replacement.from" class="mr-6" clearable label="Datum fr.o.m." />
							<div>
								<ui-datepicker v-model="replacement.to" clearable label="Datum t.o.m." hide />
								<span class="text-caption">Lämna fältet tomt för permanent ersättare</span>
							</div>
						</div>
						<div style="width:50%">
							<ui-autocomplete v-model="replacement.id"
								label="Användare"
								item-text="_userName"
								item-value="id"
								clearable
								:items="getUsers"
							/>
						</div>

						<Loader :error="errorMessage" :loading="isReplacementPending" />

						<div class="text-end">
							<v-btn text color="warning" @click="addReplacement">Spara</v-btn>
						</div>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel v-if="isUserCodeAdmin">
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Attestadministration
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<attest-administration :user="user" />
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import {run} from '@/utils/service';
import {mapActions, mapGetters} from 'vuex';
import CapabilityList from '@/components/capability-list';
import AttestAdministration from './attest-administration';

const objectToArray = obj => Object.entries(obj).map(([key, value]) => ({key, value}));

export default {
	components: {
		CapabilityList,
		AttestAdministration
	},

	props: {
		userToEdit: {
			type: Object,
			default: undefined,
			required: true
		}
	},

	data: () => ({
		panel: [0],

		user: {
			username: '',
			password: '',
			email: '',
			name: '',
			roles: {}
		},

		replacement: {
			id: null,
			from: null,
			to: null
		},

		roles: [],
		rules: {},

		isUserUpdatePending: false,
		isReplacementPending: false,
		userUpdateError: '',
		errorMessage: ''
	}),

	computed: {
		...mapGetters('userStore', ['isUserAllowedToEditUserRoles', 'isUserAllowedToMasquerade', 'isUserAllowedToMasqueradeProtect', 'isUserAllowedToMasqueradeProtectOverride', 'getUserData', 'isUserAllowedToDeleteUser', 'isUserAllowedToCapsList', 'isUserCodeAdmin']),
		...mapGetters('users', ['getUsers']),


		getUserRoles() {
			return objectToArray(this.user.roles);
		},

		canMasq() {
			return this.isUserAllowedToMasquerade && this.getUserData.id !== this.user.id;
		},

		allowedToDelete() {
			return this.isUserAllowedToDeleteUser && this.getUserData.id !== this.user.id;
		}
	},

	watch: {
		async 'userToEdit.id'() {
			this.$service.users.update.successMessage = '';
			await this.fetchUser();
			this.fetchRoles();
		}
	},

	created() {
		this.fetchUser();
		this.fetchRoles();
	},

	methods: {
		...mapActions('userStore', ['setMasquerade']),
		userHasRole(role) {
			return role in this.user.roles;
		},

		async setUserRole(role) {
			const data = this.userHasRole(role) ?
				await this.$service.users.deleteRole.call({
					id: this.user.id,
					roleId: role
				}) :
				await this.$service.users.addRole.call({
					id: this.user.id,
					body: {
						role
					}
				});

			run(data, () => {
				this.fetchUser();
				this.$bus.emit('users:updated');
			});
		},

		async removeCapability(capId) {
			run(await this.$service.users.deleteCap.call({
				userId: this.user.id,
				capId
			}), this.fetchUser);
		},

		async addCapability(capId, grant) {
			run(await this.$service.users.addCap.call({
				userId: this.user.id,
				capId,
				body: {
					grant
				}
			}), this.fetchUser);
		},

		async submitUser() {
			if (!this.$refs.form.validate()) {
				return;
			}

			try {
				await this.$service.users.update(this.user.id, {
					name: this.user.name,
					email: this.user.email
				}, {
					loading: val => {
						this.isUserUpdatePending = val;
					},
					throwException: true
				});

				this.$bus.emit('users:updated');
			} catch (error) {
				this.userUpdateError = error.message;
			}
		},

		openDeleteConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: this.$t('register.users.delete_confirm_title'),
				text: this.$t('register.users.delete_confirm_text', {name: this.user.name}),
				confirmed: this.deleteUser
			});
		},

		openMasqConfirmDialog() {
			// if(this.isUserAllowedToMasqueradeProtect || this.isUserAllowedToMasqueradeOverride) {
			// 	this.$bus.emit('confirm:open', {
			// 		headline: this.$t('register.users.masq_confirm_title'),
			// 		text: this.$t('register.users.masq_error_text', {name: this.user.name}),
			// 		alert: true
			// 	});
			// } else {
			this.$bus.emit('confirm:open', {
				headline: this.$t('register.users.masq_confirm_title'),
				text: this.$t('register.users.masq_confirm_text', {name: this.user.name}),
				confirmed: this.masq,
				error: this.errorMessage
			});

		},

		async deleteUser() {
			run(await this.$service.users.delete.call({
				id: this.user.id
			}), () => {
				this.$emit('user-deleted');
			});
		},

		async fetchRoles() {
			run(await this.$service.roles.get.call(), data => {
				this.roles = objectToArray(data);
			});
		},

		async fetchUser() {
			run(await this.$service.users.get.call({id: this.userToEdit.id}), data => {
				this.user = data;
			});
		},

		async addReplacement() {
			try {
				await this.$service.users.addReplacement(this.user.id, {
					...this.replacement,
					'from': this.$utils.date.dateToInt(this.replacement.from),
					'to': this.$utils.date.dateToInt(this.replacement.to)
				}, {
					loading: val => {
						this.isReplacementPending = val;
					},
					throwException: true
				});
			} catch(error) {
				this.errorMessage = error.message;
			}

			await this. fetchUser();
		},

		async removeReplacement() {
			try {
				await this.$service.users.deleteReplacement(this.user.id, {
					loading: val => {
						this.isReplacementPending = val;
					},
					throwException: true
				});
				this.fetchUser();
			} catch(error) {
				this.errorMessage = error.message;
			}
		},

		async masq() {
			this.errorMessage = '';

			const resp = await this.setMasquerade(this.user.id);

			if (resp._error) {
				return this.errorMessage = resp._error;
			}
		},

		close() {
			this.$emit('close');
		}
	}

};
</script>

<style scoped>
	.v-list--disabled {
		opacity: 0.5;
	}
</style>
