<template>
	<div v-if="localGroup">
		<portal to="sidebar-right-title">
			<div>
				<div>{{ localGroup.name }}</div>
				<p v-if="localGroup.parent" class="text-caption ma-0">
					Förälder: <strong class="primary--text" style="cursor: pointer;" @click="navigateToParent">{{ parentGroup.name }}</strong>
				</p>
			</div>
		</portal>

		<portal v-if="isUserAllowedToGroupEdit" to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-tooltip fab text small
					content="Koppla förälder"
					@click="addParentDialog = true"
				>
					<v-icon>mdi-family-tree</v-icon>
				</v-btn>

				<v-btn v-if="isUserAllowedToGroupEdit" v-tooltip fab text small
					content="Ta bort grupp"
					@click="openDeleteConfirmDialog"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</div>
		</portal>

		<v-expansion-panels v-model="panel" multiple>
			<user-panel
				:group-users="localGroup.users"
				:group-id="localGroup._id"
				@on-user-add="fetchGroup"
				@on-user-delete="fetchGroup"
			/>

			<verification-codes-panel
				:group-id="localGroup._id"
				@on-code-add="fetchGroup"
			/>

			<action-panel v-for="action in actions" :key="action.parent"
				:action="action"
				:actions="localGroup.actions[action.parent]"
				:group-id="localGroup._id"
				@on-action-update="fetchGroup"
			/>

			<rules-panel :group-id="localGroup._id" />
		</v-expansion-panels>

		<Dialog v-if="addParentDialog" title="Koppla förälder" @confirm="addParent" @reject="addParentDialog = false">
			<v-autocomplete
				v-model="parentToAdd"
				:items="groups"
				class="pr-8"
				label="Välj förälder i listan"
				item-text="name"
				item-value="_id"
			/>
		</Dialog>
	</div>
</template>

<script>
import {run} from '@/utils/service';
import {mapGetters, mapActions} from 'vuex';

import UserPanel from './user-panel.vue';
import VerificationCodesPanel from './verification-codes-panel.vue';
import ActionPanel from './action-panel';
import RulesPanel from './rules-panel';

export default {
	components: {
		VerificationCodesPanel,
		UserPanel,
		ActionPanel,
		RulesPanel
	},


	props: {
		group: {
			type: Object,
			default: () => ({})
		}
	},


	data: () => ({
		panel: [0, 1],
		localGroup: null,
		parentGroup: {},
		parentToAdd: null,
		actions: [],
		users: [],
		groups: [],
		addParentDialog: false
	}),


	computed: {
		...mapGetters('userStore', ['isUserAllowedToDeleteRole', 'isUserAllowedToGroupEdit'])
	},


	watch: {
		'group._id'() {
			this.fetchGroup();
		},

		addParentDialog(to) {
			if (to) {
				this.fetchGroups();
			}
		}
	},


	async created() {
		await this.fetchActions();
		await this.fetchGroup();
	},


	methods: {
		...mapActions('userStore', ['refreshToken']),


		openDeleteConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: this.$t('register.groups.delete_confirm_title'),
				text: this.$t('register.groups.delete_confirm_text', { name: this.localGroup.name }),
				confirmed: this.deleteGroup
			});
		},


		navigateToParent() {
			this.$emit('navigate-to-group', this.parentGroup);
		},


		async addParent() {
			if(!this.parentToAdd) {
				await this.$service.groups.deleteParent(this.localGroup._id);
			}

			if(this.parentToAdd) {
				await this.$service.groups.addParent(this.localGroup._id, this.parentToAdd);
			}


			this.parentToAdd = null;
			this.addParentDialog = false;
			this.fetchGroup();
			this.$emit('group-updated');
		},


		async fetchGroups() {
			run(await this.$service.groups.get.call(), data => {
				this.groups = [ {_id: null, name: 'Ingen Förälder'} ,...data.filter(group => group._id !== this.localGroup._id)];
			});
		},


		async fetchGroup() {
			this.localGroup = await this.$service.groups.get.call({
				id: this.group._id
			});

			if (this.localGroup.parent) {
				this.fetchParent();
			}
		},


		async deleteGroup() {
			await this.$service.groups.delete(this.localGroup._id);

			this.$emit('group-deleted');
		},


		async fetchParent() {
			run(await this.$service.groups.get.call({ id: this.localGroup.parent }), data => {
				this.parentGroup = data;
			});
		},


		async fetchActions() {
			const data = await this.$service.actions.list();

			this.actions = data.filter(rule => rule.name !== 'Attesteringsregler');
		}
	}
};
</script>

