import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const USER_TYPE_PRIMARY = 'primary';
export const USER_TYPE_MASQ = 'masq';

export const emptyUser = () => ({
	expires: 0,
	token: '',
	tokenData: null,
	user: {},
	group: null
});

export default {
	namespaced: true,

	state: {
		active: USER_TYPE_PRIMARY,
		[USER_TYPE_PRIMARY]: emptyUser(),
		[USER_TYPE_MASQ]: emptyUser()
	},

	actions,
	mutations,
	getters
};
