<template>
	<v-expansion-panel>
		<v-expansion-panel-header class="text-button" style="line-height: 100%;">
			Kodbehörigheter
		</v-expansion-panel-header>

		<v-expansion-panel-content>
			<div class="text-right pb-6">
				<v-btn text color="primary" @click="addDialog = true">
					<v-icon left>mdi-plus</v-icon>
					Lägg till kodbehörighet
				</v-btn>
			</div>
			<v-row>
				<v-col v-if="codesSelf.length > 0" cols="12">
					<span class="text-caption font-weight-bold ">Gruppspecifika kodbehörigheter</span>

					<v-data-table ref="registerList" :items="codesSelf" :hide-default-footer="true" :headers="getSelfHeaders" :show-sidebar="false">
						<template #item.actions="{item}">
							<div class="d-flex flex-row-reverse">
								<v-btn v-tooltip content="Ta bort" icon
									@click.stop="deleteRowDialog(codesSelf.indexOf(item) )"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</div>
						</template>
					</v-data-table>
				</v-col>

				<v-col v-if="codesInherited.length > 0" cols="12" class="pt-6">
					<span class="text-caption font-weight-bold ">Ärvda kodbehörigheter</span>
					<v-data-table ref="registerList" :items="codesInherited" :hide-default-footer="true" :headers="getInheritedHeaders" :show-sidebar="false" />
				</v-col>
			</v-row>
		</v-expansion-panel-content>
		<Dialog v-if="addDialog"
			max-width="920"
			width="920"
			title="Ny kodbehörigheter"
			padding-text="py-5"
			:loading="isPending"
			@reject="reject"
			@confirm="updateCodes"
		>
			<Loader :error="errorMessage" :loading="isPending" />

			<v-row>
				<v-col cols="3">
					<span class="text-caption font-weight-bold ">Kodbehörigheter</span>
				</v-col>
				<v-col cols="4" class="font-weight-bold"> Från </v-col>
				<v-col cols="4" class="font-weight-bold"> Till </v-col>
			</v-row>

			<v-divider />

			<div v-for="(code, index) in localCodes" :key="index">
				<v-row v-if="getIsActiveCode(index)" class="">
					<v-col cols="3" class="d-flex align-center font-weight-bold">
						{{ getCodeNames[getCodeLetters(index)] }}
					</v-col>
					<v-col cols="4" class="py-0">
						<ui-text-field v-model="localCodes[index][0]" :value="getPaddedNumber(index,0, getCodeLengths[index], '0')" />
					</v-col>
					<v-col cols="4" class="py-0">
						<ui-text-field v-model="localCodes[index][1]" :value="getPaddedNumber(index,1, getCodeLengths[index], '9')" />
					</v-col>
				</v-row>
			</div>
		</Dialog>
	</v-expansion-panel>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
const defaultData = () => ([[], [], [], [], [], [], [], [], [], []]);

export default {

	props: {
		groupId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		localCodes: [],
		codesSelf: [],
		codesInherited: [],
		codeNames: [],

		addDialog: false,
		errorMessage: '',
		isPending: false
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getCodeLengths', 'getIsActiveCode']),
		...mapGetters('userStore', ['getUser']),

		getInheritedHeaders() {
			return [this.getIsActiveCode(0) && {
				text: `${this.getCodeNames.A}`,
				value: 'codes[0]',
				sortable: false
			}, this.getIsActiveCode(1) && {
				text: `${this.getCodeNames.B}`,
				value: 'codes[1]',
				sortable: false
			}, this.getIsActiveCode(2) && {
				text: `${this.getCodeNames.C}`,
				value: 'codes[2]',
				sortable: false
			}, this.getIsActiveCode(3) && {
				text: `${this.getCodeNames.D}`,
				value: 'codes[3]',
				sortable: false
			}, this.getIsActiveCode(3) && {
				text: `${this.getCodeNames.E}`,
				value: 'codes[4]',
				sortable: false
			}, this.getIsActiveCode(5) && {
				text: `${this.getCodeNames.F}`,
				value: 'codes[5]',
				sortable: false
			}, this.getIsActiveCode(6) && {
				text: `${this.getCodeNames.G}`,
				value: 'codes[6]',
				sortable: false
			}, this.getIsActiveCode(7) && {
				text: `${this.getCodeNames.H}`,
				value: 'codes[7]',
				sortable: false
			}, this.getIsActiveCode(8) && {
				text: `${this.getCodeNames.I}`,
				value: 'codes[8]',
				sortable: false
			}, this.getIsActiveCode(9) && {
				text: `${this.getCodeNames.J}`,
				value: 'codes[9]',
				sortable: false
			}].filter(Boolean);
		},

		getSelfHeaders() {
			return [this.getIsActiveCode(0) && {
				text: `${this.getCodeNames.A}`,
				value: 'codes[0]',
				sortable: false
			}, this.getIsActiveCode(1) && {
				text: `${this.getCodeNames.B}`,
				value: 'codes[1]',
				sortable: false
			}, this.getIsActiveCode(2) && {
				text: `${this.getCodeNames.C}`,
				value: 'codes[2]',
				sortable: false
			}, this.getIsActiveCode(3) && {
				text: `${this.getCodeNames.D}`,
				value: 'codes[3]',
				sortable: false
			}, this.getIsActiveCode(4) && {
				text: `${this.getCodeNames.E}`,
				value: 'codes[4]',
				sortable: false
			}, this.getIsActiveCode(5) && {
				text: `${this.getCodeNames.F}`,
				value: 'codes[5]',
				sortable: false
			}, this.getIsActiveCode(6) && {
				text: `${this.getCodeNames.G}`,
				value: 'codes[6]',
				sortable: false
			}, this.getIsActiveCode(7) && {
				text: `${this.getCodeNames.H}`,
				value: 'codes[7]',
				sortable: false
			}, this.getIsActiveCode(8) && {
				text: `${this.getCodeNames.I}`,
				value: 'codes[8]',
				sortable: false
			}, this.getIsActiveCode(9) && {
				text: `${this.getCodeNames.J}`,
				value: 'codes[9]',
				sortable: false
			}, {
				text: '',
				value: 'actions',
				sortable: false
			}];
		}
	},

	watch: {
		'groupId'() {
			this.fetchCodes();
		}
	},

	async created() {
		this.localCodes = defaultData();
		await this.fetchCodes();
		this.setCodeNames();
	},

	methods: {
		...mapActions('userStore', ['refreshToken']),

		deleteRowDialog(i) {
			this.$bus.emit('confirm:open', {
				headline: 'Ta bort rad',
				text: `Säker på att du vill ta bort vald rad ${i + 1 }?`,
				error: this.errorMessage,
				confirmed: () => this.deleteRow(i)
			});
		},

		async deleteRow(i) {
			this.codesSelf.splice(i, 1);

			await this.updateCodes();
		},

		reject() {
			this.localCodes = defaultData();
			this.addDialog = false;
		},

		getPaddedNumber(subArrayIndex, numberIndex, codeLength, type) {
			if(!this.localCodes[subArrayIndex][numberIndex])
				return;

			const number = this.localCodes[subArrayIndex][numberIndex].toString();

			if(number.includes('*')) {
				const l = codeLength - (number.length - 1);

				return this.$set(this.localCodes[subArrayIndex], numberIndex,number.replace('*', type.repeat(l)));
			}

			return this.localCodes[subArrayIndex][numberIndex];
		},

		getCodeLetters(index) {
			const letters = 'ABCDEFGHIJ';

			return letters[index];
		},

		setCodeNames() {
			this.codeNames =  Object.entries(this.getCodeNames).map(([key, value], index) => {
				if (!value) {
					return null;
				}

				return {
					text: value,
					key: key,
					value: index
				};
			}).filter(obj => obj !== null);
		},

		addZeroToLenghtOfCode(codeLengthIndex, code) {
			const length = this.getCodeLengths[codeLengthIndex] - `${code}`.length;

			if(length <= 0) {
				return code;
			}

			return `${'0'.repeat(length)}${code}`;
		},

		async fetchCodes() {
			try {
				const data = await this.$service.groups.getCodes(this.groupId);

				this.codesSelf = data.self.map(d => ({
					rules : [...d],
					codes: d.map(code => code[0] && code[1] ? `${this.addZeroToLenghtOfCode(d.indexOf(code) , code[0])} - ${this.addZeroToLenghtOfCode(d.indexOf(code) , code[1])}`: '')
				}));

				this.codesInherited = data.inherited.map(d => ({
					...d,
					codes: d.map(code => code[0] && code[1] ? `${this.addZeroToLenghtOfCode(d.indexOf(code) , code[0])} - ${this.addZeroToLenghtOfCode(d.indexOf(code) , code[1])}`: '')
				}));

			} catch(error) {
				this.errorMessage = error.message;
			}
		},

		async updateCodes() {
			let codes = [];
			const selfRulesArray = this.codesSelf.length > 0 && this.codesSelf.map(obj => obj.rules);

			if(selfRulesArray) {
				codes = [...selfRulesArray];
			}

			this.localCodes = this.localCodes.map(x=> x.map(y => Number.isInteger(parseInt(y, 10)) ?parseInt(y) : null));


			codes = this.localCodes.some(arr => arr.length > 0) ?  [...codes, [...this.localCodes] ] : [...codes];

			try {
				await this.$service.groups.updateCodes(this.groupId, {
					'code_permissions': codes
				}, {
					loading: val => {
						this.isPending = val;
					}
				});

				this.localCodes = defaultData();
				this.addDialog = false;
				await this.refreshToken();
				await this.fetchCodes();

			} catch(error) {
				this.errorMessage = error.message;
			}
		}
	}
};
</script>
