import { get as dotProp } from 'dot-prop';
import store from '@/plugins/vuex';
import utils from '@/utils';
import router from '@/plugins/router';

export const isAuthenticated = () => {
	const user = store.getters['userStore/getUser'];

	if (!user || !user.token || !user.expires || !utils.token.validateExpireTime(user.expires)) {
		return false;
	}

	return true;
};

export const isAllowedTo = type => {
	return dotProp(store, `getters.userStore/isUserAllowedTo${type}`, false);
};

export const logout = () => {
	store.dispatch('userStore/logout');
	router.push({ name: 'Login' });
};
