<template>
	<div>
		<portal to="sidebar-right-title">
			{{ localType.name }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-if="isUserAllowedToDeleteRole" v-tooltip class="ml-auto" fab text
					small
					content="Ta bort Abonnemangstyp"
					@click="openDeleteConfirmDialog"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</div>
		</portal>
		<v-card elevation="0" />
		<v-card class="pa-16">
			<ui-text-field v-model="localType.name" label="Namn" />

			<v-card-actions class="mt-12">
				<v-btn class="ml-auto" color="primary" text @click="updatedType">
					Spara
				</v-btn>
			</v-card-actions>
		</v-card>

		<Loader :error="errorMessage" :loading="isPending" />
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {

	props: {
		type: {
			type: Object,
			default: () => ({})
		}
	},

	data: () => ({
		activeType: null,
		localType: null,
		errorMessage: '',
		isPending: false
	}),

	computed: {
		...mapGetters('userStore', ['isUserAllowedToDeleteRole'])
	},

	watch: {
		'type._id'() {
			this.localType = this.type;
		}
	},

	created() {
		this.localType = this.type;
	},

	methods: {
		async updatedType() {
			this.errorMessage = '';
			try {
				await this.$service.subscriptions.updateType(this.localType._id, {
					name: this.localType.name
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});

				this.activeType = null;
				this.$emit('fetch');
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		openDeleteConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: this.$t('register.types.delete_confirm_title'),
				text: this.$t('register.types.delete_confirm_text', {name: this.localType.name}),
				confirmed: this.deleteType
			});
		},

		async deleteType() {
			await this.$service.subscriptions.deleteType(this.localType._id);

			this.$emit('sub-deleted');
		}
	}
};
</script>

