import Vue from 'vue';
import VueRouter from 'vue-router';
import utils from '@/utils';
import i18n from '@/plugins/i18n';

import dashboardRoutes from '@/views/dashboard/routes';
import notFoundRoutes from '@/views/not-found/routes';
import inboxRoutes from '@/views/inbox/routes';
import loginRoutes from '@/views/login/routes';
import settingsRoutes from '@/views/settings/routes';
import profileRoutes from '@/views/profile/routes';
import invoicesRoutes from '@/views/invoices/routes';
import administrationRoutes from '@/views/administration/routes';
import registerRoutes from '@/views/register/routes';
import paymentRoutes from '@/views/payments/routes';
import logoutRoutes from '@/views/logout/routes';
import logRoutes from '@/views/logs/routes';

Vue.use(VueRouter);

const baseTitle = i18n.tc('base.title');
const routes = [
	...dashboardRoutes,
	...inboxRoutes,
	...loginRoutes,
	...settingsRoutes,
	...profileRoutes,
	...invoicesRoutes,
	...administrationRoutes,
	...registerRoutes,
	...paymentRoutes,
	...logoutRoutes,
	...logRoutes,
	...notFoundRoutes // Must be last in array
];
const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach((to, from, next) => {
	if (to.name !== 'Login' && !utils.user.isAuthenticated()) {
		next({name: 'Login'});
	} else {
		next();
	}
});

router.afterEach(to => {
	Vue.nextTick(() => {
		document.title = to.meta.title ? `${baseTitle} - ${to.meta.title}` : baseTitle;
	});
});

export default router;
