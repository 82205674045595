<template>
	<div>
		<div class="d-flex justify-space-between">
			<v-checkbox label="Inkludera arkiverade fakturor" @change="filterArchivedInvoices" />

			<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
				@click="exportExcel"
			>
				<v-icon>mdi-microsoft-excel</v-icon>
			</v-btn>
		</div>

		<Loader :service="[$service.invoices.searchErrors]" hide-loading />

		<RegisterList ref="registerList"
			:items="items"
			:headers="headers"
			:pagination="pagination"
			:mass-actions="massActions"
			sidebar-width="40%"
			@mass-action="onMassAction"
			@active-item-changed="setActiveItem" @pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<FaultyInvoiceEdit v-if="activeId" :id="activeId" @change="onChange" @on-archive="onChange" @retry="onChange" />
		</RegisterList>

		<Loader :error="errorMessage" />
	</div>
</template>

<script>
import FaultyInvoiceEdit from './components/edit';
// import {addFilter} from '@/utils/filter';
import RegisterList from '@/components/register-list';

export default {
	components: {
		RegisterList,
		FaultyInvoiceEdit
	},

	data: (vm) => ({
		activeId: null,

		items: [],

		headers: [{
			text: 'Tid',
			value: 'timestamp',
			width: 250,
			filter: [{
				type: 'date',
				placeholder: 'fr.o.m.',
				transform: vm.$utils.date.normalizeDate
			},
			{
				type: 'date',
				placeholder: 't.o.m.',
				transform: (dateString) => vm.$utils.date.normalizeDate(dateString, true)
			}]
		}, {
			text: 'Meddelande',
			value: 'error',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Faktura',
			value: 'invoice.ID'
		}, {
			text: 'Leverantör',
			value: 'invoice.meta.supplier_name'
		}, {
			text: 'Id',
			value: 'id'
		}],

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: ['timestamp'],
			sortDesc: [false]
		},

		massActions: [{
			text: 'Skicka på ny kontroll',
			value: 'restart'
		}],

		filter: {},
		errorMessage: '',
		includeArchived: false,
		isPending: false
	}),

	methods: {
		onChange() {
			this.activeId = null;
			this.$refs.registerList.close();
			this.fetchInvoiceErrors();
		},

		async filterArchivedInvoices(value) {
			this.includeArchived = value;
			await this.fetchInvoiceErrors();
		},

		onMassAction(data) {
			if (data.type === 'restart') {
				this.massActionRetry(data);
			}
		},

		setActiveItem(item) {
			if (!item) {
				this.activeId = undefined;

				return;
			}

			this.activeId = item.id;
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchInvoiceErrors();
		},

		async massActionRetry(data) {
			try {
				await this.$service.invoices.retryErrornousBulk({
					errors: data.items.map(x => x._id)
				}, {
					throwException: true
				});
			} catch (error) {
				this.errorMessage = error.message;
			}

			this.fetchInvoiceErrors();
		},

		async exportExcel() {
			this.isPending = true;
			const data = await this.$service.invoices.searchErrors({
				pageSize: 0,
				excel: true,
				filter: this.$utils.filter.getFilterQuery(this.filter)
			});

			await this.$utils.excel.download(data, 'Felaktiga fakturor');
			this.isPending = false;
		},

		async fetchInvoiceErrors() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const data = await this.$service.invoices.searchErrors({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					...(!this.includeArchived ? { 'archived': false  } : {})
				},
				'projection' : {
					'ID': 1,
					'Dates.DueDate' : 1,
					'Amounts.TaxInclusiveTotal' : 1,
					'Parties.Seller' : 1,
					'meta' : 1
				}
			});

			this.pagination.total = data._paginationTotal;
			this.items = data.map(x => ({
				...x,
				id: x._id,
				timestamp: this.$utils.date.getFullDateTime(x.timestamp),
				error: this.$utils.format.seperateString(x.error, ', '),
				invoice: {
					...x.invoice,
					meta: {
						...x.invoice.meta,
						supplier_name: x.invoice.meta.supplier_name ? x.invoice.meta.supplier_name  : ''
					}
				}
			}));
		},

		async onFilterUpdate(filter) {
			this.filter = filter;
			await this.fetchInvoiceErrors();
		}
	}
};
</script>
