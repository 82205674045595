<template>
	<Dialog
		title="Ny bilagetyp"
		:loading="isAddPending"
		@reject="reject"
		@confirm="confirm"
	>
		<v-form ref="form">
			<v-row>
				<v-col cols="12">
					<ui-text-field v-model="formData.name"
						label="Namn"
						:rules="$validator('base/required')"
					/>
				</v-col>
			</v-row>
		</v-form>

		<Loader :error="error" />
	</Dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	data: () => ({
		open: true,

		formData: {
			name: ''
		},

		isAddPending: false,

		error: '',
		groups: []
	}),

	computed: {
		...mapGetters('users', ['getUsers'])
	},

	async created() {
	},

	methods: {
		reject() {
			this.$emit('close');
		},

		async confirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			try {
				const data = await this.$service.attachment.createType(this.formData, {
					loading: val => {
						this.isAddPending = val;
					},
					throwException: true
				});

				this.$emit('close');
				this.$emit('created', data);
			} catch (error) {
				this.error = error.message;
			}
		}
	}
};
</script>
