var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v("Ankomstbokföring")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v("Definitiv bokföring")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('v-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.getArrival,"hide-default-footer":"","items-per-page":9999},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"tabIndex":-1}},[_vm._v(_vm._s(_vm.$utils.date.intToPretty(item.date)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"primary--text d-flex justify-end",attrs:{"tabIndex":-1}},[_vm._v(_vm._s(_vm.$utils.format.price(item.amount)))])]}},_vm._l((_vm.headers),function(head,i){return {key:("item." + head),fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip"}],key:head,attrs:{"content":item.codetexts[i],"tabIndex":-1}},[_vm._v(" "+_vm._s(_vm.getCodeValue(head, item.codes[i]))+" ")])]}}})],null,true)})],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('v-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.getDefinitive,"hide-default-footer":"","items-per-page":9999},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"tabIndex":-1}},[_vm._v(_vm._s(_vm.$utils.date.intToPretty(item.date)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"primary--text d-flex justify-end",attrs:{"tabIndex":-1}},[_vm._v(_vm._s(_vm.$utils.format.price(item.amount)))])]}},_vm._l((_vm.headers),function(head,i){return {key:("item." + head),fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip"}],key:head,attrs:{"content":item.codetexts[i],"tabIndex":-1}},[_vm._v(" "+_vm._s(_vm.getCodeValue(head, item.codes[i]))+" ")])]}}})],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }