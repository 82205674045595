<template>
	<portal to="app">
		<v-slide-x-transition>
			<div v-if="localInvoice" :style="`width: ${width};`" class="InvoicePreview" elevation="0" rounded="0">
				<div class="InvoicePreview-header d-flex align-center pl-4">
					<ui-select v-model="activeTab" item-value="_id" item-text="_name" :items="getDropdownItems" return-object />

					<v-spacer />

					<div class="InvoicePreview-header-attchmentButtonWrapper align-center justify-end d-flex">
						<v-btn class="ml-4" fab plain small @click="closePreview">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</div>

				<portal v-if="activeTab === 'history' || activeTab._id === 'history'" to="invoice-preview-tab-content">
					<invoice-history v-if="localInvoice.history" :history="localInvoice.history" />
				</portal>

				<portal v-if="getHtmlAttachment && (activeTab.type === 'generated_html' || activeTab === 'html')" to="invoice-preview-tab-content">
					<invoice-html :attachment-id="getHtmlAttachment._id" />
				</portal>

				<portal v-if="activeTab.mimetype === 'application/pdf'" to="invoice-preview-tab-content">
					<PdfViewer :attachment-id="activeTab._id" />
				</portal>

				<portal-target style="overflow: auto; height: calc(100% - 64px);" name="invoice-preview-tab-content" />
			</div>
		</v-slide-x-transition>
	</portal>
</template>

<script>

import PdfViewer from '@/components/pdf-viewer';
import InvoiceHistory from '@/components/invoice/history';
import InvoiceHtml from '@/components/invoice/html';

export default {
	components: {
		PdfViewer,
		InvoiceHistory,
		InvoiceHtml
	},

	props: {
		invoice: {
			type: Object,
			default: null
		},

		invoiceId: {
			type: String,
			default: null
		},

		width: {
			type: String,
			default: '40%'
		},

		defaultView: {
			type: String,
			default: 'history'
		}
	},

	data: () => ({
		activeTab: 'history',

		localInvoice: null,
		localTypes: [],

		isInvoiceFetchPending: false,
		invoiceFetchError: '',

		newAttachmentDialogOpen: false
	}),

	computed: {
		getDropdownItems() {
			if (!this.localInvoice) {
				return [];
			}

			return [{
				_name: 'Historik',
				_id: 'history'
			}, ...this.localInvoice.meta.attachments.map(a => (
				{
					...a,
					_name: `${a.name} (${this.localTypes.find(x => x._id === a.type).name})`
				}
			))];
		},

		getHtmlAttachment() {
			if (!this.localInvoice) {
				return null;
			}

			const attachment = this.localInvoice.meta.attachments.find(a => a.type === 'generated_html');

			return attachment;
		},

		getPdfAttachment() {
			if (!this.localInvoice) {
				return null;
			}

			const attachment = this.localInvoice.meta.attachments.find(a => a.type === 'supplied_pdf');

			return attachment;
		}
	},

	async created() {
		this.localTypes = await this.fetchAttachment();

		if (this.invoice) {
			this.localInvoice = this.$utils.cloneDeep(this.invoice);
		}

		if (this.invoiceId && this.localInvoice === null) {
			await this.fetchInvoice();
		}

		if (this.defaultView === 'generated_html' && this.getHtmlAttachment) {
			this.activeTab = this.getHtmlAttachment;
		}

		if (this.defaultView === 'supplied_pdf' && this.getPdfAttachment) {
			this.activeTab = this.getPdfAttachment;
		}
	},

	methods: {
		tabHref(index) {
			return `#tab-${index}`;
		},

		closePreview() {
			this.$emit('on-close');
		},

		async fetchAttachment() {
			return await this.$service.attachment.listTypes();
		},

		async fetchInvoice() {
			const id = this.invoiceId || this.localInvoice._id;

			try {
				this.localInvoice = await this.$service.invoices.get(id, {
					throwException: true,
					loading: val => {
						this.isInvoiceFetchPending = val;
					}
				});
			} catch (error) {
				this.fetchInvoiceError = error.message;
			}
		}
	}
};
</script>
