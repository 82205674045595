<template>
	<Dialog title="Avancerad sök"
		ok-btn-text="Sök"
		width="80%"
		@reject="reject"
		@confirm="confirm"
	>
		<div style="min-height: 500px">
			<v-row>
				<v-col cols="6">
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Fakturanr</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model="localFilter.ID" type="text" clean no-label clearable />
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">OCR-nummer</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model="localFilter['meta.account.PaymentReference']" type="text" clean no-label clearable />
						</v-col>
					</v-row>
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Dokumenttyp</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-select v-model="localFilter['meta.document_type']" clean no-label clearable
								:items="[{
									text: 'Faktura',
									value: 'invoice'
								}, {
									text: 'Abonnemang',
									value: 'subscription'
								}, {
									text: 'Självfaktura',
									value: 'self_invoice'
								}, {
									text: 'Order',
									value: 'order'
								}].sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))"
								width="100%"
							/>
						</v-col>
					</v-row>
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Fakturatyp</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-select v-model="localFilter['meta.document_sub_type']" clean no-label clearable
								:items="[{
									text: 'Debet',
									value: 'debit'
								}, {
									text: 'Kredit',
									value: 'credit'
								}]"
								width="100%"
							/>
						</v-col>
					</v-row>
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Fakturastatus</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-select v-model="localFilter['meta.status_level']" clean no-label clearable
								width="100%"
								:items="[{
									text: 'Registrerad',
									value: 1
								}, {
									text: 'Ankomstbokförd',
									value: 2
								}, {
									text: 'Makulerad ankomstbokförd faktura',
									value: 3
								}, {
									text: 'Konterad',
									value: 4
								}, {
									text: 'Attesterad',
									value: 5
								}, {
									text: 'Avbokad ankomstbokförd faktura',
									value: 6
								}, {
									text: 'Definitivt bokförd',
									value: 7
								}, {
									text: 'Skickad till betalning',
									value: 8
								}, {
									text: 'Återredovisad från betalningsinstitut',
									value: 9
								}, {
									text: 'Makulerad definitivt bokförd faktura ',
									value: 10
								}, {
									text: 'Betalningsmarkerad bokförd faktura',
									value: 11
								}, {
									text: 'Spärrad',
									value: 12
								}, {
									text: 'Arkiverad',
									value: 13
								}].sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))"
							/>
						</v-col>
					</v-row>
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Ankomstdatum</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model="localFilter.interval.arrival_date_from" type="text" clean no-label clearable />
							<div class="d-flex align-center justify-center delimiter">
								<span>&mdash;</span>
							</div>
							<ui-text-field v-model="localFilter.interval.arrival_date_to" type="text" clean no-label clearable />
						</v-col>
					</v-row>
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Leverantör</span>
						</v-col>

						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model="localFilter.supplierText" placeholder="Fritext" clean no-label clearable
								@input="handleSupplierChange"
							/>
							<div class="d-flex align-center justify-center delimiter">
								<span />
							</div>
							<search-supplier v-model="localFilter.supplier" clean no-label clearable
								@on-change="handleSupplierChange"
							/>
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Fakturamottagare</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-autocomplete v-model="localFilter['meta.reference.userId']" clean no-label clearable :items="getUsers"
								width="100%"
								item-key="id"
								item-text="_userName"
								item-value="id"
							>
								<template #item="{ item }">
									<span>{{ item.name }} ({{ item.login }})</span>
								</template>
							</ui-autocomplete>
						</v-col>
					</v-row>
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Referenskod</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model="localFilter.supplierReference" type="text" clean no-label clearable />
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Abonnemangstyper</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<search-subtype v-model="localFilter['meta.subscription_type']" clean no-label clearable />
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Betaldatum</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model="localFilter.interval.paidDateFrom" type="text" clean no-label clearable />
							<div class="d-flex align-center justify-center delimiter">
								<span>&mdash;</span>
							</div>
							<ui-text-field v-model="localFilter.interval.paidDateTo" type="text" clean no-label clearable />
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Belopp att betala</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model.number="localFilter.interval.payableFrom" type="text" clean no-label clearable />
							<div class="d-flex align-center justify-center delimiter">
								<span>&mdash;</span>
							</div>
							<ui-text-field v-model.number="localFilter.interval.payableTo" type="text" clean no-label clearable />
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Momsbelopp</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model.number="localFilter.interval.totalTaxFrom" type="text" clean no-label clearable />
							<div class="d-flex align-center justify-center delimiter">
								<span>&mdash;</span>
							</div>
							<ui-text-field v-model.number="localFilter.interval.totalTaxTo" type="text" clean no-label clearable />
						</v-col>
					</v-row>


					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Giro</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model="localFilter['meta.account.AccountNumber']" type="text" clean no-label clearable />
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="6">
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Verifikationsnr</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model="localFilter.vernoFrom" type="text" clean no-label clearable />
							<div class="d-flex align-center justify-center delimiter">
								<span>&mdash;</span>
							</div>
							<ui-text-field v-model="localFilter.vernoTo" type="text" clean no-label clearable />
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Verifikationsnr Prefix</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model="localFilter.verNoPrefix" type="text" clean no-label clearable />
						</v-col>
					</v-row>
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Dokumentlöpnr</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-text-field v-model.number="localFilter.interval.serialNoFrom" type="text" clean no-label clearable />
							<div class="d-flex align-center justify-center delimiter">
								<span>&mdash;</span>
							</div>
							<ui-text-field v-model.number="localFilter.interval.serialNoTo" type="text" clean no-label clearable />
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Sekretessnivå</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-select v-model="localFilter['meta.sensitivity']" clean no-label clearable
								:items="[{
									text: $t('privacy_level.none'),
									value: 0
								}, {
									text: $t('privacy_level.printing'),
									value: 1
								}, {
									text: $t('privacy_level.show'),
									value: 2
								}].sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))"
								width="100%"
							/>
						</v-col>
					</v-row>
					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Spärrad</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-select v-model="localFilter['meta.blocked']" clean no-label clearable
								:items="[{
									text: 'Ja',
									value: true
								}, {
									text: 'Nej',
									value: false
								}]"
								width="100%"
							/>
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Under utredning</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-select v-model="localFilter['meta.review']" clean no-label clearable
								:items="[{
									text: 'Ja',
									value: true
								}, {
									text: 'Nej',
									value: false
								}]"
								width="100%"
							/>
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Attestanter</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<ui-autocomplete v-model="localFilter.attestantId" clean no-label clearable
								:items="getUsers"
								label="Användare"
								item-text="_userName"
								item-value="id"
							/>
						</v-col>
					</v-row>

					<v-row no-gutters class="filter-row">
						<v-col cols="4" class="d-flex align-center">
							<span class="text-caption font-weight-bold pl-2">Grupp</span>
						</v-col>
						<v-col cols="8" class="d-flex align-center">
							<search-groups v-model="localFilter['meta.reference.groupId']" clean no-label clearable />
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-row v-if="getIsActiveCode(0)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.A }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.A_From"
										clean no-label clearable
										:items="getCodelist.A"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.A_To"
										clean no-label clearable
										:items="getCodelist.A"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>

							<v-row v-if="getIsActiveCode(1)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.B }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.B_From"
										clean no-label clearable
										:items="getCodelist.B"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.B_To"
										clean no-label clearable
										:items="getCodelist.B"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>

							<v-row v-if="getIsActiveCode(2)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.C }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.C_From"
										clean no-label clearable
										:items="getCodelist.C"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.C_To"
										clean no-label clearable
										:items="getCodelist.C"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>

							<v-row v-if="getIsActiveCode(3)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.D }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.D_From"
										clean no-label clearable
										:items="getCodelist.D"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.D_To"
										clean no-label clearable
										:items="getCodelist.D"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>

							<v-row v-if="getIsActiveCode(4)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.E }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.E_From"
										clean no-label clearable
										:items="getCodelist.E"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.E_To"
										clean no-label clearable
										:items="getCodelist.E"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>

							<v-row v-if="getIsActiveCode(5)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.F }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.F_From"
										clean no-label clearable
										:items="getCodelist.F"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.F_To"
										clean no-label clearable
										:items="getCodelist.F"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>

							<v-row v-if="getIsActiveCode(6)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.G }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.G_From"
										clean no-label clearable
										:items="getCodelist.G"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.G_To"
										clean no-label clearable
										:items="getCodelist.G"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>

							<v-row v-if="getIsActiveCode(7)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.H }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.H_From"
										clean no-label clearable
										:items="getCodelist.H"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.H_To"
										clean no-label clearable
										:items="getCodelist.H"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>

							<v-row v-if="getIsActiveCode(8)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.I }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.I_From"
										clean no-label clearable
										:items="getCodelist.I"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.I_To"
										clean no-label clearable
										:items="getCodelist.I"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>

							<v-row v-if="getIsActiveCode(9)" no-gutters class="filter-row">
								<v-col cols="4" class="d-flex align-center">
									<span class="text-caption font-weight-bold pl-2">{{ getCodeNames.J }}</span>
								</v-col>
								<v-col cols="8" class="d-flex align-center">
									<ui-autocomplete v-model.number="localFilter.code.J_From"
										clean no-label clearable
										:items="getCodelist.J"
										item-value="intval"
										:item-text="itemText"
									/>
									<div class="d-flex align-center justify-center delimiter">
										<span>&mdash;</span>
									</div>
									<ui-autocomplete v-model.number="localFilter.code.J_To"
										clean no-label clearable
										:items="getCodelist.J"
										item-value="intval"
										:item-text="itemText"
									/>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<div class="mt-6">
				<v-btn text color="warning" @click="clear"><v-icon left>mdi-close</v-icon>Rensa</v-btn>
			</div>
		</div>
	</Dialog>
</template>

<script>
import {mapGetters} from 'vuex';

const defaultLocalFilter = () => ({
	interval: {

	},
	code: {

	},
	'meta.sensitivity': 0

});

export default {
	props: {
		filter: {
			type: Object,
			default: () => ({})
		}
	},

	data: () => ({
		localFilter: defaultLocalFilter()
	}),

	computed: {
		...mapGetters('users', ['getUsers']),
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getCodeLengths', 'getIsActiveCode'])
	},

	watch: {
		filter() {
			this.localFilter = {
				...this.localFilter,
				...this.filter
			};
		}
	},

	created() {
		this.localFilter = {
			...this.localFilter,
			...this.filter
		};
	},

	methods: {
		reject() {
			this.$emit('reject');
		},

		clear() {
			this.localFilter = defaultLocalFilter();
		},

		async confirm() {
			const filters =  Object.entries(this.localFilter).reduce((acc, [key, value]) => {
				if (value === null || key === 'interval' || key === 'code' || value === '' || ['supplier_name', 'attestantId'].includes(key)) {
					return acc;
				}

				if (key === 'supplier' || key === 'supplierText') {
					return {
						...acc,
						...this.getSupplerFilter()
					};
				}

				if (key === 'supplierReference') {
					return {
						...acc,
						...this.getSupplierReferenceFilter(value)
					};
				}

				if (['vernoFrom', 'vernoTo'].includes(key)) {
					return {
						...acc,
						...this.getVerNoFilter()
					};
				}

				if (key === 'verNoPrefix') {
					return {
						...acc,
						...this.getVerNoPrefixFilter(value)
					};
				}

				if(['ids', 'meta.reference.groupId', 'meta.reference.userId', 'meta.document_sub_type',
					'meta.document_type', 'meta.sensitivity', 'meta.status_level',
					'meta.review', 'meta.blocked', 'meta.subscription_type', 'meta.account.AccountNumber'].includes(key)) {
					return {
						...acc,
						[key]: value
					};
				}

				return {
					...acc,
					[key]: {
						'$regex': `${value}`,
						'$options': 'i'
					}
				};
			}, {});
			const intervalFilters = {
				...this.getIntervalValue('meta.date.arrival', this.$utils.date.dateToInt(this.$utils.date.normalizeDate(this.localFilter.interval.arrival_date_from)),
					this.$utils.date.dateToInt(this.$utils.date.normalizeDate(this.localFilter.interval.arrival_date_to))),
				...this.getIntervalValue('meta.date.paid', this.$utils.date.dateToInt(this.$utils.date.normalizeDate(this.localFilter.interval.paidDateFrom)),
					this.$utils.date.dateToInt(this.$utils.date.normalizeDate(this.localFilter.interval.paidDateTo))),
				...this.getIntervalValue('Amounts.Payable.Amount', this.localFilter.interval.payableFrom, this.localFilter.interval.payableTo),
				...this.getIntervalValue('Amounts.TotalTax.Amount', this.localFilter.interval.totalTaxFrom, this.localFilter.interval.totalTaxTo),
				...this.getIntervalValue('meta.serial_no', this.localFilter.interval.serialNoFrom, this.localFilter.interval.serialNoTo)

			};
			const codesFilter = [
				this.getIntervalValue('codes.0', this.localFilter.code.A_From, this.localFilter.code.A_To),
				this.getIntervalValue('codes.1', this.localFilter.code.B_From, this.localFilter.code.B_To),
				this.getIntervalValue('codes.2', this.localFilter.code.C_From, this.localFilter.code.C_To),
				this.getIntervalValue('codes.3', this.localFilter.code.D_From, this.localFilter.code.D_To),
				this.getIntervalValue('codes.4', this.localFilter.code.E_From, this.localFilter.code.E_To),
				this.getIntervalValue('codes.5', this.localFilter.code.F_From, this.localFilter.code.F_To),
				this.getIntervalValue('codes.6', this.localFilter.code.G_From, this.localFilter.code.G_To),
				this.getIntervalValue('codes.7', this.localFilter.code.H_From, this.localFilter.code.H_To),
				this.getIntervalValue('codes.8', this.localFilter.code.I_From, this.localFilter.code.I_To),
				this.getIntervalValue('codes.9', this.localFilter.code.J_From, this.localFilter.code.J_To)
			].filter(obj => Object.keys(obj).length !== 0);
			const _invoideIds = await this.getInvoiceIds(codesFilter, this.localFilter.attestantId);

			this.$emit('confirm', {
				computed: {
					...filters,
					...intervalFilters
				},
				invoiceIds: _invoideIds,
				raw: {
					...this.localFilter
				}
			});
		},

		getIntervalValue(key, from, to) {
			return (!from && !to) ? {} : {
				[key]: {
					...(from ? { '$gte': from } : {}),
					...(to ? { '$lte': to } : {})
				}
			};
		},

		getSupplerFilter() {
			return {
				'$or': [
					{ 'meta.supplier_name': { '$regex': `${this.localFilter.supplier_name}`, '$options': 'i' } }
				]
			};
		},

		handleSupplierChange(supplier) {
			this.localFilter.supplier_name = supplier && supplier.name  ? supplier.name : supplier;

			this.localFilter.supplier = this.localFilter.supplier_name;
		},

		getVerNoFilter() {
			return {
				'$or': [
					{...this.getIntervalValue('meta.verification_no.arrival', this.localFilter.vernoFrom, this.localFilter.vernoTo)},
					{...this.getIntervalValue('meta.verification_no.deleted', this.localFilter.vernoFrom, this.localFilter.vernoTo)}
				]
			};
		},

		getVerNoPrefixFilter(value) {
			return {
				'$or': [
					{ 'meta.verification_no.arrival': { '$regex': `^${value}` } },
					{ 'meta.verification_no.deleted': { '$regex': `^${value}` } }
				]
			};
		},

		getSupplierReferenceFilter(value) {
			return {
				'$or': [
					{ 'References.Contract': { '$regex': `${value}`, '$options': 'i' } },
					{ 'References.AccountingCost': { '$regex': `${value}`, '$options': 'i' } },
					{ 'References.BuyerReference': { '$regex': `${value}`, '$options': 'i' } },
					{ 'References.SupplierReference': { '$regex': `${value}`, '$options': 'i' } },
					{ 'References.PurchaseOrderReference': { '$regex': `${value}`, '$options': 'i' } }
				]
			};
		},

		itemText(a) {
			return `${a.kod} - ${a.kodtext}`;
		},

		async getInvoiceIds(codeValues, idValue) {

			const hasCodeValues = codeValues.length !== 0;
			const hasIdValues = Boolean(idValue);

			if (!hasCodeValues && !hasIdValues) {
				return [];
			}

			const data = await this.$service.bookings.distinct({
				'$and' : [
					...[hasIdValues && {'$or': [
						{'meta.attestants.approvals': { '$elemMatch': { 'id' : idValue }} },
						{ 'meta.attestants.authorizations': { '$elemMatch': { 'id' : idValue }} }
					]}],
					...[hasCodeValues && {'$and': codeValues}]
				].filter(Boolean)
			});

			return data.length === 0 ? ['0000000000d75b0000000000'] : data; //TODO: kolla detta med Johan
		}
	}
};
</script>


<style lang="scss" scoped>
	datalist,
	select,
	input {
		font-family: "Roboto", sans-serif;
		letter-spacing: 0.04em;
		background-color: rgb(238, 238, 238);
		outline: none;
		width: 100%;
		height: 100%;
		padding: 6px 8px;
		transition: background-color 0.2s ease;

		&:focus {
			outline: none;
			background-color: rgb(212, 212, 212);
			transition: background-color 0.2s ease;
		}

		&.half {
			width: 45%;
		}
	}

	select {
		appearance: auto;
	}

	.filter-row {
		border-bottom: 1px solid rgb(180, 180, 180);
		border-left: 1px solid rgb(180, 180, 180);
		border-right: 1px solid rgb(180, 180, 180);

		&:first-child {
			border-top: 1px solid rgb(180, 180, 180);
		}
	}

	.delimiter {
		text-align: center;
		font-weight: bold;
		display: inline-block;
		width: 92px;
		height: 100%;
		background-color: rgb(238, 238, 238);
		color:var(--v-primary-base);
	}

</style>
