<template>
	<div class="PdfViewer">
		<div>
			<Loader :error="fetchPdfError" :loading="fetchPdfPending" hide-when-inactive slim />
		</div>
		<div style="height: 100vh;">
			<VuePdf v-show="pdf" :key="attachmentId" :pdf="pdf" :config="config" />
		</div>
	</div>
</template>

<script>
import Loader from '@/components/loader';

const LoaderComponent = {
	template: '<p>Laddar PDF-läsare</p>'
};
const ErrorComponent = {
	template: '<p>Fel vid inladdning av PDF-läsare</p>'
};

export default {
	name: 'PdfViewer',

	components: {
		Loader,
		VuePdf: () => ({
			component: import('vue-pdf-app'),
			loading: LoaderComponent,
			error: ErrorComponent,
			timeout: 4000
		})
	},

	props: {
		pdfData: {
			type: ArrayBuffer,
			default: null
		},

		attachmentId: {
			type: String,
			default: ''
		}
	},

	data: () => ({
		fetchPdfPending: false,
		fetchPdfError: '',

		pdf: null,

		config: {
			toolbar: {
				toolbarViewerRight: {
					viewBookmark: false
				}
			}
		}
	}),

	created() {
		if (this.pdfData != null) {
			this.pdf = this.pdfData;
		} else {
			this.$nextTick(async() => {
				await this.fetchAttachment();
			});
		}
	},

	methods: {
		async fetchAttachment() {
			this.fetchPdfError = '';
			this.pdf = null;

			try {
				this.pdf = await this.$service.attachment.get(this.attachmentId, {
					responseType: 'arraybuffer',
					headers: {
						'Content-Type': 'application/pdf',
						'Accept': 'application/pdf'
					},
					throwException: true,
					loading: val => {
						this.fetchPdfPending = val;
					}
				});
			} catch (error) {
				this.fetchPdfError = 'Unable to load document';
			}

		}
	}
};
</script>

<style lang="scss">

.pdf-app {
	.page {
		border: 0 !important;
	}

	#toolbarContainer {
		box-shadow: none !important;
		border-top: 1px solid var(--v-background-darken1) !important;
	}

	#toolbarViewer {
		box-shadow: 0px 5px 10px -6px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
		height: 100% !important;
	}

	* {
		font-family: Roboto, sans-serif !important;
		letter-spacing: 0.03125em !important
	}
}

.pdf-app.dark,
.pdf-app.light {
	--pdf-app-background-color: var(--v-background-base) !important;
	--pdf-sidebar-content-color: var(--v-background-base) !important;

	--pdf-toolbar-sidebar-color: var(--v-background-base) !important;
	--pdf-toolbar-color: var(--v-backgroundSecondary-base) !important;

	--pdf-loading-bar-color: #3f4b5b;
	--pdf-loading-bar-secondary-color: #8dd0ff;

	--pdf-find-results-count-color: var(--v-background-darken1) !important;
	--pdf-find-results-count-font-color: var(--v-secondaryFont-base) !important;
	--pdf-find-message-font-color: var(--v-secondaryFont-base) !important;

	--pdf-not-found-color: var(--v-warning-base) !important;

	--pdf-split-toolbar-button-separator-color: var(--v-background-darken3) !important;

	--pdf-toolbar-font-color: var(--v-secondaryFont-base) !important;

	--pdf-button-hover-font-color: var(--v-primary-base) !important;
	--pdf-button-toggled-color: var(--v-background-darken1) !important;

	--pdf-horizontal-toolbar-separator-color: var(--v-background-darken3) !important;

	--pdf-input-color: var(--v-primaryFont-base) !important;
	--pdf-input-font-color: var(--v-secondaryFont-base) !important;

	--pdf-find-input-placeholder-font-color: var(--v-secondaryFont-lighten3) !important;

	--pdf-thumbnail-selection-ring-color: var(--v-background-base) !important;
	--pdf-thumbnail-selection-ring-selected-color: var(--v-primary-base) !important;

	--pdf-error-wrapper-color: var(--v-error-base) !important;
	--pdf-error-more-info-color: var(--v-background-base) !important;
	--pdf-error-more-info-font-color: var(--v-secondaryFont-base) !important;

	--pdf-overlay-container-color: rgba(0,0,0,0.5) !important;
	--pdf-overlay-container-dialog-color: var(--v-background-base) !important;
	--pdf-overlay-container-dialog-font-color: var(--v-secondaryFont-base) !important;
	--pdf-overlay-container-dialog-separator-color: var(--v-secondaryFont-lighten5) !important;

	--pdf-dialog-button-font-color: white !important;
	--pdf-dialog-button-color: var(--v-primary-base) !important;
}

.vue-pdf-app-icon {
	display: flex;
	align-items: center;

	&::before,
	&::after {
		font-family: "Material Design Icons";
		font-size: 24px !important;
		opacity: 0.54;
		transition: all 0.3s ease;
	}

	&:hover {
		&::before,
		&::after {
			opacity: 1;
			transition: all 0.3s ease;
		}
	}

	&.sidebar-toggle::before {content: "\F0BAB";}
	&.dropdown-toolbar-button::after {content: "\F054F";}
	&.secondary-toolbar-toggle::before {content: "\F01D9";}
	&.find-previous::before {content: "\F004D";}
	&.find-next::before {content: "\F0054";}
	&.page-up::before {content: "\F005D";}
	&.page-down::before {content: "\F0045";}
	&.zoom-out::before {content: "\F06EC";}
	&.zoom-in::before {content: "\F06ED";}
	&.presentation-mode::before {content: "\F004C";}
	&.print-button::before {content: "\F042A";}
	&.open-file::before {content: "\F0DCF";}
	&.download-button::before {content: "\F01DA";}
	&.bookmark-button::before {content: "\f097";}
	&.view-thumbnail::before {content: "\F0570";}
	&.view-outline::before {content: "\F0572";}
	&.view-attachments::before {content: "\F03E2";}
	&.view-find::before {content: "\F0349";}
	&.first-page::before {content: "\F04AE";}
	&.last-page::before {content: "\F04AD";}
	&.rotate-clockwise::before {content: "\F0467";}
	&.rotate-counter-clockwise::before {content: "\F0465";}
	&.select-tool::before {content: "\F05E7";}
	&.hand-tool::before {content: "\F0E47";}
	&.scroll-vertical::before {content: "\F0BBE";}
	&.scroll-horizontal::before {content: "\F0BB8";}
	&.scroll-wrapped::before {content: "\F05B6";}
	&.spread-none::before {content: "\F14FB";}
	&.spread-odd::before {content: "\F0F0F";}
	&.spread-even::before {content: "\F0F10";}
	&.document-properties::before {content: "\F02FD";}
}

</style>
