
<template>
	<div class="InvoiceRegister">
		<div class="d-flex justify-end">
			<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
				@click="exportExcel"
			>
				<v-icon>mdi-microsoft-excel</v-icon>
			</v-btn>
		</div>

		<RegisterList v-model="selectedItems" :items="invoices" :headers="getHeaders" sidebar-width="60%" :push-content="false"
			item-key="_id"
			:pagination="pagination" show-select @active-item-changed="setActiveItem" @pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<InvoiceSidebar :invoice="getActiveInvoice" @on-refetch="fetchPayments" />
		</RegisterList>
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import InvoiceSidebar from '@/views/invoices/children/invoices/components/sidebar';

export default {
	name: 'InvoicesChildView',

	components: {
		RegisterList,
		InvoiceSidebar
	},

	data: () => ({
		invoices: [],
		password: '',
		passwordDialog: false,
		isPending: false,

		activeInvoice: null,
		activeInvoiceId: null,
		selectedItems: [],

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: [],
			sortDesc: [false]
		},

		filter: {
			meta: {
				payment_status: { '$in' : ['unpaid', 'blocked'] }
			}
		}
	}),

	computed: {
		getHeaders() {
			return [{
				text: 'Dokument-ID',
				value: 'invoice_no',
				filter: {
					type: 'text'
				}
			}, {
				text: 'Betalningsmottagare',
				value: 'creditor.name',
				filter: {
					type: 'text'
				}
			},{
				text: 'Belopp inkl. moms',
				value: 'amount',
				align: 'left',
				filter: [{
					type: 'text',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(val) : null
				},
				{
					type: 'text',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(val) : null
				}]
			}, {
				text: 'Betaldatum',
				value: 'paydate',
				filter: [{
					type: 'date',
					placeholder: 'fr.o.m.',
					transform: this.$utils.date.normalizeDate
				},
				{
					type: 'date',
					placeholder: 't.o.m.',
					transform: this.$utils.date.normalizeDate
				}]
			}, {
				text: 'Betalstatus',
				value: 'status',
				filter: {
					type: 'select',
					items: [{
						text: 'Accepterad av bank',
						value: 'accepted'
					}, {
						text: 'Avvisad av bank',
						value: 'rejected'
					}, {
						text: 'Betald',
						value: 'paid'
					}, {
						text: 'Fel',
						value: 'error'
					}, {
						text: 'Inskickad',
						value: 'sent'
					}, {
						text: 'Inväntar betaldatum',
						value: 'unpaid'
					}]
				}
			}, {
				text: 'Betalfil',
				value: 'payment_file',
				filter: {
					type: 'text'
				}
			}, {
				text: 'Skickad',
				value: 'payment_sent_date',
				filter: {
					type: 'text'
				}
			}];
		},

		getActiveInvoice() {
			if (!this.activeInvoice) {
				return null;
			}

			return {
				...this.activeInvoice,
				_id: this.activeInvoice.invoice,
				_paymentId: this.activeInvoice._id
			};
		}
	},

	methods: {
		setActiveItem(item) {
			this.activeInvoice = item;
			this.activeInvoiceId = item && item._id;
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchPayments();
		},

		onFilterUpdate(filter) {
			this.filter = filter;
			this.fetchPayments();
		},

		async exportExcel() {
			this.isPending = true;
			const data = await this.$service.payment.search({
				pageSize: 0,
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			});
			const excell = data.map(d => ({
				Utbetalningsenhet: d.debtor.name,
				Betalstatus: d.status,
				Betalningsdatum: this.$utils.date.intToPretty(d.paydate),
				Betalningsmottagare: d.creditor.name,
				'Konto & Giro': d.creditor.number,
				'Belopp inkl. moms': d.amount,
				'Dokument-ID': d.invoice_no,
				// Verifikationsnr: ????
				'System ID': d._id
			}));

			await this.$utils.excel.download(excell, 'Betalningar');
			this.isPending = false;
		},

		async fetchPayments() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const response = await this.$service.payment.search({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			});

			this.pagination.total = response._paginationTotal;
			this.invoices = response.map(item => {
				return {
					...item,
					status: this.$t(`sent_payments.${item.status}`),
					amount: this.$utils.format.price(item.amount),
					paydate:  this.$utils.date.intToPretty(item.paydate)

				};
			});
		}
	}
};
</script>

