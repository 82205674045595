<template>
	<div>
		<portal to="sidebar-right-title">
			{{ formData.name }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-tooltip fab text small
					content="Ta bort Bilagetyp"
					@click="openDeleteConfirmDialog"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</div>
		</portal>

		<v-form ref="form" @submit.prevent="submit">
			<ui-text-field v-model="formData.name" label="Bilagetyp" />

			<v-card-actions class="px-0">
				<v-spacer />
				<v-btn color="primary" text type="submit" :loading="isPending">
					{{ $t('base.save') }}
				</v-btn>
			</v-card-actions>
		</v-form>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		data: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		formData: {
			id: null,
			name: ''
		},

		isPending: false,
		error: ''
	}),

	computed: {
		...mapGetters('users', ['getUsers'])
	},

	watch: {
		'data._id'() {
			this.formData = {...this.data};
		}
	},

	created() {
		this.formData = {...this.data};
	},

	methods: {
		openDeleteConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: this.$t('delete.confirm_title', {name: 'bilagetyp'}),
				text: this.$t('delete.confirm_text', {name: this.formData.name}),
				confirmed: this.delete
			});
		},

		async submit() {
			if (!this.$refs.form.validate()) {
				return;
			}

			const { _id, ...body } = this.formData;

			try {
				await this.$service.attachment.updateType(_id, body, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});

				this.$emit('updated');
			} catch (error) {
				this.error = error.message;
			}
		},

		async delete() {
			await this.$service.attachment.deleteType(this.formData._id, {
				loading: val => {
					this.isPending = val;
				},
				throwException: true
			});

			this.$emit('deleted');

		}
	}
};
</script>
