<template>
	<ui-autocomplete v-model="groupId"
		:label="label"
		item-text="name"
		item-value="_id"
		clearable
		:items="items"
		:loading="isSearchPending"
		:clean="clean"
		:disabled="disabled"
		:rules="rules"
		:no-label="noLabel"
		@change="change"
	/>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: ''
		},

		label: {
			type: String,
			default: 'Grupp'
		},

		noLabel: {
			type: Boolean,
			default: false
		},

		clean: {
			type: Boolean,
			default: false
		},

		disabled: {
			type: Boolean,
			default: false
		},

		rules: {
			type: Array,
			default: () => ([])
		}
	},

	data: () => ({
		items: [],
		groupId: null,
		isSearchPending: false
	}),

	watch: {
		groupId(to) {
			this.$emit('input', to);
		},

		value(to) {
			this.groupId = to;
		}
	},

	async created() {
		this.groupId = this.value;
		await this.fetchGroups();
	},

	methods: {
		change(id) {
			const item = this.items.find(x => x._id === id);

			this.$emit('on-change', item);
		},

		async fetchGroups() {
			this.items = await this.$service.groups.search({
				pagination: {
					pageSize: 9999,
					page: 1
				}
			}, {
				loading: val => {
					this.isSearchPending = val;
				}
			});
		}
	}
};
</script>
