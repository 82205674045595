import i18n from '@/plugins/i18n';
import apiClient, { kpApiV1 } from '@/plugins/axios';
import {validate} from '@/utils/validation';
import {compose, getFields} from '@/utils/service';

const fields = {
	name: {
		rules: input => validate(input).required().maxLenght(50).test(),
		label: i18n.t('base.name'),
		counter: 50
	},

	email: {
		rules: input => validate(input).required().email().test(),
		label: i18n.t('base.email')
	},

	password: {
		rules: input => validate(input).required().minLenght(3).maxLenght(255).test(),
		label: i18n.t('base.password')
	},

	username: {
		rules: input => validate(input).required().minLenght(3).maxLenght(30).test(),
		label: i18n.t('base.username'),
		counter: 30
	},

	role: {
		rules: input => validate(input).required().test(),
		label: i18n.t('base.role')
	},

	grant: {
		rules: input => validate(input).isBoolean().test()
	}
};

export default {
	getSingle: (userId, opts = {}) => apiClient.get(`/wp-json/kp/v1/users/${userId}`, opts),

	get: compose(kpApiV1.get, ['users', 'users/<id>']),

	add: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/users', body, opts),

	search: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/users/search', body, opts),

	searchUserInfo: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/userinfo/search', body, opts),

	closeUser: (id, opts = {}) => apiClient.post(`/wp-json/kp/v1/users/${id}/closed`, opts),

	addIntern: (username, body = {}, opts = {}) => apiClient.post(`/wp-json/kp/v1/users/ldap/${username}`, body, opts),

	update: (userId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/users/${userId}`, body, opts),

	addReplacement: (userId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/users/${userId}/replacement`, body, opts),

	deleteReplacement: (userId, body, opts = {}) => apiClient.delete(`/wp-json/kp/v1/users/${userId}/replacement`, body, opts),

	getAaa: (uid, body, opts = {}) => apiClient.get(`/wp-json/kp/v1/aaa/${uid}`, body, opts),

	updateAaa: (uid, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/aaa/${uid}`, body, opts),

	delete: compose(kpApiV1.delete, 'users/<id>', {
		successMessage: 'service.users.delete_success'
	}),

	addRole: compose(kpApiV1.post, 'users/<id>/roles', {
		fields: getFields(fields, ['role'])
	}),

	deleteRole: compose(kpApiV1.delete, 'users/<id>/roles/<roleId>'),

	addCap: compose(kpApiV1.post, 'users/<userId>/cap/<capId>', {
		fields: getFields(fields, ['grant'])
	}),

	deleteCap: compose(kpApiV1.delete, 'users/<userId>/cap/<capId>'),

	getSites: compose(kpApiV1.get, 'users/<userId>/sites')
};
