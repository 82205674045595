<template>
	<div>
		<portal to="sidebar-right-title">
			{{ subscription.ID }} - {{ subscription.name }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<span v-if="subscription.archived" class="d-flex justify-end text-none text-caption">
					{{ `Arkiverad ${$utils.date.intToPretty(subscription.archived_date)}: ${subscription.archived_reason}` }}
				</span>
				<v-btn v-tooltip class="ml-2" fab text small
					:content="subscription.archived ? 'Avarkivera abonnemang' : 'Arkivera abonnemang'" @click.stop="openArhiveConfirmDialog"
				>
					<v-icon>mdi-package-down</v-icon>
				</v-btn>
			</div>
		</portal>

		<v-expansion-panels v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Abonnemangsuppgifter
					<span v-if="subscription.created_by" class="d-flex justify-end text-none text-caption">
						{{ `Skapad ${$utils.date.intToPretty(subscription.creation_date)} av ${subscription.created_by.name}(${subscription.created_by.login})` }}
					</span>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<subscription-new :subscription-id="subscription._id" :is-dialog="false" />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Kostnadskontering <span class="ml-4 text-caption">Kvar att kontera: <strong class="accent--text">{{ getAccountingAmountLeft }}%</strong></span>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<Loader :error="errorMessage" :loading="isPending" />
					<accounting v-if="getAccountingAmountLeft !== 0 || accountingAddData"
						v-model="accountingAddData"
						:amount-left="getAccountingAmountLeft"
						@add="addAccounting"
						@save="addAccounting"
						@reset="accountingAddData = null"
						@delete="removeAccountingRow(accountingAddData)"
					/>
					<Loader :error="removeError" />

					<accounting-rows :accounting="subscription.accounting"
						:attestants="subscription.attestants"
						@on-click="item => { accountingAddData = item}"
					/>

					<div class="d-flex justify-end mt-2">
						<v-btn v-if="getAccountingAmountLeft === 0" color="accent" text @click="showChooseAttestant = true">Välj attestanter</v-btn>
						<v-btn v-if="getApproveAttestAccountingRows" text color="green" @click="openSign('approve_accounting')">Godkänn Kostnadskontering</v-btn>
						<v-btn v-if="getApproveAttestAuthorizationRows" text color="green" @click="openSign('authorize_accounting')">
							Beslutsattestera
							Kostnadskontering
						</v-btn>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Momskontering <span class="ml-4 text-caption">Kvar att kontera: <strong class="accent--text">{{ getAccountingVatAmountLeft }}%</strong></span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Loader :error="vatErrorMessage" :loading="isPending" />
					<accounting v-if="getAccountingVatAmountLeft !== 0 || vatAccountingAddData"
						v-model="vatAccountingAddData"
						:is-vat="true"
						:amount-left="getAccountingVatAmountLeft"
						@add="addVatAccounting"
						@save="addVatAccounting"
						@reset="vatAccountingAddData = null"
						@delete="removeVatAccountingRow(vatAccountingAddData)"
					/>
					<Loader :error="vatRemoveError" />

					<accounting-rows :accounting="subscription.vat_accounting" :is-vat="true" :attestants="subscription.vat_attestants"
						@on-click="item => { vatAccountingAddData = item}"
					/>
					<div class="d-flex justify-end mt-2">
						<v-btn v-if="getAccountingVatAmountLeft === 0" color="accent" text @click="showChooseVatAttestant = true">
							Välj
							attestanter
						</v-btn>
						<v-btn v-if="getApproveAttestAccountingVatRows" text color="green" @click="openSign('approve_vat_accounting')">
							Godkänn
							Momskontering
						</v-btn>
						<v-btn v-if="getApproveAttestAuthorizationVatRows" text color="green" @click="openSign('authorize_vat_accounting')">
							Beslutsattestera
							Momskontering
						</v-btn>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Statistik
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<subscription-statistics :subscription-id="subscriptionId" />
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<Dialog v-if="signOpen"
			title="Signera"
			ok-btn-text="Sign"
			:loading="signLoading"
			@confirm="sign"
			@reject="signOpen = false; signType = null;"
		>
			<v-text-field ref="passwordInput" v-model="signPassword" label="Lösenord" type="password" />
			<Loader :error="signError" />
		</Dialog>

		<choose-attestant v-if="showChooseAttestant"
			:subscription-id="subscriptionId"
			:rows="subscription.accounting"
			:sub-attestants="subscription.attestants"
			@on-close="showChooseAttestant = false"
			@on-submit="
				showChooseAttestant = false;
				fetchSubscription();
			"
		/>

		<choose-attestant v-if="showChooseVatAttestant"
			:is-vat="true"
			:subscription-id="subscriptionId"
			:rows="subscription.vat_accounting"
			:sub-attestants="subscription.vat_attestants"
			@on-close="showChooseVatAttestant = false"
			@on-submit="
				showChooseVatAttestant = false;
				fetchSubscription();
			"
		/>
	</div>
</template>

<script>
import SubscriptionNew from './subscription-new';
import SubscriptionStatistics from './subscription-statistics';
import Accounting from './accounting';
import AccountingRows from './accounting-rows';
import ChooseAttestant from './choose-attestant';
import { uid } from 'uid';
import { mapGetters } from 'vuex';


export default {
	components: {
		SubscriptionNew,
		Accounting,
		AccountingRows,
		SubscriptionStatistics,
		ChooseAttestant
	},

	props: {
		subscriptionId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		panel: [0],
		accountingAddData: null,
		vatAccountingAddData: null,
		isCost: false,
		subscription: {},

		showChooseAttestant: false,
		showChooseVatAttestant: false,

		vatRemoveError: '',
		removeError: '',

		signOpen: false,
		signType: null,
		signLoading: false,
		signError: '',
		signPassword: '',

		isPending: false,
		errorMessage: '',
		vatErrorMessage: ''
	}),

	computed: {
		...mapGetters('userStore', ['getUserData']),

		getAccountingAmountLeft() {
			if (!this.subscription.accounting) {
				return 100;
			}

			return 100 - this.subscription.accounting.reduce((acc, curr) => curr.percent + acc, 0);
		},

		getAccountingVatAmountLeft() {
			if (!this.subscription.vat_accounting) {
				return 100;
			}

			return 100 - this.subscription.vat_accounting.reduce((acc, curr) => curr.percent + acc, 0);
		},

		getApproveAttestAccountingRows() {
			if (this.getAccountingAmountLeft !== 0) {
				return null;
			}

			const rows = [];

			this.subscription.attestants.forEach((row, index) => {
				const canApprove = row.approvals.some(user => user.id === this.getUserData.id);

				if (canApprove) {
					rows.push(index);
				}
			});

			return rows.length !== 0 ? rows : null;
		},

		getApproveAttestAuthorizationRows() {
			if (this.getAccountingAmountLeft !== 0) {
				return null;
			}

			const rows = [];

			this.subscription.attestants.forEach((row, index) => {
				const canApprove = row.authorizations.some(user => user.id === this.getUserData.id);

				if (canApprove) {
					rows.push(index);
				}
			});

			return rows.length !== 0 ? rows : null;
		},

		getApproveAttestAccountingVatRows() {
			if (this.getAccountingAmountLeft !== 0) {
				return null;
			}

			const rows = [];

			this.subscription.vat_attestants.forEach((row, index) => {
				const canApprove = row.approvals.some(user => user.id === this.getUserData.id);

				if (canApprove) {
					rows.push(index);
				}
			});

			return rows.length !== 0 ? rows : null;
		},

		getApproveAttestAuthorizationVatRows() {
			if (this.getAccountingAmountLeft !== 0) {
				return null;
			}

			const rows = [];

			this.subscription.vat_attestants.forEach((row, index) => {
				const canApprove = row.authorizations.some(user => user.id === this.getUserData.id);

				if (canApprove) {
					rows.push(index);
				}
			});

			return rows.length !== 0 ? rows : null;
		}
	},

	watch: {
		subscriptionId() {
			this.fetchSubscription();

			this.accountingAddData = null;
			this.vatAccountingAddData = null;
			this.showChooseAttestant = false;
			this.showChooseVatAttestant = false;
			this.vatRemoveError = '';
			this.removeError = '';
		}
	},

	created() {
		this.fetchSubscription();
	},

	methods: {
		openArhiveConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: this.subscription.archived ? 'Avarkivera felaktigt abonnemang' : 'Arkivera felaktigt abonnemang',
				text: `Är du säker på att du vill ${this.subscription.archived ? 'avarkivera' : 'arkivera'} abonnemang ${this.subscription.name} (${this.subscription.ID})?`,
				showMessage: true,
				confirmed: this.archiveSubsctiption
			});
		},

		async archiveSubsctiption(params) {
			if (!params.message) {
				throw 'Du måste fylla i meddelande.';
			}

			if(this.subscription.archived) {
				this.subscription.archived = false;
				this.subscription.archived_reason = null;
			} else {
				this.subscription.archived = true;
				this.subscription.archived_reason = params.message;
			}


			await this.$service.subscriptions.update(this.subscription._id, {
				...this.subscription
			});

			this.$emit('on-archive');
		},

		openSign(type) {
			this.signOpen = true;
			this.signType = type;
		},

		async sign() {
			this.signError = '';

			try {
				if (this.signType === 'approve_accounting') {
					await this.$service.subscriptions.approveAccounting(this.subscriptionId, {
						rows: this.getApproveAttestAccountingRows,
						password: this.signPassword
					}, {
						throwException: true,
						loading: val => {
							this.signLoading = val;
						}
					});
				}

				if (this.signType === 'authorize_accounting') {
					await this.$service.subscriptions.authorizeAccounting(this.subscriptionId, {
						rows: this.getApproveAttestAuthorizationRows,
						password: this.signPassword
					}, {
						throwException: true,
						loading: val => {
							this.signLoading = val;
						}
					});
				}

				if (this.signType === 'approve_vat_accounting') {
					await this.$service.subscriptions.approveAccountingVat(this.subscriptionId, {
						rows: this.getApproveAttestAccountingVatRows,
						password: this.signPassword
					}, {
						throwException: true,
						loading: val => {
							this.signLoading = val;
						}
					});
				}

				if (this.signType === 'authorize_vat_accounting') {
					await this.$service.subscriptions.authorizeAccountingVat(this.subscriptionId, {
						rows: this.getApproveAttestAuthorizationVatRows,
						password: this.signPassword
					}, {
						throwException: true,
						loading: val => {
							this.signLoading = val;
						}
					});
				}

				this.signPassword = '';
				this.signOpen = false;
				this.signType = null;

				this.fetchSubscription();
			} catch (error) {
				this.signError = error.message;
			}
		},

		async addAccounting() {
			// eslint-disable-next-line no-unused-vars
			const {_id, ...formData } = this.accountingAddData;
			const rows = [...this.subscription.accounting.filter(x => x._id !== this.accountingAddData._id), formData];

			try {
				await this.$service.subscriptions.updateAccounting(this.subscriptionId, {
					rows: rows.map(x => ({
						codes: x.codes,
						note: x.note,
						percent: x.percent
					}))
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});
				this.accountingAddData = null;

			} catch(error) {
				this.errorMessage = error.message;
			}


			this.fetchSubscription();
		},

		async addVatAccounting() {
			// eslint-disable-next-line no-unused-vars
			const {_id, ...formData } = this.vatAccountingAddData;
			const rows = [...this.subscription.vat_accounting.filter(x => x._id !== this.vatAccountingAddData._id), formData];

			try {
				await this.$service.subscriptions.updateAccountingVat(this.subscriptionId, {
					rows: rows.map(x => ({
						codes: x.codes,
						note: x.note,
						percent: x.percent,
						is_cost: x.is_cost
					}))
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});
				this.vatAccountingAddData = null;
			} catch(error) {
				this.vatErrorMessage = error.message;
			}


			this.fetchSubscription();
		},

		async removeAccountingRow(row) {
			this.removeError = '';
			const rows = this.subscription.accounting.filter(x => x._id !== row._id);

			try {
				await this.$service.subscriptions.updateAccounting(this.subscriptionId, {
					rows: rows.map(x => ({
						codes: x.codes,
						note: x.note,
						percent: x.percent
					}))
				}, {
					throwException: true
				});

				this.accountingAddData = null;

				this.fetchSubscription();
			} catch (error) {
				this.removeError = error.message;
			}

		},

		async removeVatAccountingRow(row) {
			this.vatRemoveError = '';
			const rows = this.subscription.vat_accounting.filter(x => x._id !== row._id);

			try {
				await this.$service.subscriptions.updateAccountingVat(this.subscriptionId, {
					rows: rows.map(x => ({
						codes: x.codes,
						note: x.note,
						percent: x.percent,
						is_cost: x.is_cost
					}))
				}, {
					throwException: true
				});

				this.vatAccountingAddData = null;

				this.fetchSubscription();
			} catch (error) {
				this.vatRemoveError = error.message;
			}
		},

		async fetchSubscription() {
			this.subscription = await this.$service.subscriptions.get(this.subscriptionId);

			this.subscription.accounting = this.subscription.accounting.map(x => ({...x, _id: uid()}));
			this.subscription.vat_accounting = this.subscription.vat_accounting.map(x => ({ ...x, _id: uid() }));
		}
	}
};
</script>
