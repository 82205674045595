import apiClient, { kpApiV1 } from '@/plugins/axios';
import { compose } from '@/utils/service';

export default {
	get: opts => apiClient.get('/wp-json/kp/v1/accounting/codelist', opts),

	getCodelist: compose(kpApiV1.get, 'accounting/codelist'),

	validate: compose(kpApiV1.post, 'accounting/validate'),

	delete: compose(kpApiV1.delete, 'accounting/<id>'),

	update: (id, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/accounting/${id}`, body, opts),

	sign: compose(kpApiV1.post, 'accounting/sign'),

	signRows: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/accounting/sign', body, opts),

	searchRules: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/accounting/attestants/search', body, opts),

	createRule: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/accounting/rules', body, opts),

	deleteRule: (ruleId, body = {}, opts = {}) => apiClient.delete(`/wp-json/kp/v1/accounting/rules/${ruleId}`, body, opts),

	updateRule: (ruleId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/accounting/rules/${ruleId}`,body, opts),

	requestAttestants: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/accounting/request_attestants', body, opts)
};
