<template>
	<Dialog
		max-width="600"
		title="Ny Bokföringsperiod"
		@reject="reject"
		@confirm="confirm"
	>
		<v-form ref="form">
			<v-row>
				<v-col cols="12">
					<v-menu
						v-model="dateMenu"
						:close-on-content-click="true"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<ui-text-field
								:value="formData.month"
								class="pr-2"
								readonly
								dense
								v-bind="attrs"
								label="Period"
								:rules="$validator('base/required')"
								v-on="on"
							/>
						</template>
						<v-date-picker
							v-model="formData.month"
							type="month"
							no-title
							scrollable
						/>
					</v-menu>
					<ui-text-field v-model="formData.before" :rules="$validator('accounting/periodMin')" label="Dagar före" />
					<ui-text-field v-model="formData.after" :rules="$validator('accounting/periodMin')" label="Dagar efter" />
				</v-col>
			</v-row>
		</v-form>
		<Loader :error="errorMessage" :loading="isAddPending" />
	</Dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	data: () => ({
		open: true,
		dateMenu: false,


		formData: {
			month: '',
			before: null,
			after: 3
		},

		isAddPending: false,

		errorMessage: '',
		groups: []
	}),

	computed: {
		...mapGetters('users', ['getUsers'])
	},

	watch: {
		'formData.month'() {
			if(new Date(this.formData.month).getTime() >= new Date().getTime()) {
				return this.formData.before = this.$utils.date.daysBetweenDates(new Date(this.formData.month), new Date());
			}

			return this.formData.before = 3;
		}
	},

	methods: {
		reject() {
			this.$emit('close');
		},

		async confirm() {
			this.errorMessage = '';

			if (!this.$refs.form.validate()) {
				return;
			}

			try {
				const data = await this.$service.bookings.createPeriod(this.formData, {
					throwException: true,
					loading: val => {
						this.isAddPending = val;
					}
				});

				this.$emit('close');
				this.$emit('created', data);
			} catch (error) {
				this.errorMessage = error.message;
			}
		}
	}
};
</script>
