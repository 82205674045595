<template>
	<div>
		<v-row no-gutters>
			<v-col cols="8" />
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
						@click="exportExcel"
					>
						<v-icon>mdi-microsoft-excel</v-icon>
					</v-btn>
					<v-btn color="accent mr-2" @click="newDialogOpen = true">
						Ny fast konteringsmall
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<register-list ref="registerList" :pagination="pagination" :items="codeStrings" :headers="headers" sidebar-width="50%"
			item-key="_id"
			:push-content="false"
			@active-item-changed="setActiveItem"
		>
			<edit-sidebar :accounting-template="active" @updated="fetch" />
		</register-list>

		<new-dialog v-if="newDialogOpen" @close="newDialogOpen = false" @created="onCreated" />
	</div>
</template>

<script>
import NewDialog from './components/accounting-template-new';
import RegisterList from '@/components/register-list';
import EditSidebar from './components/accounting-template-edit';
import {mapGetters} from 'vuex';


export default {
	components: {
		NewDialog,
		RegisterList,
		EditSidebar
	},

	data: () => ({
		newDialogOpen: false,
		codeStrings: [],
		active: null,
		sidebarOpen: false,

		headers: [{
			text: 'Namn',
			value: 'name',
			align: 'start'
		}],

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortDesc: [true]
		},
		isPending: false
	}),

	computed: {
		...mapGetters('accounting', ['getCodeNames', 'getCodeText', 'getCodeValue'])
	},

	created() {
		this.fetch();
	},

	methods: {
		setActiveItem(item) {
			this.active = item;
		},

		onCreated(item) {
			this.active = item;

			this.fetch();
		},

		async exportExcel() {
			this.isPending = true;
			const data = await this.$service.codestrings.search({
				pageSize: 0,
				excel: true,
				filter:  {
					uid: 0
				}
			});
			const exportFile = data.map(d => ({
				...d,
				[this.getCodeNames.A]: this.getCodeValue('A', d.codes[0]),
				[this.getCodeNames.B]: this.getCodeValue('B', d.codes[1]),
				[this.getCodeNames.C]: this.getCodeValue('C', d.codes[2]),
				[this.getCodeNames.D]: this.getCodeValue('D', d.codes[3]),
				[this.getCodeNames.E]: this.getCodeValue('E', d.codes[4]),
				[this.getCodeNames.F]: this.getCodeValue('F', d.codes[5]),
				[this.getCodeNames.G]: this.getCodeValue('G', d.codes[6]),
				[this.getCodeNames.H]: this.getCodeValue('H', d.codes[7]),
				[this.getCodeNames.I]: this.getCodeValue('I', d.codes[8]),
				[this.getCodeNames.J]: this.getCodeValue('J', d.codes[9])
			}));

			await this.$utils.excel.download(exportFile, 'Konteringsmallar');
			this.isPending = false;
		},

		async fetch() {
			const data = await this.$service.codestrings.search({
				filter:  {
					uid: 0
				}
			});

			this.pagination.total = data._paginationTotal;

			this.codeStrings = data;
		}
	}
};
</script>
