<template>
	<div class="d-flex">
		<v-checkbox :id="localId" v-model="localValue" :disabled="disabled" @change="handleChange" />
		<div v-if="!noLabel" :class="getHeaderClasses">
			<label :for="localId" class="text-subtitle-2 font-weight-medium" style="cursor: pointer;">
				<slot name="ui-label" />{{ label }}
			</label>
			<v-spacer />
			<span v-if="required" style="position: relative; top: 3px;"
				class="error--text text-subtitle-1 font-weight-medium"
			>*</span>
			<span v-else class="text-subtitle-1 font-weight-medium" style="color: transparent">&nbsp;</span>
		</div>
	</div>
</template>

<script>
import { uid } from 'uid';

export default {
	inheritAttrs: false,

	props: {
		label: {
			type: String,
			default: ''
		},

		required: {
			type: Boolean,
			default: false
		},

		noLabel: {
			type: Boolean,
			default: false
		},

		value: {
			type: Boolean
		},

		disabled: {
			type: Boolean
		},

		item: {
			type: Object,
			default: () => ({})
		}
	},

	data: () => ({
		localId: null,
		localValue: false
	}),

	computed: {
		getHeaderClasses() {
			return {
				'd-flex': true,
				'justify-center': true,
				'align-center': true
			};
		}
	},

	watch: {
		value(to) {
			this.localValue = to;
		},

		localValue(to) {
			this.$emit('input', to);
		}
	},

	created() {
		this.localValue = this.value;
	},

	mounted() {
		this.localId = uid();
	},

	methods: {
		handleChange() {
			this.$emit('change', {
				...this.item,
				rn: this.localValue
			});
		}
	}
};
</script>
