<template>
	<ui-movable-dialog
		title="Ny Leverantör"
		width="1024"
		:is-dialog="isDialog"
		@reject="reject"
		@confirm="confirm"
	>
		<loader v-if="createSupplierError" :error="createSupplierError" />
		<supplier-form ref="form" v-model="formData" />
	</ui-movable-dialog>
</template>

<script>
import supplierForm from './supplier-form';

export default {
	components: {
		supplierForm
	},

	props: {
		invoice: {
			type: Object,
			required: false,
			default: () => ({})
		},

		isDialog: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		formData: {},

		isCreateSupplierPending: false,
		createSupplierError: ''
	}),

	created() {
		if(this.invoice.ID) {
			this.formData = {
				orgNumber: this.invoice.Entities.Seller.Legal.ID,
				name: this.invoice.Entities.Seller.Legal.Name,
				address: this.invoice.Addresses.Seller.StreetName,
				zipCode: this.invoice.Addresses.Seller.PostalZone,
				town: this.invoice.Addresses.Seller.CityName,
				phonenumber: this.invoice.Contacts.Seller.Telephone,
				email: this.invoice.Contacts.Seller.Email,
				vatNo: this.invoice.Entities.Seller.VAT.ID
			};
		}
	},

	methods: {
		reject() {
			this.$emit('close');
		},

		async confirm() {
			this.createSupplierError = '';

			if (!this.$refs.form.validate()) {
				return;
			}

			try {

				const data = await this.$service.suppliers.create(this.formData, {
					throwException: true,
					loading: val => {
						this.isCreateSupplierPending = val;
					}
				});

				this.$emit('close');
				this.$emit('supplier-created', data);

			} catch (error) {
				this.createSupplierError = error.message;
			}
		}
	}
};
</script>
