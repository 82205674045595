<template>
	<div>
		<v-row no-gutters>
			<v-col cols="8" />
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn v-if="isUserAllowedToAttachmentEdit" color="accent mr-2" @click="newDialogOpen = true">
						Ny Bilagetyp
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<RegisterList ref="registerList" :pagination="pagination" :items="attachmentTypes" :headers="headers" sidebar-width="50%"
			:no-click="!isUserAllowedToAttachmentEdit"
			item-key="_id"
			:push-content="false"
			@active-item-changed="setActiveItem"
		>
			<EditSidebar :data="active" @updated="fetch" @deleted="onDeleted" />
		</RegisterList>

		<NewDialog v-if="newDialogOpen" @close="newDialogOpen = false" @created="onCreated" />
	</div>
</template>

<script>
import NewDialog from './components/attachment-type-new';
import RegisterList from '@/components/register-list';
import EditSidebar from './components/attachment-edit';
import { mapGetters } from 'vuex';




export default {


	components: {
		NewDialog,
		RegisterList,
		EditSidebar
	},

	data: () => ({
		newDialogOpen: false,
		attachmentTypes: [],
		active: null,
		sidebarOpen: false,

		headers: [{
			text: 'Bilagetyper',
			value: 'name',
			align: 'start'
		}],

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortDesc: [true]
		}
	}),

	computed: {
		...mapGetters('userStore', ['isUserAllowedToAttachmentEdit'])
	},

	created() {
		this.fetch();
	},

	methods: {
		onCreated(item) {
			this.active = item;

			this.fetch();
		},

		onDeleted() {
			this.active = null;

			this.$refs.registerList.close();

			this.fetch();
		},

		setActiveItem(item) {
			this.active = item;
		},

		async fetch() {
			const data = await this.$service.attachment.listTypes();

			this.pagination.total = data._paginationTotal;

			this.attachmentTypes = data;

		}
	}
};
</script>
