<template>
	<div>
		Loggar ut..

		<v-form ref="logoutForm" :action="logoutUrl" method="POST">
			<input type="hidden" name="redirectUrl" :value="getSamlRedirectUrl">
			<input type="hidden" name="revoke" value="false">
		</v-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'LogoutView',

	data: vm => ({
		logoutUrl: `${vm.$config.apiBaseUrl}wp-json/kp/v1/token/logout`
	}),

	computed: {
		...mapGetters('userStore', ['isUserMasqed']),

		getSamlRedirectUrl() {
			return `${location.origin}/login`;
		}
	},

	async created() {
		await this.doLogout();
	},

	methods: {
		...mapActions('userStore', ['logout', 'logoutMasq']),

		async doLogout() {
			if (this.isUserMasqed) {
				await this.logoutMasq();
				this.$router.push('/');

				return;
			}

			await this.logout();
			this.$router.push('/login');
			//this.$refs.logoutForm.$el.submit();
		}
	}
};
</script>
