export default {
	GLOBAL_ATTEST_ADD(state, payload) {
		if (state.attests.some(x => x.attestId === payload.attestId)) {
			return;
		}

		state.attests = [...state.attests, payload];
	},

	GLOBAL_ATTEST_REMOVE(state, payload) {
		state.attests = state.attests.filter(x => !payload.includes(x.attestId));
	}
};
