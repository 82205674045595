<template>
	<div style="height: 500px;">
		<codemirror v-model="data" :options="cmOptions" />
	</div>
</template>

<script>
import {codemirror} from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/mode/xml/xml.js';

export default {
	components: {
		codemirror
	},

	props: {
		data: {
			type: String,
			default: ''
		}
	},

	data: () => ({
		cmOptions: {
			tabSize: 4,
			mode: 'application/xml',
			theme: 'monokai',
			lineNumbers: true,
			line: true
		}
	})
};
</script>

<style>
	.vue-codemirror,
	.CodeMirror {
		height: 100%;
	}
</style>
