<template>
	<v-expansion-panel>
		<v-expansion-panel-header class="text-button" style="line-height: 100%;">
			Användare
		</v-expansion-panel-header>

		<v-expansion-panel-content>
			<Loader :error="errorMessage" :loading="isPending" />
			<label v-if="isUserAllowedToGroupEdit">Lägg till användare </label>
			<div v-if="isUserAllowedToGroupEdit" class="d-flex align-center">
				<ui-autocomplete v-model="userId" style="width: 50% !important" :items="getUsers" class="pr-8" item-text="_userName"
					item-value="id"
				/>
				<v-btn class="" fab x-small color="primary" elevation="0"
					:disabled="isPending"
					@click="addUser"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</div>

			<v-list dense nav class="pa-0">
				<v-list-item-group color="primary">
					<v-list-item v-for="user in groupUsers" :key="user.id" inactive>
						<v-list-item-content>
							<v-list-item-title v-text="getUsers.find(u => u.id === user.id)._userName" />
						</v-list-item-content>
						<v-list-item-icon v-if="isUserAllowedToGroupEdit" @click="deleteUser(user.id)">
							<v-icon>mdi-delete</v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		groupUsers: {
			type: Array,
			required: true
		},

		groupId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		userId: null,
		users: [],

		isPending: false,
		errorMessage: ''
	}),

	computed: {
		...mapGetters('users', ['getUsers']),
		...mapGetters('userStore', ['isUserAllowedToGroupEdit'])
	},

	created() {
	},

	methods: {
		async addUser() {
			this.errorMessage= '';

			try {
				await this.$service.groups.addUser(this.groupId, this.userId, {
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});
			} catch(error) {
				this.errorMessage = error.message;
			}
			this.userId = null;

			this.$emit('on-user-add');
		},

		async deleteUser(userId) {
			await this.$service.groups.deleteUser(this.groupId, userId);
			this.$emit('on-user-delete');
		}
	}
};
</script>
