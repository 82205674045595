<template>
	<div>
		<v-row no-gutters>
			<v-col cols="8" />
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
						@click="exportExcel"
					>
						<v-icon>mdi-microsoft-excel</v-icon>
					</v-btn>
					<v-btn color="accent mr-2" @click="newDialogOpen = true">
						Ny Bokföringsperiod
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<RegisterList ref="registerList" :pagination="pagination" :items="attachmentTypes" :headers="headers" sidebar-width="50%"
			item-key="_id"
			:push-content="false"
			@pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
			@active-item-changed="setActiveItem"
		>
			<EditSidebar :accounting-periods="active" @updated="fetchPeriods" @deleted="onDeleted" />
		</RegisterList>

		<NewDialog v-if="newDialogOpen" @close="newDialogOpen = false" @created="onCreated" />
	</div>
</template>

<script>
import NewDialog from './components/accounting-periods-new';
import RegisterList from '@/components/register-list';
import EditSidebar from './components/accounting-periods-edit';

export default {
	components: {
		NewDialog,
		RegisterList,
		EditSidebar
	},

	data: () => ({
		newDialogOpen: false,
		attachmentTypes: [],
		active: null,
		sidebarOpen: false,

		headers: [{
			text: 'Period',
			value: 'name',
			align: 'start',
			filter: {
				type: 'text'
			}
		}, {
			text: 'Öppen fr.o.m.',
			value: 'open_from',
			filter:[{
				type: 'date',
				placeholder: 'fr.o.m.',
				transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
			}]
		}, {
			text: 'Öppen t.o.m.',
			value: 'open_to',
			filter: [
				{
					type: 'date',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				}]
		}],
		isPending: false,
		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortDesc: [true]
		},
		filter: {}

	}),
	
	methods: {
		onCreated(item) {
			this.active = item;

			this.fetchPeriods();
		},

		onDeleted() {
			this.active = null;

			this.$refs.registerList.close();

			this.fetchPeriods();
		},

		onFilterUpdate(filter) {
			this.filter = filter;
			this.fetchPeriods();
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchPeriods();
		},

		setActiveItem(item) {
			this.active = item;
		},

		async exportExcel() {
			this.isPending = true;
			const data = await this.$service.bookings.searchPeriod({
				pageSize: 0,
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			});
			const excell = data.map(d => ({
				Period: d.name,
				'Öppen fr.o.m.': this.$utils.date.intToPretty(d.open_from),
				'Öppen t.o.m.': this.$utils.date.intToPretty(d.open_to),
				'System ID': d._id
			}));

			await this.$utils.excel.download(excell, 'Bokföringsperioder');
			this.isPending = false;
		},

		async fetchPeriods() {
			const data = await this.$service.bookings.searchPeriod({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			}, {
				loading: val => {
					this.isPending = val;
				}
			});

			this.pagination.total = data._paginationTotal;

			const monthOrder = {
				'januari': 1, 'februari': 2, 'mars': 3, 'april': 4,
				'maj': 5, 'juni': 6, 'juli': 7, 'augusti': 8,
				'september': 9, 'oktober': 10, 'november': 11, 'december': 12
			};

			this.attachmentTypes = data.map(a => ({
				...a,
				open_from: this.$utils.date.intToPretty(a.open_from),
				open_to: this.$utils.date.intToPretty(a.open_to)
			})).sort((a, b) => {
				let [monthA, yearA] = a.name.split(' ');
				let [monthB, yearB] = b.name.split(' ');

				monthA = monthA.toLowerCase();
				monthB = monthB.toLowerCase();

				if (yearA !== yearB) {
					return yearA - yearB;
				}

				return monthOrder[monthA] - monthOrder[monthB];
			});
		}


	}
};
</script>
