<template>
	<div>
		<ui-autocomplete
			v-model="template"
			class="pr-2"
			:loading="isPending"
			:items="templates"
			label="Konteringsmall"
			clearable
			attach
			@change="onChange"
		/>
		<Loader :error="error" />
	</div>
</template>

<script>
import { mapGetters} from 'vuex';

export default {
	data: () => ({
		templates: [],
		template: null,

		isPending: false,

		error: ''
	}),

	computed: {
		...mapGetters('userStore', ['getUser'])
	},

	created() {
		this.fetchTemplates();
	},

	methods: {
		onChange(value) {
			this.$emit('input', value);
		},

		async fetchTemplates() {
			const org = await this.fetchOrganisation();
			const priv = await this.fetchPrivate();
			const data = [...org, ...priv];

			this.templates = data.map(template => ({ text: template.name, value: template}));
		},

		async fetchOrganisation() {
			try {
				return await this.$service.codestrings.search({
					filter:  {
						uid: 0
					}
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});
			} catch (error) {
				this.error = error.message;
			}
		},

		async fetchPrivate() {
			try {
				return await this.$service.codestrings.search({
					filter:  {
						uid: this.getUser.user.id
					}
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});
			} catch (error) {
				this.error = error.message;
			}
		}
	}

};
</script>
