<template>
	<Dialog title="Välj attestanter"
		:loading="submitIsPending"
		@confirm="onSubmit"
		@reject="$emit('on-close')"
	>
		<Loader :error="fetchAttestantsError" />

		<v-form v-if="!fetchAttestantsError" ref="form">
			<div v-for="(row, index) in rows" :key="index">
				<div class="text-subtitle-2 font-weight-bold">
					Rad {{ index + 1 }}
				</div>

				<div class="d-flex">
					<v-alert dense color="primary" text class="d-flex justify-space-between mr-1">
						<span class="text-overline">
							{{ row.codes[0] }} - {{ row.codes[1] }} - {{ row.codes[2] }} - {{
								row.codes[3]
							}} - {{ row.codes[4] }} - {{ row.codes[5] }} - {{ row.codes[6] }} - {{
								row.codes[7] }}
						</span>
					</v-alert>
					<v-alert dense color="primary" text class="d-flex justify-space-between">
						<span class="text-overline">
							{{ row.amount }}
						</span>
					</v-alert>
				</div>
				<v-row>
					<v-col cols="6">
						<div class="text-subtitle-2 font-weight-bold">Godkännare</div>
						<ui-select v-model="selectedApprovals[index]" multiple
							:rules="[required(attestants.approvals[index] && attestants.approvals[index].required)]"
							:items="attestants.approvals[index] ? attestants.approvals[index].attestants : []" :item-text="itemText"
							item-value="id"
						/>
					</v-col>
					<v-col cols="6">
						<div class="text-subtitle-2 font-weight-bold">Beslutare</div>
						<ui-select v-model="selectedAuthorizations[index]" multiple
							:rules="[required(attestants.authorizations[index] && attestants.authorizations[index].required)]"
							:items="attestants.authorizations[index] ? attestants.authorizations[index].attestants : []" :item-text="itemText"
							item-value="id"
						/>
					</v-col>
				</v-row>
			</div>
		</v-form>

		<Loader :error="submitErrorMessage" />
	</Dialog>
</template>

<script>
export default {
	props: {
		invoiceId: {
			type: String,
			required: true
		},

		rows: {
			type: Array,
			required: true
		},

		subAttestants: {
			type: Array,
			default: () => ([])
		}
	},

	data: () => ({
		attestants:  {
			approvals: [],
			authorizations: []
		},

		selectedApprovals: [],
		selectedAuthorizations: [],

		submitIsPending: false,
		submitErrorMessage: '',

		fetchAttestantsError: ''
	}),

	async created() {
		await this.fetchAttestants();
		this.setDefaults();
		//this.setSelectedAttestants();
	},

	methods: {
		itemText(a) {
			console.log(a);

			return `${a.name} (${a.login})`;
		},

		required(count) {
			return (values) => {
				if (!values || values.length > count || values.length < count) {
					return `Antal attestanter måste vara ${count}`;
				}

				return true;
			};
		},

		setDefaults() {
			this.attestants.approvals.forEach((value, index) => {
				this.$set(this.selectedApprovals, index, value.attestants.map(x => x.id).slice(0, value.required));
			});

			this.attestants.authorizations.forEach((value, index) => {
				this.$set(this.selectedAuthorizations, index, value.attestants.map(x => x.id).slice(0, value.required));
			});
		},

		setSelectedAttestants() {
			this.subAttestants.forEach((value, index) => {
				this.$set(this.selectedApprovals, index, value.approvals.map(x => x.id));
				this.$set(this.selectedAuthorizations, index, value.authorizations.map(x => x.id));
			});
		},

		async fetchAttestants() {
			this.fetchAttestantsError = '';

			try {
				this.attestants = await this.$service.invoices.getAttestants(this.invoiceId, { throwException: true });
			} catch (error) {
				this.fetchAttestantsError = error.message;
			}
		},

		async onSubmit() {
			if (!this.$refs.form.validate()) {
				return;
			}

			const data = {
				rows: this.rows.map((_, index) => {
					return {
						approvals: this.selectedApprovals[index],
						authorizations: this.selectedAuthorizations[index]
					};
				})
			};

			try {
				await this.$service.invoices.setAttestants(this.invoiceId, data, {
					throwException: true,
					loading: val => {
						this.submitIsPending = val;
					}
				});

				this.$emit('on-submit');
			} catch (error) {
				this.submitErrorMessage = error.message;
			}
		}
	}
};
</script>
