
import apiClient from '@/plugins/axios';

export default {

	get: (code, groupId, body, opts = {}) => apiClient.get(`/wp-json/kp/v1/settings/${code}${ groupId !== 0 ? `/${groupId}` : ''}`, body, opts),

	updateSingle: (code, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/settings/${code}`, body, opts),

	list: (groupId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/settings/bulk${ groupId !== 0 ? `/${groupId}` : ''}`, body, opts),

	update: (groupId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/settings/bulk${ groupId !== 0 ? `/${groupId}` : ''}`, body, opts),

	getAll: (body, opts = {}) => apiClient.get('/wp-json/kp/v1/settings/kiPayAccounts/distinct/array', body, opts)

};
