<template>
	<div class="SitesRegister">
		<v-row no-gutters>
			<v-col cols="8" />
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn v-if="isUserAllowedToSitesAdd" color="accent" @click="newDialogOpen = true">
						Ny Organisation
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<Loader :service="[$service.roles.get]" hide-loading />

		<RegisterList ref="registerList" :items="sites" :headers="getHeaders" sidebar-width="33%"
			@active-item-changed="setActiveItem"
		>
			<SiteEdit :site="activeSite" @site-update="siteUpdated" />
		</RegisterList>

		<SiteNew v-if="newDialogOpen" @close="newDialogOpen = false" />
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import SiteNew from './components/site-new';
import SiteEdit from './components/site-edit';
import {mapGetters, mapActions} from 'vuex';

export default {
	components: {
		RegisterList,
		SiteNew,
		SiteEdit
	},

	data: () => ({
		activeSite: null,
		sites: [],

		newDialogOpen: false
	}),

	computed: {
		...mapGetters('sitesStore', ['getSites']),
		...mapGetters('userStore', ['isUserAllowedToSitesAdd']),

		getHeaders() {
			return [{
				text: 'Organisation',
				value: '_title',
				align: 'start'
			}];
		}
	},

	created() {
		this.fetchAllSites();
	},

	methods: {
		...mapActions('sitesStore', ['fetchSites']),

		setActiveItem(item) {
			this.activeSite = item;
		},

		async fetchAllSites() {
			const data = await this.$service.sites.getAll();

			data.sort((x, y) => x.is_active === y.is_active ? 0 : x.is_active ? -1 : 1);

			this.sites = data.map(x => ({
				...x,
				_title: x.is_active ? `${x.title} (aktiv)` : x.title
			}));
		},

		async siteUpdated(site) {
			await this.fetchAllSites();

			if (site) {
				this.$refs.registerList.open(site);
			} else {
				this.$refs.registerList.close();
			}
		}
	}
};
</script>
