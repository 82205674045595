<template>
	<ui-movable-dialog
		max-width="1024"
		width="1024"
		title="Ny Referenskod"
		:loading="isAddPending"
		@reject="reject"
		@confirm="confirm"
	>
		<v-form ref="form" v-model="formIsValid" :disabled="$service.references.add.isPending">
			<v-row>
				<v-col cols="6">
					<ui-text-field v-model="formData.reference"
						label="Referenskod"
						:rules="$validator('reference/reference')"
					/>
				</v-col>
				<v-col cols="6">
					<ui-autocomplete
						v-model="formData.groupId"
						:items="groups"
						:rules="$validator('reference/groupId')"
						label="Grupp"
						item-text="name"
						item-value="_id"
					/>
				</v-col>
				<v-col cols="6">
					<ui-autocomplete
						v-model="selectedItem"
						:items="getUsers"
						label="Användare"
						item-text="_userName"
						return-object
						clearable
						placeholder="Lägg till ny"
						@change="addUser"
					/>
					<v-list dense nav class="pa-0">
						<v-list-item-group color="primary">
							<v-list-item v-for="user in userList" :key="user.id" inactive>
								<v-list-item-content>
									<v-list-item-title v-text="getUsers.find(u => u.id === user.id)._userName" />
								</v-list-item-content>
								<v-list-item-icon @click="deleteUser(user.id)">
									<v-icon>mdi-delete</v-icon>
								</v-list-item-icon>
							</v-list-item>
						</v-list-item-group>
					</v-list>
					<ui-checkbox v-model="formData.export" class="text-subtitle-2 font-weight-medium" label="Exportera referens" />
					<ui-checkbox v-model="formData.active" class="text-subtitle-2 font-weight-medium" label="Aktiv referens" />
				</v-col>
				<v-col cols="6">
					<ui-textarea v-model="formData.description"
						label="Beskrivning"
						:rules="$validator('reference/description')"
					/>
				</v-col>
			</v-row>
		</v-form>

		<Loader :error="error" />
	</ui-movable-dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		reference: {
			type: String,
			default: ''
		}
	},

	data: () => ({
		open: true,
		formIsValid: false,

		selectedItem: null,

		formData: {
			reference: '',
			groupId: undefined,
			userIds: [],
			description: '',
			export: true,
			active: true
		},

		isAddPending: false,

		error: '',
		groups: [],
		userList: []
	}),

	computed: {
		...mapGetters('users', ['getUsers'])
	},

	async created() {
		this.formData.reference = this.reference;
		await this.fetchGroups();
	},

	methods: {
		itemText(a) {
			return `${a.kod} - ${a.kodtext}`;
		},

		reject() {
			this.$emit('close');
		},

		addUser(object) {
			if(object) {
				this.userList.push(object);
			}

			this.$nextTick(() => {
				this.selectedItem = null;
			});
		},

		deleteUser(id) {
			const index = this.userList.findIndex((obj) => obj.id === id);

			this.userList.splice(index, 1);
		},

		async fetchGroups() {
			this.groups = await this.$service.groups.list();
		},

		async confirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.formData.userIds = this.userList.map(item => item.id);

			try {
				const data = await this.$service.references.add(this.formData, {
					loading: val => {
						this.isAddPending = val;
					},
					throwException: true
				});

				this.$emit('close');
				this.$emit('created', data);
				this.userList = [];
			} catch (error) {
				this.error = error.message;
			}
		}
	}
};
</script>
