<template>
	<div>
		<portal to="sidebar-right-title">
			{{ accountingTemplate.name }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;" />
		</portal>

		<v-card class="pa-12">
			<v-form ref="form" @submit.prevent="submit">
				<accounting-template v-model="formData" />

				<v-card-actions class="px-0 pt-12">
					<v-spacer />
					<v-btn color="primary" text type="submit" :loading="isPending">
						{{ $t('base.save') }}
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
		<Loader :error="error" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountingTemplate from '@/components/accounting-template';


export default {
	components: {
		AccountingTemplate
	},

	props: {
		accountingTemplate: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		formData: {
			uid: null,
			name: '',
			codes: []
		},

		isPending: false,
		error: ''
	}),

	computed: {
		...mapGetters('userStore', ['getUser'])
	},

	watch: {
		'accountingTemplate._id'() {
			this.formData = {...this.accountingTemplate};
		}
	},

	created() {
		this.formData = {...this.accountingTemplate};
	},

	methods: {
		async submit() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.formData.codes = this.formData.codes.map(code => code ? parseInt(code): 0);

			try {
				await this.$service.codestrings.update(this.accountingTemplate._id, {
					...this.formData
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});

				this.$emit('updated');
			} catch (error) {
				this.error = error.message;
			}
		}
	}
};
</script>
