<template>
	<v-app>
		<Layout />
		<ConfirmDialog />
		<TokenHandler />
		<portal-target name="app" />
	</v-app>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import '@/sass/app.scss';
import Layout from '@/layouts';
import TokenHandler from '@/components/token-handler';
import ConfirmDialog from '@/components/confirm-dialog';

export default {
	name: 'App',

	components: {
		Layout,
		TokenHandler,
		ConfirmDialog
	},

	computed: {
		...mapGetters('userStore', ['isLoggedIn'])
	},

	watch: {
		isLoggedIn(to) {
			if (to) {
				this.fetchSites();
				this.fetchUsers();
			}
		}
	},

	created() {
		if (this.isLoggedIn) {
			this.fetchSites();
			this.fetchUsers();
			this.refreshToken();
		}
	},

	methods: {
		...mapActions('users', ['fetchUsers']),
		...mapActions('sitesStore', ['fetchSites']),
		...mapActions('userStore', ['refreshToken'])
	}
};
</script>
