var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"title":"Ny verifikationskod"},on:{"confirm":_vm.addCode,"reject":_vm.reject}},[_c('v-form',{ref:"form",staticClass:"pt-6",attrs:{"disabled":_vm.addRequestLoading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ui-text-field',_vm._g(_vm._b({attrs:{"label":"Från","rules":_vm.$validator('group/vernoFrom'),"readonly":"","required":""},model:{value:(_vm.formData.from),callback:function ($$v) {_vm.$set(_vm.formData, "from", $$v)},expression:"formData.from"}},'ui-text-field',attrs,false),on))]}}]),model:{value:(_vm.formCodeFromMenu),callback:function ($$v) {_vm.formCodeFromMenu=$$v},expression:"formCodeFromMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.formCodeFromMenu = false}},model:{value:(_vm.formData.from),callback:function ($$v) {_vm.$set(_vm.formData, "from", $$v)},expression:"formData.from"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ui-text-field',_vm._g(_vm._b({attrs:{"label":"Till","rules":_vm.$validator('group/vernoTo'),"readonly":"","required":""},model:{value:(_vm.formData.to),callback:function ($$v) {_vm.$set(_vm.formData, "to", $$v)},expression:"formData.to"}},'ui-text-field',attrs,false),on))]}}]),model:{value:(_vm.formCodeToMenu),callback:function ($$v) {_vm.formCodeToMenu=$$v},expression:"formCodeToMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.formCodeToMenu = false}},model:{value:(_vm.formData.to),callback:function ($$v) {_vm.$set(_vm.formData, "to", $$v)},expression:"formData.to"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ui-select',{attrs:{"items":[{
						text: 'Standard',
						value: 'standard'
					}, {
						text: 'Manuell',
						value: 'manual'
					}, {
						text: 'Manuell betalning',
						value: 'manual_payment'
					}],"label":"Typ"},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ui-text-field',{attrs:{"label":"Prefix","rules":_vm.$validator('group/vernoPrefix'),"required":""},model:{value:(_vm.formData.prefix),callback:function ($$v) {_vm.$set(_vm.formData, "prefix", $$v)},expression:"formData.prefix"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ui-text-field',{attrs:{"label":"Min","type":"number","rules":_vm.$validator('group/vernoMin'),"required":""},model:{value:(_vm.formData.min),callback:function ($$v) {_vm.$set(_vm.formData, "min", $$v)},expression:"formData.min"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ui-text-field',{attrs:{"label":"Max","type":"number","rules":_vm.$validator('group/vernoMax'),"required":""},model:{value:(_vm.formData.max),callback:function ($$v) {_vm.$set(_vm.formData, "max", $$v)},expression:"formData.max"}})],1)],1),_c('Loader',{attrs:{"error":_vm.errorMessage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }