<template>
	<div>
		<v-card class="px-8 py-4">
			<div class="d-flex align-center justify-space-between pb-6">
				<h3>Attestregler</h3>
				<div class="d-flex justify-end ">
					<v-icon small color="grey">mdi-checkbox-blank-outline</v-icon>
					<span class="text-caption mr-4">{{ $t('rules.voluntary') }}</span>

					<v-icon small color="error">mdi-minus-box</v-icon>
					<span class="text-caption mr-4">{{ $t('rules.denide') }}</span>

					<v-icon small color="success">mdi-checkbox-marked</v-icon>
					<span class="text-caption">{{ $t('rules.mandatory') }}</span>
				</div>
			</div>
			<div v-for="([key, value], i) in Object.entries(getCodeNames)" :key="key">
				<checkbox-multi v-if="getIsActiveCode(i)" :label="value" :value="attestRules[i]" @input="onRuleChange($event, i)" />
			</div>

			<div class="text-right mt-6">
				<v-btn text color="primary" :loading="isPending" @click="onSave"> Spara </v-btn>
			</div>

			<Loader :error="errorMessage" />
		</v-card>
	</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import CheckboxMulti from './checkbox-multi';

const defaultData = () => [null, null, null, null, null, null, null, null, null, null];

export default {
	components: {
		CheckboxMulti
	},

	data: () => ({
		group: 0,
		isPending: false,
		errorMessage: '',

		attestRules: []
	}),

	computed: {
		...mapGetters('accounting', [ 'getCodeNames', 'getIsActiveCode'])
	},

	watch: {},

	async created() {
		this.attestRules = defaultData();

		await this.fetchSettings();
	},

	methods: {
		...mapActions('accounting', ['setCodeRules']),

		onRuleChange(e, index) {
			this.$set(this.attestRules, index, e);
		},

		setValues(settings) {
			this.attestRules = settings.kiAttestationRuleParts.value;

			this.group = settings.kiAttestationRuleParts.group ?  settings.kiAttestationRuleParts.group : 0;
		},

		async fetchSettings() {
			try {
				const settings =  await this.$service.settings.list(this.group, {
					keys: ['kiAttestationRuleParts']
				}, {
					loading: val => {
						this.isPending = val;
					}
				});

				this.setValues(settings);

			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async onSave() {
			try {
				await this.$service.settings.update(this.group, {
					'kiAttestationRuleParts': [...this.attestRules]
				}, {
					loading: val => {
						this.isPending = val;
					}
				});

			} catch (error) {
				this.errorMessage = error.message;
			}

			await this.fetchSettings();
			await this.setCodeRules();
		}
	}
};
</script>
