<template>
	<div>
		<Loader :error="errorMessage" :loading="isRemovePending" />
		<v-row>
			<v-col cols="4">
				<v-card class="pa-6">
					<v-card-title class="px-0">Ersättare</v-card-title>

					<div v-if="user" class="d-flex text-caption mt-2 align-center justify-space-between">
						<div v-if="user.replacement">
							<div v-if="user.r_to">
								Ersätts av {{ user.replacement.name }} ({{ user.replacement.login }})
								mellan {{ $utils.date.intToPretty(user.r_from) }} och {{ $utils.date.intToPretty(user.r_to) }}
							</div>
							<div v-else>
								<v-checkbox :input-value="!user.active" disabled label="Inaktiv" />
								Permanent ersatt av {{ user.replacement.name }} ({{ user.replacement.login }})
								fr.o.m. {{ $utils.date.intToPretty(user.r_from) }}
							</div>
						</div>
						<div v-if="user.r_to">
							<v-btn v-tooltip content="Ta bort ersättare" icon small :loading="isRemovePending"
								@click="removeReplacement"
							>
								<v-icon small color="error">mdi-close</v-icon>
							</v-btn>
						</div>
					</div>
					<v-divider class="mb-4 mt-1" />

					<div class="d-flex">
						<ui-datepicker v-model="replacement.from" class="mr-6" clearable label="Datum fr.o.m." />
						<div>
							<ui-datepicker v-model="replacement.to" clearable label="Datum t.o.m." hide />
							<span class="text-caption">Lämna fältet tomt för permanent ersättare</span>
						</div>
					</div>
					<div>
						<ui-autocomplete v-model="replacement.id"
							label="Användare"
							item-text="_userName"
							item-value="id"
							clearable
							:items="getUsers"
						/>
					</div>

					<Loader :error="errorMessage" :loading="isRemovePending" />

					<div class="text-end">
						<v-btn text color="warning" :loading="isReplacementPending" @click="addReplacement">Spara</v-btn>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	data: () => ({
		replacement: {
			id: '',
			from: '',
			to: ''
		},

		user: null,

		isRemovePending: false,
		isReplacementPending: false,
		errorMessage: ''
	}),

	computed: {
		...mapGetters('userStore', ['getUserData']),
		...mapGetters('users', ['getUsers'])
	},

	async created() {
		await this.fetchUser();
	},

	methods: {
		async addReplacement() {
			try {
				await this.$service.users.addReplacement(this.getUserData.id, {
					...this.replacement,
					'from': this.$utils.date.dateToInt(this.replacement.from),
					'to': this.$utils.date.dateToInt(this.replacement.to)
				}, {
					loading: val => {
						this.isReplacementPending = val;
					},
					throwException: true
				});
				this.fetchUser();
			} catch(error) {
				this.errorMessage = error.message;
			}
		},

		async removeReplacement() {
			try {
				await this.$service.users.deleteReplacement(this.user.id, {
					loading: val => {
						this.isRemovePending = val;
					},
					throwException: true
				});

				this.fetchUser();
			} catch(error) {
				this.errorMessage = error.message;
			}
		},

		async fetchUser() {
			this.user = await this.$service.users.getSingle(this.getUserData.id);
		}
	}
};
</script>
