<template>
	<Dialog
		title="Ny attesträtt"
		width="1024px"
		:is-dialog="isDialog"
		@reject="reject"
		@confirm="confirm"
	>
		<attest-form ref="form" v-model="formData" />
		<loader v-if="createAttestError" :error="createAttestError" />
	</Dialog>
</template>

<script>
import attestForm from './attest-form';

export default {
	components: {
		attestForm
	},

	props: {
		isDialog: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		formData: {},
		createAttestError: ''
	}),

	created() {

	},

	methods: {
		reject() {
			this.$emit('close');
		},

		async confirm() {
			this.createAttestError = '';

			if (!this.$refs.form.validate()) {
				return;
			}

			try {

				const data = await this.$service.accounting.createRule(this.formData, {
					throwException: true
				});

				this.$emit('close');
				this.$emit('on-created', data);

			} catch (error) {
				this.createAttestError = error.message;
			}
		}
	}
};
</script>
