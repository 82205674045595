<template>
	<div class="RolesRegister">
		<v-row no-gutters>
			<v-col cols="8" />
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
						@click="exportExcel"
					>
						<v-icon>mdi-microsoft-excel</v-icon>
					</v-btn>
					<v-btn v-if="isUserAllowedToGroupEdit" color="accent" @click="newGroup($event)">
						Ny Grupp
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<Loader :service="[$service.groups.get]" hide-loading />

		<v-treeview :active.sync="selectedGroup" :items="groups" item-key="id" return-object
			hoverable
			activatable
		>
			<template v-slot:prepend="{ item, open }">
				<v-icon v-if="!item.isUser">
					{{ open ? 'mdi-folder-open' : 'mdi-folder' }}
				</v-icon>
				<v-icon v-else>
					mdi-account
				</v-icon>
			</template>
			<template v-if="isUserAllowedToGroupEdit" v-slot:append="{ item }">
				<v-icon style="margin-right: 10px;" @click="newGroup($event, item)">
					mdi-plus
				</v-icon>
			</template>
		</v-treeview>

		<SidebarRight v-if="activeGroup" push-content width="58%" sidebar-no-footer
			@close="activeGroup = undefined"
		>
			<GroupEdit :group="activeGroup" @group-deleted="groupDeleted" @group-updated="groupUpdated" @navigate-to-group="openGroup" />
		</SidebarRight>

		<GroupNew v-if="newDialogOpen" :parent="newParentGroup" @close="newDialogOpen = false" @group-created="groupUpdated" />
	</div>
</template>

<script>
import utils from '@/utils';
import GroupEdit from './components/group-edit';
import SidebarRight from '@/components/sidebar-right';
import GroupNew from './components/group-new';
import groupUtils from './utils';
import { mapGetters } from 'vuex';

export default {
	components: {
		GroupEdit,
		SidebarRight,
		GroupNew
	},

	props: {
		idFromQueryParam: {
			type: Number,
			default: null
		}
	},

	data: () => ({
		selectedGroup: [],

		groups: [],

		activeGroup: null,

		newDialogOpen: false,
		isPending: false,
		newParentGroup: null
	}),

	computed: {
		...mapGetters('userStore', ['isUserAllowedToGroupEdit']),

		getHeaders() {
			return [{
				text: 'Namn',
				value: 'name',
				align: 'start'
			}];
		}
	},

	watch: {
		selectedGroup(to) {
			this.activeGroup = to[0];
		}
	},

	async created() {
		this.fetchGroups();

		await groupUtils.setGroupByQueryParam(this);
	},

	methods: {
		newGroup(event, group) {
			event.stopPropagation();

			this.newParentGroup = group || null;
			this.newDialogOpen = true;
		},

		setActiveItem(item) {
			this.activeGroup = item;

			groupUtils.updateQueryParams(this, item);
		},

		async groupUpdated() {
			await this.fetchGroups();
		},

		async groupDeleted() {
			this.activeGroup = null;
			await this.fetchGroups();
		},

		openGroup(group) {
			this.activeGroup = group;
		},

		async exportExcel() {
			this.isPending = true;
			const data = utils.service.run(await this.$service.groups.get.call());

			await this.$utils.excel.download(data, 'Grupper');
			this.isPending = false;
		},

		async fetchGroups() {
			utils.service.run(await this.$service.groups.get.call(), data => {
				this.groups = this.$utils.tree.createTree(data.map(x => ({ ...x, id: x._id })), 'parent', false);
			});
		}
	}
};
</script>
