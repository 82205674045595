<template>
	<div>
		<div :class="getHeaderClasses">
			<label :for="localId" class="text-subtitle-2 font-weight-medium" style="cursor: pointer;"><slot name="ui-label" />{{ label }}</label>
			<div v-if="!label">
				<v-spacer />
				<span v-if="required" style="position: relative; top: 3px;" class="error--text text-subtitle-1 font-weight-medium">*</span>
				<span v-else class="text-subtitle-1 font-weight-medium" style="color: transparent">&nbsp;</span>
			</div>
		</div>
		<v-textarea :id="localId" v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners">
			<template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
				<slot :name="scopedSlotName" v-bind="slotData" />
			</template>
			<template v-for="(_, slotName) in $slots" #[slotName]>
				<slot :name="slotName" />
			</template>
		</v-textarea>
	</div>
</template>

<script>
import {uid} from 'uid';

export default {
	inheritAttrs: false,

	props: {
		label: {
			type: String,
			default: ''
		},

		required: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		localId: null
	}),

	computed: {
		getHeaderClasses() {
			return {
				'pr-8': 'append-outer-icon' in this.$attrs,
				'pl-8': 'prepend-icon' in this.$attrs
			};
		},

		commonAttrs() {
			return {
				label: '',
				persistentHint: true,
				outlined: true,
				dense: true,
				hideDetails: false,
				required: this.$props.required,
				class: {
					'ui-text-field': true,
					'mt-0': this.$props.label
				}
			};
		}
	},

	mounted() {
		this.localId = uid();
	}
};
</script>

