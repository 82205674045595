<template>
	<div class="RolesRegister">
		<div class="mb-6" style="min-height: 48px;">
			<v-row no-gutters>
				<v-col cols="8">
					<ui-checkbox v-model="deleted" label="Arkiverad" @change="onFilter" />
				</v-col>
				<v-col cols="4">
					<div class="d-flex justify-end align-center" style="height: 48px;">
						<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
							@click="exportExcel"
						>
							<v-icon>mdi-microsoft-excel</v-icon>
						</v-btn>
						<v-btn v-if="isUserAllowedToSupplierEdit" color="accent" @click="newDialogOpen = true"> Ny Leverantör</v-btn>
					</div>
				</v-col>
			</v-row>
		</div>

		<Loader :service="[$service.suppliers.search]" hide-loading slim />

		<RegisterList
			ref="registerList"
			:items="suppliers"
			:headers="getHeaders"
			:pagination="pagination"
			item-key="_id"
			sidebar-width="50%"
			@active-item-changed="setActiveItem" @pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<SupplierEdit v-if="activeSupplierId" :supplier-id="activeSupplierId" :invoice-id="activeInvoiceId" @supplier-deleted="supplierUpdated" @supplier-updated="supplierUpdated" />
		</RegisterList>

		<SupplierNew v-if="newDialogOpen" :invoice-id="activeInvoiceId" @close="newDialogOpen = false" @supplier-created="supplierUpdated" />
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import SupplierEdit from './components/supplier-edit';
import SupplierNew from './components/supplier-new';
import { mapGetters } from 'vuex';

export default {
	components: {
		RegisterList,
		SupplierEdit,
		SupplierNew
	},

	props: {
		queryParams: {
			type: Array,
			default: () => ([])
		}
	},

	data: () => ({
		suppliers: [],
		activeSupplierId: null,
		activeInvoiceId: null,
		newDialogOpen: false,
		filterIsOpen: false,
		fromInvoice: false,

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: ['name'],
			sortDesc: [false]
		},

		deleted: null,

		filter: {},
		isPending: false
	}),

	computed: {
		...mapGetters('userStore', ['isUserAllowedToSupplierEdit']),

		getHeaders() {
			return [{
				text: 'Leverantör',
				value: 'name',
				align: 'start',
				filter: {
					type: 'text'
				}
			}, {
				text: 'Skapad',
				value: 'created_date',
				filter:[{
					type: 'date',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				},
				{
					type: 'date',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				}]
			}, {
				text: 'Ändrad',
				value: 'updated_date',
				filter:[{
					type: 'date',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				},
				{
					type: 'date',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				}]
			}, {
				text: 'Konto/Gironr',
				value: 'giro',
				filter: {
					type: 'text'
				}
			}, {
				text: 'Organisationsnr',
				value: 'orgNumber',
				filter: {
					type: 'text'
				}
			}];
		}
	},

	watch: {
		activeSupplierId(to) {
			if (to !== null && to !== 'new') {
				if (Object.keys(this.$route.query).length !== 0) {
					this.$router.push({ query: {} });
				}

				this.activeInvoiceId = null;
			}

		}
	},

	created() {
	},

	mounted() {
		this.activeSupplierId = this.$route.query.supplierId;
		this.activeInvoiceId = this.$route.query.invoiceId;

		if (this.activeSupplierId) {
			this.$refs.registerList.open({ _id: this.activeSupplierId, invoice: this.activeInvoiceId });
		}
	},

	methods: {
		setActiveItem(item) {
			if (!item) {
				this.activeSupplierId = undefined;
				this.activeInvoiceId = undefined;

				return;
			}

			this.activeSupplierId = item._id;
			this.activeInvoiceId = item.invoice;
		},

		onFilter(filterData) {
			if(filterData.rn === true) {
				this.filter.deleted = { '$ne' : null } ;
			} else {
				// eslint-disable-next-line no-unused-vars
				const {deleted: _, ...newObj} = this.filter;

				this.filter = newObj;
			}

			this.fetchSuppliers();
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchSuppliers();
		},

		async supplierUpdated(item) {
			await this.fetchSuppliers();

			if (item) {
				this.$refs.registerList.open(item);
			} else {
				this.$refs.registerList.close();
			}
		},

		async exportExcel() {
			this.isPending = true;
			const data = await this.$service.suppliers.search({
				pageSize: 0,
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					'deleted': this.filter.deleted
				}
			});
			let excell = [];

			data.map(d => {
				if(d.giro && d.giro.length) {
					for (let i = 0; i < d.giro.length; i++) {
						excell.push({
							'Organisationsnr': d.orgNumber ? d.orgNumber : '-',
							'Leverantörsnamn': d.name,
							'Aktiv': d.active ? 'Ja': 'Nej',
							'Arkiverad': d.deleted ? 'Ja' : 'Nej',
							'Adress': d.address,
							'Postnr': d.zipCode,
							'Telefonnr': d.phonenumber,
							'E-post': d.email ? d.email : '-',
							'Momsregistrering': d.vatNo ,
							'Momstyp': d.vat_type,
							'Antal betaldagar': d.duedays,
							'Betalningsfrist': d.max_duedays,
							'Momsregistrerad': d.vatRegistered ? 'Ja' : 'Nej',
							'F-skatt': d.fvatRegistered ? 'Ja' : 'Nej',
							'Konto/gironr': d.giro[i].bic,
							'Konto': d.giro[i].number,
							'Gironr aktiv':  d.giro[i].active ? 'Ja' : 'Nej'
						});
					}
				} else if (d.giro && d.giro == []) {
					excell.push({
						'Organisationsnr': d.orgNumber ? d.orgNumber : '-',
						'Leverantörsnamn': d.name,
						'Aktiv': d.active ? 'Ja': 'Nej',
						'Arkiverad': d.deleted ? 'Ja' : 'Nej',
						'Adress': d.address,
						'Postnr': d.zipCode,
						'Telefonnr': d.phonenumber,
						'E-post': d.email ? d.email : '-',
						'Momsregistrering': d.vatNo ,
						'Momstyp': d.vat_type,
						'Antal betaldagar': d.duedays,
						'Betalningsfrist': d.max_duedays,
						'Momsregistrerad': d.vatRegistered ? 'Ja' : 'Nej',
						'F-skatt': d.fvatRegistered ? 'Ja' : 'Nej',
						'Konto/gironr': '-',
						'Konto': '-',
						'Gironr aktiv':  '-'
					});
				}
			});

			await this.$utils.excel.download(excell, 'Leverantörer');
			this.isPending = false;
		},

		async fetchSuppliers() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const data = await this.$service.suppliers.search({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					'deleted': this.filter.deleted
				}
			});

			this.pagination.total = data._paginationTotal;
			this.suppliers = data.map(supplier => ({
				...supplier,
				created_date: supplier.created_date  ? this.$utils.date.intToPretty(supplier.created_date) : '',
				updated_date:  supplier.updated_date  ? this.$utils.date.intToPretty(supplier.updated_date) : '',
				giro: supplier.giro && supplier.giro.map(giro => ({
					...giro,
					paymentMethods: `${/^\d/.test(giro.bic) ? giro.bic : giro.bic.substring(0,2)}: ${this.$utils.format.giro(giro.bic, giro.number)}`
				}))
			}));
		},

		async onFilterUpdate(filter) {
			this.filter = filter;
			await this.fetchSuppliers();
		}
	}
};
</script>
