import {run} from '@/utils/service';

export const setGroupByQueryParam = async vm => {
	if (!vm.idFromQueryParam) {
		return;
	}

	run(await vm.$service.groups.get.call({ id: vm.idFromQueryParam }), data => {
		vm.activeGroup = data;
		vm.$refs.registerList.open(data);
	});
};

export const updateQueryParams = (vm, user) => {
	if (!user) {
		// eslint-disable-next-line no-unused-vars
		const { id, ...query } = vm.$route.query;

		vm.$router.push({ query });

		return;
	}

	if (vm.idFromQueryParam !== user.id) {
		vm.$router.push({ query: { ...vm.$route.query, id: user.id } });
	}
};

export default {
	setGroupByQueryParam,
	updateQueryParams
};
