import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {dark, light} from '@/config/colors';
import i18n from './i18n';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark: localStorage.getItem('kfp-dark') ? JSON.parse(localStorage.getItem('kfp-dark')) : false,
		options: {
			customProperties: true
		},
		themes: {
			dark,
			light
		}
	},

	lang: {
		t: (key, ...params) => i18n.t(key, params)
	}
});
