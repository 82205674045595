
<template>
	<ui-movable-dialog :title="isDialog ? 'Nytt Abonnemang' : ''"
		:is-dialog="isDialog"
		:show-cancel-btn="isDialog"
		width="1024"
		:loading="formPostIsPending"
		@reject="close"
		@confirm="createOrUpdateSubscription"
	>
		<v-form ref="form">
			<v-row>
				<v-col cols="4">
					<ui-select v-model="formData.type" label="Kategori" :items="types" item-text="name" item-value="_id" />
				</v-col>
				<v-col cols="4">
					<ui-text-field v-model="formData.ID" label="Abonnemangsnr" />
				</v-col>
				<v-col cols="4">
					<ui-text-field v-model="formData.name" label="Namn" />
				</v-col>
				<v-col cols="4">
					<ui-text-field v-model="formData.note" label="Kommentar" />
				</v-col>

				<v-col cols="4">
					<search-reference v-model="formData.reference" label="Referenskod" :no-label="false" />
				</v-col>

				<v-col cols="3">
					<ui-text-field v-model.number="formData.min_days_between_invoices_per_supplier"
						label="Antal dagar"
						type="number"
						hint="Lägst antal dagar mellan två fakturor"
					/>
				</v-col>

				<v-col cols="3">
					<ui-text-field v-model.number="formData.assumed_amount" :rules="$validator('base/amount')" label="Förväntat belopp" />
				</v-col>
				<v-col cols="1" />

				<v-col cols="4" class="d-flex flex-column">
					<label class="font-weight-bold">Acceptabel balans</label>
					<div class="d-flex accepted-balance-container">
						<ui-text-field v-model.number="formData.allowed_diff_above" label=" " class="mr-2" type="number"
							:rules="$validator('accounting/isNumber')"
						/>
						<ui-text-field v-model.number="formData.allowed_diff_below" label=" " type="number" :rules="$validator('accounting/isNumber')" />
					</div>
					<div>{{ getAcceptedHint }}</div>
				</v-col>
				<v-col cols="1" class="pt-8 d-flex">
					<v-radio-group v-model="formData.allowed_diff_is_percentage">
						<v-radio :value="false" label="Kr" />
						<v-radio :value="true" label="%" />
					</v-radio-group>
				</v-col>
			</v-row>

			<v-row>
				<v-checkbox v-model="formData.blocked" label="Spärrad" class="ml-3 mr-5" />
				<v-checkbox v-model="formData.amount_check" label="Beloppkontroll" />
			</v-row>

			<div class="mt-6 text-subtitle-2 font-weight-bold">Kalendervarians i procent</div>
			<v-row>
				<v-col v-for="(month, index) in months" :key="month" cols="1">
					<ui-text-field v-model="formData.monthly_diff[index]" :label="month" />
				</v-col>
			</v-row>
		</v-form>

		<Loader :error="createOrUpdateError" />
	</ui-movable-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
import SearchReference from '@/components/search/reference';

export default {
	components: {
		SearchReference
	},

	props: {
		isDialog: {
			type: Boolean,
			default: true
		},

		subscriptionId: {
			type: String,
			default: null
		},

		missingSubscriptionId: {
			type: String,
			default: null
		}
	},

	data: () => ({
		formData: {
			ID: '',
			name: '',
			note: '',
			type: '',
			reference: '',
			blocked: false,
			amount_check: false,
			_assumed_amount: 0,
			allowed_diff_below: 0,
			allowed_diff_above: 0,
			allowed_diff_is_percentage: false,
			min_days_between_invoices_per_supplier: 0,
			monthly_diff: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
		},

		months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

		types: [],

		formPostIsPending: false,
		createOrUpdateError: ''
	}),

	computed: {
		getAcceptedHint() {
			if(this.formData.allowed_diff_above && this.formData.allowed_diff_below) {
				if(!this.formData.allowed_diff_is_percentage) {
					return `Från ${parseInt(this.formData.assumed_amount) - parseInt(this.formData.allowed_diff_above)} till ${+parseInt(this.formData.assumed_amount) + +parseInt(this.formData.allowed_diff_below)} kr`;
				}

				return `Från ${(this.formData.assumed_amount * (this.formData.allowed_diff_above/100)).toFixed(2)} till ${(this.formData.assumed_amount * (this.formData.allowed_diff_below/100)).toFixed(2)} kr`;
			} else if(this.formData.allowed_diff_above) {
				if(!this.formData.allowed_diff_is_percentage) {
					return `Från ${parseInt(this.formData.assumed_amount) - parseInt(this.formData.allowed_diff_above)} till ${this.formData.assumed_amount} kr`;
				}

				return `Från ${(this.formData.assumed_amount * (this.formData.allowed_diff_above/100)).toFixed(2)} till ${this.formData.assumed_amount} kr`;
			} else if(this.formData.allowed_diff_below) {
				if(!this.formData.allowed_diff_is_percentage) {
					return `Från ${this.formData.assumed_amount} till ${+this.formData.assumed_amount + +this.formData.allowed_diff_below} kr`;
				}

				return `Från ${this.formData.assumed_amount} till ${(this.formData.assumed_amount * (this.formData.allowed_diff_below/100)).toFixed(2)} kr`;
			}

			return 'Belopp under respektive över förväntat belopp som godkänns';
		}
	},

	watch: {
		subscriptionId(to) {
			if (to) {
				this.fetchSubscription();
			}
		}
	},

	created() {
		this.fetchTypes();

		if (this.subscriptionId) {
			this.fetchSubscription();
		}

		if (this.missingSubscriptionId) {
			this.formData.ID = this.missingSubscriptionId;
		}
	},

	methods: {
		close() {
			this.$emit('close');
		},

		async fetchTypes() {
			this.types = await this.$service.subscriptions.searchTypes({
				sort: { name: 1 }
			});
		},

		async fetchSubscription() {
			let data = await this.$service.subscriptions.get(this.subscriptionId);

			this.formData = {
				...this.formData,
				...data = {
					...data,
					assumed_amount: this.isDecimal(data.assumed_amount) ? this.$utils.format.price(data.assumed_amount): data.assumed_amount
				}
			};
		},

		isDecimal(value) {
			return /^\d+(\.\d{2})?$/.test(value);
		},

		async createOrUpdateSubscription() {
			this.createOrUpdateError = '';

			if (!this.$refs.form.validate()) {
				return;
			}

			this.formData = {
				...this.formData,
				assumed_amount: parseFloat(this.formData.assumed_amount).toFixed(2),
				allowed_diff_above : parseInt(this.formData.allowed_diff_above),
				allowed_diff_below: parseInt(this.formData.allowed_diff_below)
			};

			if (this.subscriptionId) {

				const {
					accounting, _id, creation_date,
					update_date, attestants, authorizations,
					...formData } = this.formData;

				try {
					await this.$service.subscriptions.update(this.subscriptionId, {
						...formData,
						assumed_amount: formData.assumed_amount.replace(/\s/g, '')
					}, {
						loading: val => {
							this.formPostIsPending = val;
						},
						throwException: true
					});

					this.$emit('on-updated', { ...this.formData });
					this.close();
				} catch (error) {
					this.createOrUpdateError = error.message;
				}

				return;
			}

			try {
				await this.$service.subscriptions.create({
					...this.formData,
					assumed_amount: this.formData.assumed_amount.replace(/\s/g, '')
				}, {
					loading: val => {
						this.formPostIsPending = val;
					},
					throwException: true
				});

				this.$emit('on-created', { ...this.formData });
				this.close();
			} catch (error) {
				this.createOrUpdateError = error.message;
			}

		}
	}
};
</script>

<style lang="scss">
.accepted-balance-container span {
	font-style: italic;
}
</style>
