<template>
	<div v-if="shouldRender" :class="[className, 'Loader', {'Loader--slim': slim}]">
		<v-progress-linear v-if="getLoading" color="primary" indeterminate />
		<v-alert v-if="currentMessage" :type="currentMessage.type" class="mb-0" elevation="0"
			:icon="currentMessage.type === 'error' ? 'mdi-alert-octagon' : 'mdi-check-circle'"
			text dense outlined width="100%"
		>
			<span style="white-space: pre-line">{{ currentMessage.text }}</span>
		</v-alert>
	</div>
</template>

<script>
export default {
	name: 'Loader',
	props: {
		loading: Boolean,
		error: {
			type: [String, Array],
			default: ''
		},
		success: {
			type: [String, Array],
			default: ''
		},
		hideWhenInactive: Boolean,
		slim: Boolean,
		className: {
			type: String,
			default: ''
		},
		service: {
			type: Array,
			default: () => ([])
		},
		hideLoading: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		localService: []
	}),
	computed: {
		shouldRender() {
			return this.getLoading || this.getErrors.length > 0 || this.getSuccess.length > 0;
		},
		getErrors() {
			return this.error ? (Array.isArray(this.error) ? this.error : [this.error]) : [];
		},
		getLoading() {
			return this.loading;
		},
		getSuccess() {
			return this.success ? (Array.isArray(this.success) ? this.success : [this.success]) : [];
		},
		currentMessage() {
			if (this.getErrors.length > 0) {
				return { type: 'error', text: this.getErrors[this.getErrors.length - 1] };
			} else if (this.getSuccess.length > 0) {
				return { type: 'success', text: this.getSuccess[this.getSuccess.length - 1] };
			}

			return null;
		}
	}
};
</script>

<style scoped>
    .Loader {
        width: 100%;
        min-height: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .Loader > div {
        margin: 5px 0;
    }
    .Loader--slim {
        min-height: 0px;
    }
</style>
