<template>
	<div>
		<v-text-field v-if="clean" :id="localId" v-model="date" class="pr-2 textField"
			append-icon="mdi-calendar"
			dense
			:label="label"
			:clearable="clearable"
			:placeholder="placeholder"
			:hide-details="hide"
			:no-label="noLabel"
			:rules="rules"
			@input="onChange"
			@click:append="menu = !menu"
		/>
		<ui-text-field v-else :id="localId" v-model="date" class="pr-2"
			append-icon="mdi-calendar"
			:label="label"
			:clearable="clearable"
			:disabled="disabled"
			:placeholder="placeholder"
			:hide-details="hide"
			:no-label="noLabel"
			:rules="rules"
			@input="onChange"
			@click:append="menu = !menu"
			@click:clear="onClear"
		/>
		<v-menu v-model="menu" :close-on-content-click="closeOnContentClick" transition="scale-transition" offset-y
			max-width="290px" min-width="auto"
		>
			<template v-slot:activator="{ on }">
				<div class="pa-0" v-on="on" />
			</template>
			<v-date-picker v-model="date" :min="getAllowedDates(allowedDates)" locale="sv"
				:first-day-of-week="1"
			/>
		</v-menu>
	</div>
</template>

<script>
import { uid } from 'uid';

export default {
	inheritAttrs: false,

	props: {
		label: {
			type: String,
			default: ''
		},

		placeholder: {
			type: String,
			default: ''
		},

		allowedDates: {
			type: String,
			default: null
		},

		required: {
			type: Boolean,
			default: false
		},

		noLabel: {
			type: Boolean,
			default: false
		},

		value: {
			type: String,
			default: ''
		},

		closeOnContentClick: {
			type: Boolean,
			default: false
		},

		clearable: {
			type: Boolean,
			default: false
		},

		disabled: {
			type: Boolean,
			default: false
		},

		hide: {
			type: Boolean,
			default: false
		},

		clean: {
			type: Boolean,
			default: false
		},

		rules: {
			type: Array,
			default: () => ([])
		}
	},

	data: () => ({
		localId: null,
		date: null,
		manualDate: null,
		menu: false
	}),

	computed: {
		getHeaderClasses() {
			return {
				'd-flex': true,
				'justify-center': false,
				'align-center': true,
				'pr-8': 'append-outer-icon' in this.$attrs,
				'pl-8': 'prepend-icon' in this.$attrs
			};
		},

		commonAttrs() {
			return {
				label: '',
				persistentHint: true,
				outlined: true,
				dense: true,
				hideDetails: false,
				required: this.$props.required,
				class: {
					'ui-text-field': true,
					'mt-1': this.$props.label
				}
			};
		}
	},

	watch: {
		value(to, from) {
			if (to !== from) {
				this.date = to;
			}
		},

		date(to, from) {
			if (!to) {
				this.date = null;
			}

			if (this.$utils.date.dateTo(to)) {
				const dateNum = this.$utils.date.dateTo(to);

				if (to !== from) {
					this.$emit('input', dateNum);
					this.menu = false;
				}
			}
		}
	},

	created() {
		this.date = this.value;
	},

	mounted() {
		this.localId = uid();
	},

	methods: {
		onChange(e) {
			this.$emit('input', e);
		},

		onClear() {
			const utc = new Date().toJSON().slice(0,10).replace(/-/g,'-');

			this.date = utc;
			this.$emit('clear');
		},

		getAllowedDates(allowedStartDate) {
			const startDate = new Date(allowedStartDate);
			const year = startDate.getFullYear();
			const month = startDate.getMonth() + 1;
			const day = startDate.getDate();
			const currentDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

			return currentDate;
		}
	}
};
</script>
