<template>
	<div>
		<v-row no-gutters>
			<v-col cols="8" />
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn color="accent mr-2" @click="newDialogOpen = true">
						Ny konteringsmall
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<register-list ref="registerList" :pagination="pagination" :items="codeStrings" :headers="headers" sidebar-width="50%"
			item-key="_id"
			:push-content="false"
			@active-item-changed="setActiveItem"
		>
			<edit-sidebar :accounting-template="active" @updated="fetch" />
		</register-list>

		<new-dialog v-if="newDialogOpen" @close="newDialogOpen = false" @created="onCreated" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import NewDialog from './components/accounting-template-new';
import RegisterList from '@/components/register-list';
import EditSidebar from './components/accounting-template-edit';

export default {
	components: {
		NewDialog,
		RegisterList,
		EditSidebar
	},

	data: () => ({
		newDialogOpen: false,
		codeStrings: [],
		active: null,
		sidebarOpen: false,

		headers: [{
			text: 'Namn',
			value: 'name',
			align: 'start'
		}],

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortDesc: [true]
		}

	}),

	computed: {
		...mapGetters('userStore', ['getUser'])
	},

	created() {
		this.fetch();
	},

	methods: {
		setActiveItem(item) {
			this.active = item;
		},

		onCreated(item) {
			this.active = item;

			this.fetch();
		},

		async fetch() {
			const data = await this.$service.codestrings.search({
				filter:  {
					uid: this.getUser.user.id
				}
			});

			this.pagination.total = data._paginationTotal;

			this.codeStrings = data;
		}
	}
};
</script>
