import i18n from '@/plugins/i18n';
import DashboardView from './dashboard.vue';

export default [{
	path: '/',
	name: 'Dashboard',
	component: DashboardView,
	meta: {
		title: i18n.t('routes.titles.dashboard')
	}
}];
