import apiClient, { kpApiV1 } from '@/plugins/axios';
import { compose } from '@/utils/service';

export default {
	getXml: compose(kpApiV1.get, 'invoices/<id>/xml'),

	getErrors: compose(kpApiV1.get, ['invoices/erroneous', 'invoices/erroneous/<id>']),

	accountingAdd: compose(kpApiV1.post, 'invoices/<id>/accounting'),

	accountingRemove: compose(kpApiV1.delete, 'invoices/<id>/accounting/<accountingId>'),

	accountingEdit: compose(kpApiV1.put, 'invoices/<id>/accounting/<accountingId>'),

	accountingValidate: compose(kpApiV1.post, 'invoices/<id>/accounting/validate'),

	accountingComplete: compose(kpApiV1.post, 'invoices/<id>/accounting/complete'),

	markAsBlocked: compose(kpApiV1.post, ['invoices/<id>/block', 'invoices/block']),

	markAsUnblocked: compose(kpApiV1.post, ['invoices/<id>/unblock', 'invoices/unblock']),

	check: compose(kpApiV1.post, 'invoices/<id>/checklist/<key>/<value>'),



	pay: (body = {},opts = {}) => apiClient.post('/wp-json/kp/v1/invoices/pay', body, opts),

	get: (invoiceId, opts = {}) => apiClient.get(`/wp-json/kp/v1/invoices/${invoiceId}`, opts),

	delete: (invoiceId, cancelDate, opts = {}) => apiClient.delete(`/wp-json/kp/v1/invoices/${invoiceId}/${cancelDate}`, opts),

	getAttestants: (invoiceId, opts = {}) => apiClient.get(`/wp-json/kp/v1/invoices/${invoiceId}/request_attestants`, opts),

	setAttestants: (invoiceId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/invoices/${invoiceId}/attestants`, body, opts),

	setSensitivity: (invoiceId, level, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/${invoiceId}/sensitivity/${level}`, {}, opts),

	search: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/invoices/search', body, opts),

	getErrornous: (invoiceId, opts = {}) => apiClient.get(`/wp-json/kp/v1/invoices/erroneous/${invoiceId}`, opts),

	getErrornousAttachments: (invoiceId, opts = {}) => apiClient.get(`/wp-json/kp/v1/invoices/erroneous/${invoiceId}/attachments`, opts),

	retryErrornous: (invoiceId, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/erroneous/${invoiceId}/retry`, {}, opts),

	retryErrornousBulk: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/invoices/erroneous/retry', body, opts),

	archiveErrornous: (invoiceId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/erroneous/${invoiceId}/archive`, body, opts),

	searchErrors: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/invoices/erroneous/search', body, opts),

	updateErrornous: (id, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/erroneous/${id}/save`, body, opts),

	getRejected: (invoiceId, opts = {}) => apiClient.get(`/wp-json/kp/v1/invoices/rejected/${invoiceId}`, opts),

	searchRejected: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/invoices/rejected/search', body, opts),

	retryRejected: (invoiceId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/rejected/${invoiceId}/retry`, body, opts),

	archiveRejected: (invoiceId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/rejected/${invoiceId}/archive`, body, opts),

	uploadAttachment: (invoiceId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/${invoiceId}/attachments`, body, opts),

	review: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/invoices/review', body, opts),

	unreview: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/invoices/unreview', body, opts),

	setPaymentMeta: (invoiceId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/invoices/${invoiceId}/paymentmeta`, body, opts),

	unlock: (invoiceId, body = {}, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/${invoiceId}/unlock`, body, opts),

	updateReference: (invoiceId, referenceId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/${invoiceId}/reference/${referenceId}`, body, opts),

	updateVatAmount: (invoiceId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/invoices/${invoiceId}/vat`, body, opts),

	changeGiro: (invoiceId ,giroIndex, opts = {}) => apiClient.post(`/wp-json/kp/v1/invoices/${invoiceId}/account/${giroIndex}`, {}, opts),

	checkSettings: (body = {}) => apiClient.get('/wp-json/kp/v1/invoices/settings', body),

	getInvoiceStatus: (invoiceId = {}) => apiClient.get(`/wp-json/kp/v1/invoices/${invoiceId}/todos`),

	setPaydate: (invoiceId, date, opts = {}) => apiClient.put(`/wp-json/kp/v1/invoices/${invoiceId}/duedate/${date}`, {}, opts)
};
