<template>
	<div>
		<portal to="sidebar-right-title">
			{{ attest.name }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-tooltip fab text small content="Ta bort"
					@click="openAttestDeleteDialog"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</div>
		</portal>

		<v-expansion-panels v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Attestregel
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<attest-form ref="form" v-model="data" :read-only="false" :info-attest="infoData" @save="saveAttest" />

					<loader v-if="updateAttestError" :error="updateAttestError" />
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import attestForm from './attest-form';
export default {
	components: {
		attestForm
	},

	props: {
		attest: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		panel: [0],
		data: null,
		infoData: null,

		deleteAttestError: '',
		updateAttestError: ''
	}),

	watch: {
		attest: {
			deep: true,
			handler(to) {
				this.infoData = {
					uid: to.id,
					type: to._type,
					from: to._from,
					to: to._to,
					amount:to._amount,
					codes: to._codes
				};
			}
		}
	},

	created() {
		this.infoData = {
			uid: this.attest.id,
			type: this.attest._type,
			from: this.attest._from,
			to: this.attest._to,
			amount: this.attest._amount,
			codes: this.attest._codes
		};
	},

	methods: {
		openAttestDeleteDialog() {
			this.$bus.emit('confirm:open', {
				headline: `Ta bort attestregel på ${this.attest.name}`,
				text: `Säker på att du vill ta bort attestregel på ${this.attest.name}?`,
				error: this.deleteAttestError,
				confirmed: () => this.deleteAttest()
			});
		},

		async deleteAttest() {
			this.deleteAttestError = '';

			try {
				await this.$service.accounting.deleteRule(this.attest._id, {
					throwException: true
				});

				this.$emit('on-updated');
			} catch (error) {
				this.deleteAttestError = error.message;
			}
		},

		async saveAttest() {
			this.updateAttestError = '';

			if (!this.$refs.form.validate()) {
				return;
			}

			try {
				await this.$service.accounting.updateRule(this.attest._id, {
					...this.data
				},{
					throwException: true
				});

				this.$emit('on-updated');
			} catch (error) {
				this.updateAttestError = error.message;
			}
		}
	}
};
</script>
