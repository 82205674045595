<template>
	<div>
		<v-row>
			<v-col cols="4">
				<rules-card />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import rulesCard from './components/rules-card';

export default {
	components: {
		rulesCard
	},

	data:  () => ({

	}),

	created() {}
};
</script>
