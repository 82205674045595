<template>
	<div class="RolesRegister">
		<v-row no-gutters>
			<v-col cols="8" />
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn color="accent" @click="newDialogOpen = true">
						Ny Roll
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<Loader :service="[$service.roles.get]" hide-loading />

		<RegisterList ref="registerList" :items="roles" :headers="getHeaders" sidebar-width="33%"
			@active-item-changed="setActiveItem"
		>
			<RoleEdit :role="activeRole" @role-deleted="fetchRoles" />
		</RegisterList>

		<RoleNew v-if="newDialogOpen" @close="newDialogOpen = false" @role-created="fetchRoles" />
	</div>
</template>

<script>
import {run} from '@/utils/service';
import RegisterList from '@/components/register-list';
import RoleEdit from './components/role-edit';
import RoleNew from './components/role-new';
import roleUtils from './utils';

export default {
	components: {
		RegisterList,
		RoleEdit,
		RoleNew
	},

	props: {
		idFromQueryParam: {
			type: String,
			default: ''
		}
	},

	data: () => ({
		roles: [],

		activeRole: null,

		newDialogOpen: false
	}),

	computed: {
		getHeaders() {
			return [{
				text: 'Roll',
				value: 'value',
				align: 'start',
				sortable: false
			}];
		}
	},

	async created() {
		this.fetchRoles();

		await roleUtils.setRoleByQueryParam(this);
	},

	methods: {
		setActiveItem(item) {
			this.activeRole = item;

			roleUtils.updateQueryParams(this, item);
		},

		async fetchRoles() {
			run(await this.$service.roles.get.call(), data => {
				this.roles = Object.entries(data).map(([name, value]) => ({name, value, id: name}));
			});

			this.roles = this.roles.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
		}
	}
};
</script>
