import {get as dotProp} from 'dot-prop';
import {USER_TYPE_MASQ} from './';

export default {
	isLoggedIn(state) {
		return Boolean(state[state.active].token);
	},

	getUser(state) {
		return state[state.active];
	},

	getUserData(state) {
		return state[state.active].user;
	},

	getUserCaps(state) {
		return state[state.active].user.caps;
	},

	getUserGroups(state) {
		return state[state.active].user.groups;
	},

	getCodePermissions(state, getters) {
		const groups = getters.getUserGroups;
		const indexMap = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
		const permissions = groups.reduce((acc, curr) => [...acc, ...curr.code_permissions] , []);
		const data = {
			A: [],
			B: [],
			C: [],
			D: [],
			E: [],
			F: [],
			G: [],
			H: [],
			I: [],
			J: []
		};

		permissions.forEach(perm => {
			perm.forEach((codePart, index) => {
				const key = indexMap[index];

				if (codePart.length !== 0) {
					data[key].push(codePart);
				}
			});
		});

		return data;
	},

	getUserGroupId(state) {
		return state[state.active].user.active_group;
	},

	isUserMasqed(state) {
		return state.active === USER_TYPE_MASQ;
	},

	isUserAllowedToAuthorizePayment(state) {
		return dotProp(state[state.active], 'user.caps.kportal_authorize_payment', false);
	},

	isUserAllowedToCapsList(state) {
		return dotProp(state[state.active], 'user.caps.kportal_caps_list', false);
	},

	isUserAllowedToEditAttachmentTypes(state) {
		return dotProp(state[state.active], 'user.caps.kportal_attachment_types', false);
	},

	isUserAllowedToGroupAdd(state) {
		return dotProp(state[state.active], 'user.caps.kportal_group_edit', false);
	},

	isUserAllowedToGroupAddAction(state) {
		return dotProp(state[state.active], 'user.caps.kportal_group_edit', false);
	},

	isUserAllowedToGroupAddUser(state) {
		return dotProp(state[state.active], 'user.caps.kportal_group_edit', false);
	},

	isUserAllowedToGroupEdit(state) {
		return dotProp(state[state.active], 'user.caps.kportal_group_edit', false);
	},

	isUserAllowedToGroupRemoveAction(state) {
		return dotProp(state[state.active], 'user.caps.kportal_group_edit', false);
	},

	isUserAllowedToGroupRemoveUser(state) {
		return dotProp(state[state.active], 'user.caps.kportal_group_edit', false);
	},

	isUserAllowedToGroupView(state) {
		return dotProp(state[state.active], 'user.caps.kportal_group_edit', false);
	},

	isUserAllowedToInvoiceDeleteRejections(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_delete_rejections', false);
	},

	isUserAllowedToInvoiceHandleErrors(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_handle_errors', false);
	},

	isUserAllowedToInvoiceHandleRejections(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_handle_rejections', false);
	},

	isUserAllowedToChangeVat(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_alter_vat', false);
	},

	isUserAllowedToInvoiceAlterPostAttestation(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_alter_post_attestation', false);
	},

	isUserAllowedToInvoiceAlterInvoiceReference(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_alter_reference', false);
	},

	isUserAllowedToInvoiceAlterBookingDate(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_alter_booking_date', false);
	},

	isUserAllowedToAlterCreditorAccount(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_alter_creditor_account', false);
	},

	isUserAllowedToLogView(state) {
		return dotProp(state[state.active], 'user.caps.kportal_logs_view', false);
	},

	isUserAllowedToMasquerade(state) {
		return dotProp(state[state.active], 'user.caps.kportal_masquerade', false);
	},

	isUserAllowedToMasqueradeProtect(state) {
		return dotProp(state[state.active], 'user.caps.kportal_masquerade_protect', false);
	},

	isUserAllowedToMasqueradeProtectOverride(state) {
		return dotProp(state[state.active], 'user.caps.kportal_masquerade_protect_override', false);
	},

	isUserAllowedToProjekt(state) {
		return dotProp(state[state.active], 'user.caps.kportal_projekt', false);
	},

	isUserAllowedToAddUser(state) {
		return dotProp(state[state.active], 'user.caps.kportal_user_edit', false);
	},

	isUserAllowedToDeleteUser(state) {
		return dotProp(state[state.active], 'user.caps.kportal_user_edit', false);
	},

	isUserAllowedToEditUser(state) {
		return dotProp(state[state.active], 'user.caps.kportal_user_edit', false);
	},

	isUserAllowedToEditUserRoles(state) {
		return dotProp(state[state.active], 'user.caps.kportal_user_edit', false);
	},

	isUserAllowedToManageInactive(state) {
		return dotProp(state[state.active], 'user.caps.kportal_user_edit', false);
	},

	isUserAllowedToRemoveCap(state) {
		return dotProp(state[state.active], 'user.caps.kportal_user_edit', false);
	},

	isUserAllowedToSetCap(state) {
		return dotProp(state[state.active], 'user.caps.kportal_user_edit', false);
	},

	isUserAllowedToViewRoles(state) {
		return dotProp(state[state.active], 'user.caps.kportal_role_edit', false);
	},

	isUserAllowedToAddRole(state) {
		return dotProp(state[state.active], 'user.caps.kportal_role_edit', false);
	},

	isUserAllowedToDeleteRole(state) {
		return dotProp(state[state.active], 'user.caps.kportal_role_edit', false);
	},

	isUserAllowedToSetRoleCapability(state) {
		return dotProp(state[state.active], 'user.caps.kportal_role_edit', false);
	},

	isUserAllowedToRemoveRoleCapability(state) {
		return dotProp(state[state.active], 'user.caps.kportal_role_edit', false);
	},

	isUserAllowedToSitesAdd(state) {
		return dotProp(state[state.active], 'user.caps.kportal_sites_edit', false);
	},

	isUserAllowedToSubscriptionEdit(state) {
		return dotProp(state[state.active], 'user.caps.kportal_subscription_edit', false);
	},

	isUserAllowedToSupplierEdit(state) {
		return dotProp(state[state.active], 'user.caps.kportal_supplier_edit', false);
	},

	isUserAllowedToSupplierIsActive(state) {
		return dotProp(state[state.active], 'user.caps.kportal_supplier_edit', false);
	},

	isUserAllowedToSupplierSetGiro(state) {
		return dotProp(state[state.active], 'user.caps.kportal_supplier_edit', false);
	},

	isUserAllowedToSupplierView(state) {
		return dotProp(state[state.active], 'user.caps.kportal_supplier_edit', false);
	},

	isUserAllowedToHandleManageSensitiveInvoices(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_sensitive', false);
	},

	isUserAllowedToDeleteAccountedInvoice(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_handle', false);
	},

	isUserAllowedToDeletePostInvoice(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_handle', false);
	},

	isUserAllowedToAddDirectPayment(state) {
		return dotProp(state[state.active], 'user.caps.kportal_payment_add', false);
	},

	isUserAllowedToEditRoles(state) {
		return dotProp(state[state.active], 'user.caps.kportal_role_edit', false);
	},

	isUserAllowedToInvoicePrint(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_print', false);
	},

	isUserCodeAdmin(state) {
		return dotProp(state[state.active], 'user.caps.kportal_code_admin', false);
	},

	isUserCodeRequirements(state) {
		return dotProp(state[state.active], 'user.caps.kportal_code_requirements', false);
	},

	isUserAllowedToHandleAttestRegister(state) {
		return dotProp(state[state.active], 'user.caps.kportal_attestation_edit', false);
	},

	isUserAllowedToHandleReferenceCodes(state) {
		return dotProp(state[state.active], 'user.caps.kportal_reference_edit', false);
	},

	isUserAllowedToInvoiceHandle(state) {
		return dotProp(state[state.active], 'user.caps.kportal_invoice_handle', false);
	},

	isUserAllowedToAttachmentEdit(state) {
		return dotProp(state[state.active], 'user.caps.kportal_attachment_edit', false);
	},

	isUserAllowedToUserReplacementEdit(state) {
		return dotProp(state[state.active], 'user.caps.kportal_user_replacement_edit', false);
	},

	isUserAllowedToPaymentAuthorize(state) {
		return dotProp(state[state.active], 'user.caps.kportal_payment_authorize', false);
	},

	isUserAllowedToSettingsEdit(state) {
		return dotProp(state[state.active], 'user.caps.kportal_settings_edit', false);
	},

	isUserAllowedToAutodeactivateProtect(state) {
		return dotProp(state[state.active], 'user.caps.kportal_autodeactivate_protect', false);
	}
};
