<template>
	<div>
		<v-row class="">
			<v-col cols="4" class="pr-16">
				<v-text-field label="Antal fakturor" readonly :value="stats.count" />
				<v-text-field label="Genomsnittsbelopp" readonly :value="stats.average_amount" />
			</v-col>

			<v-col cols="4" class="pl-16">
				<v-text-field label="Senaste faktura" readonly :value="stats.latest_date" />
				<v-text-field label="Senaste belopp" readonly :value="stats.latest_amount" />
			</v-col>

			<v-col v-if="stats.latest_invoice" cols="4" class="pt-8">
				<v-btn color="primary" small text @click="previewOpen = true">Visa faktura</v-btn>
			</v-col>
		</v-row>

		<invoice-preview v-if="previewOpen" default-view="generated_html" :invoice-id="stats.latest_invoice" @on-close="previewOpen = false" />
	</div>
</template>

<script>
import invoicePreview from '@/components/invoice-preview';

export default {
	components: {
		invoicePreview
	},

	props: {
		subscriptionId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		stats: {},
		previewOpen: false
	}),

	watch: {
		subscriptionId() {
			this.fetchStats();
		}
	},

	created() {
		this.fetchStats();
	},

	methods: {
		async fetchStats() {
			this.stats = await this.$service.subscriptions.getStatistics(this.subscriptionId);
		}
	}
};
</script>
