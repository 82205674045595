import isValidEmail from 'is-valid-email';
import i18n from '@/plugins/i18n';

export const validate = function(input) {
	return {
		input,
		errors: [],

		required: function()  {
			if (!this.input) {
				this.errors.push({ type: 'required', message: i18n.t('validation.required') });
			}

			return this;
		},

		email: function()  {
			if (!isValidEmail(this.input)) {
				this.errors.push({ type: 'email', message: i18n.t('validation.invalid_email') });
			}

			return this;
		},

		minLenght: function(n) {
			if (typeof this.input !== 'string' || this.input.length < n) {
				this.errors.push({ type: 'minLenght', message: i18n.t('validation.min_lenght', { n }) });
			}

			return this;
		},

		maxLenght: function(n) {
			if (typeof this.input !== 'string' || this.input.length > n) {
				this.errors.push({ type: 'maxLenght', message: i18n.t('validation.max_lenght', { n }) });
			}

			return this;
		},

		isBoolean: function()  {
			if (typeof this.input !== 'boolean') {
				this.errors.push({ type: 'isBoolean', message: i18n.t('validation.is_boolean') });
			}

			return this;
		},

		match: function(value, name) {
			if (this.input !== value) {
				this.errors.push({ type: 'match', message: i18n.t('validation.match', {name}) });
			}

			return this;
		},

		test: function()  {
			return this.errors.length === 0 || this.errors[0].message;
		}
	};
};
