<template>
	<div>
		<div class="d-flex justify-start">
			<v-col cols="8">
				<v-row>
					<v-col cols="3">
						<ui-datepicker v-model="control" clearable
							no-label placeholder="Kontroll Datum" @clear="onClear" @input="fetchPersonalLedger"
						/>
					</v-col>
					<v-col cols="6">
						<search-supplier v-model="supplier" clearable hide-details no-label @input="fetchPersonalLedger" />
					</v-col>
					<v-col cols="3">
						<ui-datepicker v-model="filterdate"
							clearable no-label placeholder="Filter Datum" @input="fetchPersonalLedger"
						/>
					</v-col>
				</v-row>


				<v-row v-if="invoices.length > 0">
					<v-col cols="4">
						<div class="text-caption">Summa leverantörsskuld: <div class="text-subtitle-2">{{ amounts.debt_total }}</div></div>
					</v-col>
					<v-col cols="4">
						<div class="text-caption">Summa netto: <div class="text-subtitle-2">{{ amounts.net_total }}</div></div>
					</v-col>
					<v-col cols="4">
						<div class="text-caption">Summa moms: <div class="text-subtitle-2">{{ amounts.vat_total }}</div></div>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="4">
				<div class="d-flex justify-end">
					<v-btn v-tooltip content="Exportera till excel" :loading="isPending" color="primary" class="mx-4"
						@click="exportExcel"
					>
						<v-icon>mdi-microsoft-excel</v-icon>
					</v-btn>
				</div>
			</v-col>
		</div>

		<loader v-if="changeError" :error="changeError" />

		<v-data-table
			:sort-by="pagination.sortBy"
			:sort-desc="pagination.sortDesc"
			:items-per-page="pagination.pageSize"
			multi-sort
			:headers="getHeaders"
			:items="invoices"
			:hide-default-footer="true"
		>
			<template v-slot:item.matched_against="{item}">
				<v-row v-for="post, index in item.matched_against" :key="index">{{ post }}</v-row>
			</template>

			<template v-slot:item.bic="{item}">
				<v-row>{{ formatBic(item) }}</v-row>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

	data: () => ({
		control: null,
		filterdate: null,
		supplier: null,

		filter: {},
		pagination: {
			page: 1,
			pageSize: 9999,
			total: null,
			sortBy: [],
			sortDesc: []
		},

		invoices: [],
		amounts: {},

		isPending: false,

		changeError: ''
	}),

	computed: {
		...mapGetters('accounting', ['getCodeNames', 'getIsActiveCode']),

		getHeaders() {
			return [{
				text: 'Status',
				value: 'is_open'
			},{
				text: 'Leverantör',
				value: 'supplier_name'
			},{
				text: 'Faktura nr',
				value: 'invoice_no'
			},{
				text: 'Levskuld',
				value: 'debt',
				align: 'right'
			},{
				text: 'Netto',
				align: 'right',
				value: 'net'
			}, {
				text: 'Moms',
				align: 'right',
				value: 'vat'
			},{
				text: 'Ankomstdatum',
				value: 'arrival_date'
			},{
				text: 'Stängd datum',
				value: 'closed_date'
			},{
				text: 'Matchad mot',
				value: 'matched_against'
			},{
				text: 'Betald via',
				value: 'bic'
			}];
		}
	},

	methods: {
		formatBic(item) {
			if(item.bic) {
				switch (item.bic) {
				case 'BGABSESS':
					return `BG ${this.$utils.format.giro('BG', item.number)}`;
				case 'PGSISESS':
					return `PG ${this.$utils.format.giro('PG', item.number)}`;
				case 'note':
					return 'AVI';
				default:
					return `Konto ${item.number}`;
				}
			}
		},

		setActiveItem(item) {
			this.activeCode = item;
		},

		onClear() {
			this.invoices = [];
			this.amounts = {};
		},

		async exportExcel() {
			this.isPending = true;
			const exportFile = this.invoices.map(d => {
				this.isPending = true;

				return {
					'Status': d.is_open,
					'Leverantör': d.supplier_name,
					'Faktura nr': d.invoice_no,
					'Levsku Id': d.debt,
					'Netto': d.net,
					'Moms': d.vatm,
					'Ankomst datum': d.arrival_date,
					'Stängd datum': d.closed_date
				};
			});

			await this.$utils.excel.download(exportFile, 'Reskontra');
			this.isPending = false;
		},

		async fetchPersonalLedger() {
			if(!this.control) {
				return;
			}

			const filterlist = {
				control_date: null,
				filter_date: null,
				supplier: null
			};

			filterlist.control_date = this.$utils.date.dateToInt(this.control);
			filterlist.filter_date = this.$utils.date.dateToInt(this.filterdate);
			filterlist.supplier = this.supplier;

			try {
				const data = await this.$service.ledger.get({
					...filterlist,
					pageSize: this.pagination.pageSize,
					page: this.pagination.page
				},{
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.invoices = data.invoices.map(d => ({
					...d,
					is_open: d.is_open ? 'Öppen' : 'Stängd',
					arrival_date: d.arrival_date ? this.$utils.date.intToPretty(d.arrival_date) : '',
					closed_date: d.closed_date ? this.$utils.date.intToPretty(d.closed_date) : '',
					net: this.$utils.format.price(d.vat),
					vat: this.$utils.format.price(d.net),
					debt: this.$utils.format.price(d.debt)
				}));

				this.amounts = {
					debt_total: this.$utils.format.price(data.amounts.debt_total),
					net_total: this.$utils.format.price(data.amounts.net_total),
					vat_total: this.$utils.format.price(data.amounts.vat_total)
				};
			} catch (error) {
				this.changeError = error.message;
			}
		}
	}
};
</script>
