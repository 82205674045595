<template>
	<v-card class="px-8 py-4">
		<v-card-title class="px-0 d-flex justify-space-between">
			<div>{{ label }}</div>
		</v-card-title>
		<ui-select v-model="settingValues['kiBankBIC'].value" :items="banks" label="Bank" item-text="name" item-value="_id"
			clearable
		/>
		<v-divider class="my-4" />

		<div>
			<ui-text-field v-model="settingValues['kiBankCustName'].value" label="Organisationsnamn hos bank" />
		</div>

		<div>
			<ui-text-field v-model="settingValues['kiBankCustNo'].value" label="Kundnr hos bank" />
		</div>

		<v-checkbox v-model="settingValues['kiBankCustNoIsTest'].value" label="Testläge" />

		<v-card-actions class="d-flex justify-end">
			<v-btn text color="primary" :loading="isPending" :disabled="isPending" @click="updateSetting">Spara</v-btn>
		</v-card-actions>

		<Loader :error="errorMessage" />
	</v-card>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ''
		},
		groups: {
			type: Array,
			required: true
		}
	},

	data: () => ({
		group: 0,
		isPending: false,
		errorMessage: '',

		settingValues: {kiBankBIC: {
			value: '',
			group: 0
		}, kiBankCustName: {
			value: '',
			group: 0
		}, kiBankCustNo: {
			value: '',
			group: 0
		}, kiBankCustNoIsTest: {
			value: '',
			group: null
		}},

		banks: [{name: 'Swedbank', _id: 'SWEDSESS'}]
	}),

	async created() {
		await this.fetchSettings();
	},

	methods: {
		setValues(settings) {
			Object.entries(settings).forEach(([key, value]) => {
				this.settingValues[key].value = value.value;
				this.settingValues[key].group = value.group;
			});
		},

		async fetchSettings() {
			try {
				const settings =  await this.$service.settings.list(this.group, {
					keys: ['kiBankBIC', 'kiBankCustNo', 'kiBankCustNoIsTest', 'kiBankCustName']
				}, {
					loading: val => {
						this.isPending = val;
					}
				});

				this.setValues(settings);
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async updateSetting() {
			const settings = Object.entries(this.settingValues).reduce((acc, [key, value]) => {
				return {
					...acc,
					[key]: value.value
				};
			}, {});

			await this.$service.settings.update(this.group, settings, {
				loading: val => {
					this.isPending = val;
				}
			});

			await this.fetchSettings();
		}
	}
};
</script>
