import i18n from '@/plugins/i18n';

export const getFields = (obj, arr) => arr.reduce((acc, curr) => {
	if (curr in obj) {
		return {
			...acc,
			[curr]: obj[curr]
		};
	}

	return acc;
}, {});

export const run = (data, successFn, errorFn) => {
	if (typeof data === 'object' && '_error' in data) {
		if (errorFn && typeof errorFn === 'function') {
			errorFn(data._error);
		}

		return data;
	}

	if (typeof successFn === 'function') {
		successFn(data);
	}

	return data;
};


export const compose = (method, inputPath, opts = { fields: {}, successMessage: '' }) => {
	const fields = opts.fields;
	const path = Array.isArray(inputPath) ? inputPath : [inputPath];
	const successMessage = opts.successMessage;

	function validate(body) {
		if (!body) {
			return [];
		}

		let errors = [];

		Object.entries(body).forEach(([key, value]) => {
			const field = fields[key];

			if (!field || !field.rules) {
				return;
			}

			const ret = field.rules(value);

			if (typeof ret === 'string') {
				errors.push(`${field.label ? field.label : key}: ${ret}`);
			}
		});

		return errors.length !== 0 ? errors : [];
	}

	function getPath(pathParams) {
		const pathRegex = /(?<=<)\w{1,}(?=>)/mg;
		const paths = path.map(path => {
			const propsForPath = path.match(pathRegex) || [];
			const isValid = propsForPath.reduce((acc, propKey) => (propKey in pathParams && acc), true);

			return {
				propsForPath,
				isValid,
				path
			};
		}).filter(path => path.isValid).sort((a, b) => a.propsForPath.length < b.propsForPath.length && 1 || -1);

		return paths.length !== 0 && paths[0].path;
	}

	return {
		fields,
		isPending: false,
		errorMessage: '',
		successMessage: '',

		call: function(opts = {}, p1, p2) {
			this.errorMessage = '';
			this.successMessage = '';

			const { body, params, rawBody, ...p } = opts;
			const pathParams = p || {};
			const path = getPath(pathParams);
			const message = [
				...validate(body),
				...validate(params),
				...(!path ? [i18n.t('service.missing_params', { path: path.map(x => `[ ${x} ]`) })] : [])
			];

			if (message.length !== 0) {
				this.errorMessage = message[0];

				return {
					_error: message[0]
				};
			}

			const finalPath = Object.entries(pathParams).reduce((acc, [key, value]) => acc.replace(`<${key}>`, value), path);
			const finalBody = rawBody ? rawBody : {
				...(body ? body : {}),
				...(params ? {params} : {})
			};

			this.isPending = true;

			return method(finalPath, finalBody, p1, p2).then(data => {
				this.successMessage = successMessage ? i18n.t(successMessage, body) : '';

				return data;
			}).catch(error => {
				this.errorMessage = i18n.te(`error.${error.code}`) ? i18n.t(`error.${error.code}`) : error.message;
				this.successMessage = '';

				return {
					_error: error.message
				};
			}).finally(() => {
				this.isPending = false;
			});
		}
	};
};
