
export const toLocal = d => {
	const date = new Date(d);
	const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

	return newDate;
};

export const isToday = date => {
	const today = new Date();

	return date.getDate() == today.getDate() &&
		date.getMonth() == today.getMonth() &&
		date.getFullYear() == today.getFullYear();
};

export const isYesterday = date => {
	const today = new Date();
	const yesterday = new Date(today);

	yesterday.setDate(yesterday.getDate() - 1);

	const d1 = yesterday.toISOString().split('T')[0];
	const d2 = date.toISOString().split('T')[0];

	return d1 === d2;
};

export const getYear = date => {
	return date.getFullYear();
};

export const getMonth = date => {
	const month = date.getMonth() + 1;

	return month < 10 ? `0${month}` : month;
};

export const getHours = date => {
	const h = date.getUTCHours();

	return h < 10 ? `0${h}` : `${h}`;
};

export const getMinutes = date => {
	const m = date.getUTCMinutes();

	return m < 10 ? `0${m}` : `${m}`;
};

export const getISODate = date => {
	return date.split('T')[0];
};

export const getFullDate = date => {
	try {
		const d = new Date(date);

		return d.toISOString().split('T')[0];
	} catch (e) {
		console.error(e);

		return '';
	}
};

export const getFullDateTime = date => {

	const d = toLocal(date);
	const fd = getFullDate(d);
	const h = getHours(d);
	const m = getMinutes(d);

	return `${fd} ${h}:${m}`;
};

export const monthDiff = (dateFrom, dateTo) => {
	return dateTo.getMonth() - dateFrom.getMonth() +
		(12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
};

export const addMonths = (date, monthsToAdd) => {
	return new Date(date.setMonth(date.getMonth() + monthsToAdd));
};

export const intToDate = date => {
	const ds = String(date);
	const d = `${ds.substring(0, 4)}-${ds.substring(4, 6)}-${ds.substring(6, 8)}`;

	return new Date(d);
};

export const intToPretty = iDate => {
	const date = intToDate(iDate);

	return getFullDate(date);
};

export const dateToInt = date => {
	if (date === null || date === undefined) {
		return date;
	}

	return Number(date.replaceAll('-', ''));
};

export const todayInPretty = () => {
	const d = new Date();

	return d.toISOString().split('T')[0];
};

export const daysBetweenDates = (date1, date2) => {
	const ONE_DAY = 1000 * 60 * 60 * 24;
	let date1_ms = date1.getTime();
	let date2_ms = date2.getTime();
	const differenceMs = Math.abs(date1_ms - date2_ms);

	return Math.round(differenceMs / ONE_DAY);
};


export const normalizeDate = (dateString, isEndDate = false) => {
	if (!dateString) {
		return null;
	}

	let date = new Date(dateString);

	if (isEndDate) {
		date.setDate(date.getDate() + 1);
	}

	const normalizedDate = date.toISOString().split('T')[0];

	return normalizedDate;

};



export const dateTo = (date) => {
	if (!date) {
		return;
	}

	const regex1 = /^\d{2}-\d{2}-\d{2}$/;
	const regex2 = /^\d{4}-\d{2}-\d{2}$/;
	const regex3 = /^[0-9]{6}$/;
	const regex4 = /^[0-9]{8}$/;

	if (date.substring(0, 2) === '20' && regex4.test(date)) {
		return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
	} else if (regex2.test(date)) {
		return `${date}`;
	} else if (date.substring(0, 2) !== '20' && regex3.test(date)) {
		return `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4, 6)}`;
	} else if (regex1.test(date)) {
		return `20${date.substring(0, 2)}-${date.substring(3, 5)}-${date.substring(6, 8)}`;
	}

	return '';

};
