<template>
	<div>
		<Loader :error="errorMessage" :loading="isPending" />
		<v-row>
			<v-col cols="3">
				<ui-select v-model="group" label="Välj aktiv grupp" :items="newUserGroups" item-text="name"
					item-value="_id" hide-details clearable
				>
					<template v-slot:item="{ item }">
						<span v-if="item._hasParent" :class="item._hasGrand ? 'ml-10' : 'ml-5'">{{ item.name }}</span>
						<span v-else>{{ item.name }}</span>
					</template>
				</ui-select>
			</v-col>
			<v-col cols="3" class="d-flex align-center">
				<ui-autocomplete v-model.number="code"
					clearable
					label="Koddel"
					:items="codeNames"
					item-value="key"
					hide-details
				/>
			</v-col>
			<v-col cols="3" class="d-flex ">
				<ui-text-field v-model="localCodes[getCodeValue][0]" label="Från" :disabled="!code" :value="getPaddedNumber(getCodeValue, '0')" hide-details />
			</v-col>
			<v-col cols="3" class="d-flex ">
				<ui-text-field v-model="localCodes[getCodeValue][1]" label="Till" :disabled="!code" :value="getPaddedNumber(getCodeValue, '9')" hide-details />
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="9" />
			<v-col cols="3" class="d-flex justify-end align-center">
				<v-btn text :disabled="!localCodes[getCodeValue][1] && !localCodes[getCodeValue][1]" color="primary" @click="addCodes(getCodeValue)">
					<v-icon left>mdi-plus</v-icon>
					Lägg till
				</v-btn>
			</v-col>
		</v-row>

		<div v-for="(item, index) in aaa" :key="index">
			<div v-if="item.code_intervals[0]">
				<v-card
					class="mx-auto"
					dense
					elevation="0"
				>
					<v-list dense>
						<v-list-item-title class="px-4 primary--text"><strong>{{ user.groups.find(x => x._id === item.group_id).name }}</strong></v-list-item-title>
						<v-divider />
						<v-list-item-group
							color="primary"
						>
							<div v-for="(list, intIndex) in item.code_intervals" :key="intIndex">
								<div v-for="(code, subIndex) in list.filter(n => n)" :key="subIndex">
									<v-list-item v-if="code.length">
										<v-list-item-content>
											<v-row class="d-flex px-8" no-gutters>
												<v-col cols="3"><strong>{{ codeNames[subIndex].text }}</strong></v-col>
												<v-col cols="5"><strong>Från: </strong>{{ addZeroToLenghtOfCode(subIndex, `${code[0]}`.length) }}{{ code[0] }}</v-col>
												<v-col cols="4"><strong>Till: </strong>{{ addZeroToLenghtOfCode(subIndex, `${code[1]}`.length) }}{{ code[1] }}</v-col>
											</v-row>
										</v-list-item-content>

										<v-list-item-icon>
											<v-btn small color="warning" text @click="openDeleteDialog(index, intIndex, subIndex)">
												Ta bort
											</v-btn>
										</v-list-item-icon>
									</v-list-item>
								</div>
							</div>
						</v-list-item-group>
					</v-list>
				</v-card>
			</div>
		</div>
		<Dialog v-if="deleteDialog" :loading="isPending" title="Ta bort rad" ok-btn-text="Ta bort" @confirm="removeCodes"
			@reject="deleteDialog = false"
		>
			Är du säker på att du vill ta bort <strong> {{ array.name }}:
				{{ aaa[array.index].code_intervals[array.intIndex][array.subIndex][0] }}
				- {{ aaa[array.index].code_intervals[array.intIndex][array.subIndex][1] }}</strong>?
		</Dialog>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

const defaultData = () => ([ [], [], [], [], [], [], [], [], [], [] ]);


export default {
	props: {
		user: {
			type: Object,
			default: ()=> ({})
		}
	},

	data: () => ({
		code: '',
		codeNames: [],
		group: '',
		aaa: [],
		localCodes: [],

		activeAAA: {
			uid: null,
			group_id: '',
			code_intervals: [
				[ [], [], [], [], [], [], [], [], [], [] ]
			]
		},

		array: {},

		deleteDialog: false,
		isPending: false,
		errorMessage: ''
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getCodeLengths', 'getIsActiveCode']),

		getCodeValue() {
			if(!this.code) {
				return 0;
			}

			return this.codeNames.find(c => c.key === this.code).value;
		},

		newUserGroups() {
			const items = this.user.groups.map(g => {
				if(g.parent) {
					const parent = this.user.groups.find(x => x._id === g.parent);

					if(parent.parent != null) {
						return {
							...g,
							_hasParent: g.parent != null ? true : false,
							_hasGrand: true
						};
					}

					return {
						...g,
						_hasParent: g.parent != null ? true : false
					};
				}

				return {
					...g,
					_hasParent: g.parent != null ? true : false
				};
			});

			return items;
		}
	},

	watch: {
		'user.id'() {
			this.fetchAaa();
		}
	},

	async created() {
		this.localCodes = defaultData();
		this.setCodeNames();

		await this.fetchAaa();
	},

	methods: {
		itemText(a) {
			return `${a.kod} - ${a.kodtext}`;
		},

		getCodeLetters(index) {
			const letters = 'ABCDEFGHIJ';

			return letters[index];
		},

		addZeroToLenghtOfCode(codeIndex, codeLength) {
			const length = this.getCodeLengths[codeIndex] - codeLength;

			if(length <= 0) {
				return;
			}

			return '0'.repeat(length);
		},

		setCodeNames() {
			this.codeNames =  Object.entries(this.getCodeNames).map(([key, value], index) => {
				if (!value || !this.getIsActiveCode(index)) {
					return null;
				}

				return {
					text: value,
					key: key,
					value: index
				};
			}).filter(obj => obj !== null);
		},

		removeCodes() {
			this.deleteDialog = false;
			this.aaa[this.array.index].code_intervals.splice(this.array.intIndex, 1);
			this.activeAAA = this.aaa[this.array.index];
			this.updateAaa();
		},

		openDeleteDialog(index, intIndex, subArrayIndex) {
			this.array = {name: this.codeNames[subArrayIndex].text, index: index, intIndex: intIndex, subIndex: subArrayIndex};
			this.deleteDialog = true;
		},

		async addCodes(index) {
			if (!this.code && !this.localCodes[index][0] && !this.localCodes[index][1]) {
				return;
			}

			const current = this.aaa.find(x => x.group_id === this.group);

			this.activeAAA.group_id = this.group;
			current.code_intervals.map(codes => this.activeAAA.code_intervals.push(codes));
			this.activeAAA.code_intervals[0][this.codeNames.find(code => code.key == this.code).value].push(
				parseInt(this.localCodes[index][0]), parseInt(this.localCodes[index][1])
			);
			this.localCodes = defaultData();

			await this.updateAaa();
		},

		getPaddedNumber(subArrayIndex, type) {
			const numberIndex = type === '0' ? 0 : 1;

			if(!this.localCodes[subArrayIndex][numberIndex])
				return;

			const number = this.localCodes[subArrayIndex][numberIndex].toString();

			if(number.includes('*')) {
				const length = this.getCodeLengths[subArrayIndex] - (number.length - 1);

				return this.$set(this.localCodes[subArrayIndex], numberIndex, number.replace('*', type.repeat(length)));
			}

			return numberIndex, this.localCodes[subArrayIndex][numberIndex];
		},

		async fetchAaa() {
			this.errorMessage = '';
			try {
				this.aaa  = await this.$service.users.getAaa(this.user.id);
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async updateAaa() {
			this.errorMessage = '';
			this.activeAAA.uid = this.user.id;
			try {
				await this.$service.users.updateAaa(this.user.id, {
					...this.activeAAA
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});

				this.fetchAaa();
				this.activeAAA = {
					uid: null,
					group_id: '',
					code_intervals: [
						[ [], [], [], [], [], [], [], [], [], [] ]
					]
				};
			} catch (error) {
				this.errorMessage = error.message;
			}
		}
	}
};
</script>
