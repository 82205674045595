import { get as dotProp } from 'dot-prop';
import * as service from './service';
import * as user from './user';
import * as token from './token';
import * as validation from './validation';
import * as router from './router';
import * as pagination from './pagination';
import * as date from './date';
import * as emoji from './emoji';
import * as format from './format';
import * as filter from './filter';
import * as tree from './tree';
import * as file from './file';
import * as excel from './excel';


export default {
	service,
	user,
	token,
	validation,
	router,
	pagination,
	date,
	emoji,
	format,
	filter,
	cloneDeep: obj => JSON.parse(JSON.stringify(obj)),
	dotProp,
	tree,
	file,
	excel
};
