<template>
	<div>
		<portal-target name="register-header" />

		<v-card elevation="2">
			<v-tabs show-arrows>
				<v-tab v-for="tab in tabs" :key="tab.path" ref="tab" :to="tab.path">
					{{ tab.label }}
				</v-tab>
			</v-tabs>
			<div class="pa-6">
				<router-view />
			</div>
		</v-card>
	</div>
</template>

<script>

export default {
	computed: {

		tabs() {
			return [{
				path: '/settings/system',
				label: 'System'
			}, {
				path: '/settings/accounting-template',
				label: 'Fasta konteringsmallar'
			}, {
				path: '/settings/bank-days',
				label: 'Bankdagar'
			}, {
				path: '/settings/attachment-types',
				label: 'Bilagetyper'
			}, {
				path: '/settings/accounting',
				label: 'Bokföring'
			}, {
				path: '/settings/code',
				label: 'Kontering'
			}, {
				path: '/settings/attest-rules',
				label: 'Attesträtt'
			}, {
				path: '/settings/organisation',
				label: 'Organisation'
			}, {
				path: '/settings/payments',
				label: 'Betalningar'
			}].filter(Boolean);
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.$refs['tab'][0].$el.focus();
		});
	}
};
</script>
