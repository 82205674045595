const ApiResponseError = function(code, message, status, fileName, lineNumber) {
	const instance = new Error(message, fileName, lineNumber);

	instance.name = 'ApiResponseError';
	instance.code = code;
	instance.status = status;

	Object.setPrototypeOf(instance, Object.getPrototypeOf(this));

	if (Error.captureStackTrace) {
		Error.captureStackTrace(instance, ApiResponseError);
	}

	return instance;
};

ApiResponseError.prototype = Object.create(Error.prototype, {
	constructor: {
		value: Error,
		enumerable: false,
		writable: true,
		configurable: true
	}
});

if (Object.setPrototypeOf) {
	Object.setPrototypeOf(ApiResponseError, Error);
} else {
	ApiResponseError.__proto__ = Error;
}

export default ApiResponseError;
