<template>
	<div>
		<portal-target name="register-header" />

		<v-card elevation="2">
			<v-tabs show-arrows>
				<v-tab v-for="tab in tabs" :key="tab.path" ref="tab" :to="tab.path">
					{{ tab.label }}
				</v-tab>
			</v-tabs>
			<div class="pa-6">
				<router-view />
			</div>
		</v-card>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	computed: {
		...mapGetters('userStore', ['isUserAllowedToViewRoles', 'isUserAllowedToGroupView']),

		tabs() {
			return [{
				path: '/administration/users',
				label: 'Användare'
			},  this.isUserAllowedToViewRoles && {
				path: '/administration/roles',
				label: 'Roller'
			},this.isUserAllowedToGroupView && {
				path: '/administration/groups',
				label: 'Grupper'
			}, {
				path: '/administration/sites',
				label: 'Organisationer'
			},{
				path: '/administration/subscriptiontypes',
				label: 'Abonnemangstyper'
			},{
				path: '/administration/accounting-periods',
				label: 'Bokföringsperioder'
			},{
				path: '/administration/inactive-users',
				label: 'Avslutade användare'
			}].filter(Boolean);
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.$refs['tab'][0].$el.focus();
		});
	}
};
</script>
