import jwtDecode from 'jwt-decode';
import {USER_TYPE_PRIMARY, USER_TYPE_MASQ, emptyUser} from './';

export default {
	SET_USER(state, payload) {
		state[state.active] = payload;
		state[state.active].tokenData = payload.token ? jwtDecode(payload.token) : null;
	},

	SET_MASQUERADE(state, payload) {
		state.active = USER_TYPE_MASQ;
		state.masq = payload;
		state.masq.tokenData = payload.token ? jwtDecode(payload.token) : null;
	},

	REMOVE_MASQUERADE(state) {
		state.active = USER_TYPE_PRIMARY;
		state.masq = emptyUser();
	}
};
