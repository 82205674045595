import * as rules from '../rules';

export default {
	name: () => ([
		rules.required(),
		rules.maxLenght(50)
	]),

	username: () => ([
		rules.required(),
		rules.maxLenght(30),
		rules.minLenght(3)
	]),

	password: () => ([
		rules.required(),
		rules.maxLenght(255),
		rules.minLenght(3)
	]),

	passwordRepeat: (opts) => ([
		rules.required(),
		rules.match(opts.password, opts.label)
	]),

	role: () => ([
		rules.required()
	]),

	email: () => ([
		rules.required(),
		rules.email()
	]),

	grant: () => ([
		rules.isBoolean()
	])
};
