<template>
	<div>
		<div class="d-flex mb-6 align-center">
			<div class="text-caption">
				Nuvarande värde: <strong>{{ $utils.dotProp(localInvoice, 'References.BuyerReference', '') }}</strong>
			</div>
			<v-spacer />
			<v-btn color="primary" small @click="newDialogOpen = true">
				<v-icon left>mdi-plus</v-icon>
				Ny referens
			</v-btn>
		</div>

		<search-reference ref="reference" label="Sök referens" @on-change="onChange" />

		<div v-if="form && form.reference">
			<p class="text-caption">Vill du uppdatera till referens <strong class="primary--text">{{ form.reference }}</strong>?</p>
			<v-btn color="success" text small @click="updateInvoice">Ja, uppdatera</v-btn>
		</div>

		<reference-new v-if="newDialogOpen"
			:reference="$utils.dotProp(localInvoice, 'References.BuyerReference', '')"
			@close="newDialogOpen = false"
			@created="onChange"
		/>
	</div>
</template>

<script>
import SearchReference from '@/components/search/reference';
import ReferenceNew from '@/views/register/children/references/components/new.vue';

export default {
	components: {
		SearchReference,
		ReferenceNew

	},

	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		form: {
			reference:''
		},

		newDialogOpen: false,
		localInvoice: null,
		giroToAdd: null,

		supplier: null
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
					this.form = {
						reference:''
					};
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);
	},

	methods: {
		onChange(ref) {
			this.form = ref;
			this.$refs.reference.fetchRefrences();
		},

		updateInvoice() {
			this.localInvoice.References.BuyerReference = this.form.reference;

			this.$emit('on-invoice-update', this.localInvoice);
		}
	}
};
</script>
