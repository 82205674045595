import apiClient from '@/plugins/axios';

export default {
	get: (subscriptionId, opts = {}) => apiClient.get(`/wp-json/kp/v1/subscriptions/${subscriptionId}`, opts),

	create: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/subscriptions', body, opts),

	update: (subscriptionId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/subscriptions/${subscriptionId}`, body, opts),

	search: (body = {}, opts = {}) => apiClient.post('/wp-json/kp/v1/subscriptions/search', body, opts),

	updateAccounting: (subscriptionId, body = {}, opts = {}) => apiClient.put(`/wp-json/kp/v1/subscriptions/${subscriptionId}/accounting`, body, opts),

	updateAccountingVat: (subscriptionId, body = {}, opts = {}) => apiClient.put(`/wp-json/kp/v1/subscriptions/${subscriptionId}/vat_accounting`, body, opts),

	getAttestants: (subscriptionId, opts = {}) => apiClient.get(`/wp-json/kp/v1/subscriptions/${subscriptionId}/request_attestants`, opts),

	getVatAttestants: (subscriptionId, opts = {}) => apiClient.get(`/wp-json/kp/v1/subscriptions/${subscriptionId}/request_vat_attestants`, opts),

	setAttestants: (subscriptionId, body = {}, opts = {}) => apiClient.post(`/wp-json/kp/v1/subscriptions/${subscriptionId}/attestants`, body, opts),

	setVatAttestants: (subscriptionId, body = {}, opts = {}) => apiClient.post(`/wp-json/kp/v1/subscriptions/${subscriptionId}/vat_attestants`, body, opts),

	searchTypes: (body = {}, opts = {}) => apiClient.post('/wp-json/kp/v1/subscriptions/types/search', body, opts),

	createType: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/subscriptions/types', body, opts),

	updateType: (typeId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/subscriptions/types/${typeId}`, body, opts),

	deleteType: (typeId, body = {}, opts = {}) => apiClient.delete(`/wp-json/kp/v1/subscriptions/types/${typeId}`, body, opts),

	getStatistics: (subscriptionId, opts = {}) => apiClient.get(`/wp-json/kp/v1/subscriptions/${subscriptionId}/statistics`, opts),

	approveAccounting: (subscriptionId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/subscriptions/${subscriptionId}/approve`, body, opts),

	authorizeAccounting: (subscriptionId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/subscriptions/${subscriptionId}/authorize`, body, opts),

	approveAccountingVat: (subscriptionId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/subscriptions/${subscriptionId}/vat_approve`, body, opts),

	authorizeAccountingVat: (subscriptionId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/subscriptions/${subscriptionId}/vat_authorize`, body, opts),

	replaceInactiveAttestant: (subscriptionId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/subscriptions/${subscriptionId}/replace_inactive_attestant`, body, opts)

};
