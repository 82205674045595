<template>
	<noscript />
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import utils from '@/utils';
import router from '@/plugins/router';

const MINUTES_LEFT_BEFORE_REFRESH = 5;
const EXPIRE_CHECK_INTERVAL = 10000;

export default {
	name: 'TokenHandler',

	data: () => ({
		interval: null
	}),

	computed: {
		...mapGetters('userStore', ['getUser'])
	},

	watch: {
		'getUser.token'() {
			this.init();
		}
	},

	created() {
		this.init();
	},

	methods: {
		...mapActions('userStore', ['refreshToken', 'logout']),

		init() {
			if (!utils.user.isAuthenticated(this.getUser)) {
				return clearInterval(this.interval);
			}

			clearInterval(this.interval);
			this.interval = setInterval(this.tokenRefresh, EXPIRE_CHECK_INTERVAL);
		},

		async tokenRefresh() {
			if (!utils.token.shouldRefresh(this.getUser.expires, MINUTES_LEFT_BEFORE_REFRESH)) {
				return;
			}

			utils.service.run(await this.refreshToken(), () => {}, () => {
				this.$bus.emit('message:add', {
					type: 'info',
					text: this.$t('error.refesh_token')
				});

				this.logout();

				router.push({name: 'Login'});
			});
		}
	}
};
</script>
