<template>
	<div>
		<v-card class="px-8 py-4">
			<div class="d-flex align-center justify-space-between">
				<h3>Sista aktiva koddel</h3>
				<ui-select v-model="settingValues['kiCodeCount'].value"
					:items="codeValues"
					item-value="value"
					item-text="key"
					style="width: 50%;"
				/>
			</div>
			<div v-for="(setting, i) in settingValues['kiCodeNames'].value" :key="setting.key">
				<div v-if="i + 1 <= settingValues['kiCodeCount'].value " class="d-flex justify-space-between align-center">
					<div class="d-flex align-center">
						<div class="pr-4">{{ codeType[i] }} :</div>
						<ui-text-field v-model="settingValues['kiCodeNames'].value[i]" :value="setting" />
					</div>
					<v-text-field
						v-model="settingValues['kiCodeLengths'].value[i]"
						class=""
						type="number"
						style="max-width: 50px"
					/>
				</div>
			</div>

			<div class="text-right mt-6">
				<v-btn text color="primary" :loading="isPending" @click="onSave"> Spara </v-btn>
			</div>

			<Loader :error="errorMessage" />
		</v-card>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		settings: {
			type: Array,
			required: true
		}
	},

	data: () => ({
		group: 0,
		isPending: false,
		errorMessage: '',

		settingValues: {},
		codeValues: [],
		activeKey: null,
		codeType: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

	}),

	watch: {},

	async created() {
		this.setDefaultValues();
		await this.fetchSettings();
	},

	methods: {
		...mapActions('accounting', ['setCodeRules']),

		setDefaultValues() {
			this.settings.forEach(setting => {
				this.$set(this.settingValues, setting.key, {
					value: setting.value,
					group: setting.group
				});
			});
		},

		setValues(settings) {
			Object.entries(settings).forEach(([key, value]) => {
				this.settingValues[key] =  value ;
			});

			this.codeValues = this.settingValues['kiCodeNames'].value.map((code, i) => {
				return {
					key :`${this.codeType[i]} - ${code} `,
					value: i + 1
				};
			});
		},

		async fetchSettings() {
			try {
				const settings =  await this.$service.settings.list(this.group, {
					keys: this.settings.map(x => x.key)
				}, {
					loading: val => {
						this.isPending = val;
					}
				});

				this.setValues(settings);

			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async onSave() {
			const CodeLengths = this.settingValues['kiCodeLengths'].value.map(value => parseInt(value));
			const codeCount = parseInt(this.settingValues['kiCodeCount'].value);

			try {

				await this.$service.settings.update(this.group, {
					'kiCodeNames': this.settingValues['kiCodeNames'].value,
					'kiCodeLengths': CodeLengths,
					'kiCodeCount' : codeCount
				}, {
					loading: val => {
						this.isPending = val;
					}
				});

			} catch (error) {
				this.errorMessage = error.message;
			}

			this.fetchSettings();
			await this.setCodeRules();
		}
	}
};
</script>
