<template>
	<div>
		<div class="d-flex mb-6 align-center">
			<div class="text-caption">
				Nuvarande värde: <strong>{{ $utils.dotProp(localInvoice, 'References.SupplierReference', '') }}</strong>
			</div>
			<v-spacer />
			<v-btn color="primary" small @click="newDialogOpen = true"><v-icon left>mdi-plus</v-icon>Nytt Abonnemang</v-btn>
		</div>
		<ui-select v-if="subscriptions" v-model="newSubscription" :items="subscriptions" item-value="number" return-object
			label="Välj Abonnemang"
		>
			<template #selection="{ item }">
				<span class="text-caption">{{ item.ID }} ({{ item.name }})</span>
			</template>
			<template #item="{ item }">
				<span class="text-caption">{{ item.ID }} ({{ item.name }})</span>
			</template>
		</ui-select>
		<div v-if="newSubscription">
			<p class="text-caption">
				Vill du uppdatera till abonnemang <strong class="primary--text">{{ newSubscription.ID
				}} ({{ newSubscription.name }})</strong>?
			</p>
			<v-btn color="success" text small @click="updateInvoice">Ja, uppdatera</v-btn>
		</div>

		<SubscriptionForm
			v-if="newDialogOpen"
			:missing-subscription-id="invoice.References.SupplierReference"
			@close="newDialogOpen = false"
			@on-created="onCreated"
		/>
	</div>
</template>

<script>
import SubscriptionForm from '@/views/register/children/subscriptions/components/subscription-new';

export default {
	components: {
		SubscriptionForm
	},

	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		newSubscription: null,
		newDialogOpen: false,
		localInvoice: null,

		subscriptions: null
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
					this.newSubscription = null;
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);

		this.fetchSubscription();
	},

	methods: {
		onCreated(subscription) {
			this.newSubscription = subscription;
			this.newDialogOpen = false;
		},

		updateInvoice() {
			this.localInvoice.References.SupplierReference = this.newSubscription.ID;
			this.$emit('on-invoice-update', this.localInvoice);
		},

		async fetchSubscription() {
			this.subscriptions = await this.$service.subscriptions.search({
				filter : { 'archived' : false }
			});
		}
	}
};
</script>
