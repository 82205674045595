import actions from './actions';
import mutations from './mutations';
import getters from './getters';


export default {
	namespaced: true,

	state: {
		codes: {},
		codeNames: {},
		codeLengths: [],
		activeCodeCount: null,
		codeRules: []
	},

	actions,
	mutations,
	getters
};
