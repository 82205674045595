import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import stores from '@/stores';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		...stores
	},
	plugins: [
		createPersistedState({
			paths: ['userStore', 'globalAttest'],
			key: 'kfp-store'
		})
	]
});
