<template>
	<div>
		<v-row>
			<v-col cols="4">
				<settings-card :groups="groups" :settings="system_settings" label="System" />
			</v-col>
			<v-col cols="4">
				<settings-card :groups="groups" :settings="attestants_settings" label="Attest" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import settingsCard from './components/settings-card';

export default {
	components: {
		settingsCard
	},

	data: () => ({
		groups: [],

		system_settings: [{
			key: 'kiMaxAttachmentSize',
			label: 'Max storlek på bilaga',
			size: 'kB',
			value: '',
			type: '',
			group: 0
		}, {
			key: 'kiDeletionVerNo',
			label: 'Nytt verifikationsnr vid makulering',
			value: true,
			type: {
				value: 'checkbox'
			},
			group: 0
		}],

		attestants_settings: [{
			key: 'kiInUnison',
			label: 'Två i förening',
			value: false,
			type: {
				value: 'checkbox'
			},
			group: 0
		}, {
			key: 'kiNumAttestants1',
			label: 'Antal godkännare',
			value: 0,
			type: {
				value: 'slider',
				stepSize: 1,
				from: 0,
				to: 3
			},
			group: 0
		}, {
			key: 'kiNumAttestants2',
			label: 'Antal beslutsattestanter',
			value: 0,
			type: {
				value: 'slider',
				stepSize: 1,
				from: 0,
				to: 3
			},
			group: 0
		}, {
			key: 'kiNumAttestantsLowAmount1',
			label: 'Antal godkännare för fakturor med "lågt belopp"',
			value: 0,
			type: {
				value: 'slider',
				stepSize: 1,
				from: 0,
				to: 3
			},
			group: 0
		}, {
			key: 'kiNumAttestantsLowAmount2',
			label: 'Antal beslutsattestanter för fakturor med "lågt belopp"',
			value: 0,
			type: {
				value: 'slider',
				stepSize: 1,
				from: 0,
				to: 3
			},
			group: 0
		}, {
			key: 'kiNumAttestantsHighAmount1',
			label: 'Antal godkännare för fakturor med "högt belopp"',
			value: 1,
			type: {
				value: 'slider',
				stepSize: 1,
				from: 1,
				to: 3
			},
			group: 0
		}, {
			key: 'kiNumAttestantsHighAmount2',
			label: 'Antal beslutsattestanter för fakturor med "högt belopp"',
			value: 1,
			type: {
				value: 'slider',
				stepSize: 1,
				from: 1,
				to: 3
			},
			group: 0
		}, {
			key: 'kiLowAccountingAmount',
			label: 'Belopp under vilket en faktura anses ha "lågt belopp"',
			value: 0,
			type: {
				value: 'number',
				stepSize: 100,
				from: 0,
				to: 100000,
				showSliderInput: true
			},
			group: 0
		}, {
			key: 'kiHighAccountingAmount',
			label: 'Belopp över vilket en faktura anses ha "högt belopp"',
			value: 10000,
			type: {
				value: 'number',
				stepSize: 100000,
				from: 100000,
				to: 100000000,
				showSliderInput: true
			},
			group: 0
		}]
	}),

	async created() {
		await this.fetchGroups();

	},

	methods: {
		async fetchGroups() {
			const data = await this.$service.groups.list();

			this.groups = [{_id: 0, name: ''}, ...data];
		}
	}
};
</script>
