<template>
	<Dialog v-model="open" title="Ny Roll" max-width="640" width="640" :retain-focus="false"
		@confirm="confirm"
		@reject="reject"
	>
		<v-container>
			<v-card-text>
				<v-form ref="form" v-model="formIsValid" :disabled="$service.roles.add.isPending">
					<ui-text-field v-model="formData.name" :label="$service.roles.add.fields.name.label"
						:rules="[$service.roles.add.fields.name.rules]" @keydown.enter="confirm"
					/>
				</v-form>

				<Loader :service="[$service.roles.add]" />
			</v-card-text>
		</v-container>
	</Dialog>
</template>

<script>
import {run} from '@/utils/service';

export default {
	data: () => ({
		open: true,

		formIsValid: false,
		formData: {
			name: ''
		}
	}),

	methods: {
		reject() {
			this.$emit('close');
		},

		async confirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			run(await this.$service.roles.add.call({
				body: this.formData
			}), async roleId => {
				run(await this.$service.roles.get.call({
					roleId
				}), data => {
					this.$emit('close');
					this.$emit('role-created', data);
				});
			});

		}
	}
};
</script>
