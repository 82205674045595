import { SET_USERS } from '../mutation-types';

export default {
	[SET_USERS](state, payload) {
		state.users = payload.map(user => {
			return {
				...user,
				_userName: `${user.name} (${user.login})`
			};
		});
	}
};
