<template>
	<Dialog
		max-width="1024"
		width="640"
		:title="localReference ? localReference.reference : 'Byt användare på referenskod'"
		:loading="isAddPending"
		@reject="reject"
		@confirm="confirm"
	>
		<v-form ref="form" v-model="formIsValid" :disabled="$service.references.add.isPending">
			<ui-autocomplete
				v-model="selectedItem"
				:items="getUsers"
				label="Användare"
				item-text="_userName"
				return-object
				clearable
				placeholder="Lägg till ny"
				@change="addUser"
			/>
			<v-list dense nav class="pa-0">
				<v-list-item-group color="primary">
					<v-list-item v-for="user in userList" :key="user.id" inactive>
						<v-list-item-content>
							<v-list-item-title v-text="getUsers.find(u => u.id === user.id)._userName" />
						</v-list-item-content>
						<v-list-item-icon @click="deleteUser(user.id)">
							<v-icon>mdi-delete</v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-form>

		<Loader :error="error" />
	</Dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		reference: {
			type: Object,
			default: () => ({})
		},

		id: {
			type: Number,
			default: null
		}
	},

	data: () => ({
		open: true,
		formIsValid: false,

		selectedItem: null,

		localReference: null,

		isAddPending: false,

		error: '',
		groups: [],
		userList: []
	}),

	computed: {
		...mapGetters('users', ['getUsers']),

		getErrorMessage() {
			return 'Lägg till ny användare för att avsluta denna referens.';
		}
	},

	async created() {
		this.localReference = this.reference;
		this.userList = this.localReference.userIds.map(x => this.getUsers.find(u => u.id === x));
		await this.fetchGroups();
	},

	methods: {
		reject() {
			this.$emit('close');
		},

		addUser(object) {
			if(object) {
				this.userList.push(object);
			}

			this.$nextTick(() => {
				this.selectedItem = null;
			});
		},

		deleteUser(id) {
			const index = this.userList.findIndex((obj) => obj.id === id);

			this.userList.splice(index, 1);
		},

		async fetchGroups() {
			this.groups = await this.$service.groups.list();
		},

		async confirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			if(this.userList.length === 1 && this.userList[0].id === this.id) {
				return this.error = 'Lägg till ny användare för att avsluta denna referens.';
			}

			this.userList = this.userList.filter(x => x.id !== this.id);
			this.localReference.userIds = this.userList.map(item => item.id);

			try {
				const data = await this.$service.references.update(this.localReference._id,{
					...this.localReference
				}, {
					loading: val => {
						this.isAddPending = val;
					},
					throwException: true
				});

				this.$emit('close');
				this.$emit('created', data);
				this.userList = [];
			} catch (error) {
				this.error = error.message;
			}
		}
	}
};
</script>
