<template>
	<div>
		<v-row no-gutters>
			<v-col cols="8" />
			<v-col cols="4">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn color="accent" @click="newDialogOpen = true">
						Ny Konversation
					</v-btn>
				</div>
			</v-col>
		</v-row>


		<Loader :service="[$service.groups.get]" hide-loading />
		<RegisterList ref="registerList" :items="mails" :headers="headers" sort-by="value"
			:show-sidebar="false"
			item-key="_id"
			@active-item-changed="setActiveItem"
		/>
		<Chat v-if="activeItem" width="50%" :inbox-open="true" :mail="activeItem" @leave-chat="mailUpdated"
			@close="mailUpdated"
		/>
		<ChatNew v-if="newDialogOpen" @close="newDialogOpen = false" @mail-created="mailUpdated" />
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import sortBy from 'sort-by';
import RegisterList from '@/components/register-list';
import Chat from './components/chat';
import ChatNew from './components/chat-new';

export default {
	components: {
		RegisterList,
		Chat,
		ChatNew
	},

	data: () => ({
		newDialogOpen: false,

		headers: [{
			text: 'Ämne',
			value: 'subject',
			align: 'start'
		}, {
			text: 'Från',
			value: '_from'
		}, {
			text: 'Skickat',
			value: '_date',
			align: 'end'
		}],

		mails: [],

		activeItem: null
	}),

	computed: {
		...mapGetters('users', ['getUsers'])
	},

	created() {
		this.fetchMails();
	},

	methods: {
		setActiveItem(item) {
			this.activeItem = item;
		},

		async mailUpdated(item) {
			await this.fetchMails();

			if (item) {
				this.$refs.registerList.open(item);
			} else {
				this.$refs.registerList.close();
			}
		},

		async fetchMails() {
			const data = await this.$service.mail.search({},
				{
					filter: [
						{
							root_id: {
								method: 'EQ',
								value: 'id'
							}
						}
					]
				}
			);

			this.mails = data.map(x => ({
				...x,
				_date: this.$utils.date.intToPretty(x.date),
				_isBold: x.has_unread,
				_from: this.getUsers.find(u => u.id === x.from_user)._userName
			})).sort(sortBy('-has_unread', '-sent_timestamp'));
		}
	}
};
</script>
