import i18n from '@/plugins/i18n';
import component from './profile';
import SettingsComponent from './children/settings/settings';
import TemplateComponent from './children/accounting-template/accounting-template';
import UserReplacementComponent from './children/user-replacement/user-replacement';

export default [{
	path: '/profile',
	name: 'Profile',
	meta: {
		title: i18n.t('routes.titles.profile')
	},
	component,
	children: [{
		path: 'settings',
		name: 'Profile_Settings',
		component: SettingsComponent,
		meta: {
			title: i18n.t('routes.titles.profile_settings')
		}
	}, {
		path: 'accounting-template',
		name: 'Profile_Info',
		component: TemplateComponent,
		meta: {
			title: i18n.t('routes.titles.profile_template')
		}
	}, {
		path: 'user-replacement',
		name: 'User_Replacement',
		component: UserReplacementComponent,
		meta: {
			title: i18n.t('routes.titles.user_replacement')
		}
	}]
}];
