<template>
	<Dialog v-model="open" title="Ny Organisation" max-width="640" width="640" :retain-focus="false"
		@confirm="confirm"
		@reject="reject"
	>
		<v-container>
			<v-card-text>
				<v-form ref="form" v-model="formIsValid" :disabled="$service.sites.add.isPending">
					<v-text-field v-model="formData.title" :label="$service.sites.add.fields.title.label" filled
						dense :rules="[$service.sites.add.fields.title.rules]"
					/>
				</v-form>

				<Loader :service="[$service.sites.add]" />
			</v-card-text>
		</v-container>
	</Dialog>
</template>

<script>
import {run} from '@/utils/service';
import {mapActions} from 'vuex';

export default {
	data: () => ({
		open: true,

		formIsValid: false,
		formData: {
			title: ''
		}
	}),

	methods: {
		...mapActions('sitesStore', ['addSite']),

		reject() {
			this.$emit('close');
		},

		async confirm() {
			if (!this.$refs.form.validate()) {
				return;
			}

			run(await this.addSite(this.formData.title), () => {
				this.$emit('close');
			});

		}
	}
};
</script>
