import token from './fields/token';
import group from './fields/group';
import reference from './fields/reference';
import user from './fields/user';
import supplier from './fields/supplier';
import base from './fields/base';
import accounting from './fields/accounting';
import settings from './fields/settings';

export default {
	base,
	token,
	group,
	reference,
	user,
	supplier,
	accounting,
	settings
};
