export default {
	getCodelist(state) {
		return Object.keys(state.codes).reduce((acc, key) => {
			return {
				...acc,
				[key]: state.codes[key].map(x => ({
					kod: x[0],
					kodtext: x[1],
					intval: parseInt(x[0]),
					kommentar: [x[2], x[3]]
				})).sort((a, b) => a.intval - b.intval)
			};
		}, {});
	},

	getIsActiveCode: (state) => (prop) => {
		return state.activeCodeCount > prop;
	},

	getCodeRules: (state) => (prop) => {
		return state.codeRules[prop];
	},

	getCodeNames(state) {
		const codes = {
			'A': '',
			'B': '',
			'C': '',
			'D': '',
			'E': '',
			'F': '',
			'G': '',
			'H': '',
			'I': '',
			'J': ''
		};

		state.codeNames.reduce((obj, item, index) => {
			if(state.codeNames[index]) {
				codes[String.fromCharCode(65 + index)] = state.codeNames[index];
			}

			return obj;
		}, {});

		return codes;
	},


	getCodeLengths(state) {
		const lengths = [0,0,0,0,0,0,0,0,0,0];

		state.codeLengths.reduce((obj, item, index) => {
			if(state.codeLengths[index]) {
				lengths[index] = state.codeLengths[index];
			}

			return obj;
		}, []);

		return lengths;
	},

	getCodeText: (state, getters) => (type, value) => {
		if(getters.getCodelistIntKeys[type] && getters.getCodelistIntKeys[type][value]) {
			return getters.getCodelistIntKeys[type][value].kodtext;
		}

		return '';
	},

	getCodeValue: (state, getters) => (type, value) => {
		if(getters.getCodelistIntKeys[type] && getters.getCodelistIntKeys[type][value]) {
			return getters.getCodelistIntKeys[type][value].kod;
		}

		return null;
	},

	getCodelistIntKeys(state) {
		if (Object.keys(state.codes).length === 0) {
			return {
				'A': {},
				'B': {},
				'C': {},
				'D': {},
				'E': {},
				'F': {},
				'G': {},
				'H': {},
				'I': {},
				'J': {}
			};
		}

		return Object.keys(state.codes).reduce((acc, key) => {
			let data;

			if (key in acc) {
				data = acc[key];
			} else {
				data = {};
			}

			state.codes[key].forEach(x => {
				data[parseInt(x[0])] = {
					kod: x[0],
					kodtext: x[1],
					intval: parseInt(x[0])
				};
			});

			return {
				...acc,
				[key]: data
			};
		}, {});
	}
};
