import i18n from '@/plugins/i18n';
import component from './logout';

export default [{
	path: '/logout',
	name: 'Logout',
	meta: {
		title: i18n.t('routes.titles.logout'),
		layout: 'plain'
	},
	component
}];
