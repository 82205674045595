import Vue from 'vue';
import VueI18n from 'vue-i18n';
import sv from '@/locales/sv';
import en from '@/locales/en';
import vutifySv from 'vuetify/es5/locale/sv';
import vutifyEn from 'vuetify/es5/locale/en';

Vue.use(VueI18n);

const locale = localStorage.getItem('kfp-locale') || 'sv';

export default new VueI18n({
	locale,
	messages: {
		sv: {
			'$vuetify': {
				...vutifySv
			},
			...sv
		},
		en: {
			'$vuetify': {
				...vutifyEn
			},
			...en
		}
	}
});
