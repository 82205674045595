<template>
	<div>
		<v-simple-table dense>
			<template v-slot:default>
				<thead>
					<tr>
						<th v-if="getIsActiveCode(0)" class="text-left">{{ getCodeNames.A }}</th>
						<th v-if="getIsActiveCode(1)" class="text-left">{{ getCodeNames.B }}</th>
						<th v-if="getIsActiveCode(2)" class="text-left">{{ getCodeNames.C }}</th>
						<th v-if="getIsActiveCode(3)" class="text-left">{{ getCodeNames.D }}</th>
						<th v-if="getIsActiveCode(4)" class="text-left">{{ getCodeNames.E }}</th>
						<th v-if="getIsActiveCode(5)" class="text-left">{{ getCodeNames.F }}</th>
						<th v-if="getIsActiveCode(6)" class="text-left">{{ getCodeNames.G }}</th>
						<th v-if="getIsActiveCode(7)" class="text-left">{{ getCodeNames.H }}</th>
						<th v-if="getIsActiveCode(8)" class="text-left">{{ getCodeNames.I }}</th>
						<th v-if="getIsActiveCode(9)" class="text-left">{{ getCodeNames.J }}</th>
						<th class="text-right">Belopp</th>
						<th v-if="isVat" class="text-right">Kostnad</th>
						<th class="text-left">Godkännare</th>
						<th class="text-left">Beslutsattestant</th>
						<th class="text-left">Anteckning</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in accounting" :key="index" @click="onRowClick(item)">
						<td v-if="getIsActiveCode(0)" v-tooltip :content="getCodeText('A', item.codes[0])">{{ getCodeValue('A', item.codes[0]) }}</td>
						<td v-if="getIsActiveCode(1)" v-tooltip :content="getCodeText('B', item.codes[1])">{{ getCodeValue('B',item.codes[1]) }}</td>
						<td v-if="getIsActiveCode(2)" v-tooltip :content="getCodeText('C', item.codes[2])">{{ getCodeValue('C',item.codes[2]) }}</td>
						<td v-if="getIsActiveCode(3)" v-tooltip :content="getCodeText('D', item.codes[3])">{{ getCodeValue('D',item.codes[3]) }}</td>
						<td v-if="getIsActiveCode(4)" v-tooltip :content="getCodeText('E', item.codes[4])">{{ getCodeValue('E',item.codes[4]) }}</td>
						<td v-if="getIsActiveCode(4)" v-tooltip :content="getCodeText('F', item.codes[5])">{{ getCodeValue('F',item.codes[5]) }}</td>
						<td v-if="getIsActiveCode(6)" v-tooltip :content="getCodeText('G', item.codes[6])">{{ getCodeValue('G',item.codes[6]) }}</td>
						<td v-if="getIsActiveCode(7)" v-tooltip :content="getCodeText('H', item.codes[7])">{{ getCodeValue('H',item.codes[7]) }}</td>
						<td v-if="getIsActiveCode(8)" v-tooltip :content="getCodeText('I', item.codes[8])">{{ getCodeValue('I',item.codes[8]) }}</td>
						<td v-if="getIsActiveCode(9)" v-tooltip :content="getCodeText('J', item.codes[9])">{{ getCodeValue('J',item.codes[9]) }}</td>
						<td class="text-right">{{ $utils.format.price(item.amount) }}kr</td>
						<td v-if="isVat" class="text-right">{{ item.is_cost ? 'Ja' : 'Nej' }}</td>
						<td>
							<v-btn v-tooltip text small class="px-0"
								elevation="0" :content="getAttestant('approvals', index, item).name"
							>
								{{ getAttestant('approvals', index, item).login }}
							</v-btn>
						</td>

						<td>
							<v-btn v-tooltip text small class="px-0"
								elevation="0" :content="getAttestant('authorizations', index, item).name"
							>
								{{ getAttestant('authorizations', index, item).login }}
							</v-btn>
						</td>
						<td v-tooltip :content="item.note ? item.note : '-'">
							<v-btn icon @click="onRowClick(item)">
								<v-icon v-if="item.note" small>mdi-note-outline</v-icon>
							</v-btn>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		accounting: {
			type: Array,
			required: true
		},

		attestants: {
			type: Array,
			required: true
		},

		isVat: {
			type: Boolean,
			dafault: false
		}
	},

	data: () => ({

	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getCodeValue', 'getCodeLengths','getCodeText', 'getIsActiveCode'])
	},

	methods: {
		onRowClick(item) {
			this.$emit('on-click', {...item});
		},

		getAttestant(type, index) {
			if (this.attestants[index] && this.attestants[index][type]) {
				const login = this.attestants[index][type].map(att => ` ${att.login}`);
				const name = this.attestants[index][type].map(att => ` ${att.name}`);

				return {
					login: login.toString(),
					name: name.toString()
				};
			}

			return {
				login: '',
				name: ''
			};
		}
	}
};
</script>
