export const createPaginationObject = function({pageSize}) {

	return {
		maxPage: 9999,
		page: 1,
		pageSize,

		updateMaxPage: function(total) {
			this.maxPage = Math.ceil(total / this.pageSize);
		},

		next: function() {
			if (this.page + 1 <= this.maxPage) {
				this.page++;

				return true;
			}

			return false;
		},

		prev: function() {
			if (this.page - 1 > 0) {
				this.page--;

				return true;
			}

			return false;
		}
	};
};

export const convertToSortObject = (sortBy, sortDesc) => {
	return sortBy.map((sort, i) => {
		const descValue = sortDesc[i] ? 1 : -1;

		return {
			[sort]: descValue
		};
	});
};

export const getSortObj = pagination => {
	const ret = {};

	pagination.sortBy.forEach((item, i) => {
		ret[item] = pagination.sortDesc[i] ? -1 : 1;
	});

	return ret;
};
