<template>
	<div>
		<v-card class="px-8 py-4">
			<v-card-title class="px-0 d-flex justify-space-between">
				<div>{{ label }}</div>
			</v-card-title>
			<ui-select v-model="group" :items="groups" label="Grupp" item-text="name" item-value="_id"
				@input="fetchSettings"
			/>
			<v-divider class="my-4" />
			<div class="text-caption font-italic">
				<v-icon small color="warning">mdi-alert-box-outline</v-icon>
				Det bankgironr som ligger överst i listan är det som kommer användas vid betalning om inget annat anges
			</div>
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left"> Giro </th>
							<th />
						</tr>
					</thead>
					<tbody>
						<tr v-for="(account, index ) in settingAccounts"
							:key="index"
						>
							<td>{{ $utils.format.giro('BG', account) }}</td>
							<td class="text-right">
								<v-btn icon color="primary" :disabled="isPending || index === 0" @click.stop="moveTo(index, index -1)">
									<v-icon dark>mdi-chevron-up</v-icon>
								</v-btn>

								<v-btn icon color="primary" :disabled="isPending || index === settingAccounts.length - 1" @click.stop="moveTo(index, index + 1)">
									<v-icon dark>mdi-chevron-down</v-icon>
								</v-btn>

								<v-btn icon @click.stop="setActiveBG({ account, index })">
									<v-icon dark>mdi-delete</v-icon>
								</v-btn>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>

			<v-card-actions class="d-flex justify-end">
				<v-btn text color="primary" @click="addDialog = true">
					<v-icon left>mdi-plus</v-icon>
					Lägg till
				</v-btn>
				<v-btn text color="primary" :loading="isPending" @click="updateSetting"> Spara </v-btn>
			</v-card-actions>

			<Loader :error="errorMessage" />
		</v-card>


		<Dialog v-if="deleteDialog"
			title="Ta bort Bankgiro"
			ok-btn-text="Ta bort"
			width="400px"
			:loader="isPending"
			@reject="onDeleteReject"
			@confirm="deleteGiro"
		>
			<div class="mb-4">
				Är du säger på att du vill ta bort BG: <strong>{{ activeItem.account }}</strong>
			</div>
		</Dialog>

		<Dialog v-if="addDialog"
			title="Lägg till giro"
			ok-btn-text="Lägg till"
			width="450px"
			:loader="isPending"
			@reject="addDialog = false"
			@confirm="addGiro"
		>
			<v-form ref="form">
				<ui-text-field v-model="newGiroNumber" :rules="$validator('supplier/BGNumber')" label="Konto/Gironr" />
			</v-form>
			<Loader :error="errorMessageNew" :loader="isPending" slim />
		</Dialog>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ''
		},
		groups: {
			type: Array,
			required: true
		}
	},

	data: () => ({
		group: 0,
		newGiroNumber: '',
		addDialog: false,
		deleteDialog: false,

		settingAccounts: [],
		activeItem: null,

		isPending: false,
		errorMessage: '',
		errorMessageNew: ''
	}),

	async created() {
		await this.fetchSettings();
	},

	methods: {
		moveTo(fromIndex, toIndex) {
			const giro = this.settingAccounts[fromIndex];

			this.settingAccounts.splice(fromIndex, 1);
			this.settingAccounts.splice(toIndex, 0, giro);

			this.updateSetting();
		},

		setActiveBG(item) {
			this.activeItem = item;

			this.deleteDialog = true;
		},

		onDeleteReject() {
			this.deleteDialog = false;
			this.activeItem = {};
		},

		async fetchSettings() {
			try {
				const settings =  await this.$service.settings.get('kiPayAccounts', this.group, {
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.settingAccounts = settings.value;
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		async updateSetting() {
			const BG = this.settingAccounts.map(x => x.replace(/[^0-9]/g, ''));

			await this.$service.settings.update(this.group, {
				kiPayAccounts: BG
			}, {
				loading: val => {
					this.isPending = val;
				},
				throwException: true
			});

			await this.fetchSettings();
		},

		async addGiro() {
			if(!this.$refs.form.validate()) {
				return;
			}

			this.errorMessageNew= '';
			const BG = this.newGiroNumber.replace(/[^0-9]/g, '');

			try{
				await this.$service.settings.update(this.group, {
					'kiPayAccounts': [...this.settingAccounts, BG]
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});

				this.newGiroNumber = '';
				this.addDialog = false;
			} catch (error) {
				this.errorMessageNew = error.message;
			}

			await this.fetchSettings();
		},

		async deleteGiro() {
			if(this.settingAccounts.length > 1) {
				this.settingAccounts.splice(1, this.activeItem.index);
			} else {
				this.settingAccounts = [];
			}

			await this.updateSetting();

			this.activeItem = null;
			this.deleteDialog = false;

			this.fetchSettings();
		}
	}
};
</script>
