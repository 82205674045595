<template>
	<div>
		<Dialog
			title="Bjud in användare"
			text="Välj i listan vilken användare du vill bjuda in till konversationen."
			ok-btn-text="Bjud in"
			:disable-ok-btn="!selectedUser"
			@reject="close"
			@confirm="confirm"
		>
			<div class="pt-8">
				<ui-autocomplete
					v-model="selectedUser"
					return-object
					:items="getUsers"
					item-text="_userName"
					item-value="id"
					filled
					label="Användare"
				/>
			</div>
		</Dialog>
	</div>
</template>

<script>
import Dialog from '@/components/dialog';
import { mapGetters } from 'vuex';

export default {
	components: {
		Dialog
	},

	props: {

	},

	data: () => ({
		selectedUser: null,
		users: []
	}),

	computed: {
		...mapGetters('users', ['getUsers'])
	},

	methods: {
		close() {
			this.$emit('reject');
		},

		confirm() {
			this.$emit('add-user', this.selectedUser);
			this.close();
		}
	}
};
</script>
