<template>
	<div>
		<portal to="sidebar-right-title">
			{{ accountingPeriods.name }}
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;" />
		</portal>

		<v-form ref="form" @submit.prevent="submit">
			<ui-text-field v-model="formData.before" :rules="$validator('accounting/periodMin')" label="Dagar före" />
			<ui-text-field v-model="formData.after" :rules="$validator('accounting/periodMin')" label="Dagar efter" type="number" />

			<v-card-actions class="px-0">
				<v-spacer />
				<v-btn color="primary" text type="submit" :loading="isPending">
					{{ $t('base.save') }}
				</v-btn>
			</v-card-actions>
		</v-form>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: {
		accountingPeriods: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		formData: {
			before: 0,
			after: 0
		},

		isPending: false,
		error: ''
	}),

	computed: {
		...mapGetters('users', ['getUsers'])
	},

	watch: {
		'accountingPeriods._id'() {
			this.formData = {...this.accountingPeriods};
		}
	},

	created() {
		this.formData = {...this.accountingPeriods};
	},

	methods: {
		openDeleteConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: this.$t('delete.confirm_title', {name: 'Bokföringsperiod'}),
				text: this.$t('delete.confirm_text', {name: this.formData.name}),
				confirmed: this.delete
			});
		},

		async submit() {
			if (!this.$refs.form.validate()) {
				return;
			}

			try {
				await this.$service.bookings.updatePeriod(this.accountingPeriods._id, {
					before: parseInt(this.formData.before),
					after: parseInt(this.formData.after)
				}, {
					loading: val => {
						this.isPending = val;
					},
					throwException: true
				});

				this.$emit('updated');
			} catch (error) {
				this.error = error.message;
			}
		},

		async delete() {
			await this.$service.bookings.deletePeriod(this.formData._id, {
				loading: val => {
					this.isPending = val;
				}
			});

			this.$emit('deleted');

		}
	}
};
</script>
