import * as rules from '../rules';

export default {
	periodMin: () => ([
		rules.required(),
		rules.isInteger()
	]),

	isNumber: () => ([
		rules.isInteger()
	])
};
