<template>
	<v-row no-gutters>
		<v-col cols="6" class="pr-14">
			<v-card class="rounded-xl py-8 px-8">
				<UserEdit :user-to-edit="getUserData" />
			</v-card>
		</v-col>
		<v-col cols="6">
			<h4 class="text-h4 mb-2">Färgschema</h4>
			<v-card class="rounded-xl py-8 px-12 mb-14">
				<v-row>
					<v-col>
						<v-select v-model="activeTheme" :items="themesSelect" label="Tema" outlined />
						<div v-for="(value, key) in colors[activeTheme]" :key="key">
							<v-btn :color="value" @click="setColorToEdit(key)">{{ key }}</v-btn>
						</div>
					</v-col>
					<v-col>
						<v-color-picker
							v-if="activeProp"
							:value="colors[activeTheme][activeProp]"
							dot-size="15"
							mode="hexa"
							show-swatches
							swatches-max-height="200"
							@update:color="colorUpdate"
						/>
					</v-col>
				</v-row>
				<div class="d-flex justify-end">
					<v-btn color="primary" text @click="save">Spara</v-btn>
				</div>
			</v-card>
			<h4 class="text-h4 mb-2">Att göra</h4>
			<v-card class="rounded-xl py-8 px-12" height="60px" />
		</v-col>
	</v-row>
</template>

<script>
import {mapGetters} from 'vuex';
import UserEdit from '@/views/administration/children/users/components/user-edit';

export default {
	components: {
		UserEdit
	},

	props: {

	},

	data: () => ({
		themesSelect: ['light', 'dark'],
		activeTheme: 'light',
		activeProp: '',

		colors: {
			light: {
				primary: '#1976D2',
				accent: '#e91e63',
				secondary: '#30b1dc',
				success: '#4CAF50',
				info: '#2196F3',
				warning: '#FB8C00',
				error: '#FF5252',
				background: '#F5F5F5',
				backgroundSecondary: '#FFFFFF',
				primaryFont: '#FFFFFF',
				secondaryFont: '#000000'
			},

			dark: {
				primary: '#21CFF3',
				accent: '#FF4081',
				secondary: '#ffe18d',
				success: '#4CAF50',
				info: '#2196F3',
				warning: '#FB8C00',
				error: '#FF5252',
				background: '#2d282a',
				backgroundSecondary: '#1E1E1E',
				primaryFont: '#000000',
				secondaryFont: '#FFFFFF'
			}
		}
	}),

	computed: {
		...mapGetters('userStore', ['getUserData'])
	},

	methods: {
		setColorToEdit(prop) {
			this.activeProp = prop;
		},

		colorUpdate(value) {
			this.colors[this.activeTheme][this.activeProp] = value.hex;
		},

		save() {
			this.$vuetify.theme.themes.dark = {...this.colors.dark};
			this.$vuetify.theme.themes.light = {...this.colors.light};
			localStorage.setItem('fkp-colors', JSON.stringify(this.colors));
		}
	}
};
</script>
