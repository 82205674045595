import { kpApiV1 } from '@/plugins/axios';
import { compose } from '@/utils/service';

export default {
	get: compose(kpApiV1.get, 'calendar/<year>'),

	addYear: compose(kpApiV1.post, 'calendar/<year>'),

	update: compose(kpApiV1.post, 'calendar/<date>/<value>')
};
