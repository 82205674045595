import i18n from '@/plugins/i18n';
import component from './settings';
import SystemSettings from './children/system/system';
import BankDays from './children/bank-days/bank-days';
import AttachmentsTypes from './children/attachment-types/attachment-types';
import AccountingTemplate from './children/accounting-template/accounting-template';
import Accounting from './children/accounting/accounting';
import Code from './children/code/code';
import AttestRules from './children/attest-rules/attest-rules';
import Organisation from './children/organisation/organisation';
import Payments from './children/payments/payments';

export default [{
	path: '/settings',
	name: 'Settings',
	meta: {
		title: i18n.t('routes.titles.register')
	},
	component,
	children: [{
		path: 'system',
		name: 'System_Settings',
		component: SystemSettings,
		meta: {
			title: i18n.t('routes.titles.system_settings')
		}
	}, {
		path: 'accounting-template',
		name: 'AccountingTemplate_Settings',
		component: AccountingTemplate,
		meta: {
			title: i18n.t('routes.titles.accounting_template')
		}
	}, {
		path: 'bank-days',
		name: 'Bankdays_Settings',
		component: BankDays,
		meta: {
			title: i18n.t('routes.titles.bank_days')
		}
	}, {
		path: 'attachment-types',
		name: 'AttachmentTypes_Settings',
		component: AttachmentsTypes,
		meta: {
			title: i18n.t('routes.titles.attachment_types')
		}
	}, {
		path: 'accounting',
		name: 'AccountiongSettings',
		component: Accounting,
		meta: {
			title: i18n.t('routes.titles.accounting')
		}
	}, {
		path: 'code',
		name: 'code_Settings',
		component: Code,
		meta: {
			title: i18n.t('routes.titles.code')
		}
	}, {
		path: 'attest-rules',
		name: 'AttestRules_Settings',
		component: AttestRules,
		meta: {
			title: i18n.t('routes.titles.attest_rules')
		}
	}, {
		path: 'organisation',
		name: 'Organisation_Settings',
		component: Organisation,
		meta: {
			title: i18n.t('routes.titles.organisation')
		}
	}, {
		path: 'payments',
		name: 'Payments_Settings',
		component: Payments,
		meta: {
			title: i18n.t('routes.titles.payments')
		}
	}]
}];
