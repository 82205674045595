export const light = {
	primary: '#106487',
	accent: '#106487',
	secondary: '#30b1dc',
	success: '#4CAF50',
	info: '#2196F3',
	warning: '#FB8C00',
	error: '#FF5252',
	background: '#f0f0f0',
	backgroundSecondary: '#FFFFFF',
	primaryFont: '#FFFFFF',
	secondaryFont: '#000000'
};

export const dark = {
	primary: '#106487',
	accent: '#106487',
	secondary: '#FFE18D',
	success: '#4CAF50',
	info: '#2196F3',
	warning: '#FB8C00',
	error: '#FF5252',
	background: '#2A2A2A',
	backgroundSecondary: '#1E1E1E',
	primaryFont: '#000000',
	secondaryFont: '#FFFFFF'
};
