import apiClient from '@/plugins/axios';

export default {
	search: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/references/search', body, opts),

	get: (id, opts = {}) => apiClient.get(`/wp-json/kp/v1/references/${id}`, opts),

	add: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/references', body, opts),

	update: (referenceId, body, opts = {}) => apiClient.put(`/wp-json/kp/v1/references/${referenceId}`, body, opts),

	remove: (referenceId, opts = {}) => apiClient.delete(`/wp-json/kp/v1/references/${referenceId}`, opts)
};
