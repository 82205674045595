<template>
	<div>
		<v-row>
			<v-col cols="4">
				<code-card :settings="code_settings" label="System" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import codeCard from './components/card';

export default {
	components: {
		codeCard
	},

	data: () => ({
		groups: [],

		code_settings: [{
			key: 'kiCodeCount',
			label: 'Antal aktiva koddelar',
			group: 0
		}, {
			key: 'kiCodeNames',
			label: 'Namn på koddelar',
			group: 0
		}, {
			key: 'kiCodeLengths',
			label: 'Längd på koddelar',
			group: 0
		}]

	})
};
</script>
