import i18n from '@/plugins/i18n';
import component from './login';

export default [{
	path: '/login',
	name: 'Login',
	meta: {
		title: i18n.t('routes.titles.login'),
		layout: 'plain'
	},
	component
}];
