import i18n from '@/plugins/i18n';
import utils from '@/utils';
import component from './register';
import suppliersComponent from './children/suppliers/suppliers';
import accouningComponent from './children/accounting-records/accounting-records';
import Subscriptions from './children/subscriptions/subscriptions';
import referencesComponent from './children/references/references';
import attestComponent from './children/attest/attest';
import codelistComponent from './children/codelist/codelist';
import ledgerComponent from './children/ledger/ledger';

export default [{
	path: '/register',
	name: 'Register',
	meta: {
		title: i18n.t('routes.titles.register')
	},
	component,
	children: [{
		path: 'suppliers',
		name: 'Register_Suppliers',
		component: suppliersComponent,
		meta: {
			title: i18n.t('routes.titles.suppliers')
		},
		beforeEnter: (to, from, next) => {
			return utils.user.isAllowedTo('SupplierEdit') ? next() : next({ name: 'Register_Accouting' });
		}
	}, {
		path: 'accounting-records',
		name: 'Register_Accouting',
		component: accouningComponent,
		meta: {
			title: i18n.t('routes.titles.accounting_records')
		}
	},
	{
		path: 'subscriptions',
		name: 'Register_Subscriptions',
		component: Subscriptions,
		meta: {
			title: 'Abonnemang'
		},
		beforeEnter: (to, from, next) => {
			return utils.user.isAllowedTo('SubscriptionEdit') ? next() : next({ name: 'Register_Accouting' });
		}
	}, {
		path: 'references',
		name: 'Register_References',
		component: referencesComponent,
		meta: {
			title: i18n.t('routes.titles.profile_references')
		}
	}, {
		path: 'attest',
		name: 'Register_Attest',
		component: attestComponent,
		meta: {
			title: i18n.t('routes.titles.attest')
		}
	}, {
		path: 'codelist',
		name: 'Register_Codelist',
		component: codelistComponent,
		meta: {
			title: i18n.t('routes.titles.codelist')
		}
	}, {
		path: 'ledger',
		name: 'Register_Ledger',
		component: ledgerComponent,
		meta: {
			title: i18n.t('routes.titles.ledger')
		}
	}]
}];
