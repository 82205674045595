export default {
	SET_ACCOUNTING_CODES(state, payload) {
		state.codes = payload;
	},

	SET_CODE_NAMES(state, payload) {
		state.codeNames = payload;
	},

	SET_CODE_LENGTHS(state, payload) {
		state.codeLengths = payload;
	},

	SET_CODE_RULES(state, payload) {
		state.codeRules = payload;
	},

	SET_ACTIVE_CODE_COUNT(state, payload) {
		state.activeCodeCount = payload;
	}
};
