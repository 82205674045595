<template>
	<div>
		<div class="d-flex align-center justify-space-between">
			<v-btn class="ml-2" color="warning" small
				@click.stop="openConfirmDialog"
			>
				Godkänn med fel
			</v-btn>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		newDate: null,
		localInvoice: null
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
					this.newDate = null;
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);
	},

	methods: {
		updateInvoice() {
			this.localInvoice.Dates.IssueDate = this.newDate;
			this.newDate = null;

			this.$emit('on-invoice-update', this.localInvoice);
		},

		async confirmFaultyIvoice(params) {
			if (!params.message) {
				throw 'Du måste skriva en kommentar.';
			}

			this.localInvoice.meta.actions.skip = [...this.localInvoice.meta.actions.skip, 'F07_InvoiceTotalCheck'];
			this.localInvoice.meta.actions.skip_comment = [...this.localInvoice.meta.actions.skip_comment, `${params.message}`];
			await this.$emit('on-invoice-update', this.localInvoice);
		},

		openConfirmDialog() {
			this.$bus.emit('confirm:open', {
				headline: 'Godgänn felaktig faktura',
				text: 'Är du säker på att du vill godkänna fakturan med fel?',
				showMessage: true,
				confirmed: this.confirmFaultyIvoice
			});
		}
	}
};
</script>
