import Vue from 'vue';
import services from '@/services';
import {run} from '@/utils/service';
import {SET_SITES} from '../mutation-types';

export default {
	async fetchSites({commit}) {
		run(await services.sites.get.call(), data => commit(SET_SITES, data), error => Vue.bus.emit('message:add', {
			type: 'error',
			text: error
		}));
	},

	async addSite({dispatch}, title) {
		const data = await services.sites.add.call({ body: {title} });

		dispatch('fetchSites');

		return data;
	}
};
