import store from '@/plugins/vuex';

export default {
	addGlobalAttest({ commit }, payload) {
		const userId = store.getters['userStore/getUserData'].id;
		const isApproval = payload.attestants.approvals.some(a => a.id === userId);
		const isAuthorize = payload.attestants.authorizations.some(a => a.id === userId);
		const data = {
			invoiceId: payload.invoiceId,
			invoiceNumber: payload.invoiceNumber ? payload.invoiceNumber : '',
			attestId: payload._id,
			date: payload.date,
			codes: payload.codes,
			amount: payload.amount,
			isApproval,
			isAuthorize,
			sellerName: payload.sellerName ? payload.sellerName : ''
		};

		commit('GLOBAL_ATTEST_ADD', data);
	},

	removeRows({commit}, payload) {
		commit('GLOBAL_ATTEST_REMOVE', payload);
	}
};


