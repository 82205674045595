<template>
	<div>
		<div class="d-flex mb-6 align-center">
			<div class="text-caption">
				Nuvarande värde: <strong>{{ $utils.dotProp(localInvoice, 'Entities.Seller.Legal.ID', '') }}</strong>
			</div>
			<v-spacer />
			<v-btn color="primary" small @click="newDialogOpen = true"><v-icon left>mdi-plus</v-icon>Ny Leverantör</v-btn>
		</div>
		<search-supplier ref="Supplier" label="Sök Leverantör" @on-change="onChange" />
		<div v-if="newSupplier">
			<p class="text-caption">Vill du uppdatera till leverantören <strong class="primary--text">{{ newSupplier.name }}</strong> med organisationsnr <strong class="primary--text">{{ newSupplier.orgNumber }}</strong>?</p>
			<v-btn color="success" text small @click="updateInvoice">Ja, uppdatera</v-btn>
		</div>

		<SupplierNew v-if="newDialogOpen" :invoice="localInvoice" @close="newDialogOpen = false" @supplier-created="supplierCreated" />
	</div>
</template>

<script>
import SupplierNew from '@/views/register/children/suppliers/components/supplier-new';

export default {
	components: {
		SupplierNew
	},

	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		newSupplier: null,
		newDialogOpen: false,
		localInvoice: null
	}),

	watch: {
		invoice:{
			deep: true,
			handler(val, oldVal) {
				if(val != oldVal) {
					this.localInvoice = this.$utils.cloneDeep(val);
					this.newSupplier = null;
				}
			}
		}
	},

	created() {
		this.localInvoice = this.$utils.cloneDeep(this.invoice);
	},

	methods: {
		onChange(supplier) {
			this.newSupplier = supplier;
		},

		supplierCreated(supplier) {
			this.newSupplier = supplier;
			this.$refs.Supplier.fetchSuppliers();
		},

		updateInvoice() {
			this.localInvoice.Entities.Seller.Legal.ID = this.newSupplier.orgNumber;

			this.$emit('on-invoice-update', this.localInvoice);
		}
	}
};
</script>
