import service from '@/services';

export default {
	async fetchAccountingCodelist({ commit }) {
		const codelistData = await service.accounting.get();

		commit('SET_ACCOUNTING_CODES', codelistData);
	},

	async setCodeRules({commit}) {
		const data =  await service.settings.list(0, {
			keys: ['kiCodeNames', 'kiCodeLengths', 'kiCodeCount', 'kiAttestationRuleParts']
		});

		commit('SET_CODE_RULES', data.kiAttestationRuleParts.value);
		commit('SET_CODE_LENGTHS', data.kiCodeLengths.value);
		commit('SET_ACTIVE_CODE_COUNT', data.kiCodeCount.value);
		commit('SET_CODE_NAMES', data.kiCodeNames.value);
	}
};
