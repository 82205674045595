<template>
	<div>
		<v-row no-gutters>
			<v-col cols="6" />
			<v-col cols="6">
				<div class="d-flex justify-end align-center" style="height: 48px;">
					<v-btn v-tooltip :loading="isPending" content="Exportera till excel" color="primary" class="mx-4"
						@click="exportExcel"
					>
						<v-icon>mdi-microsoft-excel</v-icon>
					</v-btn>
					<v-btn v-if="isUserAllowedToAddUser" color="accent" @click="newUserDialogOpen = true">
						{{ $t('register.users.new_user') }}
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<!--Loader :service="[$service.users.get]" hide-loading /-->

		<RegisterList ref="registerList" :pagination="pagination" :items="users" :headers="getHeaders" sidebar-width="50%"
			item-key="id"
			:push-content="false"
			@active-item-changed="setActiveItem"
			@pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<UserEdit v-if="activeItem" :user-to-edit="activeItem" @user-deleted="userUpdated" />
		</RegisterList>

		<UserNew v-if="newUserDialogOpen" @close="onClose" @user-created="userUpdated" />
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import UserEdit from './components/user-edit';
import UserNew from './components/user-new';
import RegisterList from '@/components/register-list';

export default {
	name: 'UsersChildView',

	components: {
		UserEdit,
		UserNew,
		RegisterList
	},

	data: () => ({
		activeItem: undefined,
		users: [],
		newUserDialogOpen: false,
		filter: {},
		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: ['name'],
			sortDesc: [true]
		},
		isPending: false
	}),

	computed: {
		...mapGetters('userStore', [
			'getUserData',
			'isUserAllowedToAddUser',
			'isUserAllowedToViewRoles'
		]),

		getHeaders() {
			return [{
				text: this.$t('base.name'),
				value: 'name',
				align: 'start',
				filter: {
					type: 'text'
				}
			}, {
				text: this.$t('base.email'),
				value: 'email',
				filter: {
					type: 'text'
				}
			}, this.isUserAllowedToViewRoles && {
				text: this.$t('base.roles'),
				value: 'roles',
				sortable: false
			}].filter(Boolean);
		}
	},

	created() {
		this.$bus.on('users:updated', this.fetchUsers);
	},

	beforeDestroy() {
		this.$bus.off('users:updated', this.fetchUsers);
	},

	methods: {
		setActiveItem(user) {
			this.activeItem = user;
		},

		async onFilterUpdate(filter) {
			this.filter = filter;

			await this.fetchUsers();
		},

		onClose() {
			this.activeItem = null;
			this.newUserDialogOpen = false;
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchUsers();
		},

		async userUpdated(user) {
			await this.fetchUsers();


			if (user) {
				this.$refs.registerList.open(user);
			} else {
				this.$refs.registerList.close();
			}
		},

		async onAddUser(user) {
			console.log(user);
			await this.fetchUsers();
		},

		async exportExcel() {
			this.isPending = true;
			const data = await this.$service.users.search({
				pageSize: 0,
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			});

			await this.$utils.excel.download(data, 'Användare');
			this.isPending = false;
		},

		async fetchUsers() {
			const data = await this.$service.users.search({
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				},
				page: this.pagination.page,
				pageSize: this.pagination.pageSize
			});

			this.pagination.total = data._paginationTotal;

			this.users = data.map(user => {
				return {
					...user,
					name: `${user.name} (${user.login})`,
					roles: Object.entries(user.roles).map(([key, value]) => ({key, value}))
				};
			});

		}
	}
};
</script>
