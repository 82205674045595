<template>
	<div>
		<v-row class="mb-4" no-gutters>
			<v-col cols="12" class="justify-space-between">
				<ui-checkbox v-model="formData.post" label="Skicka postavi" />
				<v-col v-if="formData.post" cols="12" class="d-flex justify">
					<v-row>
						<ui-text-field ref="name" v-model="formData.name" label="Namn" />
					</v-row>
				</v-col>
				<v-col v-if="formData.post" cols="12" class="d-flex justify-space-between">
					<v-row class="justify-space-between">
						<ui-text-field ref="address1" v-model="formData.addr1" label="Gatuadress" />
						<ui-text-field ref="address2" v-model="formData.addr2" label="C/O-adress" />
						<ui-text-field ref="zip" v-model="formData.postcode" label="Postnummer" />
						<ui-text-field ref="city" v-model="formData.town" label="Stad" />
					</v-row>
				</v-col>
			</v-col>
			<v-col cols="8" />
			<v-col cols="6">
				<ui-datepicker v-model="formData.paydate" :allowed-dates="`${new Date()}`" label="Betaldatum" />
			</v-col>
			<v-col cols="6"> <ui-autocomplete v-model="formData.number" :items="payAccounts" label="Betalkonton" /></v-col>
			<v-col cols="12">
				<credits :invoice="invoice" />
			</v-col>
			<v-col cols="12">
				<div v-if="invoice.meta.payment_status !== 'paid'" class="d-flex justify-end">
					<v-btn v-if="invoice.meta.payment_status === 'unpaid'" text color="warning" @click="blockPayment">Blockera betalning</v-btn>
					<v-btn v-if="invoice.meta.payment_status === 'blocked'" color="warning" @click="unBlockPayment">Avblockera betalning</v-btn>
					<v-btn :disabled="invoice.meta.payment_status === 'blocked'" color="primary" class="ml-4" @click.stop="openSign">Godkänn betalning</v-btn>
				</div>
				<p v-else> Fakturan är betald </p>
			</v-col>
			<!-- <v-col cols="2">
				<div class="d-flex justify-end">
					<v-btn color="primary" text @click="updatePayment"> Spara </v-btn>
				</div>
			</v-col> -->
		</v-row>
		<Loader :error="errorMessage" :loading="isPending" />
		<Dialog v-if="signOpen" title="Signera" ok-btn-text="Sign" :loading="signLoading" @confirm="completePayment"
			@reject="signOpen = false"
		>
			<v-text-field ref="passwordInput" v-model="password" label="Lösenord" type="password" @keydown.enter.prevent="completePayment" />
			<Loader :error="signError" hide-loading slim />
		</Dialog>
	</div>
</template>

<script>
import {run} from '@/utils/service';
import {mapGetters} from 'vuex';
import Credits from './credits.vue';

export default {
	components: {
		Credits
	},

	props: {
		invoice: {
			type: Object,
			required: true,
			default: () => ({})
		}
	},

	data: () => ({
		payAccounts: [],

		formData: {
			paydate: '',
			number: '',
			post: false,
			name: '',
			addr1: '',
			addr2: '',
			postcode: '',
			town: ''
		},


		signOpen: false,
		password: '',

		errorMessage: '',
		signError: '',
		signPending: false,
		isPending: false
	}),

	computed: {
		...mapGetters('userStore', ['getUserGroupId'])
	},

	watch: {
		'invoice._id'() {
			this.errorMessage = '';
			this.setData();
		}
	},

	created() {
		this.setData();
		this.getPayAccount();
	},

	methods: {
		setData() {
			this.formData = {
				paydate: this.$utils.date.intToPretty(this.invoice.meta.payment.paydate),
				number: this.$utils.format.giro('BG', `${this.invoice.meta.payment.debtor.number}`),
				post: this.invoice.meta.payment.post,
				name: this.invoice.meta.payment.creditor.name ? this.invoice.meta.payment.creditor.name : '',
				addr1: this.invoice.meta.payment.creditor.addr1 ? this.invoice.meta.payment.creditor.addr1 : '',
				addr2: this.invoice.meta.payment.creditor.addr2 ? this.invoice.meta.payment.creditor.addr2 : '',
				postcode: this.invoice.meta.payment.creditor.postcode ? this.invoice.meta.payment.creditor.postcode : '',
				town: this.invoice.meta.payment.creditor.town ? this.invoice.meta.payment.creditor.town : ''
			};
		},

		async blockPayment() {
			run(await this.$service.invoices.markAsBlocked.call({
				body: {
					invoiceIds: [this.invoice._id]
				}
			}));
		},

		async unBlockPayment() {
			run(await this.$service.invoices.markAsUnblocked.call({
				body: {
					invoiceIds: [this.invoice._id]
				}
			}));
		},

		updateIn() {
			if (!this.$refs.form.validate()) {
				return;
			}
		},

		async openSign() {
			this.signOpen = true;
			await this.$nextTick();
			this.$refs.passwordInput.$el.querySelector('input').focus();
		},

		async completePayment() {
			const res = await this.updatePayment();

			if(res) {
				try {
					await this.$service.invoices.pay({
						invoiceIds: [this.invoice._id],
						password: this.password
					}, {
						throwException: true,
						loading: val => {
							this.signLoading = val;
						}
					});

					this.password = '';
					this.signOpen = false;
					this.errorMessage = '';
					this.$emit('on-update');
				} catch (error) {
					this.signError = error.message;
				}
			}
		},

		async updatePayment() {
			try {
				const data = await this.$service.invoices.setPaymentMeta(this.invoice._id, {
					...this.formData,
					paydate: this.$utils.date.dateToInt(this.formData.paydate),
					number: this.formData.number.replace(/[^0-9]/g, '')
				}, {
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				return data;
			} catch(error) {
				this.signOpen = false;
				this.errorMessage = error.message;
			}
		},

		async getPayAccount() {
			try {
				const data =  await this.$service.settings.getAll({
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.payAccounts = data.map(value => this.$utils.format.giro('BG', value));
			} catch(error) {
				this.errorMessage = error.message;
			}
		}
	}
};


</script>
