<template>
	<Dialog
		title="Reskontra"
		width="2100px"
		@reject="close"
	>
		<div>
			<div class="d-flex">
				<ui-datepicker v-model="ledgerData.from" class="pr-2" label="Från" scrollable />
				<ui-datepicker v-model="ledgerData.to" class="pr-2" label="Till" scrollable />
			</div>

			<div class="text-subtitle-2 font-weight-bold">Belopp:</div>
			<v-data-table :headers="header"
				:loading="isLedgerPending"
				:items="ledger"
				:hide-default-footer="true"
				:items-per-page="-1"
			>
				<template #item.sum_debit="{item}">
					<div>
						{{ item.sum_debit.toFixed(2) }}
					</div>
				</template>
				<template #item.sum_credit="{item}">
					<div>
						{{ item.sum_credit.toFixed(2) }}
					</div>
				</template>
				<template #item.sum="{item}">
					<div>
						{{ item.sum.toFixed(2) }}
					</div>
				</template>
			</v-data-table>

			<v-data-table :headers="getHeaders"
				:loading="isLedgerPending"
				:items="bookings"
				:hide-default-footer="true"
				:items-per-page="-1"
				multi-sort
			>
				<template #item.attestants="{item}">
					<div>
						{{ getAttestant(item) }}
					</div>
				</template>
				<template #item.approvals="{item}">
					<div>
						{{ getApproval(item) }}
					</div>
				</template>
				<template #item.amount="{item}">
					<div>
						{{ item.amount.toFixed(2) }}
					</div>
				</template>
			</v-data-table>
		</div>
	</Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {

	props: {
		supplierId: {
			type: String,
			default: ''
		}
	},

	data: vm => ({
		isLedgerPending: false,

		ledgerData: {
			from: '2000-01-01',
			to: vm.$utils.date.todayInPretty()
		},

		bookings: [],
		ledger: [],

		header: [
			{ text: 'Debet', value: 'sum_debit', align: 'start'},
			{ text: 'Kredit', value: 'sum_credit' },
			{ text: 'Netto', value: 'sum' }
		]
	}),

	computed: {
		...mapGetters('accounting', ['getCodeNames', 'getCodeLengths']),
		getHeaders() {
			return [
				{ text: 'Datum', value: 'date', align: 'start'},
				{ text: 'Vernr (Ursp.vernr)', value: '_vernr'},
				{ text: 'Belopp', value: 'amount', align: 'right'},
				{ text: `${this.getCodeNames.A}`, value: 'codes[0]'},
				{ text: `${this.getCodeNames.B}`, value: 'codes[1]'},
				{ text: `${this.getCodeNames.C}`, value: 'codes[2]'},
				{ text: `${this.getCodeNames.D}`, value: 'codes[3]'},
				{ text: `${this.getCodeNames.E}`, value: 'codes[4]'},
				{ text: `${this.getCodeNames.F}`, value: 'codes[5]'},
				{ text: `${this.getCodeNames.G}`, value: 'codes[6]'},
				{ text: `${this.getCodeNames.H}`, value: 'codes[7]'},
				{ text: `${this.getCodeNames.I}`, value: 'codes[8]'},
				{ text: `${this.getCodeNames.J}`, value: 'codes[9]'},
				{ text: 'Utbetdat', value: 'payment'},
				{ text: 'Fakturanr', value: 'invoiceId'},
				{ text: 'Beslutsattestanter (Datum)', value: 'attestants'},
				{ text: 'Godkännare (Datum)', value: 'approvals'},
				{ text: 'Dokumentlöpnr', value: 'serial_no', align: 'right'}
			];
		}
	},

	watch: {
		'ledgerData.to'() {
			this.fetchLedger();
		},

		'ledgerData.from'() {
			this.fetchLedger();
		}
	},

	async created() {
		await this.fetchLedger();
	},

	methods: {
		getAttestant(item) {
			return item.attestants[0] ? item.attestants.map(x => `${x.login} (${this.$utils.date.intToPretty(x.date)})`).join(', ') : '';
		},

		getApproval(item) {
			return item.approvals[0] ? item.approvals.map(x => `${x.login} (${this.$utils.date.intToPretty(x.date)})`).join(', ') : '';
		},

		formatCodes(codes) {
			const formatted = codes.map((x, index) => String(x).padStart(this.getCodeLengths[index], '0'));

			return formatted;
		},

		async fetchLedger() {
			const searchFormat = {
				supplier_id: this.supplierId,
				from: this.$utils.date.dateToInt(this.ledgerData.from),
				to: this.$utils.date.dateToInt(this.ledgerData.to)
			};
			const data = await this.$service.suppliers.searchLedger(searchFormat, {
				throwException: true,
				loading: val => {
					this.isLedgerPending = val;
				}
			});

			this.ledger = [data];
			this.bookings = data.bookings.map(b => ({
				date: this.$utils.date.intToPretty(b.date),
				_vernr: b.meta.accounts_payable.verno_other ? `${b.meta.accounts_payable.verno} (${b.meta.accounts_payable.verno_other})` : `${b.meta.accounts_payable.verno}`,
				amount: b.amount,
				codes: this.formatCodes(b.codes.filter(n => n !== 0)),
				payment: this.$utils.date.intToPretty(b.meta.accounts_payable.payment_due),
				invoiceId: b.meta.accounts_payable.ID,
				attestants: b.meta.attestants.authorizations,
				approvals: b.meta.attestants.approvals,
				serial_no: b.meta.accounts_payable.serial_no
			}));
		},

		close() {
			this.$emit('close');
		}
	}
};
</script>
