import apiClient from '@/plugins/axios';

export default {
	get: (supplierId, opts = {}) => apiClient.get(`/wp-json/kp/v1/suppliers/${supplierId}`, opts),

	create: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/suppliers', body, opts),

	update: (supplierId, body, opts = {}) => apiClient.patch(`/wp-json/kp/v1/suppliers/${supplierId}`, body, opts),

	delete: (supplierId, opts = {}) => apiClient.delete(`/wp-json/kp/v1/suppliers/${supplierId}`, opts),

	getFromInyett: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/suppliers/inyett', body, opts),

	updateGiro: (supplierId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/suppliers/${supplierId}/giro`, body, opts),

	search: (body = {}, opts = {}) => apiClient.post('/wp-json/kp/v1/suppliers/search', body, opts),

	searchLedger: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/balance', body, opts),

	undelete: (supplierId, body = {}, opts = {}) => apiClient.post(`/wp-json/kp/v1/suppliers/${supplierId}/undelete`, body, opts),

	getCredits: (supplierId, opts = {}) => apiClient.get(`/wp-json/kp/v1/credits/supplier/${supplierId}`, opts),

	reserveCredit: (creditId, debitId, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/credits/${creditId}/reserve/${debitId}`, body, opts),

	deleteCredit: (creditId, debitId, opts = {}) => apiClient.delete(`/wp-json/kp/v1/credits/${creditId}/reserve/${debitId}`, opts)
};
