import apiClient from '@/plugins/axios';

export default {
	search: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/mail/search', body, opts),

	get: (id, opts = {}) => apiClient.get(`/wp-json/kp/v1/mail/${id}/all`, opts),

	add: (body, opts = {}) => apiClient.post('/wp-json/kp/v1/mail', body, opts),

	answer: (id, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/mail/${id}`, body, opts),

	owner: (id, opts = {}) => apiClient.get(`/wp-json/kp/v1/mail/${id}/owner`, opts),

	leaveConversation: (id, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/mail/${id}/leave`,body, opts),

	addUserToConversation: (id, body, opts = {}) => apiClient.post(`/wp-json/kp/v1/mail/${id}/add`, body, opts),

	markMessageAsRead: (id, opts = {}) => apiClient.post(`/wp-json/kp/v1/mail/${id}/read`, opts),

	markMessageAsUnread: (id, opts = {}) => apiClient.post(`/wp-json/kp/v1/mail/${id}/unread`, opts),

	markMessageAsComplete: (id, opts = {}) => apiClient.post(`/wp-json/kp/v1/mail/${id}/complete`, opts),

	markMessageAsIncomplete: (id, opts = {}) => apiClient.post(`/wp-json/kp/v1/mail/${id}/incomplete`, opts),

	archiveConversation: (id, opts = {}) => apiClient.post(`/wp-json/kp/v1/mail/${id}/archive`, opts),

	unarchiveConversation: (id, opts = {}) => apiClient.post(`/wp-json/kp/v1/mail/${id}/unarchive`, opts)
};
