<template>
	<div>
		<div class="d-flex justify-end align-center" style="height: 48px;">
			<v-btn v-tooltip content="Exportera till excel" :loading="isPending" color="primary" class="mx-4"
				@click="exportExcel"
			>
				<v-icon>mdi-microsoft-excel</v-icon>
			</v-btn>
			<v-btn v-if="isUserAllowedToHandleAttestRegister" color="accent mr-2" @click="newDialogOpen = true">
				Ny attesträtt
			</v-btn>
		</div>

		<RegisterList
			ref="registerList"
			:no-click="!isUserAllowedToHandleAttestRegister"
			:items="attestants"
			:headers="getHeaders"
			sidebar-width="60%"
			item-key="_id"
			:pagination="pagination"
			@active-item-changed="setActiveItem"
			@pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<attest-edit v-if="activeAttest" :attest="activeAttest" @on-updated="attestUpdated" />
		</RegisterList>

		<AttestNew v-if="newDialogOpen" @close="newDialogOpen = false" @on-created="fetchAttest" />
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import AttestNew from './components/attest-new';
import AttestEdit from './components/attest-edit';
import { mapGetters } from 'vuex';

export default {
	components: {
		RegisterList,
		AttestNew,
		AttestEdit
	},

	data: () => ({
		newDialogOpen: false,

		attestants: [],

		filter: {},
		searchType: null,
		activeAttest: null,

		active: null,
		isPending: false,

		pagination: {
			page: 1,
			pageSize: 25,
			total: 1,
			sortBy: ['name'],
			sortDesc: [true]
		}
	}),

	computed: {
		...mapGetters('users', ['getUsers']),
		...mapGetters('accounting', ['getCodelistIntKeys', 'getCodeNames', 'getIsActiveCode']),
		...mapGetters('userStore', ['isUserAllowedToHandleAttestRegister']),

		getHeaders() {
			return [{
				text: 'Användare',
				value: 'name',
				filter: {
					type: 'text',
					placeholder: ''
				}
			}, {
				text: 'Roll',
				value: 'type',
				filter: {
					type: 'select',
					items: [{
						text: 'Beslutsattestant',
						value: 2
					}, {
						text: 'Godkännare',
						value: 1
					}]
				}
			}, this.getIsActiveCode(0) && {
				text: `${this.getCodeNames.A}`,
				value: '_A',
				sortable: false
			}, this.getIsActiveCode(1) && {
				text: `${this.getCodeNames.B}`,
				value: '_B',
				sortable: false
			}, this.getIsActiveCode(2) && {
				text: `${this.getCodeNames.C}`,
				value: '_C',
				sortable: false
			}, this.getIsActiveCode(3) && {
				text: `${this.getCodeNames.D}`,
				value: '_D',
				sortable: false
			}, this.getIsActiveCode(4) && {
				text: `${this.getCodeNames.E}`,
				value: '_E',
				sortable: false
			}, this.getIsActiveCode(5) && {
				text: `${this.getCodeNames.F}`,
				value: '_F',
				sortable: false
			}, this.getIsActiveCode(6) && {
				text: `${this.getCodeNames.G}`,
				value: '_G',
				sortable: false
			}, this.getIsActiveCode(7) && {
				text: `${this.getCodeNames.H}`,
				value: '_H',
				sortable: false
			}, this.getIsActiveCode(8) && {
				text: `${this.getCodeNames.I}`,
				value: '_I',
				sortable: false
			}, this.getIsActiveCode(9) && {
				text: `${this.getCodeNames.J}`,
				value: '_J',
				sortable: false
			}, {
				text: 'Fr.o.m',
				value: '_from'
			}, {
				text: 'T.o.m.',
				value: '_to'
			}, {
				text: 'Belopp',
				value: 'amount',
				align: 'right',
				filter: [{
					type: 'text',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(val) : null
				},
				{
					type: 'text',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(val) : null
				}]
			}].filter(Boolean);
		}
	},

	methods: {
		setActiveItem(item) {

			if (!item) {
				this.activeAttestId = undefined;

				return;
			}

			this.activeAttest = item;
		},

		onFilterUpdate(filter) {
			this.filter = filter;
			this.fetchAttest();
		},


		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchAttest();
		},

		async attestUpdated(item) {
			await this.fetchAttest();

			if (item) {
				this.$refs.registerList.open(item);
			} else {
				this.$refs.registerList.close();
			}
		},

		async exportExcel() {
			this.isPending = true;
			const charCode = ['A', 'B','C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
			const data = await this.$service.attest.search({
				pageSize: 0,
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					...this.searchType
				}
			});
			const exportFile = data.map(d => {
				this.isPending = true;
				let activeCodes = [];

				for (let i = 0; i < 10; i++) {
					if (this.getIsActiveCode(i)) {
						activeCodes.push({ key: this.getCodeNames[charCode[i]], value: `${d.codes[i][0]} - ${d.codes[i][1]}` || '' });
					}
				}

				activeCodes = activeCodes.reduce((acc, code) => {
					acc[code.key] = code.value;

					return acc;
				}, {});

				return {
					'Namn': `${d.name} (${d.login})`,
					'Roll': d.type === 1 ? 'Godkännare' : 'Beslutsattestant',
					...activeCodes,
					'Fr.o.m': d.from ? this.$utils.date.intToPretty(d.from) : '-',
					'T.o.m.': d.to ? this.$utils.date.intToPretty(d.to) : '-',
					'Belopp': d.amount
				};
			});

			await this.$utils.excel.download(exportFile, 'Attest');
			this.isPending = false;
		},

		async fetchAttest() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const data = await this.$service.attest.search({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter)
				}
			});

			this.pagination.total = data._paginationTotal;

			this.attestants = data.map(d => ({
				...d,
				name: `${d.name} (${d.login})`,
				type: d.type === 1 ? 'Godkännare' : 'Beslutsattestant',
				_type: d.type,
				_from:  `${d.from !== 99999999 ? this.$utils.date.intToPretty(d.from): ''}`,
				_to:  `${d.to !== 99999999 ? this.$utils.date.intToPretty(d.to): ''}`,
				amount: this.$utils.format.price(d.amount),
				_amount: d.amount,
				_codes: d.codes,
				codes: d.codes.map(code => 	`${code[0]} - ${code[1]}`),
				_A : this.getCodeText('A', d.codes[0]),
				_B : this.getCodeText('B', d.codes[1]),
				_C : this.getCodeText('C', d.codes[2]),
				_D : this.getCodeText('D', d.codes[3]),
				_E : this.getCodeText('E', d.codes[4]),
				_F : this.getCodeText('F', d.codes[5]),
				_G : this.getCodeText('G', d.codes[6]),
				_H : this.getCodeText('H', d.codes[7]),
				_I : this.getCodeText('I', d.codes[8]),
				_J : this.getCodeText('J', d.codes[9])
			}));

		},

		getCodeText(codeType, codes) {
			if(!this.getCodelistIntKeys[codeType]) {
				return '';
			}

			const code1 = codes[0] in this.getCodelistIntKeys[codeType] ? this.getCodelistIntKeys[codeType][codes[0]].kod : `${codes[0]}`;
			const code2 = codes[1] in this.getCodelistIntKeys[codeType] ? this.getCodelistIntKeys[codeType][codes[1]].kod : `${codes[1]}`;

			return code1 == code2 ? code1 : `${code1} - ${code2}`;
		}
	}
};
</script>
