<template>
	<div class="RegisterList">
		<div class="d-flex align-end mb-5">
			<v-select
				v-model="massAction"
				dense
				class="MassActionSelect mr-2"
				style="max-width: 200px;"
				:items="onlyForward ? onlyAction : massActions"
				label="Massåtgärd"
			/>
			<v-btn small color="primary" :disabled="!massAction" @click="doMassAction">Kör</v-btn>
		</div>

		<v-data-table
			ref="table"
			v-model="selectedRows"
			:sort-by="localPagination.sortBy"
			:sort-desc="localPagination.sortDesc"
			multi-sort
			:headers="getHeaders"
			:page="localPagination.page"
			:items="getItems"
			:items-per-page="localPagination.pageSize"
			:footer-props="{
				'items-per-page-options': [10, 25, 50, 100]
			}"
			item-class="_className"
			show-select
			item-key="_id"
			:hide-default-footer="hideFooter"
			:server-items-length="localPagination.total"
			:loading="loading"
			@click:row="open"
			@update:sort-by="onUpdateSort"
			@update:sort-desc="onUpdateSortDesc"
			@update:items-per-page="onUpdateItemsPerPage"
			@update:page="onUpdatePage"
		>
			<template v-slot:header="{props}">
				<tbody v-if="hasFilters(props.headers)">
					<tr class="RegisterList-Filter-Row ">
						<td v-for="header in props.headers" :key="header.value">
							<v-row v-if="Array.isArray(header.filterObj)" no-gutters>
								<v-col v-for="(item, index) in header.filterObj" :key="index" cols="6" class="pa-0">
									<v-text-field v-if="item.type === 'text'"
										v-model="filter[header.value][index]"
										class="RegisterList-Filter-Input mr-2 pt-1 "
										desen
										hide-details
										:placeholder="item.placeholder ? item.placeholder : 'Sök'"
										style="max-width: 250px; padding-bottom: 9px;"
										clearable
										no-label
									/>
									<ui-datepicker v-if="item.type === 'date'"
										v-model="filter[header.value][index]"
										class="RegisterList-Filter-Input"
										:clean="true"
										:placeholder="item.placeholder"
										:allowed-dates="index > 0 ? filter[header.value][0] : null"
										clearable
										no-label
									/>
								</v-col>
							</v-row>
							<div v-else>
								<div v-if="header.filterObj" class="mb-1">
									<v-text-field v-if="'filterObj' in header && header.filterObj.type === 'text'"
										v-model="filter[header.value]"
										dense
										hide-details
										class="RegisterList-Filter-Input textField"
										style="max-width: 250px;"
										:placeholder="header.filterObj.placeholder ? header.filterObj.placeholder : 'Sök'"
										clearable
										no-label
									/>
									<div v-if="'filterObj' in header && header.filterObj.type === 'users'">
										<v-autocomplete
											v-model.number="filter[header.value]"
											clearable
											class="RegisterList-Filter-Input textField"
											dense
											:items="getUsers"
											item-text="_userName"
											item-value="id"
											no-label
										/>
									</div>
									<div v-if="'filterObj' in header && header.filterObj.type === 'group' && groups ">
										<v-autocomplete
											v-model="filter[header.value]"
											clearable
											class="RegisterList-Filter-Input textField"
											dense
											:items="groups"
											item-text="name"
											item-value="_id"
										/>
									</div>

									<ui-datepicker v-if="'filterObj' in header && header.filterObj.type === 'date'"
										v-model="filter[header.value]"
										:clean="true"
										class="RegisterList-Filter-Input"
										:placeholder="header.filterObj.placeholder"
										clearable
										no-label
									/>

									<v-autocomplete v-if="'filterObj' in header && header.filterObj.type === 'select'"
										v-model="filter[header.value]"
										class="RegisterList-Filter-Input textField"
										:placeholder="header.filterObj.placeholder"
										:items="header.filterObj.items"
										clearable
										no-label
										dense
									/>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</template>

			<template v-slot:item.todo_type="{item}">
				<div v-if="item.todo_type === 'invoice'">
					<v-icon color="#28715F">mdi-file-document-outline</v-icon>
					<span class="ml-2">Faktura</span>
				</div>

				<div v-if="item.todo_type === 'order'">
					<v-icon color="#812C57">mdi-cart-variant</v-icon>
					<span class="ml-2">Beställning</span>
				</div>

				<div v-if="item.todo_type === 'pay'">
					<v-icon color="#106487">mdi-bank-transfer-in</v-icon>
					<span class="ml-2">Godkänna betalning</span>
				</div>

				<div v-if="item.todo_type === 'subscription'">
					<v-icon color="#106487">mdi-update</v-icon>
					<span class="ml-2">Hantera Abonnemang</span>
				</div>

				<div v-if="item.todo_type === 'user'">
					<v-icon color="#106487">mdi-account</v-icon>
					<span class="ml-2">Avsluta användare</span>
				</div>
			</template>

			<template v-slot:item.todo="{item}">
				<v-chip v-if="item.todo === 'ac'" color="#dd1946" class="white--text">Kontera</v-chip>
				<v-chip v-if="item.todo === 'acap'" color="#007496" class="white--text">Godkänn kontering</v-chip>
				<v-chip v-if="item.todo === 'acau'" color="#5e69b9" class="white--text">Beslutsattestera</v-chip>
				<v-chip v-if="item.todo === 'pay'" color="#007496" class="white--text">Godkänn betalning</v-chip>
				<v-chip v-if="item.todo === 'serr'" color="#dd1946" class="white--text">Hantera abonnemangsfel</v-chip>
				<v-chip v-if="item.todo === 'cap'" color="#007496" class="white--text">Godkänn kostnadskontering</v-chip>
				<v-chip v-if="item.todo === 'cau'" color="#dd1946" class="white--text">Beslutsattestera kostnadskontering</v-chip>
				<v-chip v-if="item.todo === 'vap'" color="#007496" class="white--text">Godkänn momskontering</v-chip>
				<v-chip v-if="item.todo === 'vau'" color="#dd1946" class="white--text">Beslutsattestera momskontering</v-chip>
				<v-chip v-if="item.todo === 'hi'" color="#5e69b9" class="white--text">Hantera avslutad användare</v-chip>
			</template>

			<template v-slot:item.meta.amount="{item}">
				<td style="text-align: end;">
					{{ item.meta.amount }}
				</td>
			</template>

			<template v-slot:item.meta.investigation="{item}">
				<v-icon v-if="item.meta.investigation">mdi-text-box-search-outline</v-icon>
			</template>

			<template v-slot:item.from="{item}">
				<span>{{ getUser(item.from) }}</span>
			</template>

			<template v-slot:item.meta.date="{item}">
				<div v-if="item.meta.date">
					<span v-if="isDueDate($utils.date.intToDate(item.meta.date))" class="red--text">{{ $utils.date.getFullDate($utils.date.intToDate(item.meta.date)) }}</span>
					<span v-else>{{ $utils.date.getFullDate($utils.date.intToDate(item.meta.date)) }}</span>
				</div>
			</template>

			<template v-slot:item.misc="{item}">
				<div class="d-flex justify-space-between">
					<div v-if="item.meta.formal">
						<v-icon v-if="item.meta.formal.not_ok" v-tooltip content="Inte OK" color="red">mdi-alert-box</v-icon>
						<v-icon v-if="item.meta.formal.unknown" v-tooltip content="Okänd" color="orange">mdi-alert-box</v-icon>
						<v-icon v-if="item.meta.subscription_error" v-tooltip :content="item.meta.subscription_error" color="primary">mdi-alert-box</v-icon>
					</div>
					<v-icon v-if="item.meta.sensitivity && item.meta.sensitivity > 0" v-tooltip :content="item.meta.sensitivity === 1 ? 'Utskrift': 'Visning'" small>mdi-lock</v-icon>
					<v-icon v-if="!item.meta.note" small />
					<v-icon v-if="item.meta.note" small>mdi-forum</v-icon>
				</div>
			</template>
		</v-data-table>


		<div class="d-flex align-end mt-2">
			<v-select
				v-model="massAction"
				dense
				class="MassActionSelect mr-2"
				style="max-width: 200px;"
				:items="onlyForward ? onlyAction : massActions"
				label="Massåtgärd"
			/>
			<v-btn small color="primary" :disabled="!massAction" @click="doMassAction">Kör</v-btn>
		</div>

		<SidebarRight v-if="sidebarOpen && showSidebar" :push-content="pushContent" :width="sidebarWidth" :sidebar-no-footer="sidebarNoFooter"
			@close="close"
		>
			<slot />
			<template v-slot:footer>
				<div class="d-flex justify-space-between">
					<v-btn color="primary" text :disabled="getNavigateToStatus.prevDisabled" @click="navigateTo(getNavigateToStatus.prevIndex)">
						<v-icon left>mdi-chevron-left</v-icon>
						Föregående
					</v-btn>
					<v-btn color="primary" text :disabled="getNavigateToStatus.nextDisabled" @click="navigateTo(getNavigateToStatus.nextIndex)">
						Nästa
						<v-icon right>mdi-chevron-right</v-icon>
					</v-btn>
				</div>
			</template>
		</SidebarRight>
	</div>
</template>

<script>
import SidebarRight from '@/components/sidebar-right';
import {mapGetters} from 'vuex';

const ACTIVE_ITEM_CLASSNAME = 'RegisterList-activeItem';
const BOLD_ITEM_CLASSNAME = 'RegisterList-boldItem';

export default {
	name: 'RegisterList',

	components: {
		SidebarRight
	},

	props: {
		loading: {
			type: Boolean,
			default: false
		},
		itemKey: {
			type: String,
			default: 'id'
		},
		items: {
			type: Array,
			default: () => [],
			required: true
		},
		headers: {
			type: Array,
			default: () => [],
			required: true
		},
		sidebarWidth: {
			type: String,
			default: '50%'
		},
		sidebarNoFooter: {
			type: Boolean,
			default: false
		},
		pagination: {
			type: Object,
			required: false,
			default: () => ({
				noPagination: true
			})
		},
		pushContent: {
			type: Boolean,
			default: true
		},
		showSidebar: {
			type: Boolean,
			default: true
		},
		hideFooter: {
			type: Boolean,
			default: false
		},
		onlyForward: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		sidebarOpen: false,
		activeItem: undefined,
		localPagination: {
			page: null,
			pageSize: null,
			total: null,
			sort: []
		},

		filter: {},

		selectedRows: [],
		massAction: null,
		massActions: [{
			value: 'markAsRead',
			text: 'Markera som läst'
		}, {
			value: 'markAsUnread',
			text: 'Markera som oläst'
		}, {
			value: 'forward',
			text: 'Vidarebefordra'
		}],

		onlyAction: [{
			value: 'forward',
			text: 'Vidarebefordra'
		}]
	}),

	computed: {
		...mapGetters('users', ['getUsers']),

		getHeaders() {
			return this.headers.map(header => {
				if (header.filter) {
					const obj = {
						...header,
						filterObj: header.filter
					};
					// eslint-disable-next-line no-unused-vars
					const {filter, ...newObj} = obj;

					return newObj;

				}

				const obj = {
					...header
				};
					// eslint-disable-next-line no-unused-vars
				const {filter, ...newObj} = obj;

				return newObj;
			});
		},

		getNavigateToStatus() {
			if (!this.activeItem) {
				return;
			}

			const index = this.getItems.findIndex(item => item[this.itemKey] === this.activeItem[this.itemKey]);

			return {
				prevDisabled: index === 0,
				nextDisabled: index === (this.getItems.length - 1),
				prevIndex: index - 1,
				nextIndex: index + 1
			};
		},

		getItems() {
			return this.items.map(item => {
				const className = {
					[ACTIVE_ITEM_CLASSNAME]: this.activeItem && this.activeItem[this.itemKey] === item[this.itemKey],
					[BOLD_ITEM_CLASSNAME]: !item.read
				};

				return {
					...item,
					_className: className
				};
			});
		}
	},

	watch: {
		activeItem(to) {
			this.$emit('active-item-changed', to);
		},

		'pagination.total'(to) {
			this.localPagination.total = to;
		},

		getItems: {
			deep: true,
			handler() {
				this.selectedRows = [];
			}
		},

		filter: {
			deep: true,
			handler(to) {
				const filters = Object.entries(to).reduce((acc, [key, value]) => {
					let headerObj = this.getHeaders.find(header => header.value === key);

					this.errorMessage = '';

					if (Array.isArray(value)) {
						const values = value.map((item, index) => {
							const transformer = headerObj.filterObj  && 'transform' in headerObj.filterObj[index] ? headerObj.filterObj[index].transform : val => val;

							return transformer(item);
						});
						const date0 = new Date(value[0]);
						const date1 = new Date(value[1]);

						if (date0 > date1 && value[1] && value[1].length >= 10) {
							this.errorMessage = 'Datum fr.o.m måste komma efter datum t.o.m.';
						}

						return values.some(value => value !== null) ? { ...acc, [key]: values} : acc;
					}

					const transformer = headerObj.filterObj  && 'transform' in headerObj.filterObj ? headerObj.filterObj.transform : val => val;


					return value !== null ? { ...acc, [key]: transformer(value)} : acc;
				}, {});

				this.$emit('on-filter-update', filters);
			}
		}
	},

	created() {
		this.localPagination = {...this.pagination};
		this.setDefaultValues();
	},

	methods: {
		hasFilters(headers) {
			return headers.some(header => header.filterObj);
		},

		setDefaultValues() {
			this.headers.forEach(header => {
				if (Array.isArray(header.filter)) {
					this.$set(this.filter, header.value, []);

					header.filter.forEach((_, index) => {
						this.$set(this.filter[header.value], index, null);
					});

					return;
				}

				this.$set(this.filter, header.value, null);
			});
		},

		isDueDate(date) {
			const offset = 604800000; // 1 week
			const target = date.getTime();
			const now = new Date().getTime();

			if ((target - offset) < now) {
				return true;
			}

			return false;
		},

		getUser(id) {
			if (!id) {
				return '';
			}

			const u = this.getUsers.find(x => x.id === id);

			return u.name;
		},

		open(item) {
			this.activeItem = item;
			this.sidebarOpen = true;
		},

		close() {
			this.activeItem = undefined;
			this.sidebarOpen = false;
		},

		onUpdateSort(value) {
			this.localPagination.sortBy = value;

			this.$emit('pagination-update', this.localPagination);
		},

		onUpdateSortDesc(value) {
			this.localPagination.sortDesc = value;

			this.$emit('pagination-update', this.localPagination);
		},

		onUpdateItemsPerPage(value) {
			this.localPagination.pageSize = value;

			this.$emit('pagination-update', this.localPagination);
		},

		onUpdatePage(value) {
			this.localPagination.page = value;

			this.$emit('pagination-update', this.localPagination);
		},

		navigateTo(index) {
			this.activeItem = this.getItems[index];
		},

		doMassAction() {
			this.$emit('mass-action', {
				items: this.selectedRows,
				type: this.massAction
			});
		}
	}
};
</script>

<style lang="scss">
	.RegisterList-activeItem td {
		color: var(--v-accent-base);

		.RegisterList-Filter-Row td {
			border-bottom: 1px solid #e0e0e0;
		}
	}

	.RegisterList-boldItem td {
		font-weight: bold;
	}

	.RegisterList tbody tr {
		cursor: pointer;
	}

	.MassActionSelect {
		.v-text-field__details {
			display: none !important;
		}

		.v-input__slot {
			margin: 0 !important;
		}
	}
</style>
