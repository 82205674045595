import * as rules from '../rules';

export default {
	username: () => ([
		rules.required()
	]),

	password: () => ([
		rules.required()
	])
};
